import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import { ACTIVITY_TOOL_TYPE, ACTIVITY_TYPE } from "../../constants/GenericConstants";
import { getAllActivities, archiveActivity, saveCurrentPage, clearCurrentPage } from "../../store/actions/Activities";
import Loader from '../../components/Loader/Loader';
import Pagination from "../Pagination/Pagination";
import { allTrue, arrayOfGivenElement, atleastOneTrue, formatDate, parentAddedEntity, formatTimerNumber } from "../../helpers/GenericHelper";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import UserCell from "../UserCellListing/UserCell";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";

const ChildActivities = ({ editAct }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const childState = useSelector((state) => state.child);
    const activitiesState = useSelector((state) => state.activities);

    const [selectedCategory, setSelectedCategory] = useState(ACTIVITY_TOOL_TYPE.ACTIVE);

    const [allChecked, setAllChecked] = useState(false);
    const [checkboxesStatus, setCheckboxesStatus] = useState([]);
    const [activitiesData, setActivitiesData] = useState([]);

    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [activityArchiveIndex, setActivityArchiveIndex] = useState(-1);
    const [activityArchiveSuccess, setActivityArchiveSuccess] = useState(false);
    const [multiArchiveDialogOpen, setMultiArchiveDialogOpen] = useState(false);
    const [activityArchiveMultiIndex, setActivityArchiveMultiIndex] = useState([]);
    const [activityMultiArchiveSuccess, setActivityMultiArchiveSuccess] = useState(false);
    const [areMultiple, setAreMultiple] = useState(false);

    const onSuccessFetch = (data) => {
        setCheckboxesStatus(arrayOfGivenElement(data.length, false));
        setAllChecked(false);
        setActivitiesData(data);
    }

    const getActivityType = () => {
        return selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? ACTIVITY_TYPE.ACTIVE : ACTIVITY_TYPE.ARCHIVED;
    }

    useEffect(() => {
        console.log('useEffect called from ChildActivities');
        if (Object.keys(activitiesState.listingCurrentPageData).length > 0) {
            const { category, page } = activitiesState.listingCurrentPageData;
            setSelectedCategory(category);
            const apiCat = category === ACTIVITY_TOOL_TYPE.ACTIVE ? ACTIVITY_TYPE.ACTIVE : ACTIVITY_TYPE.ARCHIVED;
            dispatch(getAllActivities(childState.selectedChild.childId, page, apiCat, onSuccessFetch));
            dispatch(clearCurrentPage());
        } else {
            dispatch(getAllActivities(childState.selectedChild.childId, 1, getActivityType(), onSuccessFetch));
        }
    }, []);

    const fetchData = (page) => {
        dispatch(getAllActivities(childState.selectedChild.childId, page, getActivityType(), onSuccessFetch));
    }

    const handleAllCheckedChange = (event) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        setAllChecked(input);
        setCheckboxesStatus(arrayOfGivenElement(checkboxesStatus.length, input));
    }

    const handleCheckboxChange = (event, index) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        const checkedStatus = [...checkboxesStatus];
        checkedStatus[index] = input;
        setCheckboxesStatus(checkedStatus);
        if (allTrue(checkedStatus)) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    }

    const openArchiveDialog = (index) => {
        setActivityArchiveIndex(index);
        setArchiveDialogOpen(true);
    }

    const getActivitiesRows = (activities) => {
        return activities.map((activity, index) => (
            <TableRow key={index} className={checkboxesStatus[index] && 'row-highlighted'}>
                <TableCell><Checkbox checked={checkboxesStatus[index]} onChange={(event) => handleCheckboxChange(event, index)} /></TableCell>
                <TableCell onClick={() => moveToEditActivityScreen(activity.id)}>
                    <div className="table-profile">
                        <figure>
                            <img alt="Activity Image" src={activity.imageURL} />
                        </figure>
                        {activity.name}
                    </div>
                </TableCell>
                <TableCell align="center" onClick={() => moveToEditActivityScreen(activity.id)}>{formatTimerNumber(activity.subTasksCount)}</TableCell>
                <TableCell align="center" onClick={() => moveToEditActivityScreen(activity.id)}>{formatDate(activity.createdAt)}</TableCell>
                <TableCell onClick={() => moveToEditActivityScreen(activity.id)}>
                    <UserCell entity={activity} />
                </TableCell>
                <TableCell align="center">
                    {!(allChecked || atleastOneTrue(checkboxesStatus)) && (
                        selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ?
                            <span className={editAct ? "archiveIcon" : "disabled-icon archiveIcon"}><ArchiveOutlinedIcon onClick={() => editAct && openArchiveDialog(index)} /></span> :
                            <span className={editAct ? "archiveIcon" : "disabled-icon archiveIcon"}><UnarchiveOutlinedIcon onClick={() => editAct && openArchiveDialog(index)} /></span>
                    )}
                </TableCell>
            </TableRow>
        ));
    }

    const savePageAndCategory = () => {
        const data = {};
        if (selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE) {
            data.category = ACTIVITY_TOOL_TYPE.ACTIVE;
        } else {
            data.category = ACTIVITY_TOOL_TYPE.ARCHIVED;
        }
        data.page = activitiesState.activityListMeta.page;
        dispatch(saveCurrentPage(data));
    }

    const moveToEditActivityScreen = (id) => {
        savePageAndCategory();
        history.push({ pathname: RouteConstants.EDIT_ACTIVITY.replace(':childId', childState.selectedChild.childId).replace(':activityId', id) });
    }

    const moveToAddActivityScreen = () => {
        history.push({ pathname: RouteConstants.ADD_ACTIVITY.replace(':childId', childState.selectedChild.childId) });
    }

    const handleArchiveDialogClose = () => {
        setArchiveDialogOpen(false);
    }

    const onSuccessFetchAfterArchive = (data) => {
        onSuccessFetch(data);
        setActivityArchiveSuccess(true);
    }

    const onSuccessArchiveOne = () => {
         //mixpanel
         Mixpanel.track(MIX_PANEL_EVENTS.ACTIVITY_ARCHIVED);

         Mixpanel.increment({
             [MIX_PANEL_EVENTS.ACTIVITY_ARCHIVE_COUNT]: activitiesState.length,
         });
         
        dispatch(getAllActivities(childState.selectedChild.childId, activitiesState.activityListMeta.page, getActivityType(), (data) => onSuccessFetchAfterArchive(data)));
    }

    const onFailureArchiveOne = (error) => {
        console.log(error);
    }

    const archiveOne = () => {
        let array = [];
        array = [
            {
                activityId: activitiesData[activityArchiveIndex].id,
                childId: childState.selectedChild.childId,
                isArchive: selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? true : false,
            }
        ];

        const payload = {
            archiveActivity: array,
        };

        setArchiveDialogOpen(false);
        dispatch(archiveActivity(payload, onSuccessArchiveOne, onFailureArchiveOne));
    }

    const handleActivityArchiveSuccessClose = () => {
        setActivityArchiveSuccess(false);
    }

    const handleActivityMultiArchiveSuccessClose = () => {
        setActivityMultiArchiveSuccess(false);
    }

    const openMultiArchiveDialog = () => {
        const indices = [];
        for (let i = 0; i < checkboxesStatus.length; i++) {
            if (checkboxesStatus[i]) {
                indices.push(i);
            }
        }
        setActivityArchiveMultiIndex(indices);
        setMultiArchiveDialogOpen(true);
    }

    const handleMultiArchiveDialogClose = () => {
        setMultiArchiveDialogOpen(false);
    }

    const onSuccessFetchAfterMultiArchive = (data) => {
        onSuccessFetch(data);
        setActivityMultiArchiveSuccess(true);
    }

    const onSuccessArchiveMultiple = () => {
        //mixpanel
        Mixpanel.track(MIX_PANEL_EVENTS.ACTIVITY_ARCHIVED);

        Mixpanel.increment({
            [MIX_PANEL_EVENTS.ACTIVITY_ARCHIVE_COUNT]: activitiesState.length,
        });

        dispatch(getAllActivities(childState.selectedChild.childId, activitiesState.activityListMeta.page, getActivityType(), (data) => onSuccessFetchAfterMultiArchive(data)));
    }

    const onFailureArchiveMultiple = (error) => {
        console.log(error);
    }

    const archiveMultiple = () => {
        let array = [];
        for (let i = 0; i < activityArchiveMultiIndex.length; i++) {
            const index = activityArchiveMultiIndex[i];
            const obj = {
                activityId: activitiesData[index].id,
                childId: childState.selectedChild.childId,
                isArchive: selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? true : false,
            };
            array.push(obj);
        }
        if (array.length > 1) {
            setAreMultiple(true);
        } else {
            setAreMultiple(false);
        }

        const payload = {
            archiveActivity: array,
        };

        setMultiArchiveDialogOpen(false);
        dispatch(archiveActivity(payload, onSuccessArchiveMultiple, onFailureArchiveMultiple));
    }

    const handleCategorySelection = (type) => {
        if (type === selectedCategory) return;
        setSelectedCategory(type);
        let actType = type === ACTIVITY_TOOL_TYPE.ACTIVE ? ACTIVITY_TYPE.ACTIVE : ACTIVITY_TYPE.ARCHIVED;
        dispatch(getAllActivities(childState.selectedChild.childId, 1, actType, onSuccessFetch));
    }

    return (
        <div className="generic-card">
            {activitiesState.loading && <Loader />}
            <SnackbarAlert
                show={activityArchiveSuccess}
                message={selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("activityArchived") : t("activityUnArchived")}
                duration={5000}
                onCloseAlert={handleActivityArchiveSuccessClose} />

            <SnackbarAlert
                show={activityMultiArchiveSuccess}
                message={selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? (areMultiple ? t("activitiesArchived") : t("activityArchived")) : (areMultiple ? t("activitiesUnArchived") : t("activityUnArchived"))}
                duration={5000}
                onCloseAlert={handleActivityMultiArchiveSuccessClose} />

            <Dialog maxWidth="xs" open={archiveDialogOpen} onClose={handleArchiveDialogClose} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleArchiveDialogClose} />
                <DialogTitle id="alert-dialog-title">{selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("archiveActivityConfirmation") : t("unarchiveActivityConfirmation")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleArchiveDialogClose} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => archiveOne()} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="xs" open={multiArchiveDialogOpen} onClose={handleMultiArchiveDialogClose} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleMultiArchiveDialogClose} />
                <DialogTitle id="alert-dialog-title">{selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("multiArchiveActivityConfirmation") : t("multiUnArchiveActivityConfirmation")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleMultiArchiveDialogClose} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => archiveMultiple()} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>
            <Card className="no-style">

                <div className="jc-space-between-v-middle mb-15">
                    <h1 className="m-0">{t("activityList")}</h1>
                    <Button variant="contained" className={!editAct ? 'Mui-disabled btn-rounded' : "btn-rounded"} disabled={!editAct} color="primary" size="large" onClick={moveToAddActivityScreen}>
                        <i className="ticon-plus"></i>
                        {t("createNewActivity")}
                    </Button>
                </div>
                {
                    (allChecked || atleastOneTrue(checkboxesStatus)) ?
                        <div className="archive-Selection">
                            <Button variant="contained" className={!editAct ? 'Mui-disabled btn-rounded' : "btn-rounded"} disabled={!editAct} onClick={openMultiArchiveDialog}>
                                {selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ?
                                    <div>
                                        <ArchiveIcon />
                                        {t("archiveSelected")}
                                    </div> :
                                    <div>
                                        <UnarchiveIcon />
                                        {t("unarchiveSelected")}
                                    </div>
                                }
                            </Button>
                        </div> :
                        <div className="buttons-head">
                            <span className={selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? "active" : "inactive"} onClick={() => handleCategorySelection(ACTIVITY_TOOL_TYPE.ACTIVE)}>{t("active")}</span>
                            <span className={selectedCategory === ACTIVITY_TOOL_TYPE.ARCHIVED ? "active" : "inactive"} onClick={() => handleCategorySelection(ACTIVITY_TOOL_TYPE.ARCHIVED)}>{t("archived")}</span>
                        </div>
                }
                <div className="viewActivityListing">
                    {
                        activitiesData.length > 0 ?
                            <div>
                                <TableContainer className="table-container">
                                    <Table aria-label="simple table" className="has-profile-photo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Checkbox checked={allChecked} onChange={handleAllCheckedChange} /></TableCell>
                                                <TableCell>{t("activityName")}</TableCell>
                                                <TableCell align="center">{t("subTask")}</TableCell>
                                                <TableCell align="center">{t("dateCreated")}</TableCell>
                                                <TableCell>{t("createdBy")}</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getActivitiesRows(activitiesData)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="pagination">
                                    <Pagination pageCount={activitiesState.activityListMeta.pageCount} current={activitiesState.activityListMeta.page} fetch={fetchData} />
                                </div>
                            </div> :
                            !activitiesState.loading &&

                            <div className="noActivityListing">
                                <img src={AssetConstants.emptylist} />
                                <span>{selectedCategory === ACTIVITY_TOOL_TYPE.ACTIVE ? t("emptyActivitiesListMessage") : t("emptyArchivedActivitiesListMessage")}</span>
                            </div>
                    }
                </div>
            </Card>
        </div>
    );
};

export default ChildActivities;