import React from "react";
import { Route } from "react-router-dom";

export const PaymentRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return <Component {...props} {...rest} />;
            }}
        />
    );
};