import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Card, InputLabel, Input, FormHelperText, Select, MenuItem, Button, Container, Dialog, DialogTitle, DialogContent, Snackbar, DialogContentText, DialogActions } from "@material-ui/core";
import { validateEmail, validateMandatoryValue, validateMaximumLength } from "../../../helpers/Validation";
import { getMonthsDays, differenceYears } from "../../../helpers/GenericHelper";
import { APP_CONSTANTS, GENDER } from "../../../constants/GenericConstants";
import ChildStepper from "../ChildStepper/ChildStepper";
import { getPresignedURL, uploadImage } from "../../../store/actions/Child";
import CropperComponent from "../../../components/CropperComponent/CropperComponent";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { getGenderList } from "../../../store/actions/Authentication";

const useStyles = makeStyles((theme) => ({
    select: {
        "& ul": {
            height: "181px",
            paddingTop: 0,
        },
    },
}));

const StepOne = (props) => {

    const classes = useStyles();
    const userState = useSelector((state) => state.authentication);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { step, data, setData, errors, setErrors, onCancel, onSubmit, changeStep } = props;
    const { firstName, lastName, month, date, year, age, email, gender, childImage } = data;
    const { firstNameError, lastNameError, dateOfBirthError, ageError, emailError, genderError, childImageError } = errors;

    const monthsDays = getMonthsDays();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [totalDays, setTotalDays] = useState(month.length > 0 ? monthsDays[month].days : false);

    const [showAddImageDialog, setShowAddImageDialog] = useState(false);
    const [compressing, setCompressing] = useState(false);

    useEffect(() => {
        dispatch(getGenderList());
    }, []);

    const handleCloseDialog = () => {
        setData({ ...data, childImage: {} });
        setShowAddImageDialog(false);
    }

    const handleFirstNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, firstName: input });
        validateFirstName(input);
    }

    const handleLastNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, lastName: input });
        validateLastName(input);
    }

    const handleMonthChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setTotalDays(monthsDays[input].days);
        let ageInYears = calculateAge(input, date, year);
        if (ageInYears) {
            setData({ ...data, month: input, age: ageInYears });
        } else {
            setData({ ...data, month: input });
        }
        if (formSubmitted) {
            validateDateOfBirth(input, date, year, ageInYears);
        }
    }

    const getDateOptions = () => {
        let dateArray = [];
        for (let i = 1; i <= totalDays; i++) {
            dateArray.push(<MenuItem key={i} value={i.toString()}>{i}</MenuItem>);
        }
        return dateArray;
    }

    const handleDateChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        let ageInYears = calculateAge(month, input, year);
        if (ageInYears) {
            setData({ ...data, date: input, age: ageInYears });
        } else {
            setData({ ...data, date: input });
        }
        if (formSubmitted) {
            validateDateOfBirth(month, input, year, ageInYears);
        }
    }

    const getYearOptions = () => {
        let yearArray = [];
        const year = new Date().getFullYear();
        const minYear = 1970;
        // const minYear = year - APP_CONSTANTS.MAX_CHILD_AGE;
        const maxYear = year - APP_CONSTANTS.MIN_CHILD_AGE;
        
        for (let i = maxYear; i >= minYear; i--) {
            yearArray.push(<MenuItem key={i} value={i.toString()}>{i}</MenuItem>);
        }
        return yearArray;
    }

    const handleYearChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        let ageInYears = calculateAge(month, date, input);
        if (ageInYears) {
            setData({ ...data, year: input, age: ageInYears });
        } else {
            setData({ ...data, year: input });
        }
        if (formSubmitted) {
            validateDateOfBirth(month, date, input, ageInYears);
        }
    }

    const calculateAge = (month, date, year) => {
        if (!validateMandatoryValue(month) || !validateMandatoryValue(date) || !validateMandatoryValue(year)) {
            return;
        }
        const currentDate = new Date();
        const inputDate = new Date(year, monthsDays[month].order, date);
        let ageInYears = differenceYears(inputDate, new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
        return ageInYears;
    }

    const handleEmailChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, email: input });
        validateEmailAddress(input);
    }

    const setGender = (gender) => {
        setData({ ...data, gender: gender });
        validateGender(gender);
    }

    const handleImageUpload = (event) => {
        event.preventDefault();
        let imageObject = null;
        let fileObject = null;

        if (event.target.files.length > 0) {
            fileObject = event.target.files[0];
            let imgSize = Math.round((fileObject.size / 1024 / 1024) * 10) / 10;
            // if (imgSize <= 5) {

            let fileName = fileObject.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                setCompressing(true)

                const fileURL = URL.createObjectURL(fileObject);
                imageObject = new Image();
                imageObject.src = fileURL;
                imageObject.onload = () => {
                    setCompressing(false)
                }
                setData({ ...data, childImage: { file: fileObject, image: imageObject } });
                setShowAddImageDialog(true)
                setErrors({ ...errors, childImageError: "" });

            }
            else {
                setData({ ...data, childImage: {} });
                setErrors({ ...errors, childImageError: t("imageTypeError") });
            }


            // }
            // else {
            //     setData({ ...data, childImage: {} });
            //     setErrors({ ...errors, childImageError: t("imageSizeError") });
            // }

        }
        event.target.value = null;
        // validateImage({ file: fileObject, image: imageObject });
    }

    const handleImageRemoval = () => {
        setData({ ...data, childImage: {} });
        validateImage({});
    }

    const validateFirstName = (firstName) => {
        if (!validateMandatoryValue(firstName)) {
            setErrors({ ...errors, firstNameError: t("firstNameRequired") });
        } else if (!validateMaximumLength(firstName, APP_CONSTANTS.MAX_NAMES_LENGTH)) {
            setErrors({ ...errors, firstNameError: t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_NAMES_LENGTH) });
        } else {
            setErrors({ ...errors, firstNameError: "" });
        }
    }

    const validateLastName = (lastName) => {
        if (!validateMandatoryValue(lastName)) {
            setErrors({ ...errors, lastNameError: t("lastNameRequired") });
        } else if (!validateMaximumLength(lastName, APP_CONSTANTS.MAX_NAMES_LENGTH)) {
            setErrors({ ...errors, lastNameError: t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_NAMES_LENGTH) });
        } else {
            setErrors({ ...errors, lastNameError: "" });
        }
    }

    const validateDateOfBirth = (month, date, year, age) => {
        if (!validateMandatoryValue(month) || !validateMandatoryValue(date) || !validateMandatoryValue(year)) {
            setErrors({ ...errors, dateOfBirthError: t("incompleteDateOfBirth"), ageError: t("ageRequired") });
        } else if (age < 3) {
            setErrors({ ...errors, dateOfBirthError: "", ageError: t("minAgeError").replace(/{minAge}/, APP_CONSTANTS.MIN_CHILD_AGE) });
        } else {
            setErrors({ ...errors, dateOfBirthError: "", ageError: "" });
        }
    }

    const validateEmailAddress = (email) => {
        if (validateMandatoryValue(email) && !validateEmail(email)) {
            setErrors({ ...errors, emailError: t("invalidEmailError") });
        } else {
            setErrors({ ...errors, emailError: "" });
        }
    }

    const validateGender = (gender) => {
        if (!validateMandatoryValue(gender)) {
            setErrors({ ...errors, genderError: t("genderRequired") });
        } else {
            setErrors({ ...errors, genderError: "" });
        }
    }

    const validateImage = (childImage) => {
        if (!childImage.image) {
            setErrors({ ...errors, childImageError: t("imageRequiredError") });
        } else {
            setErrors({ ...errors, childImageError: "" });
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const showImageError = (field) => {
        return field.length > 0;
    }

    const isFormValid = () => {
        let localFirstNameError = "";
        let localLastNameError = "";
        let localDateOfBirthError = "";
        let localAgeError = "";
        let localEmailError = "";
        let localGenderError = "";
        let localImageError = "";
        let valid = true;

        if (!validateMandatoryValue(firstName)) {
            localFirstNameError = t("firstNameRequired");
            valid = false;
        } else if (!validateMaximumLength(firstName, APP_CONSTANTS.MAX_NAMES_LENGTH)) {
            localFirstNameError = t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_NAMES_LENGTH);
            valid = false;
        }

        if (!validateMandatoryValue(lastName)) {
            localLastNameError = t("lastNameRequired");
            valid = false;
        } else if (!validateMaximumLength(lastName, APP_CONSTANTS.MAX_NAMES_LENGTH)) {
            localLastNameError = t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_NAMES_LENGTH);
            valid = false;
        }

        if (!validateMandatoryValue(month) || !validateMandatoryValue(date) || !validateMandatoryValue(year)) {
            localDateOfBirthError = t("incompleteDateOfBirth");
            valid = false;
        }
        if (!validateMandatoryValue(age)) {
            localAgeError = t("ageRequired");
            valid = false;
        } else if (age < 3) {
            localAgeError = t("minAgeError").replace(/{minAge}/, APP_CONSTANTS.MIN_CHILD_AGE);
            valid = false;
        }
        if (!validateMandatoryValue(email) || !validateEmail(email)) {
            localEmailError = t("invalidEmailError");
            valid = false;
        }
        if (!validateMandatoryValue(gender)) {
            localGenderError = t("genderRequired");
            valid = false;
        }
        if (!childImage.image) {
            localImageError = t("imageRequiredError");
            valid = false;
        }
        setErrors({
            ...errors,
            firstNameError: localFirstNameError,
            lastNameError: localLastNameError,
            dateOfBirthError: localDateOfBirthError,
            ageError: localAgeError,
            emailError: localEmailError,
            genderError: localGenderError,
            childImageError: localImageError,
        });
        return valid;
    }

    const onCancelPress = () => {
        onCancel();
    }

    const onSubmitPress = () => {
        setFormSubmitted(true);

        if (!isFormValid()) {
            return;
        }
        onSubmit();
    }


    const goToOtherStep = (index) => {
        setFormSubmitted(true);
        if (!isFormValid()) {
            return;
        }
        changeStep(index);
    }

    const onImageSave = (img) => {
        const fileURL = URL.createObjectURL(img);
        let imageObject = new Image();
        imageObject.src = fileURL;

        setData({ ...data, childImage: { file: img, image: imageObject } });
        setErrors({ ...errors, childImageError: "" });

        setShowAddImageDialog(false);

    }

    const getGenderDropdownList = () => {
        return userState.genderList.map((gender, index) => {
            return <MenuItem key={index} value={gender.name}>{gender.label}</MenuItem>;
        });
    }

    const handleGenderChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setGender(input);
    }

    return (
        <div>

            {compressing && <Loader />}

            <Dialog className="AddPopup-Box" disableBackdropClick disableEscapeKeyDown maxWidth="md" open={showAddImageDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">{t("addNewImage")}</DialogTitle>
                <DialogContent>
                    <CropperComponent addedImage={childImage} closeDialog={handleCloseDialog} onSave={onImageSave} compressing={compressing} setCompressing={setCompressing} />
                </DialogContent>
            </Dialog>

            <Card className="add-child-flow">
                <ChildStepper activeStep={step} goToOtherStep={goToOtherStep} />
                <form className="py-15">
                    <Container maxWidth="false">
                        <Grid container spacing={3}>
                            <Grid item md={8} xs={12}>
                                <Grid container spacing={2} className="pb-16">
                                    <Grid item sm={6} xs={12}>
                                        <div className="form-field">
                                            <InputLabel htmlFor="firstName" required>{t("firstName")}</InputLabel>
                                            <Input id="firstName" name="firstName" required type="text" value={firstName || ""} placeholder={t("firstNamePlaceholder")} onChange={handleFirstNameChange} error={showInputError(firstNameError)} />
                                            {
                                                showInputError(firstNameError) && <FormHelperText error={showInputError(firstNameError)}>{firstNameError}</FormHelperText>
                                            }
                                        </div>
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <div className="form-field">
                                            <InputLabel htmlFor="lastName" required>{t("lastName")}</InputLabel>
                                            <Input id="lastName" name="lastName" required type="text" value={lastName || ""} placeholder={t("lastNamePlaceholder")} onChange={handleLastNameChange} error={showInputError(lastNameError)} />
                                            {
                                                showInputError(lastNameError) && <FormHelperText error={showInputError(lastNameError)}>{lastNameError}</FormHelperText>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>


                                <div className="form-field pb-16">
                                    <InputLabel required>{t("dateOfBirth")}</InputLabel>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} sm={4} xs={12}>
                                            <Select id="monthSelect" name="monthSelect" value={month} onChange={handleMonthChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                                                <MenuItem value="" disabled>{t("month")}</MenuItem>
                                                <MenuItem key="january" value="january">{t("january")}</MenuItem>
                                                <MenuItem key="february" value="february" > {t("february")}</MenuItem>
                                                <MenuItem key="march" value="march">{t("march")}</MenuItem>
                                                <MenuItem key="april" value="april">{t("april")}</MenuItem>
                                                <MenuItem key="may" value="may">{t("may")}</MenuItem>
                                                <MenuItem key="june" value="june">{t("june")}</MenuItem>
                                                <MenuItem key="july" value="july">{t("july")}</MenuItem>
                                                <MenuItem key="august" value="august">{t("august")}</MenuItem>
                                                <MenuItem key="september" value="september">{t("september")}</MenuItem>
                                                <MenuItem key="october" value="october">{t("october")}</MenuItem>
                                                <MenuItem key="november" value="november">{t("november")}</MenuItem>
                                                <MenuItem key="december" value="december">{t("december")}</MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item md={6} sm={4} xs={12}>
                                            <Select id="dateSelect" name="dateSelect" value={date} onChange={handleDateChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                                                <MenuItem value="" disabled>{t("date")}</MenuItem>
                                                {getDateOptions()}
                                            </Select>
                                        </Grid>

                                        <Grid item md={3} sm={4} xs={12}>
                                            <Select id="yearSelect" name="yearSelect" value={year} onChange={handleYearChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                                                <MenuItem value="" disabled>{t("year")}</MenuItem>
                                                {getYearOptions()}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    {
                                        showInputError(dateOfBirthError) && <FormHelperText error={showInputError(dateOfBirthError)}>{dateOfBirthError}</FormHelperText>
                                    }
                                </div>

                                <Grid container spacing={2} className="pb-16">
                                    <Grid item sm={6} xs={12}>
                                        <div className="form-field">
                                            <InputLabel htmlFor="age" required>{t("age")}</InputLabel>
                                            <Input id="age" name="age" required type="text" value={age || ""} error={showInputError(ageError)} disabled />
                                            {
                                                showInputError(ageError) && <FormHelperText error={showInputError(ageError)}>{ageError}</FormHelperText>
                                            }
                                        </div>
                                    </Grid>


                                    <Grid item sm={6} xs={12}>
                                        <div className="form-field">
                                            <InputLabel htmlFor="email" required>{t("email")}</InputLabel>
                                            <Input id="email" name="email" type="email" value={email || ""} placeholder={t("emailPlaceholder")} onChange={handleEmailChange} error={showInputError(emailError)} required/>
                                            {
                                                showInputError(emailError) && <FormHelperText error={showInputError(emailError)}>{emailError}</FormHelperText>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="gender" required>{t("gender")}</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            id="gender" name="gender" value={gender} onChange={handleGenderChange} >
                                            {userState.genderList.length === 0 ? <MenuItem value="" disabled>{t("loading")}</MenuItem> : getGenderDropdownList()}
                                        </Select>
                                        {
                                            showInputError(genderError) && <FormHelperText error={showInputError(genderError)}>{genderError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>


                            </Grid>

                            <Grid item md={4} xs={12}>
                                <div className="profile-image-section">
                                    <figure>
                                        {
                                            childImage.image ? <img alt="Child Profile Picture" src={childImage.image.src} /> : <img alt="Child Profile Picture" src="https://i.stack.imgur.com/l60Hf.png" />
                                        }

                                    </figure>
                                    {/* <span className="child-photo-limit">{t("photoSizeLimit").replace(/{size}/, APP_CONSTANTS.PHOTO_SIZE_LIMIT)}</span> */}
                                    <br></br>
                                    {showImageError(childImageError) && <FormHelperText error={showImageError(childImageError)}>{childImageError}</FormHelperText>}
                                    <div className="upload-photo">
                                        <Input id="childImage" name="childImage" type="file" inputProps={{ accept: 'image/png, image/jpeg' }} onChange={handleImageUpload} />
                                        <Button variant="contained" color="primary" disableElevation>{t("uploadPhoto")}</Button>
                                    </div>
                                    <Button variant="contained" disableElevation onClick={handleImageRemoval}>{t("removePhoto")}</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </Card>
            <div className="add-child-form-footer">
                <Button variant="contained" disableElevation onClick={onCancelPress}>{t("goToDashboard")}</Button>
                <Button color="primary" variant="contained" disableElevation onClick={onSubmitPress}>{t("continue")}</Button>
            </div>
        </div >
    );
};

export default StepOne;