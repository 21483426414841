import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Chip,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddIcon from "@material-ui/icons/Add";
import Badge from "@material-ui/core/Badge";
import { GroupSidebarItem } from "./components/groups/GroupSidebarItem";
import CreateGroupModal from "./components/modals/CreateGroupModal";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#02C8A7",
    color: "#02C8A7",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    position: "absolute",
    bottom: 0,
    right: 0,
    // "&::after": {
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   width: "100%",
    //   height: "100%",
    //   borderRadius: "50%",
    //   animation: "$ripple 1.2s infinite ease-in-out",
    //   border: "1px solid currentColor",
    //   content: '""',
    // },
  },
  // "@keyframes ripple": {
  //   "0%": {
  //     transform: "scale(.8)",
  //     opacity: 1,
  //   },
  //   "100%": {
  //     transform: "scale(2.4)",
  //     opacity: 0,
  //   },
  // },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "270px",
    backgroundColor: "#FFF",
    flex: "0 0 auto",
  },

  sidebarHeader: {
    height: "90px",
    padding: "10px 30px",
    boxSizing: "borderBox",
    flexShrink: 0,
    borderBottom: "1px solid #429494925",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },

  conversationSearchBar: {
    // background-color: ${({ theme }) => theme.input.backgroundColor};
    // color: ${({ theme }) => theme.input.color};
    width: "100%",
    // padding: "10px 16px",
    border: "none",
    outline: "none",
    fontSize: "14px",
    borderRadius: "5px",
    boxSizing: "borderBox",
  },

  scrollableContainer: {
    flex: "1 1 auto",
    overflowY: "auto",
    minHeight: 0,
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const ConversationSidebar = () => {
  const classes = useStyles();

  const groups = useSelector((state) => state.groups.groups);
  const conversationType = useSelector(
    (state) => state.selectedConversationType.type
  );

  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(null);

  const dispatch = useDispatch();

  const MAX_TITLE_LENGTH = 20;
  const MAX_MESSAGE_LENGTH = 50;

  const getTransformedTitle = (group) => {
    if (!group.title) {
      const usersToString = group?.users
        ?.map((user) => user.firstName)
        .join(", ");
      return usersToString.length > MAX_TITLE_LENGTH
        ? usersToString.slice(0, MAX_TITLE_LENGTH).concat("...")
        : usersToString;
    }
    return group.title.length > MAX_TITLE_LENGTH
      ? group.title.slice(0, MAX_TITLE_LENGTH).concat("...")
      : group.title;
  };

  return (
    <>
      {/* {showModal && conversationType === "private" && (
        <CreateConversationModal setShowModal={setShowModal} />
      )} */}

      {showModal && conversationType === "group" && (
        <CreateGroupModal open={showModal} setOpen={setShowModal} />
      )}

      <Card className="no-style">
        <Box className={classes.sidebar}>
          <Box className={classes.sidebarHeader}>
            <Input
              className={classes.conversationSearchBar}
              placeholder="Search conversation"
              fullWidth
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>

          <Box className={classes.scrollableContainer}>
            <Box>
              {/* Conversations */}
              <List>
                <ListItem>
                  <ListItemIcon button onClick={() => setOpen("private")}>
                    {open === "private" ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Chat" />
                  <AddIcon />
                </ListItem>

                <Collapse in={open === "private"} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button>
                      <ListItemAvatar>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                          />
                        </StyledBadge>
                      </ListItemAvatar>
                      <ListItemText
                        id={"labelId"}
                        primary={`Line item`}
                        secondary={"Secondary text"}
                      />

                      <ListItemSecondaryAction>
                        <Chip
                          size="small"
                          label="2"
                          style={{
                            backgroundColor: "#02C8A7",
                            color: "#FFF",
                          }}
                        />
                        <Typography component={"h6"} variant="caption">
                          12:2
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              {/* GROUPS */}
              <List>
                <ListItem>
                  <ListItemIcon button onClick={() => setOpen("group")}>
                    {open === "group" ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Groups" />
                  <AddIcon />
                </ListItem>

                {groups.map((group) => {
                  return (
                    <Collapse
                      key={group.id}
                      in={open === "group"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <GroupSidebarItem group={group} />
                    </Collapse>
                  );
                })}
              </List>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};
