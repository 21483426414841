import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch, useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Grid, Container, makeStyles, Box } from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import { useSelector, useDispatch } from "react-redux";
import ChatUsersList from "../../components/ChatUsersList/ChatUsersList";
import Loader from "../../components/Loader/Loader";
import { SocketContext } from "../../context/SocketContext";
import ChatModal from "../../components/Chat/ChatModal";
import { getExternalUsers } from "../../store/actions/ExternalUser";
import { PrivatePaymentValidRoute } from "../../components/Routes/PrivatePaymentValidRoute";
import { ConversationChannelPage } from "./ConversationChannelPage";
import { addMessage } from "../../store/actions/Chat/Messages";
import {
  addConversation,
  fetchConversations,
  filteredConversations,
  markedAsReadConversation,
  removeConversation,
  updateConversation,
} from "../../store/actions/Chat/Conversations";
import GroupChannelPage from "./group/GroupChannelPage";
import { SelectedConversationPage } from "./SelectConversationPage";
import {
  addGroup,
  fetchAllGroups,
  filteredGroups,
  markedAsReadGroup,
  removeGroup,
  updateGroup,
} from "../../store/actions/Chat/Group";
import { addGroupMessage } from "../../store/actions/Chat/GroupMessages";
import { UpdateGroupAction } from "../../store/reducers/Chat/Groups";
import { setSearchFilter } from "../../store/actions/Chat/Filter";
import { getIdFromUrl } from "../../helpers/GenericHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
  },
  wrapper: {
    // flexGrow: 1,
    height: "100vh",
  },

  chatWrapper: {
    height: "calc(100% - 77px)",
    maxWidth: "85%",
    margin: "auto",
    padding: "41px 0px",

    position: "relative",
    top: 0,
    display: "flex",
    width: "100%",
    overflow: "hidden",
  },

  chatSidebar: {
    // display: "flex",
    // flexDirection: "column",
    // zIndex: 101,
    // overflow: "visible",
    // flex: "0 0 23%",
    // maxWidth: "23%",
  },

  chatContainer: {
    display: "flex",
    width: "100%",
    paddingBottom: "6px",
    gap: "10px",
    borderRadius: "8px",
  },
  mainContainer: {
    display: "flex",
  },
}));

const ChatScreen = (props) => {
  document.title = "Thrive App - Chat";

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { socket } = useContext(SocketContext);

  const [inputValue, setInputValue] = useState("");

  const chatState = useSelector((state) => state.chat);

  const conversations = useSelector(filteredConversations);
  const groups = useSelector(filteredGroups);

  const conversationType = useSelector(
    (state) => state.selectedConversationType.type
  );
  const isConversationLoading = useSelector((state) => state.messages.loading);
  const isGroupMessagesLoading = useSelector(
    (state) => state.groupMessages.loading
  );

  const [showNewMessageModal, setShowNewMessageModal] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    dispatch(fetchConversations());
    dispatch(fetchAllGroups());
    dispatch(getExternalUsers());
  }, [dispatch]);

  useEffect(() => {
    console.log("user socket messages ===>", socket);

    if (socket) {
      socket.on("connected", (message) => {
        console.log("Socket Connected", message);
      });

      socket.on("connect_error", () => {
        console.log("Socket connect_error");
        socket.io.opts.transports = ["polling", "websocket"];
      });

      socket.on("onMessage", (payload) => {
        const { conversation, message } = payload;
        console.log("Message Received ===>", { conversation, message });
        if (getIdFromUrl(history) === conversation.id) {
          dispatch(addMessage(payload));
        }

        dispatch(updateConversation(conversation));
      });

      socket.on("onConversation", (payload) => {
        console.log("Received onConversation Event");
        console.log(payload);
        dispatch(addConversation(payload));
      });

      socket.on("onConversationDelete", async (conversation) => {
        console.log("Received onConversationDelete =>", conversation);
        await dispatch(removeConversation(conversation));
        history.replace({ pathname: RouteConstants.CHAT });
      });

      socket.on("onConversationReadStatusUpdate", async (conversation) => {
        console.log("Received readStatusUpdate =>", conversation);
        await dispatch(markedAsReadConversation(conversation));
      });
    }

    return () => {
      if (socket) {
        socket.off("connected");
        socket.off("onMessage");
        socket.off("onConversation");
        socket.off("onConversationDelete");
        socket.off("onConversationReadStatusUpdate");
      }
    };
  }, [socket, dispatch, history]);

  /** GROUP EVENTS */
  useEffect(() => {
    if (socket) {
      socket.on("onGroupMessage", (payload) => {
        console.log("Group Message Received ...", payload);
        const { group } = payload;
        if (getIdFromUrl(history) === group.id) {
          console.log("received in same group", group.id);
          dispatch(addGroupMessage(payload));
        }
        dispatch(updateGroup({ type: UpdateGroupAction.NEW_MESSAGE, group }));
      });

      socket.on("onGroupCreate", (payload) => {
        console.log("Group Created...", payload);
        dispatch(addGroup(payload));
      });

      /**
       * Adds the group for the user being added
       * to the group.
       */
      socket.on("onGroupUserAdd", (payload) => {
        console.log("onGroupUserAdd => ", payload);
        dispatch(addGroup(payload.group));
      });

      /**
       * Update all other clients in the room
       * so that they can also see the participant
       */
      socket.on("onGroupReceivedNewUser", ({ group }) => {
        console.log("Received onGroupReceivedNewUser");
        dispatch(updateGroup({ group }));
      });

      socket.on("onGroupRecipientRemoved", ({ group }) => {
        console.log("onGroupRecipientRemoved");
        dispatch(updateGroup({ group }));
      });

      socket.on("onGroupRemoved", (payload) => {
        dispatch(removeGroup(payload.group));
        // if (id && parseInt(id) === payload.group.id) {
        console.log("Navigating User to /groups");
        // navigate('/groups');
        // }
      });

      socket.on("onGroupParticipantLeft", ({ group, userId }) => {
        console.log("onGroupParticipantLeft received ==>");
        dispatch(updateGroup({ group }));
        // if (userId === user?.id) {
        //   console.log('payload.userId matches user.id...');
        //   dispatch(removeGroup(group));
        //   navigate('/groups');
        // }
      });

      socket.on("onGroupOwnerUpdate", (group) => {
        console.log("received onGroupOwnerUpdate");
        dispatch(updateGroup({ group }));
      });

      socket.on("onGroupDelete", async (group) => {
        console.log("received onGroupDelete =>", group);
        await dispatch(removeGroup(group));
        history.replace({ pathname: RouteConstants.CHAT });
      });

      socket.on("onGroupReadStatusUpdate", async (group) => {
        console.log("Received onGroupReadStatusUpdate =>", group);
        await dispatch(markedAsReadGroup(group));
      });
    }

    return () => {
      if (socket) {
        socket.off("onGroupMessage");
        socket.off("onGroupCreate");
        socket.off("onGroupUserAdd");
        socket.off("onGroupReceivedNewUser");
        socket.off("onGroupRecipientRemoved");
        socket.off("onGroupRemoved");
        socket.off("onGroupParticipantLeft");
        socket.off("onGroupOwnerUpdate");
        socket.off("onGroupDelete");
        socket.off("onGroupReadStatusUpdate");
      }
    };
  }, [socket, dispatch, history]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    dispatch(setSearchFilter(e.target.value));
  };

  return (
    <>
      {(isConversationLoading || isGroupMessagesLoading) && <Loader />}

      {showNewMessageModal && conversationType === "private" && (
        <ChatModal
          open={showNewMessageModal}
          setOpen={setShowNewMessageModal}
          modalType={modalType}
          conversationList={chatState.conversationList}
        />
      )}

      {showNewMessageModal && conversationType === "group" && (
        <ChatModal
          open={showNewMessageModal}
          setOpen={setShowNewMessageModal}
          modalType={modalType}
          conversationList={chatState.conversationList}
        />
      )}
      {/* {showNewMessageModal && conversationType === "group" && (
         <CreateGroupModal
           open={showNewMessageModal}
           setOpen={setShowNewMessageModal}
         />
       )} */}

      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Box className="app-header">
            <Header />
          </Box>

          <Container fixed className={classes.chatWrapper}>
            <Box className={classes.chatSidebar}>
              <ChatUsersList
                setShowNewMessageModal={setShowNewMessageModal}
                selectedConversationId={selectedConversationId}
                setSelectedConversationId={setSelectedConversationId}
                setModalType={setModalType}
                search={inputValue}
                handleSearch={handleInputChange}
                chatList={conversations}
                groupList={groups}
              />
            </Box>

            <Box style={{ flexGrow: 1 }}>
              <Switch>
                <PrivatePaymentValidRoute
                  path={RouteConstants.SELECTED_GROUP}
                  component={GroupChannelPage}
                />

                <PrivatePaymentValidRoute
                  path={RouteConstants.SELECTED_CHAT}
                  component={ConversationChannelPage}
                />

                {/* <PrivatePaymentValidRoute
                  path={RouteConstants.CHAT}
                  component={SelectedConversationPage}
                /> */}
              </Switch>

              <SelectedConversationPage />
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ChatScreen;