import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  FormHelperText,
  FormLabel,
  InputAdornment,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createNewGroup } from "../../store/actions/Chat/Group";
import { useTranslation } from "react-i18next";
import useToast from "../../hooks/useToast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "4rem 2rem",
    display: "flex",
    flexDirection: "column",
    rowGap: "2rem",
    outline: "none",
    maxWidth: "765px",
    maxHeight: "517px",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  createButton: {
    borderRadius: "20px",
    backgroundColor: "#032C61",
    color: "white",
    padding: "0.8rem 2.8rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  cancelButton: {
    borderRadius: "25.5px",
    backgroundColor: "white",
    border: "1px solid #979797",
    color: "#9E9E9E",
    padding: "0.8rem 2rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  buttonsDiv: {
    columnGap: "4px",
    flexDirection: "row-reverse",
    display: "flex",
    columnGap: "10px",
    marginTop: "auto",
  },
  spaceTitle: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "24px",
    color: "#003471",
  },
  labels: {
    color: "#103170",
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    /* identical to box height */

    letterSpacing: "-0.5px",
  },
  noBorder: {
    border: "none",
  },
}));

export default function NewGroupChat({ setOpen = () => {} }) {
  const classes = useStyles();
  //   const [open, setOpen] = useState(showNewMessageModal);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const toasts = useToast()

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const [nameSpaceError, setNameSpaceError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [searchFieldError, setSearchFieldError] = useState(null);

  let recipients = useSelector((state) => state.recipients.recipients);
  recipients = recipients.map((recipient) => {
    return {
      id: recipient.id,
      label: `${recipient.firstName} ${recipient.lastName}`,
      value: `${recipient.firstName} ${recipient.lastName}`,
      user: recipient,
    };
  });

  const handleChange = (e, newValues) => {
    setSearchFieldError("");
    setSelectedRecipients(newValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedRecipients.length === 0 || !title) {
      if (selectedRecipients.length === 0) {
        setSearchFieldError(t("requiredField"));
      }
      // if (!description) {
      //   setDescriptionError(t("requiredField"));
      // }
      if (!title) {
        setNameSpaceError(t("requiredField"));
      }
      return;
    }

    const users = selectedRecipients.map(
      (selectedRecipient) => selectedRecipient.user.id
    );
    const recipients = [...new Set(users)];

    await dispatch(createNewGroup({ title, users: recipients }, history, toasts));
    setOpen(false);
  };

  return (
    <>
      <div>
        <p className={classes.labels}>Group Name</p>
        <TextField
          id="title"
          value={title}
          fullWidth={true}
          style={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "19px",
            marginTop: "4px",
          }}
          onChange={(e) => {
            setNameSpaceError("");
            setTitle(e.target.value);
          }}
        />
        {
          <FormHelperText error={nameSpaceError}>
            {nameSpaceError}
          </FormHelperText>
        }
      </div>
      <div>
        <p className={classes.labels}>Description (optional)</p>
        <TextField
          id="description"
          value={description}
          fullWidth={true}
          onChange={(e) => {
            // setDescriptionError("");
            setDescription(e.target.value);
          }}
          style={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "30px",
            lineHeight: "30px",
            marginTop: "4px",
          }}
        />
        {
          <FormHelperText error={descriptionError}>
            {descriptionError}
          </FormHelperText>
        }
      </div>

      <div>
        <p className={classes.labels}>
          Search name of people to add in this group
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "4px",
            borderRadius: "8px",
            border: "1px solid #E0E0E0",
            marginTop: 2,
          }}
        >
          <img
            alt="search"
            style={{
              width: "25px",
              height: "25px",
              alignSelf: "center",
              marginLeft: "6px",
            }}
            src={require("../../assets/images/search.svg")}
          />
          <Autocomplete
            id="selectedRecipients"
            options={recipients}
            getOptionLabel={(option) => option.label}
            limitTags={4}
            style={{ width: "100%", padding: "0 0 0 4px" }}
            value={selectedRecipients}
            onChange={handleChange}
            getOptionSelected={(option, value) => option.id === value.id}
            filterSelectedOptions
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                style={{ width: "100%", padding: "0 0 0 4px" }}
                placeholder={
                  selectedRecipients.length !== 0 ||
                  "Search name of people to add in this group"
                }
              />
            )}
          />
        </div>
        {
          <FormHelperText error={searchFieldError}>
            {searchFieldError}
          </FormHelperText>
        }
      </div>
      <div className={classes.buttonsDiv}>
        <Button
          variant="contained"
          className={classes.createButton}
          onClick={handleSubmit}
        >
          Create
        </Button>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
