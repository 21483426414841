import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, InputLabel, Input, FormHelperText, Button, Link, FormControlLabel, Checkbox, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { validateEmail, validateMandatoryValue, validatePasswordLength } from "../../helpers/Validation";
import AssetConstants from "../../constants/AssetConstants";
import { login, resetForgottenPassword, resetPassword, verifyForgotCode } from "../../store/actions/Authentication";
import { Alert } from "@material-ui/lab";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import { APP_CONSTANTS } from "../../constants/GenericConstants";
import Header from "../../components/Header/Header";


const ForgotPasswordScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [forgotPCode, setForgotPCode] = useState("");
    const [email, setEmail] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [apiError, setApiError] = useState("");

    const [isCodeValid, setIsCodeValid] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        let code = params.get('forgotPasswordCode');
        let emailGot = params.get('email');
        if (code) {
            setForgotPCode(code.trim())
            dispatch(verifyForgotCode({ "forgotPasswordCode": code.trim() }, onVerifiedSuccess, onVerifiedFailure));

        } else {
            setForgotPCode("")
        }
        if (emailGot) {
            setEmail(emailGot)
        } else {
            setEmail("")
        }

    }, [])

    const onVerifiedSuccess = (res) => {
        setIsCodeValid(res.data)
        setLoaded(true)
    }
    const onVerifiedFailure = (err) => {
        setIsCodeValid(false)
        setApiError(err)
    }

    const handlePasswordChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        console.log("event.target", event.target.name);

        switch (event.target.name) {

            case "password":
                setPassword(input);
                if (formSubmitted) {
                    checkPasswordMatch(input, confirmPassword)
                    validatePasswordField(input)
                }
                break;

            case "confirmPassword":
                setConfirmPassword(input);
                if (formSubmitted) {
                    checkPasswordMatch(password, input)
                    validateConfirmPasswordField(input)
                }
                break;

            default:
                break;
        }


    }

    const validatePassword = (password) => {

        var message = "";
        var status = true;
        if (!validateMandatoryValue(password)) {
            message = t("passwordRequiredError");
            status = false;

        } else if (!validatePasswordLength(password)) {
            message = t("passwordLengthError").replace(/{minLength}/, APP_CONSTANTS.MIN_PASSWORD_LENGTH).replace(/{maxLength}/, APP_CONSTANTS.MAX_PASSWORD_LENGTH);
            status = false;
        }

        return [status, message];
    }



    const showInputError = (field) => {
        return field.length > 0;
    }

    const checkPasswordMatch = (password, confirmPassword) => {
        if (confirmPassword !== password) {
            setApiError(t("passwordMismatchError"));
            return false
        }
        else {
            setApiError("");
            return true
        }
    }


    const onSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);
        setApiError("");


        var [passwordStatus, pResult] = validatePassword(password);
        setPasswordError(pResult);

        var [confirmStatus, cResult] = validatePassword(confirmPassword);
        setConfirmPasswordError(cResult);

        if (passwordStatus && confirmStatus) {
            if (checkPasswordMatch(password, confirmPassword)) {

                const data = {
                    "forgotPasswordCode": forgotPCode,
                    "password": password,
                    "confirmPassword": confirmPassword
                }
                dispatch(resetForgottenPassword(data, onResetSuccess, onResetFailure));
            }
        }

    }

    const onResetSuccess = (res) => {
        history.replace({ pathname: RouteConstants.LOGIN, state: { resetMsg: res.message } });
    }
    const onResetFailure = (res) => {
        setApiError(res)
    }

    const validatePasswordField = (password) => {
        var [passwordStatus, pResult] = validatePassword(password);
        setPasswordError(pResult);
    }

    const validateConfirmPasswordField = (confirmPassword) => {
        var [confirmStatus, cResult] = validatePassword(confirmPassword);
        setConfirmPasswordError(cResult);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickConfirmShowPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="is-login">

            {userState.loading && <Loader />}
            <Grid container>
                <Grid item xs={12} sm={6} className="login-form">
                    <div className="login-form-content">
                        <img className="login-logo" src={AssetConstants.logo} />
                        <form>

                            {!isCodeValid && loaded ?
                                <>

                                    <h1>{t("sessionExpire")}</h1>

                                    <p>{t("sessionExpireMsg")}</p>

                                    <div className="page-not-found">
                                        <img src={AssetConstants.pagenotfound} />
                                    </div>

                                    <div className="form-field login-button">
                                        <Button type="submit" color="primary" variant="contained" disableElevation onClick={() => {
                                            history.replace({ pathname: RouteConstants.LOGIN });
                                        }}>{t("goToLogin")}</Button>
                                    </div>

                                </>
                                :
                                <>
                                    <h1>{t("resetPasswordHeading")}</h1>
                                    {
                                        apiError && <Alert severity="error">{apiError}</Alert>
                                    }

                                    <p className="forgotPasswordText">Enter a new password for your <strong>{email}</strong> account. </p>

                                    <div className="form-field">
                                        {/* <InputLabel htmlFor="password">{t("newPassword")}</InputLabel> */}
                                        <Input
                                            id="password"
                                            name="password"
                                            required
                                            type={showPassword ? "text" : "password"}
                                            value={password || ""}
                                            placeholder={t("newPasswordPlaceholder")}
                                            onChange={handlePasswordChange}
                                            error={showInputError(passwordError)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>} />
                                        {
                                            showInputError(passwordError) && <FormHelperText error={showInputError(passwordError)}>{passwordError}</FormHelperText>
                                        }
                                    </div>
                                    <div className="form-field">
                                        {/* <InputLabel htmlFor="confirmPassword">{t("confirmPassword")}</InputLabel> */}
                                        <Input
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            required
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={confirmPassword || ""}
                                            placeholder={t("reEnterPassword")}
                                            onChange={handlePasswordChange}
                                            error={showInputError(confirmPasswordError)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickConfirmShowPassword}>
                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>} />
                                        {
                                            showInputError(confirmPasswordError) && <FormHelperText error={showInputError(confirmPasswordError)}>{confirmPasswordError}</FormHelperText>
                                        }
                                    </div>
                                    <div className="form-field login-button">
                                        <Button type="submit" color="primary" variant="contained" disableElevation onClick={onSubmit}>{t("resetPasswordButtonTitle")}</Button>
                                    </div>
                                </>
                            }
                            <span className="title-has-icon signin-to-your-account">
                                <a onClick={() => history.push({ pathname: RouteConstants.LOGIN })}>
                                    <span>Or</span> Go Back to Sign in</a>
                            </span>

                        </form>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} className="screen-half-splash">

                </Grid>
            </Grid>
        </div>
    );
};

export default ForgotPasswordScreen;