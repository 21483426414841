import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Container, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';

import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { addExternalUser } from "../../store/actions/ExternalUser";
import AddExternalUser from "../../components/AddExternalUser/AddExternalUser";
import { AddCircle, AddCircleOutlineOutlined, ArrowBackIosRounded, PlusOneOutlined } from "@material-ui/icons";
import { validateMandatoryValue, validateAtLeastOne, validatePermissions, validateEmail, validateLength45C } from "../../helpers/Validation";
import { getViewPermValue, getViewPermValueIndividual } from "../../helpers/PermissionCalculator";
import { PRIV_CONSTS } from "../../constants/StaticData";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";
import { getTimeDifference } from "../../helpers/GenericHelper";
import { Mixpanel } from "../../lib/mixpanleEvents";

const AddExternalUserScreen = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const startTime = useRef(new Date().getTime());

    const externalUsersState = useSelector((state) => state.externalUser);
    const [dialogOpen, setDialogOpen] = useState(false);

    var permLocal = useRef(0);

    const setPermission = (val) => {
        permLocal.current = val
    }

    const checkDataChanged = () => {
        if (formData.alias !== "" || formData.emailAddress !== "") {
            return true;
        } else {
            return false;
        }
    }

    const [apiMsg, setApiMsg] = useState(
        {
            type: "",
            msg: ""
        }
    )

    const [formData, setFormData] = useState(
        {
            alias: "",
            emailAddress: "",
            children: [],
            permissions: 0,
            role: "TEACHER"
        }
    )

    const [formDataErrors, setFormDataErrors] = useState(
        {
            aliasError: "",
            emailAddressError: "",
            childrenError: "",
            permissionsError: "",
            roleError: ""
        }
    );

    const [formSubmitted, setFormSubmitted] = useState(false);

    const isFormValid = () => {
        let localAliasError = "";
        let localemailAddressError = "";
        let localChildrenError = "";
        let localPermissionsError = "";
        let localRoleError = "";
        let valid = true;

        if (!validateMandatoryValue(formData.alias)) {
            localAliasError = t("requiredField");
            valid = false;
        } else if (!validateLength45C(formData.alias)) {
            localAliasError = t("onlyNameLength");
            valid = false;
        }


        if (!validateMandatoryValue(formData.emailAddress)) {
            localemailAddressError = t("requiredField");
            valid = false;
        } else if (!validateEmail(formData.emailAddress)) {
            localemailAddressError = t("invalidEmailError");
            valid = false;
        }

        if (!validateAtLeastOne(formData.children)) {
            localChildrenError = t("atLeastOneErrorChild");
            valid = false;
        }

        if (!validateMandatoryValue(formData.role?.roleName)) {
            localRoleError = t("atLeastOneErrorRole");
            valid = false;
        }

        setFormDataErrors({
            ...formDataErrors,
            aliasError: localAliasError,
            emailAddressError: localemailAddressError,
            childrenError: localChildrenError,
            permissionsError: localPermissionsError,
            roleError: localRoleError

        });
        return valid;
    }

    const onSubmit = () => {
        setFormSubmitted(true)
        if (isFormValid()) {
            setApiMsg({ type: "", msg: "" })
            dispatch(addExternalUser({ ...formData, role: formData.role?.roleName, permissions: permLocal.current }, onSuccess, onFailure))
        }
    }

    const onSuccess = (res) => {
        setApiMsg({ type: "success", msg: "External User Added Successfully!" })
        
        //mixpanel
        const endTime = new Date().getTime();
        const duration = getTimeDifference(startTime.current, endTime);
        Mixpanel.track(MIX_PANEL_EVENTS.EXTERNAL_USER_CREATED, {
          [MIX_PANEL_EVENTS.TIME_SPENT]: duration,
          [MIX_PANEL_EVENTS.USER_TYPE]: res.userType,
        });

        history.replace({
            pathname: "/externalUsers",
            state: { apiMsg: "External User Added Successfully!" }
        })
    }
    const onFailure = (err) => {
        var err1 = err.data && err.data.message ? err.data.message : err.message
        setApiMsg({ type: "error", msg: err1 })

    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    return (
        <div>

            {externalUsersState.loading && <Loader />}

            {/* <Snackbar open={apiMsg.type==="error"} autoHideDuration={3000} onClose={()=>setApiMsg({type:"", msg:""})}>
                <Alert onClose={()=>setApiMsg({type:"", msg:""})} severity="error">
                    {apiMsg.msg}
                </Alert>
            </Snackbar> */}

            <SnackbarAlert isError={apiMsg.type==="error"}  show={apiMsg.msg !== ""} message={apiMsg.msg} duration={3000} onCloseAlert={() => setApiMsg({ type: "", msg: "" })} />


            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("informationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                        onClick={() => {
                            history.goBack()
                        }}
                        color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>

                </DialogActions>
            </Dialog>

            <Grid container className="app">
                <Grid xs={12} item className="app-header">
                    <Header />
                </Grid>
                <Grid item xs={12} className="grid-body">
                    <Container maxWidth="xl">
                        <div className="new-external-user">
                            <Container maxWidth="lg">

                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <h1 className="title-has-icon mb-15">
                                            <i
                                                onClick={() => {
                                                    if (checkDataChanged()) {
                                                        setDialogOpen(true);
                                                    }
                                                    else {
                                                        history.goBack()
                                                    }
                                                }}
                                                className="ticon-arrow-right back-arrow"></i>{t("createExternalUsers")}
                                        </h1>
                                    </Grid>
                                </Grid>

                                <AddExternalUser
                                    formData={formData}
                                    formDataErrors={formDataErrors}
                                    setFormDataErrors={setFormDataErrors}
                                    setFormData={setFormData}
                                    formSubmitted={formSubmitted}
                                    setPerm={setPermission}
                                />
                                {/* {apiMsg.msg !== "" ?
                                    <Alert severity={apiMsg.type}>
                                        {apiMsg.msg}
                                    </Alert>
                                    : null
                                } */}
                                <div className="buttons-footer">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="large"
                                        onClick={() => {
                                            if (checkDataChanged()) {
                                                setDialogOpen(true);
                                            }
                                            else {
                                                history.goBack()
                                            }
                                        }}
                                    >
                                        Cancel
                                          </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => {
                                            onSubmit()
                                        }}
                                    >
                                        Create User
                                          </Button>
                                </div>
                            </Container >

                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl">
                        <Grid container spacing={6} className="my-0">
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}

export default AddExternalUserScreen;