import { SIDEBAR_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    collapsed: false
};

const sideBar = (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR_ACTIONS.COLLAPSED:
            console.log("SIDE BAR COLLAPSED REDUCER: ", action.payload.collapsed)
            return {
                ...state,
                collapsed: action.payload.collapsed
            };
        default: 
            return state;
    }
}

export default sideBar;