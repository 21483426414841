import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Container, Button } from "@material-ui/core";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ImageLibrary from "../../components/ImageLibrary/ImageLibrary";
import MenuIcon from '@material-ui/icons/Menu';
import { collapseSideBar } from "../../store/actions/SideBar";

const ImageLibraryScreen = () => {
    document.title = "Thrive App - Activities"

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const childState = useSelector((state) => state.child);
    const sideBarState = useSelector((state) => state.sideBar);

    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(sideBarState.collapsed);

    const handleSidebarCollapse = () => {
        setSidebarCollapsed(!sidebarCollapsed);
        dispatch(collapseSideBar(!sidebarCollapsed));
    }


    return (
        <Grid container className="app">
            <Grid xs={12} item className="app-header">
                <Header />
            </Grid>
            <Grid item xs={12} className="grid-body">
                <Container maxWidth="xl">
                    <Button className="navBtn" variant="contained"
                        onClick={() => {
                            setSideBarOpen(prev => !prev)
                        }}
                        color="primary">
                        <MenuIcon />
                    </Button>
                    <Grid container spacing={3}>
                        <Grid item xs={sidebarCollapsed? 1: 2} 
                        className={!sideBarOpen ? "generic-card-full-height boxopen" : "generic-card-full-height boxclose"}>
                            <Sidebar 
                                selectedItem={t("activities")} 
                                childIdGot={childState.selectedChild.childId} 
                                onCollapse={handleSidebarCollapse}
                                collapseOnLaunch={sidebarCollapsed}
                            />
                        </Grid>
                        <Grid item xs={sidebarCollapsed? 11: 10} className="generic-card-full-height box-full-width">
                            <ImageLibrary />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="xl">
                    <Grid container spacing={6} className="my-0">
                        <Grid item xs={12}>
                            <Footer />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ImageLibraryScreen;