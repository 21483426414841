import React, { useState } from "react";
import {
  InputLabel,
  Grid,
  makeStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getPaymentAdverb } from "../../helpers/GenericHelper";

const useStyles = makeStyles((theme) => ({
  select: {
    "& ul": {
      height: 30,
      paddingTop: 0,
    },
  },
  billedDur: {
    color: "#a4a4a4",
    fontSize: "14px",
    letterSpacing: "-0.39px",
  },
  savetag: {
    width: "75px",
    height: "25px",
    background: "#f9be02",
    fontSize: "12px",
    letterSpacing: "-0.33px",
    color: "#333333",
    position: "absolute",
    left: "13px",
    top: "8px",
    borderRadius: "12.5px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
  },
  paymentsCard: {
    marginTop: "14px",
    // padding: "20px",
    // height: "1.5rem",
  },
  container: {
    marginTop: "6px",
    paddingLeft: "15px",
  },
  noPaymentSelected: {
    display: "block",
    width: "100%",
  },
  paymentHeader: {
    width: "100%",
  },
  SubscriptionText: {
    marginTop: "8px",
  },
}));

const AccountPaymentInfo = (props) => {
  const { data = {} } = props;
  const { firstName, userSubscription } = data;

  const classes = useStyles();
  const { t } = useTranslation();

  const userState = useSelector((state) => state.authentication);

  const [paymentPlan, setPaymentPlan] = useState("");
  const [paymentPlanError, setPaymentPlanError] = useState("");

  const handlePaymentPlanChange = (event) => {
    setPaymentPlan(event.target.value);
  };

  return (
    <Grid item xs={12} className="grid-body">
      <Grid container spacing={3} className={classes.container}>
        <h4 className={classes.paymentHeader}>{t("paymentDetail")}</h4>
        <div>
          {!props.localLoading ? (
            userSubscription?.name ? (
              <div className="select-payment-plan plr-78">
                <InputLabel>{t("yourSubscriptionPlan")}</InputLabel>
                <div className="radio-block">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label=""
                      name=""
                      value={paymentPlan}
                      onChange={handlePaymentPlanChange}
                      row
                    >
                      {userState.subscriptionList.map((subscription, index) => {
                        return (
                          <FormControlLabel
                            disabled
                            checked={
                              subscription.name === userSubscription.name
                                ? true
                                : false
                            }
                            control={<Radio />}
                            label={
                              <p>
                                {subscription.discountedPrice > 0 && (
                                  <span className={classes.savetag}>
                                    {t("save") +
                                      " " +
                                      Math.round(
                                        (((subscription.price -
                                          subscription.discountedPrice) *
                                          100) /
                                          subscription.price) *
                                          10
                                      ) /
                                        10 +
                                      "%"}
                                  </span>
                                )}
                                <span className={classes.paymentsCard}>
                                  {subscription.name}
                                </span>
                                <strong className={classes.strong}>
                                  {subscription.discountedPrice > 0
                                    ? "$" + subscription.discountedPrice
                                    : "$" + subscription.price}
                                </strong>
                                <span className={classes.billedDur}>
                                  {(subscription.discountedPrice > 0
                                    ? "$" + subscription.price + " "
                                    : "") +
                                    t("billed") +
                                    " " +
                                    getPaymentAdverb(subscription.name)}
                                </span>
                              </p>
                            }
                            value={"" + subscription.id}
                          />
                        );
                      })}
                    </RadioGroup>
                    <p className={classes.SubscriptionText}>
                      {t("yourSubscriptionMessage")}
                      <b>
                        {new Date(userSubscription?.renewalDate)
                          .toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })
                          .split("/")
                          .join("-")}
                      </b>
                      {t("yourSubscriptionMessage2")}
                      <b>{userSubscription.price} USD + tax</b>.{" "}
                      {t("yourSubscriptionMessage3")}
                      <a href="mailto:support@thrive.com">support@thrive.com</a>
                      .
                    </p>
                  </FormControl>
                </div>
              </div>
            ) : (
              <div className={classes.noPaymentSelected}>
                <p>
                  {t("usingFreePlan1").replace("{firstName}", firstName)}{" "}
                  <Link to="/payment">click here</Link> {t("usingFreePlan2")}
                </p>
              </div>
            )
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};
export default AccountPaymentInfo;
