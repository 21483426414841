import { STATUS_CODES } from '../../constants/HttpStatusCodes';
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

export function handleResponse(response) {
    if (response.data.status) {
        return response.data;
    } else {
        handleError(response);
    }
}

export function handleResponseUser(response) {
    if (response.status) {
        return response.data;
    } else {
        handleError(response);
    }
}

export function handleS3ImageUploadResponse(response) {
    if (response.status == 200) {
        return response.config.data.name;
    } else {
        handleError(response);
    }
}

export function handleError(errorResponse) {
    if(errorResponse.response?.data?.statusCode === 401) {
        if(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN)) {
            localStorage.clear();
            window.location.reload();
            localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_EXPIRED_TOKEN_ERROR, true);
        }
    } else {
        throw errorResponse;
    }
}