import React from "react";
import "./styles.scss";
import Login from "../../components/Login/Login";

const LoginScreen = () => {

    return (
        <div>
            <Login />
        </div>
    );
};

export default LoginScreen;