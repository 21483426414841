export const MIX_PANEL_EVENTS = {
  USER_INSTALL_COUNT: "User Install Count", //attribute: installation status
  USER_UNINSTALL_COUNT: "User Uninstall Count", //attribute: installation status
  INACTIVE_USER_COUNT: "Inactive User Count",
  ACTIVE_USER_COUNT: "Active User Count",

  SIGNUP: "Sign Up", //event
  LOGIN: "Log In", //event
  LOGIN_TIME_SPENT: "THRIVE - Login Time Spent", //property
  SIGNUP_TIME_SPENT: "THRIVE - Sign Up Time Spent", //property //attribute: user type(single/multi)

  SESSION_TIME: "Session Time", //* event  //without attribute and also with attribute of user type(single/multi)

  // EXTERNAL_USER_CREATED: "External User Created", //event //with user type and time spent

  ACTIVITIES_SCHEDULED_COUNT: "THRIVE - Activities Scheduled Count", //property
  REMINDER_COUNT: "THRIVE - Reminder Count", //property  //without group and also group by reminder duration
  CALENDAR_VIEW_TIME: "THRIVE - Calendar View Time", //group by calendar view
  REMINDER_NOTIFICATION_COUNT: "THRIVE - Reminder Notification Count",

  ACTIVITY_SCHEDULED: "Activity Scheduled", //event
  ACTIVITY_SCHEDULING_TIME_SPENT: "THRIVE - Activity Scheduling Time Spent", //property

  SCHEDULE_SCREEN_TIME: "Schedule Screen Time", //event

  ACTIVITY_CREATED: "Activity Created", //event
  ACTIVITY_CREATION_TIME_SPENT: "THRIVE - Activity Creation Time Spent", //property
  ACTIVITY_CREATION_TIME: "THRIVE - Activity Creation Time", //property
  SUBTASKS_COUNT: "THRIVE - Subtasks Count", //event

  ACTIVITY_COUNT: "Activity count", //event

  ACTIVITY_ARCHIVED: "Activity Archived", //event
  ACTIVITY_ARCHIVING_TIME_SPENT: "THRIVE - Activity Archiving Time Spent", //property

  ACTIVITY_ARCHIVE_COUNT: "Activity Archive Count", //event

  ACTIVITY_SCREEN_TIME: "Activity Screen Time", //* event

  TOOLS_COUNT: "tools count", //group by web/mobile and by status(active/inactive)

  EMOTION_REGULATION_SCREEN_TIME: "Emotion Regulation Screen Time", //event

  // TOOL_CREATED: "Tool Created", //event
  TOOL_CREATION_TIME: "THRIVE - Tool Creation Time Spent", //property
  INSTRUCTIONS_COUNT: "THRIVE - Instructions Count", //property

  NAME: "name",
  EMAIL: "email",
  USER_TYPE: "THRIVE - User Type", //general property
  TIME_SPENT: "THRIVE - Time Spent",

  /** */

  USER_PROFILE_CREATED: "User Profile Created",
  USER_PROFILE_DELETED: "User Profile Deleted",
  USER_PROFILE_UPDATED: "User Profile Updated",

  EXTERNAL_USER_CREATED: "External User Created",
  EXTERNAL_USER_DELETED: "External User Deleted",
  EXTERNAL_USER_UPDATED: "External User Updated",

  SUB_TASK_CREATED: "Sub Task Created",
  SUB_TASK_DELETED: "Sub Task Deleted",
  SUB_TASK_UPDATED: "Sub Task Updated",

  EMOTION_REGULATION_TOOL_CREATED: "Emotion Regulation Tool Created",
  EMOTION_REGULATION_TOOL_UPDATED: "Emotion Regulation Tool Updated",
  EMOTION_REGULATION_TOOL_DELETED: "Emotion Regulation Tool Deleted",

  EMOTION_REGULATION_TOOL_ACTIVATE: "Emotion Regulation Tool Activate",
  EMOTION_REGULATION_TOOL_DISABLED: "Emotion Regulation Tool Disabled",

  SELF_TRIGGER_ENABLE: "Self Trigger Enable",
  SELF_TRIGGER_DISABLE: "Emotion Regulation Tool Disabled",
};
