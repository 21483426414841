import {
  CREATE_CONVERSATION_ACTIONS,
  GET_ALL_CONVERSATIONS_ACTIONS,
  GET_CONVERSATION_MESSAGES_ACTIONS,
  CREATE_NEW_MESSAGE_ACTIONS,
  CREATE_NEW_GROUP_ACTIONS,
  GET_ALL_GROUPS_ACTIONS,
} from "../../constants/ActionConstants";

let initialState = {
  loading: false,
  conversationList: [],
  conversationMessages: [],
  groups: [],
  chatLoading: false,
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONVERSATION_ACTIONS.CREATE_CONVERSATION:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CONVERSATION_ACTIONS.CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_CONVERSATION_ACTIONS.CREATE_CONVERSATION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_CONVERSATIONS_ACTIONS.GET_ALL_CONVERSATIONS:
      return {
        ...state,
        loading: true,
        chatLoading: true,
      };

    case GET_ALL_CONVERSATIONS_ACTIONS.GET_ALL_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        conversationList: action.payload.data.data,
      };

    case GET_ALL_CONVERSATIONS_ACTIONS.GET_ALL_CONVERSATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES:
      return {
        ...state,
        loading: false,
        chatLoading: true,
      };
    case GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES_CLEAR_OLD_DATA:
      return {
        ...state,
        conversationMessages: [],
        loading: false,
        chatLoading: true,
      };

    case GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        conversationMessages: [
          ...state.conversationMessages,
          ...action.payload.data.data,
        ],
        conversationMeta: action.payload.data.meta,
        loading: false,
        chatLoading: false,
      };

    case GET_CONVERSATION_MESSAGES_ACTIONS.GET_CONVERSATION_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CREATE_NEW_MESSAGE_ACTIONS.CREATE_NEW_MESSAGE:
      return {
        ...state,
        loading: false,
      };

    case CREATE_NEW_MESSAGE_ACTIONS.CREATE_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_NEW_MESSAGE_ACTIONS.CREATE_NEW_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CREATE_NEW_GROUP_ACTIONS.CREATE_NEW_GROUP:
      return {
        ...state,
        loading: true,
      };

    case CREATE_NEW_GROUP_ACTIONS.CREATE_NEW_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_NEW_GROUP_ACTIONS.CREATE_NEW_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
      };

    // case GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //   };

    // case GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //   };

    default:
      return state;
  }
};

export default chat;
