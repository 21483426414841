import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { getNotifications, markAllRead, markNotificationAsRead } from "../../store/actions/Notifications";
import { setCurrentChild, childExists, childExistsOnly } from "../../store/actions/Child";
import DateHelper from "../../helpers/DateHelper";
import { GENERIC_STATUS, NOTIFICATION_TYPE } from "../../constants/GenericConstants";
import RouteConstants from "../../constants/RouteConstants";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";

const Notifications = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [ref, setRef] = useState(null);
    const notificationState = useSelector((state) => state.notifications);
    const userState = useSelector((state) => state.authentication);
    const childState = useSelector((state) => state.child);

    const [showError, setShowError] = useState(false);

    const onLoadSuccess = (data) => {
        const index = data.data.findIndex(notification => notification.notificationType === NOTIFICATION_TYPE.PARENT_PORTAL_SIGNUP);
        console.log(index);
        if (index > -1 && !data.data[index].isRead) {
            dispatch(markNotificationAsRead(data.data[index].id, index));
        }
    }

    useEffect(() => {
        dispatch(getNotifications(1, onLoadSuccess));
    }, []);

    const fetchMore = () => {
        if (!notificationState.loading && notificationState.notificationListMeta.hasNextPage) {
            dispatch(getNotifications(notificationState.notificationListMeta.page + 1));
        }
    }

    const checkValidChild = (index, notification) => {

        if (isDisabledParentChild(notification.notificationType, notification.subjectId)) return;
        if (notification.notificationType === NOTIFICATION_TYPE.PARENT_PORTAL_SIGNUP ||
            notification.notificationType === NOTIFICATION_TYPE.EXTERNAL_USER_STATUS) {
            return;
        }
        else if (notification.notificationType === NOTIFICATION_TYPE.PAYMENT_FAIL_PARENT ||
            notification.notificationType === NOTIFICATION_TYPE.PAYMENT_REMINDER ||
            notification.notificationType === NOTIFICATION_TYPE.PAYMENT_SUCCESS ||
            notification.notificationType === NOTIFICATION_TYPE.GENERAL_NOTIF_STATUS_CHANGE ||
            notification.notificationType === NOTIFICATION_TYPE.CHAT_NEW_MESSAGE ||
            notification.notificationType === NOTIFICATION_TYPE.GROUP_NEW_MESSAGE
        ) {
            openRelevantScreen(index, notification)
            return
        }

        // dispatch(childExists(notification.subjectId, (data) => onCheckValidity(data, index, notification)));
        dispatch(childExistsOnly(notification.subjectId, (data) => onCheckValidity(data, index, notification)));
    }

    const onCheckValidity = (data, index, notification) => {
        if (data) {
            openRelevantScreen(index, notification);
        } else {
            if (notification.notificationType !== NOTIFICATION_TYPE.EXTERNAL_USER_INVITE) {
                setShowError(true);
                setTimeout(() => {
                    history.push({ pathname: RouteConstants.DASHBOARD });
                }, 1000);
            }
        }
    }

    const openRelevantScreen = (index, notification) => {
        dispatch(markAllRead());
        console.log("Opening relevant screen....");
        console.log(notification);
        if (notification.notificationType === NOTIFICATION_TYPE.PARENT_PORTAL_SIGNUP || notification.notificationType === NOTIFICATION_TYPE.EXTERNAL_USER_STATUS) {
            return;
        }
        // if (!notification.isRead) {
        //     // dispatch(markNotificationAsRead(notification.id, index));
        // }

        switch (notification.notificationType) {
            case NOTIFICATION_TYPE.ACTIVITY_CREATION:
                dispatch(setCurrentChild(notification.subjectId));
                history.push({ pathname: RouteConstants.CHILD_ACTIVITIES.replace(':childId', notification.subjectId) });
                break;

            case NOTIFICATION_TYPE.SCHEDULE_UPDATE:
                dispatch(setCurrentChild(notification.subjectId));
                history.push({ pathname: RouteConstants.SCHEDULE_ACTIVITY.replace(':childId', notification.subjectId), additionalData: notification.additionalData });
                break;

            case NOTIFICATION_TYPE.WATCH_ADD:
            case NOTIFICATION_TYPE.MOBILE_ADD:
                if(!userState.isSingleUser) {
                    dispatch(setCurrentChild(notification.subjectId));
                    history.push({ pathname: RouteConstants.EDIT_CHILD_PROFILE.replace(':childId', notification.subjectId) });
                }
                break;

            case NOTIFICATION_TYPE.GENERAL_NOTIF_TIMEZONE:
                if(!userState.isSingleUser) {
                    dispatch(setCurrentChild(notification.subjectId));
                    history.push({ pathname: RouteConstants.EDIT_CHILD_PROFILE.replace(':childId', notification.subjectId) });
                }
                break;

            case NOTIFICATION_TYPE.GENERAL_NOTIF_STATUS_CHANGE:
                history.push({ pathname: RouteConstants.ACCOUNT_SETTINGS });
                break;

            case NOTIFICATION_TYPE.EXTERNAL_USER_INVITE:
                dispatch(setCurrentChild(notification.subjectId));
                history.push({ pathname: RouteConstants.SCHEDULE_ACTIVITY.replace(':childId', notification.subjectId) });
                break;

            case NOTIFICATION_TYPE.PAYMENT_FAIL_PARENT:
                history.push({ pathname: RouteConstants.ACCOUNT_SETTINGS.replace(':userId', userState.user.id) });
                break;
            case NOTIFICATION_TYPE.PAYMENT_REMINDER:
                history.push({ pathname: RouteConstants.ACCOUNT_SETTINGS.replace(':userId', userState.user.id) });
                break;
            case NOTIFICATION_TYPE.PAYMENT_SUCCESS:
                history.push({ pathname: RouteConstants.ACCOUNT_SETTINGS.replace(':userId', userState.user.id) });
                break;
            case NOTIFICATION_TYPE.CHAT_NEW_MESSAGE:
                if (notification.subjectId) {
                    history.push({ pathname: RouteConstants.SELECTED_CHAT.replace(':id', notification.subjectId) });
                }
                break;
            case NOTIFICATION_TYPE.GROUP_NEW_MESSAGE:
                if (notification.subjectId) {
                    history.push({ pathname: RouteConstants.SELECTED_GROUP.replace(':id', notification.subjectId) });
                }
                break;

            default:
                return;

        };
    }

    const isDisabledParentChild = (nType, childId) => {
        if (nType === NOTIFICATION_TYPE.WATCH_ADD || nType === NOTIFICATION_TYPE.SCHEDULE_UPDATE || nType === NOTIFICATION_TYPE.ACTIVITY_CREATION || nType === NOTIFICATION_TYPE.CHILD_SCHEDULED_ACTIVITY) {
            const childArray = childState.childrenList.filter(child => child.childId === childId);
            if (childArray.length > 0) {
                const parentAccountId = childArray[0].accountId;
                const parentArray = userState.user.accounts.filter(account => account.id === parentAccountId);
                if (parentArray.length > 0) {
                    if (parentArray[0].status === GENERIC_STATUS.DISABLE) return true;
                }
            }
        }
        return false;
    }

    const getNotificationsList = () => {
        return notificationState.notificationList.map((notification, index) => {
            return (
                <div className={notification.notificationType === NOTIFICATION_TYPE.PARENT_PORTAL_SIGNUP || notification.notificationType === NOTIFICATION_TYPE.EXTERNAL_USER_STATUS || isDisabledParentChild(notification.notificationType, notification.subjectId) ? "d-flex row disabled-icon" : "d-flex row "} onClick={() => checkValidChild(index, notification)}>
                    <img src={notification.imageURL.length > 0 ? notification.imageURL : "https://i.stack.imgur.com/l60Hf.png"} />
                    <div className="txt-container">
                        <h4>{notification.title}</h4>
                        <p>{notification.message}</p>
                        <p className={!notification.isRead ? "notification-time" : "notification-time-gray"}>{DateHelper.formatDateForNotifications(notification.createdAt)}</p>
                    </div>
                    <div className="reddot">{!notification.isRead && <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: '#e54644' }}></div>}</div>
                </div>
            );
        });
    }

    const handleErrorClose = () => {
        setShowError(false);
    }

    return (
        <div className="notify-box">
            <SnackbarAlert show={showError} message="Child profile not found!" duration={3000} onCloseAlert={handleErrorClose} />
            <h5>{t("notifications")}</h5>
            <div ref={ref => { setRef(ref); }} className="notificationsScroll">
                <InfiniteScroll
                    pageStart={1}
                    loadMore={fetchMore}
                    hasMore={notificationState.notificationListMeta.hasNextPage}
                    useWindow={false}
                    initialLoad={true}
                    threshold={30}
                    getScrollParent={() => ref}>
                    {notificationState.notificationList.length === 0 && !notificationState.loading ?
                        <div>
                            <p>{t("noNotifications")}</p>
                        </div> :
                        getNotificationsList()
                    }
                    {
                        notificationState.loading &&
                        <div className="loader mr-auto"></div>
                    }
                </InfiniteScroll>
            </div>

        </div>
    );
}

export default Notifications;