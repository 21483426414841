import { REGEX_CONSTANTS, APP_CONSTANTS } from '../constants/GenericConstants';

export function validateEmail(str) {
    return (REGEX_CONSTANTS.Email.test(str))
}

export function validateMandatoryValue(str) {
    return !(!str || str === '' || str.trim().length < 1)
}

export function isEmpty(errors) {
    return !Object.keys(errors).length;
}

export function validatePasswordLength(str) {
    return str.length >= APP_CONSTANTS.MIN_PASSWORD_LENGTH && str.length <= APP_CONSTANTS.MAX_PASSWORD_LENGTH;
}
export function validateZipLength(str) {
    return str.length >= 0 && str.length <= 10;
}

export function validateTagsLength(arr) {
    return arr.length < APP_CONSTANTS.MAX_TAGS_LENGTH;
}

export function validateTagsLengthSubmit(arr) {
    return arr.length <= APP_CONSTANTS.MAX_TAGS_LENGTH;
}

export function validateAtLeastOne(arr) {
    return arr.length >= 1;
}

export function validatePermissions(perm) {
    return perm > 0;
}

export function validateNamesLength(str) {
    return str.length >= APP_CONSTANTS.MIN_NAMES_LENGTH && str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH;
}

export function validateLength15C(str) {
    return str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH15;
}

export function validateLength18C(str) {
    return str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH18;
}

export function validateLength30C(str) {
    return str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH30;
}

export function validateLength45C(str) {
    return str.length <= 45;
}

export function validateLength50C(str) {
    return str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH50;
}

export function validateLength100C(str) {
    return str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH100;
}

export function validateLength300C(str) {
    return str.length <= APP_CONSTANTS.MAX_NAMES_LENGTH300;
}

export function validateMaximumLength(str, length) {
    return str.length <= length;
}
