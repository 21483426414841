import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputLabel, Input, FormHelperText, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PhoneInput, { getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input';
import { validateEmail, validateMandatoryValue, validateMaximumLength } from "../../../helpers/Validation";
import { APP_CONSTANTS } from "../../../constants/GenericConstants";

const ContactInformationSection = (props) => {

    const { t } = useTranslation();

    const { data, setData, errors, setErrors, disabled, formSubmitted, personalInfoData, personalInfoErrors} = props;
    const { homeAddress, schoolName, schoolAddress, contactName1, contactPhone1,  contactName2, contactPhone2, newsletterSubscribed } = data;
    const { schoolNameError, contactName1Error, contactPhone1Error, contactName2Error, contactPhone2Error } = errors;

    const [expanded, setExpanded] = useState(false);

    var phoneCode1 = ""
    var phoneCode2 = ""



    const validateEmailAddress = (email) => {
        if (!validateMandatoryValue(email) || !validateEmail(email)) {
            setErrors({ ...errors, emailError: t("invalidEmailError") });
        } else {
            setErrors({ ...errors, emailError: "" });
        }
    }
    
    const handleEmailChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, email: input });
        validateEmailAddress(input);
    }

    const handleInputChange = (event) => {
        const name = event.target && event.target.name;
        const value = event.target && event.target.value ? event.target.value : "";
        setData({
            ...data,
            [name]: value,
        });
        if (name === "schoolName") {
            validateSchoolName(value);
        }
        if (name === "contactName1") {
            validateContactName1(value);
        }
        if (name === "contactName2") {
            validateContactName2(value);
        }
    }

    const validateSchoolName = (schoolName) => {
        if (validateMandatoryValue(schoolName) && !validateMaximumLength(schoolName, APP_CONSTANTS.MAX_SCHOOL_NAME_LENGTH)) {
            setErrors({ ...errors, schoolNameError: t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_SCHOOL_NAME_LENGTH) });
        } else {
            setErrors({ ...errors, schoolNameError: "" });
        }
    }

    const validateContactName1 = (contactName) => {
        if (validateMandatoryValue(contactName) && !validateMaximumLength(contactName, APP_CONSTANTS.MAX_LENGTH_CONTACT)) {
            setErrors({ ...errors, contactName1Error: t("contactNameLengthError").replace(/{maxLength}/, APP_CONSTANTS.MAX_LENGTH_CONTACT) });
        } else {
            setErrors({ ...errors, contactName1Error: "" });
        }
    }
    const validateContactName2 = (contactName) => {
        if (validateMandatoryValue(contactName) && !validateMaximumLength(contactName, APP_CONSTANTS.MAX_LENGTH_CONTACT)) {
            setErrors({ ...errors, contactName2Error: t("contactNameLengthError").replace(/{maxLength}/, APP_CONSTANTS.MAX_LENGTH_CONTACT) });
        } else {
            setErrors({ ...errors, contactName2Error: "" });
        }
    }

    const handleContactPhone1Change = (value) => {

        if (value === undefined) {
            // setData({
            //     ...data,
            //     contactPhone1: phoneCode1,
            // });
        }
        else {
            setData({
                ...data,
                contactPhone1: value,
            });
        }

            validatePhone1(value);
    }
    const handleContactPhone2Change = (value) => {

        if (value === undefined) {
            // setData({
            //     ...data,
            //     contactPhone2: phoneCode2,
            // });
        }
        else {
            setData({
                ...data,
                contactPhone2: value,
            });
        }

            validatePhone2(value);
    }

    const handleSubscriptionChange = (value) => {

        if (value !== undefined) {
            setData({
                ...data,
                newsletterSubscribed: value
            });
        }
    }

    const validatePhone1 = (phone) => {
        if (validateMandatoryValue(phone) && !isPossiblePhoneNumber(phone)) {
            setErrors({ ...errors, contactPhone1Error: t("invalidPhone") });
        } else {
            setErrors({ ...errors, contactPhone1Error: "" });
        }
    }
    const validatePhone2 = (phone) => {
        if (validateMandatoryValue(phone) && !isPossiblePhoneNumber(phone)) {
            setErrors({ ...errors, contactPhone2Error: t("invalidPhone") });
        } else {
            setErrors({ ...errors, contactPhone2Error: "" });
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    return (
        <div className="view-edit-section view-edit-contact">
            <div className="expand-collapse-title">
                <h4>{t("contactAndAddress")}</h4>
                {
                    expanded ? <ExpandLessIcon onClick={() => setExpanded(false)} /> : <ExpandMoreIcon onClick={() => setExpanded(true)} />
                }
            </div>

            {
                expanded &&
                <form>
                    <Grid container spacing={3}>
                            <Grid item md={8} xs={12}>
                                <div className="form-field">
                                    <InputLabel htmlFor="email" required>{t("email")}</InputLabel>
                                    <Input id="email" name="email" disabled={disabled} type="email" value={personalInfoData?.email || ""} onChange={handleEmailChange} error={showInputError(personalInfoErrors?.emailError)} />
                                    {/* {
                                        showInputError(emailError) && <FormHelperText error={showInputError(emailError)}>{emailError}</FormHelperText>
                                    } */}
                                </div>
                            </Grid>

                        <Grid item md={8} sm={6} xs={12}>
                            <div>
                                <InputLabel htmlFor="homeAddress">{t("homeAddress")}</InputLabel>
                                <Input disabled={disabled} id="homeAddress" name="homeAddress" type="text" value={homeAddress || ""} onChange={handleInputChange} placeholder={t("homeAddressPlaceholder")} />
                            </div>
                        </Grid>

                        {/* <Grid item md={4} sm={6} xs={12}>
                            <div>
                                <InputLabel htmlFor="schoolName">{t("schoolName")}</InputLabel>
                                <Input disabled={disabled} id="schoolName" name="schoolName" type="text" value={schoolName || ""} onChange={handleInputChange} placeholder={t("schoolNamePlaceholder")} />
                                {
                                    showInputError(schoolNameError) && <FormHelperText error={showInputError(schoolNameError)}>{schoolNameError}</FormHelperText>
                                }
                            </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <div>
                                <InputLabel htmlFor="schoolAddress">{t("schoolAddress")}</InputLabel>
                                <Input disabled={disabled} id="schoolAddress" name="schoolAddress" type="text" value={schoolAddress || ""} onChange={handleInputChange} placeholder={t("schoolAddressPlaceholder")} />
                            </div>
                        </Grid> */}
                        <Grid item md={4} sm={6} xs={12}></Grid>
                        {/* <Grid item md={4} sm={6} xs={12}>
                            <div>
                                <InputLabel htmlFor="contactName1">{t("contactNamePlaceholder")}</InputLabel>
                                <Input disabled={disabled} id="contactName1" name="contactName1" type="text" value={contactName1 || ""} onChange={handleInputChange} placeholder={t("contactNamePlaceholder")} />
                                {
                                    showInputError(contactName1Error) && <FormHelperText error={showInputError(contactName1Error)}>{contactName1Error}</FormHelperText>
                                }
                            </div>
                        </Grid>
                        <Grid></Grid> */}

                        <Grid item md={8} sm={6} xs={12}>
                            <div>
                                <InputLabel>{t("contactPhonePlaceholder")}</InputLabel>
                                <PhoneInput
                                    disabled={disabled}
                                    value={contactPhone1}
                                    defaultCountry={"US"}
                                    placeholder={t("contactPhonePlaceholder")}
                                    countryCallingCodeEditable={false}
                                    withCountryCallingCode
                                    international
                                    onCountryChange={(e) => {
                                        try {
                                            phoneCode1 = "+" + getCountryCallingCode(e)
                                        }
                                        catch (err) { }
                                    }}

                                    countryOptionsOrder={["US", "..."]}
                                    onChange={handleContactPhone1Change} />
                                {
                                   formSubmitted && showInputError(contactPhone1Error) && <FormHelperText error={showInputError(contactPhone1Error)}>{contactPhone1Error}</FormHelperText>
                                }
                            </div>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}></Grid>
                        {/* <Grid item md={4} sm={6} xs={12} >
                            <div>
                                <InputLabel htmlFor="contactName2">{t("contactNamePlaceholder")} 2</InputLabel>
                                <Input disabled={disabled} id="contactName2" name="contactName2" type="text" value={contactName2 || ""} onChange={handleInputChange} placeholder={t("contactNamePlaceholder")} />
                                {
                                    showInputError(contactName2Error) && <FormHelperText error={showInputError(contactName2Error)}>{contactName2Error}</FormHelperText>
                                }
                            </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <div>
                                <InputLabel>{t("contactPhonePlaceholder")} 2</InputLabel>
                                <PhoneInput
                                    disabled={disabled}
                                    value={contactPhone2}
                                    defaultCountry={"US"}
                                    countryCallingCodeEditable={false}
                                    placeholder={t("contactPhonePlaceholder")}
                                    withCountryCallingCode
                                    international
                                    onCountryChange={(e) => {
                                        try {
                                            phoneCode2 = "+" + getCountryCallingCode(e)
                                        }
                                        catch (err) { }
                                    }}

                                    countryOptionsOrder={["US", "..."]}
                                    onChange={handleContactPhone2Change} />
                                {
                                   formSubmitted && showInputError(contactPhone2Error) && <FormHelperText error={showInputError(contactPhone2Error)}>{contactPhone2Error}</FormHelperText>
                                }
                            </div>
                        </Grid> */}
                        <Grid item md={4} sm={6} xs={12}></Grid>
                        
                        <Grid item md={12} sm={6} xs={12}>
                            <div className="subscribe-newsletter">
                                <label>
                                <input type="checkbox" 
                                // value={newsletterSubscribed} onChange={handleSubscriptionChange}
                                 defaultChecked={newsletterSubscribed}
                                 onChange={() => handleSubscriptionChange(!newsletterSubscribed)}
                                 ></input>
                                    {t("subscribeToNewsletter")}
                                </label>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            }
        </div>
    );
};

export default ContactInformationSection;