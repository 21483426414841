import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Container, Card, Link, Button, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import AccountInfoPersonal from "../../components/AccountSettings/AccountInfoPersonal";

import { getUser, editUser, getPresignedURL as getPresignedURLUser, uploadImage, getAllCountriesInitial, getStatesOfCountryInitial, getCitiesOfStateInitial, cancelSubscription, getSubscriptionList, updatePaymentCard, resubscribePayment, updateUserInStorage } from "../../store/actions/Authentication";
import { getTimeInMS, parseQueryParams } from "../../helpers/GenericHelper";
import { ADD_CHILD_STATUS, LOCAL_STORAGE_KEYS, SUBSCRIPTION_STATUS, USER_TYPE } from "../../constants/GenericConstants";
import { getEditPermValueIndividual } from "../../helpers/PermissionCalculator";
import { PRIV_CONSTS } from "../../constants/StaticData";
import { getAllCountries, getStatesOfCountry, getCitiesOfState } from "../../store/actions/Authentication";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";
import { validateMandatoryValue, validateZipLength } from "../../helpers/Validation";
import { getPaymentAdverb, getSubscriptionPeriod } from "../../helpers/GenericHelper";
import DateHelper from "../../helpers/DateHelper";
import AssetConstants from "../../constants/AssetConstants";
import { getPresignedURL } from "../../store/actions/Child";


const AccountScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);

    const [userId, setUserId] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [changePlanDialogOpen, setChangePlanDialogOpen] = useState(false);
    const [paymentPlan, setPaymentPlan] = useState("");
    const [cancelPlanDialogOpen, setCancelPlanDialogOpen] = useState(false);
    const [cancelPlanSuccess, setCancelPlanSuccess] = useState(false);
    const [cancelPlanError, setCancelPlanError] = useState(false);
    const [cancelPlanErrorMessage, setCancelPlanErrorMessage] = useState("");
    const [paymentPlanError, setPaymentPlanError] = useState("");
    const [updateCardFailure, setUpdateCardFailure] = useState(false);
    const [updateCardFailureMessage, setUpdateCardFailureMessage] = useState("");
    const [paymentInfoUpdatedSuccess, setPaymentInfoUpdatedSuccess] = useState(false);
    const [paymentInfoUpdatedFailure, setPaymentInfoUpdatedFailure] = useState(false);

    const [resubscribeSuccess, setResubscribeSuccess] = useState(false);
    const [resubscribeFailure, setResubscribeFailure] = useState(false);
    const [resubscribeFailureMessage, setResubscribeFailureMessage] = useState("");

    const [stripeResubscribeSuccess, setStripeResubscribeSuccess] = useState(false);
    const [stripeResubscribeFailure, setStripeResubscribeFailure] = useState(false);

    const [dataGot, setDataGot] = useState({});

    var countries = [];

    const [personalInfoData, setPersonalInfoData] = useState(
        {
            firstName: "",
            lastName: "",
            country: "",
            countryState: "",
            city: "",
            zipCode: "",
            email: "",
            phoneNumber: "",
            childImage: {},
            photoURL: "",
            photo: "",
            userSubscription: {},
        }
    );

    const [personalInfoErrors, setPersonalInfoErrors] = useState(
        {
            firstNameError: "",
            lastNameError: "",
            countryError: "",
            cityError: "",
            zipCodeError: "",
            emailError: "",
            phoneNumberError: "",
            photoURLError: "",
            photoError: "",
            childImageError: "",

        }
    );

    const [subscriptionData, setSubscriptionData] = useState({});

    const [countryStateError, setCountryStateError] = useState("");

    const initializeScreen = (afterInitialize = null) => {
        setLocalLoading(true)
        dispatch(getSubscriptionList());
        dispatch(getAllCountriesInitial((res) => {
            const userId = decodeURIComponent(props.match.params.userId);
            if (userId) {
                countries = res;
                dispatch(getUser(onLoadSuccess, onLoadFailure));
                setUserId(userId)
                const queryParams = parseQueryParams(props);
                if (queryParams.cardUpdateStatus) {
                    const status = queryParams.cardUpdateStatus;
                    if (status == "success") {
                        setPaymentInfoUpdatedSuccess(true);
                    } else {
                        setPaymentInfoUpdatedFailure(true);
                    }
                }
                if (afterInitialize) {
                    afterInitialize();
                }
            }
        }, () => { //failure
            onLoadFailure()
        }));
    }

    useEffect(() => {
        document.title = "Thrive App - Account Settings"
        initializeScreen();
    }, []);


    const onLoadSuccess = async (userData) => {
        const queryParams = parseQueryParams(props);
        if (queryParams.subscriptionRenewed) {
            const status = queryParams.subscriptionRenewed;
            if (status == "success") {
                const cloneData = JSON.parse(JSON.stringify(userData));
                dispatch(updateUserInStorage(cloneData));
                setStripeResubscribeSuccess(true);
            } else {
                setStripeResubscribeFailure(true);
            }
        }

        if (userData.subscription && Object.entries(userData.subscription).length > 0) {
            setSubscriptionData(userData.subscription);
            setPaymentPlan("" + userData.subscription.planId);
        }
        var temp = {}

        const countryGot = countries.find((currentCountry) => currentCountry.name === userData.country);
        let stateGot = "";
        let cityGot = "";
        dispatch(getStatesOfCountryInitial(countryGot?.isoCode, (res) => {
            stateGot = res.find((currentState) => currentState.name === userData.state)
            dispatch(getCitiesOfStateInitial(countryGot && countryGot.isoCode, stateGot && stateGot.isoCode, (resCity) => {
                cityGot = resCity.find((currentState) => currentState.name === userData.city)
                setPersonalInfoData({
                    ...personalInfoData,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    country: countryGot && countryGot.isoCode,
                    countryState: stateGot && stateGot.isoCode,
                    phoneNumber: userData.phoneNumber,
                    city: cityGot.name,
                    zipCode: userData.zipCode,
                    email: userData.emailAddress,
                    photo: userData.photo,
                    photoURL: userData.photo && userData.photo.includes(".") ? userData.photoURL : "https://i.stack.imgur.com/l60Hf.png",
                    userSubscription: userData.subscription,
                    userType: userData.userType
                });
                temp = { ...userData, country: countryGot && countryGot.isoCode, countryState: stateGot && stateGot.isoCode, city: cityGot.name }
                setDataGot(temp)

                setLocalLoading(false)

            }, () => { //failure
                // onLoadFailure()
                setPersonalInfoData({
                    ...personalInfoData,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    country: countryGot && countryGot.isoCode,
                    countryState: stateGot && stateGot.isoCode,
                    phoneNumber: userData.phoneNumber,
                    zipCode: userData.zipCode,
                    email: userData.emailAddress,
                    photo: userData.photo,
                    photoURL: userData.photo && userData.photo.includes(".") ? userData.photoURL : "https://i.stack.imgur.com/l60Hf.png",
                    userType: userData.userType
                });
                temp = { ...userData, country: countryGot && countryGot.isoCode, countryState: stateGot && stateGot.isoCode, city: "" }
                setDataGot(temp)

                setLocalLoading(false)

            }))
        }, () => { //failure
            // onLoadFailure()
            setPersonalInfoData({
                ...personalInfoData,
                firstName: userData.firstName,
                lastName: userData.lastName,
                country: countryGot && countryGot.isoCode,
                phoneNumber: userData.phoneNumber,
                zipCode: userData.zipCode,
                email: userData.emailAddress,
                photo: userData.photo,
                photoURL: userData.photo && userData.photo.includes(".") ? userData.photoURL : "https://i.stack.imgur.com/l60Hf.png",
                userType: userData.userType
            });
            temp = { ...userData, country: countryGot && countryGot.isoCode, countryState: "", city: "" }
            setDataGot(temp)
            setLocalLoading(false)

        }))



    }

    const onLoadFailure = (err) => {
        setLocalLoading(false)
        setErrorAlert(true);
        setTimeout(() => {
            history.replace({ pathname: RouteConstants.DASHBOARD });
        }, 2000);
    }


    const goToDashboard = () => {
        history.push({ pathname: RouteConstants.DASHBOARD });
    }

    const onEditSuccess = () => {
        setEditSuccess(true);
        setTimeout(() => {
            goToDashboard();
        }, 2000);
    };

    const onEditFailure = (err) => {
        alert(err)
        setErrorAlert(true);
    };

    const validateCountryState = (countryState, city, zipCode) => {
        let localStateError = "", localCityError = "", localZipCodeError = "";
        let valid = true;
        if (!validateMandatoryValue(countryState)) {
            localStateError = t("stateRequiredError");
            valid = false;
        }
        if (!validateMandatoryValue(city)) {
            localCityError = t("cityRequired");
            valid = false;
        }
        if (!validateMandatoryValue(zipCode)) {
            localZipCodeError = t("zipCodeRequiredError");
            valid = false;
        }
        else if (!validateZipLength(zipCode)) {
            localZipCodeError = t("zipCodeLengthError");
            valid = false;
        }
        setPersonalInfoErrors({ ...personalInfoErrors, cityError: localCityError, zipCodeError: localZipCodeError });
        setCountryStateError(localStateError);
        return valid;
    }

    const isFormValid = () => {
        const { countryState, city, zipCode } = personalInfoData;
        // if (!validateCountryState(countryState, city, zipCode)) return false;
        const { firstNameError, lastNameError, countryError, cityError, zipCodeError, emailError, phoneNumberError, childImageError } = personalInfoErrors;

        if (firstNameError === "" && lastNameError === "" && countryError === "" &&
            (cityError === "" || (personalInfoData.countryState !== "" && userState.citiesList.length === 0))
            &&
            zipCodeError === "" && emailError === "" && phoneNumberError === "" && countryStateError === "" && childImageError === "") {
            return true;
        }
        return false;
    }

    const saveChanges = () => {
        setFormSubmitted(true)
        if (isFormValid()) {
            if (Object.keys(personalInfoData.childImage).length !== 0) {
                personalInfoData.childImage.file.name = getTimeInMS(personalInfoData.childImage.file.name);

            if (personalInfoData.userType === USER_TYPE.SINGLE) {
                dispatch(getPresignedURL(personalInfoData.childImage.file.name, onGetUrl, onFailedUrl))
            }

            dispatch(getPresignedURLUser(personalInfoData.childImage.file.name, onGetUrl, onFailedUrl))
                
        }
            else {
                const country = userState.countriesList.find((currentCountry) => currentCountry.isoCode === personalInfoData.country);
                let index = userState.statesList.findIndex((currentState) => currentState.isoCode === personalInfoData.countryState);
                dispatch(editUser({ ...personalInfoData, country: country.name, countryState: userState.statesList[index].name, id: userId }, onEditSuccess, onEditFailure));
            }
        }
    }

    const onGetUrl = (res) => {

        dispatch(uploadImage(personalInfoData.childImage.file, res.data.url,
            (res) => {
                console.log("Success Called", res);
                const country = userState.countriesList.find((currentCountry) => currentCountry.isoCode === personalInfoData.country);
                let index = userState.statesList.findIndex((currentState) => currentState.isoCode === personalInfoData.countryState);

                dispatch(editUser({ ...personalInfoData, country: country.name, countryState: userState.statesList[index].name, id: userId }, onEditSuccess, onEditFailure));
            }
            ,
            (err) => {
                console.log("Error Called", err);
            }
        ))
    }

    const onFailedUrl = (err) => {

    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleClose = () => {
        if (editSuccess) {
            setEditSuccess(false);
        } else {
            setDeleteSuccess(false);
        }
    }

    const handleErrorAlertClose = () => {
        setErrorAlert(false);
    }

    const goToPreviousScreen = () => {
        if (checkChanged()) {
            setDialogOpen(true)
        }
        else {
            if (history.length > 1 && document.referrer) {
                const referrer = new URL(document.referrer);
                console.log("refferer",referrer.origin, window.location.origin)
                if (referrer.origin === window.location.origin) {
                  history.goBack();
                }
              }
            else{
                history.push({pathname: RouteConstants.DASHBOARD});
            }
        }
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteSuccess = () => {
        setDeleteSuccess(true);
        setTimeout(() => {
            history.push({ pathname: RouteConstants.DASHBOARD });
        }, 2000);
    }

    const deleteProfile = () => {
        // dispatch(deleteChild(childId, onDeleteSuccess));
        // handleCloseDeleteDialog();
    }

    const checkChanged = () => {

        let changed = false;

        if (personalInfoData.firstName !== dataGot.firstName
            || personalInfoData.lastName !== dataGot.lastName
            || personalInfoData.country !== dataGot.country
            || personalInfoData.countryState !== dataGot.countryState
            || personalInfoData.city !== dataGot.city
            || personalInfoData.zipCode !== dataGot.zipCode
            || Object.entries(personalInfoData.childImage).length !== 0) {
            changed = true
        }
        return changed;
    }

    const openChangePlanDialog = () => {
        setChangePlanDialogOpen(true);
    }

    const handleChangePlanDialogClose = () => {
        setChangePlanDialogOpen(false);
    }

    const handlePaymentPlanChange = (event) => {
        setPaymentPlan(event.target.value);
    }

    const openCancelPlanDialog = () => {
        setCancelPlanDialogOpen(true);
    }

    const handleCancelPlanDialogClose = () => {
        setCancelPlanDialogOpen(false);
    }

    const onSuccessCancelSubscription = () => {
        initializeScreen(() => { setCancelPlanSuccess(true); });
    }

    const onFailureCancelSubscription = (errorMessage) => {
        setCancelPlanErrorMessage(errorMessage);
        setCancelPlanError(true);
    }

    const cancelSubscriptionPlan = () => {
        setCancelPlanDialogOpen(false);
        dispatch(cancelSubscription(onSuccessCancelSubscription, onFailureCancelSubscription));
    }

    const validatePaymentPlan = (paymentPlan) => {
        if (!validateMandatoryValue(paymentPlan)) {
            setPaymentPlanError(t("paymentPlanRequiredError"));
            return false;
        } else {
            setPaymentPlanError("");
            return true;
        }
    }

    const onProceed = () => {
        if (!validatePaymentPlan(paymentPlan)) {
            return;
        }
        console.log("Payment plan:");
        console.log(paymentPlan);
        // dispatch(createPaymentSession(+paymentPlan));
    }

    const showInputError = (field) => {
        return field.length > 0;
    }


    return (
        <div>
            {(!localLoading && userState.loading) && <Loader />}
            {localLoading && <Loader />}

            <SnackbarAlert show={editSuccess} message={t("editProfileSuccessMessage")} duration={3000} onCloseAlert={handleClose} />

            <SnackbarAlert show={cancelPlanSuccess} message={t("subscriptionPlanCancelled")} duration={5000} onCloseAlert={() => { setCancelPlanSuccess(false); }} />
            <SnackbarAlert isError={true} show={cancelPlanError} message={cancelPlanErrorMessage} duration={5000} onCloseAlert={() => { setCancelPlanError(false); }} />
            <SnackbarAlert isError={true} show={updateCardFailure} message={updateCardFailureMessage} duration={5000} onCloseAlert={() => { setUpdateCardFailure(false); }} />
            <SnackbarAlert show={paymentInfoUpdatedSuccess} message={t("paymentInfoUpdatedSuccessMessage")} duration={5000} onCloseAlert={() => { setPaymentInfoUpdatedSuccess(false); }} fontSize={16} />
            <SnackbarAlert isError={true} show={paymentInfoUpdatedFailure} message={t("paymentInfoUpdatedFailureMessage")} duration={5000} onCloseAlert={() => { setPaymentInfoUpdatedFailure(false); }} fontSize={16} />
            <SnackbarAlert show={stripeResubscribeSuccess} message={t("subscriptionRenewedMessage")} duration={5000} onCloseAlert={() => { setStripeResubscribeSuccess(false); }} />
            <SnackbarAlert isError={true} show={stripeResubscribeFailure} message={t("resubscriptionFailureMessage")} duration={5000} onCloseAlert={() => { setStripeResubscribeFailure(false); }} />

            <SnackbarAlert show={resubscribeSuccess} message={t("subscriptionRenewedMessage")} duration={5000} onCloseAlert={() => { setResubscribeSuccess(false); }} />
            <SnackbarAlert isError={true} show={resubscribeFailure} message={resubscribeFailureMessage} duration={5000} onCloseAlert={() => { setResubscribeFailure(false); }} />


            <Snackbar open={errorAlert} autoHideDuration={5000} onClose={handleErrorAlertClose} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <Alert onClose={handleErrorAlertClose} severity="error">{t("unknownError")}</Alert>
            </Snackbar>
            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("updatedInformationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={goToDashboard} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>

                </DialogActions>
            </Dialog>
            <Dialog maxWidth="xs" open={cancelPlanDialogOpen} onClose={handleCancelPlanDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCancelPlanDialogClose} />
                <DialogTitle id="alert-dialog-title">{t("subscriptionCancelHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("subscriptionCancelMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCancelPlanDialogClose} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={cancelSubscriptionPlan} color="primary">{t("yes")}</Button>

                </DialogActions>
            </Dialog>
            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("confirmDeleteChildProfile")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("confirmDeleteChildProfileMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeleteDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={deleteProfile} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth="xs" className="change-payment-plan" open={changePlanDialogOpen} onClose={handleChangePlanDialogClose} aria-labelledby="alert-payment-dialog-title" aria-describedby="alert-payment-dialog-description">
                <CloseIcon className="popup-close" onClick={handleChangePlanDialogClose} />
                <DialogTitle id="alert-payment-dialog-title">{t("choosePaymentPlan")}</DialogTitle>
                <DialogContent>
                    <div className="radio-block">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="" name="" value={paymentPlan} onChange={handlePaymentPlanChange} row >
                                {
                                    userState.subscriptionList.map((subscription, index) => {
                                        return <FormControlLabel
                                            control={<Radio />}
                                            label={
                                                <p>
                                                    {subscription.discountedPrice > 0 && <span className="savetag">{t("save") + " " + Math.round(((subscription.price - subscription.discountedPrice) * 100 / subscription.price) * 10) / 10 + "%"}</span>}
                                                    <span>{subscription.name}</span>
                                                    <strong>{subscription.discountedPrice > 0 ? '$' + subscription.discountedPrice : '$' + subscription.price}</strong>
                                                    <span className="billed-dur">{(subscription.discountedPrice > 0 ? "$" + subscription.price + " " : "") + t("billed") + " " + getPaymentAdverb(subscription.name)}</span>
                                                </p>
                                            }
                                            value={"" + subscription.id}
                                        />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                        {
                            showInputError(paymentPlanError) && <FormHelperText error={showInputError(paymentPlanError)}>{paymentPlanError}</FormHelperText>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleChangePlanDialogClose} className="goback-btn">{t("goBack")}</Button>
                    <Button variant="contained" onClick={onProceed} color="primary">{t("proceedToCheckout")}</Button>

                </DialogActions>
            </Dialog>
            <Grid container className="app">
                <Grid xs={12} item className="app-header">
                    <Header />
                </Grid>
                <Grid item xs={12} className="grid-body">
                    <Container maxWidth="false">
                        <div className="view-edit-child">
                            {
                                <Container maxWidth="lg">
                                    <h1 className="title-has-icon mb-15">
                                        <i class="ticon-arrow-right back-arrow" onClick={goToPreviousScreen}></i>
                                        {t("accountSetting")}</h1>
                                    <Card className="generic-card">
                                        <AccountInfoPersonal
                                            localLoading={localLoading}
                                            data={personalInfoData}
                                            formSubmitted={formSubmitted}
                                            setData={setPersonalInfoData}
                                            errors={personalInfoErrors}
                                            setErrors={setPersonalInfoErrors}
                                            countryStateError={countryStateError}
                                            setCountryStateError={setCountryStateError} />


                                        {/* <hr className="section-hr" /> */}


                                {/* ADD PAYMENT BACKUP FROM BACKUP FILE HERE */}


                                        
                                    </Card>
                                    <div className="add-child-form-footer">



                                        <Button variant="contained" color="secondary" disableElevation onClick={goToDashboard}>{t("cancel")}</Button>
                                        <Button color="primary" variant="contained" className="greenBtn" disableElevation onClick={saveChanges}
                                        // disabled={!isFormValid()}
                                        >{t("saveChanges")}</Button>

                                    </div>
                                </Container>
                            }
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl">
                        <Grid container spacing={6} className="my-0">
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountScreen;