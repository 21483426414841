import React from "react";
import { useTranslation } from "react-i18next";
import AssetConstants from "../../constants/AssetConstants";

const PageNotFound = () => {

    const { t } = useTranslation();

    return (
        <div>
            <h1>{t("pageNotFoundMessage")}</h1>
            <img src={AssetConstants.pagenotfound} />
        </div>
    );

};

export default PageNotFound;