import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import { ROLE_TYPES } from "../../constants/GenericConstants";
import {
  getImageURL,
  setCurrentChild,
  getChildrenList,
} from "../../store/actions/Child";
import { PRIV_CONSTS } from "../../constants/StaticData";
import { getViewPermValueIndividual } from "../../helpers/PermissionCalculator";
import ChildListItem from "./ChildListItem";
import { isEmpty } from "../../helpers/Validation";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const childState = useSelector((state) => state.child);
  const userState = useSelector((state) => state.authentication);
  const {
    selectedItem,
    editAccess,
    childIdGot,
    sideBarClose,
    onCollapse,
    collapseOnLaunch,
    isChat = false,
  } = props;

  const [viewAct, setViewAct] = useState(false);
  const [viewChildPriv, setViewChildPriv] = useState(false);
  const [viewSchPriv, setViewSchPriv] = useState(false);
  const [viewRegPriv, setViewRegPriv] = useState(false);

  const [childImage, setChildImage] = useState(
    "https://i.stack.imgur.com/l60Hf.png"
  );
  const [loaded, setLoaded] = useState(false);

  // child list sub panel
  const [expanded, setExpanded] = useState(false);
  // side menu expanded or collapsed
  const [collapsed, setCollapsed] = useState(collapseOnLaunch);

  useEffect(() => {
    dispatch(getChildrenList(true, childState.selectedChild?.childId));
    if (childIdGot) {
      let perm = checkParentAndPermView(childIdGot, PRIV_CONSTS.ACTIVITY_PRIV);
      let permChildView = checkParentAndPermView(
        childIdGot,
        PRIV_CONSTS.CHILDREN_PRIV
      );
      let permSchView = checkParentAndPermView(
        childIdGot,
        PRIV_CONSTS.SCHEDULE_PRIV
      );
      let permRegView = checkParentAndPermView(
        childIdGot,
        PRIV_CONSTS.EMOT_REG_PRIV
      );
      setViewAct(perm);
      setViewChildPriv(permChildView);
      setViewSchPriv(permSchView);
      setViewRegPriv(permRegView);
    }

    // if(!userState.isSingleUser) {
    if (
      !userState.isSingleUser ||
      childState.selectedChild?.userRole !== ROLE_TYPES.PARENT
    ) {
      if (
        childState.selectedChild?.childPhoto &&
        childState.selectedChild?.childPhoto.includes(".")
      ) {
        dispatch(
          getImageURL(
            childState.selectedChild?.childPhoto,
            onGetUrl,
            onFailedUrl
          )
        );
      } else {
        setLoaded(true);
      }
    }
  }, [childIdGot]);

  const onGetUrl = (res) => {
    setChildImage(res.data.url);
    setLoaded(false);
  };

  const onFailedUrl = (err) => {
    console.log(err);
  };

  const checkParentAndPermView = (childId, priv_const) => {
    let permGot = checkOwnChild(childId);
    if (!permGot && childState.otherChildrenList) {
      childState.otherChildrenList.map((clientData) => {
        if (clientData.childProfiles) {
          clientData.childProfiles.map((c) => {
            if (c.childId == childId) {
              let perm = getViewPermValueIndividual(
                c.userPermissions,
                priv_const
              );
              permGot = perm;
            }
          });
        }
      });
    }
    return permGot;
  };

  const checkOwnChild = (childId) => {
    let ownChild = false;
    if (childId) {
      //TO CHECK IF OWN CHILD
      if (childState.childrenList) {
        childState.childrenList.map((c) => {
          if (c.childId == childId) {
            ownChild = true;
          }
        });
      }
    }
    return ownChild;
  };

  const moveToEditChildScreen = () => {
    history.push({
      pathname: RouteConstants.EDIT_CHILD_PROFILE.replace(
        ":childId",
        childState.selectedChild?.childId
      ),
    });
  };

  const moveToChildActivitiesScreen = (childId) => {
    changeStatus();
    dispatch(setCurrentChild(childId));
    history.replace({
      pathname: RouteConstants.CHILD_ACTIVITIES.replace(":childId", childId),
    });
  };

  const getChildListItem = (child) => {
    return (
      <ChildListItem
        child={child}
        moveToChildActivitiesScreen={moveToChildActivitiesScreen}
      />
    );
  };

  const noChildrenMessage = () => {
    const parentChildren = childState.childrenList.filter(
      (child) => child.childId !== childState.selectedChild?.childId
    );
    let teacherArray = [];
    let therapistArray = [];
    for (let i = 0; i < childState.otherChildrenList.length; i++) {
      if (childState.otherChildrenList[i].role === ROLE_TYPES.TEACHER) {
        teacherArray = [
          ...teacherArray,
          ...childState.otherChildrenList[i].childProfiles,
        ];
      } else if (
        childState.otherChildrenList[i].role === ROLE_TYPES.THERAPIST
      ) {
        therapistArray = [
          ...therapistArray,
          ...childState.otherChildrenList[i].childProfiles,
        ];
      }
    }
    teacherArray = teacherArray.filter(
      (child) => child.childId !== childState.selectedChild?.childId
    );
    therapistArray = therapistArray.filter(
      (child) => child.childId !== childState.selectedChild?.childId
    );
    if (
      parentChildren.length === 0 &&
      teacherArray.length === 0 &&
      therapistArray.length === 0
    ) {
      return <span>{t("noChildFound")}</span>;
    } else {
      return null;
    }
  };

  const getParentChildren = () => {
    const parentChildren = childState.childrenList.filter(
      (child) => child.childId !== childState.selectedChild?.childId
    );
    if (parentChildren.length === 0) return;
    return (
      <div
        className={
          parentChildren.length === 1
            ? "sidebar-profile-one"
            : parentChildren.length === 2
            ? "sidebar-profile-two"
            : "sidebar-profile"
        }
      >
        <span>
          {userState.isSingleUser
            ? t("yourSingleUser")
            : t("yourChildrenAsParent")}
        </span>
        {parentChildren.map((child) => getChildListItem(child))}
      </div>
    );
  };

  const getExternalChildren = () => {
    let teacherArray = [];
    let therapistArray = [];
    for (let i = 0; i < childState.otherChildrenList.length; i++) {
      if (childState.otherChildrenList[i].role === ROLE_TYPES.TEACHER) {
        teacherArray = [
          ...teacherArray,
          ...childState.otherChildrenList[i].childProfiles,
        ];
      } else if (
        childState.otherChildrenList[i].role === ROLE_TYPES.THERAPIST
      ) {
        therapistArray = [
          ...therapistArray,
          ...childState.otherChildrenList[i].childProfiles,
        ];
      }
    }
    teacherArray = teacherArray.filter(
      (child) => child.childId !== childState.selectedChild?.childId
    );
    therapistArray = therapistArray.filter(
      (child) => child.childId !== childState.selectedChild?.childId
    );

    return (
      <div>
        {teacherArray.length > 0 && (
          <div
            className={
              teacherArray.length === 1
                ? "sidebar-profile-one"
                : teacherArray.length === 2
                ? "sidebar-profile-two"
                : "sidebar-profile"
            }
          >
            <span>{t("yourChildrenAsTeacher")}</span>
            {teacherArray.map((child) => getChildListItem(child))}
          </div>
        )}
        {therapistArray.length > 0 && (
          <div
            className={
              therapistArray.length === 1
                ? "sidebar-profile-one"
                : therapistArray.length === 2
                ? "sidebar-profile-two"
                : "sidebar-profile"
            }
          >
            <span>{t("yourChildrenAsTherapist")}</span>
            {therapistArray.map((child) => getChildListItem(child))}
          </div>
        )}
      </div>
    );
  };

  const changeStatus = () => {
    setExpanded(!expanded);
  };

  const moveToDashboard = () => {
    history.push({ pathname: RouteConstants.DASHBOARD });
  };

  const moveToScheduleScreen = () => {
    history.push({
      pathname: RouteConstants.SCHEDULE_ACTIVITY.replace(
        ":childId",
        childState.selectedChild?.childId
      ),
    });
  };

  const moveToActivitiesScreen = () => {
    history.push({
      pathname: RouteConstants.CHILD_ACTIVITIES.replace(
        ":childId",
        childState.selectedChild?.childId
      ),
    });
  };

  const moveToSelfRegulationScreen = () => {
    history.push({
      pathname: RouteConstants.SELF_REGULATION.replace(
        ":childId",
        childState.selectedChild?.childId
      ),
    });
  };

  const moveToChatScreen = () => {
    history.push({
      pathname: RouteConstants.CHAT.replace(
        ":childId",
        childState.selectedChild.childId
      ),
    });
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    setExpanded(false);

    if (onCollapse) onCollapse();
  };

  const getImageSrc = () => {
    if (
      userState.isSingleUser &&
      childState.selectedChild?.userRole === ROLE_TYPES.PARENT
    ) {
      return !isEmpty(userState.user) &&
        userState.user.photo &&
        userState.user.photo.includes(".")
        ? userState.user.photoURL
        : "https://i.stack.imgur.com/l60Hf.png";
    } else return childImage;
  };

  const getUserName = () => {
    if (
      userState.isSingleUser &&
      childState.selectedChild?.userRole === ROLE_TYPES.PARENT
    ) {
      return childState.selectedChild?.parentFirstName;
    }
    return childState.selectedChild?.childFirstName;
  };

  return (
    <div
      className="generic-card px-0 py-0"
      style={{marginRight:"10px"}}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="sidebar-menu">
        <p
          onClick={() => {
            sideBarClose();
          }}
          className="sidebarClose"
        >
          x
        </p>

        <div className="sidebar-profile">
          <div className="ls-elements-container">
            <div className="imgLoader">
              <figure>
                {!loaded && <p className="loader"></p>}
                <img
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  src={getImageSrc()}
                  alt="Child Profile"
                />
              </figure>
            </div>
            {!collapsed && (
              <>
                <div className="sidebar-profile-title">
                  <h5>{getUserName()}</h5>
                  {viewChildPriv &&
                  (!userState.isSingleUser ||
                    childState.selectedChild?.userRole !==
                      ROLE_TYPES.PARENT) ? (
                    <Link onClick={moveToEditChildScreen}>{t("edit")}</Link>
                  ) : null}
                </div>
                <i
                  className={
                    childState.selectedChild?.deviceStatus
                      ? "ticon-sync-on"
                      : "ticon-sync-off"
                  }
                ></i>{" "}
                {/* DEV to do: please handle the sync icon dynamically */}
                <span onClick={changeStatus}>
                  {/* <i className="ticon-chevron-down"></i> */}
                  <i className="ticon-chevron-down"></i>
                </span>
              </>
            )}
          </div>
          {expanded && (
            <div className="secondSidebarMenu">
              {(!userState.isSingleUser ||
                childState.selectedChild?.userRole !== ROLE_TYPES.PARENT) &&
                getParentChildren()}
              {getExternalChildren()}
              {noChildrenMessage()}
            </div>
          )}
        </div>

        <List className="menu-list">
         { !isChat && <MenuListItem
            text={t("dashboard")}
            icon="ticon-meter"
            selected={selectedItem === t("dashboard")}
            collapsed={collapsed}
            comingSoon={false}
            onClick={moveToDashboard}
          /> }

          {viewSchPriv && (
            <MenuListItem
              text={t("schedule")}
              icon="ticon-calender-clock"
              selected={selectedItem === t("schedule")}
              collapsed={collapsed}
              comingSoon={false}
              onClick={moveToScheduleScreen}
            />
          )}
          {viewAct && (
            <MenuListItem
              text={t("activities")}
              icon="ticon-profile-card"
              selected={selectedItem === t("activities")}
              collapsed={collapsed}
              comingSoon={false}
              onClick={moveToActivitiesScreen}
            />
          )}
          {viewAct && (
            <MenuListItem
              text={t("selfRegulation")}
              icon="ticon-lotus"
              selected={selectedItem === t("selfRegulation")}
              collapsed={collapsed}
              comingSoon={false}
              onClick={moveToSelfRegulationScreen}
            />
          )}

          {/* {
            viewAct && (
              <MenuListItem
                text={t("chat")}
                icon="ticon-chatting"
                selected={selectedItem === t("chat")}
                collapsed={collapsed}
                onClick={moveToChatScreen}
              />
            )
          } */}

          {/* <div className="comingsoon-sep"><span className="comingSoon">Coming soon</span></div> */}

          {/* <MenuListItem 
                        text={t("rewards")}
                        icon="ticon-badge"
                        selected={selectedItem === t("rewards")}
                        collapsed={collapsed}
                        comingSoon={true} 
                    /> */}

          {/* <MenuListItem 
                        text={t("reports")}
                        icon="ticon-graph"
                        selected={selectedItem === t("reports")}
                        collapsed={collapsed}
                        comingSoon={true} 
                    />

                    <MenuListItem 
                        text={t("messages")}
                        icon="ticon-chatting"
                        selected={selectedItem === t("messages")}
                        collapsed={collapsed}
                        comingSoon={true} 
                    /> */}

          {isChat && (
            <>
              <MenuListItem
                text={t("chat")}
                icon="ticon-graph"
                selected={selectedItem === t("chat")}
                collapsed={collapsed}
              />

              <MenuListItem
                text={t("chatUser")}
                icon="ticon-chatting"
                selected={selectedItem === t("chatUser")}
                collapsed={collapsed}
              />
              <MenuListItem
                text={t("chatSetting")}
                icon="ticon-badge"
                selected={selectedItem === t("chatSetting")}
                collapsed={collapsed}
              />
            </>
          )}

          {/* <ListItem button className={selectedItem === t("geoFence") && "selected"}>
                        <i className="ticon-fence"></i>
                        <ListItemText primary={t("geoFence")} />
                    </ListItem> */}
        </List>
        {/* <div style={{textAlign:'center', height:'30px'}}></div> */}
        <div className="ocBtn">
          <Button onClick={handleCollapse}>
            {collapsed ? (
              <span className="cbox"></span>
            ) : (
              <span className="obox"></span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const MenuListItem = ({
  text,
  icon,
  selected,
  onClick,
  collapsed,
  comingSoon,
}) => {
  return (
    <ListItem
      button
      className={selected && "selected"}
      onClick={onClick}
      style={
        collapsed
          ? { paddingLeft: "14px", height: "40px" }
          : { paddingLeft: "0px", height: "40px" }
      }
    >
      <i className={icon}></i>
      {!collapsed && <ListItemText primary={text} />}
      {!collapsed && comingSoon && (
        <span className="comingSoon">Coming soon</span>
      )}
    </ListItem>
  );
};

export default Sidebar;
