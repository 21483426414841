export const LOGIN_ACTIONS = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  RESET_LOGIN_ERROR: "RESET_LOGIN_ERROR",
};

export const GET_HEAR_ABOUT_US_OPTIONS = {
  GET_HEAR_ABOUT_US_OPTIONS: "GET_HEAR_ABOUT_US_OPTIONS",
  GET_HEAR_ABOUT_US_OPTIONS_SUCCESS: "GET_HEAR_ABOUT_US_OPTIONS_SUCCESS",
  GET_HEAR_ABOUT_US_OPTIONS_FAILURE: "GET_HEAR_ABOUT_US_OPTIONS_FAILURE",
};

export const SIGN_UP_ACTIONS = {
  SIGN_UP: "SIGN_UP",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
};

export const FIRST_LOGIN_ACTIONS = {
  FIRST_LOGIN: "FIRST_LOGIN",
  FIRST_LOGIN_SUCCESS: "FIRST_LOGIN_SUCCESS",
  FIRST_LOGIN_FAILURE: "FIRST_LOGIN_FAILURE",
};

export const GET_ALL_COUNTRIES_ACTIONS = {
  GET_ALL_COUNTRIES: "GET_ALL_COUNTRIES",
  GET_ALL_COUNTRIES_SUCCESS: "GET_ALL_COUNTRIES_SUCCESS",
  GET_ALL_COUNTRIES_FAILURE: "GET_ALL_COUNTRIES_FAILURE",
};

export const GET_GENDER_LIST_ACTIONS = {
  GET_GENDER_LIST: "GET_GENDER_LIST",
  GET_GENDER_LIST_SUCCESS: "GET_GENDER_LIST_SUCCESS",
  GET_GENDER_LIST_FAILURE: "GET_GENDER_LIST_FAILURE",
};

export const GET_COUNTRY_STATES_ACTIONS = {
  GET_COUNTRY_STATES: "GET_COUNTRY_STATES",
  GET_COUNTRY_STATES_SUCCESS: "GET_COUNTRY_STATES_SUCCESS",
  GET_COUNTRY_STATES_FAILURE: "GET_COUNTRY_STATES_FAILURE",
};

export const GET_STATE_CITIES_ACTIONS = {
  GET_STATE_CITIES: "GET_STATE_CITIES",
  GET_STATE_CITIES_SUCCESS: "GET_STATE_CITIES_SUCCESS",
  GET_STATE_CITIES_FAILURE: "GET_STATE_CITIES_FAILURE",
};

export const VIEW_USER_ACTIONS = {
  VIEW_USER: "VIEW_USER",
  VIEW_USER_SUCCESS: "VIEW_USER_SUCCESS",
  VIEW_USER_FAILURE: "VIEW_USER_FAILURE",
};

export const EDIT_USER_ACTIONS = {
  EDIT_USER: "EDIT_USER",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",
};

export const CONFIRM_EMAIL_ACTIONS = {
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAILURE: "CONFIRM_EMAIL_FAILURE",
};
export const FORGOT_RESET_ACTIONS = {
  FORGOT_RESET: "FORGOT_RESET",
  FORGOT_RESET_SUCCESS: "FORGOT_RESET_SUCCESS",
  FORGOT_RESET_FAILURE: "FORGOT_RESET_FAILURE",
};

export const JUST_LOADING_ACTIONS = {
  JUST_LOADING: "JUST_LOADING",
  JUST_LOADING_SUCCESS: "JUST_LOADING_SUCCESS",
  JUST_LOADING_FAILURE: "JUST_LOADING_FAILURE",
};

export const RESET_PASSWORD_ACTIONS = {
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};

export const SIGN_OUT_ACTIONS = {
  SIGN_OUT: "SIGN_OUT",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
};

export const ADD_CHILD_ACTIONS = {
  ADD_CHILD: "ADD_CHILD",
  ADD_CHILD_SUCCESS: "ADD_CHILD_SUCCESS",
  ADD_CHILD_FAILURE: "ADD_CHILD_FAILURE",
};

export const ADD_EX_USER_ACTIONS = {
  ADD_EX_USER: "ADD_EX_USER",
  ADD_EX_USER_SUCCESS: "ADD_EX_USER_SUCCESS",
  ADD_EX_USER_FAILURE: "ADD_EX_USER_FAILURE",
};

export const GET_EX_USER_LIST_ACTIONS = {
  GET_EX_USER_LIST: "GET_EX_USER_LIST",
  GET_EX_USER_LIST_SUCCESS: "GET_EX_USER_LIST_SUCCESS",
  GET_EX_USER_LIST_FAILURE: "GET_EX_USER_LIST_FAILURE",
};

export const GET_ROLE_LIST_ACTIONS = {
  GET_ROLE_LIST: "GET_ROLE_LIST",
  GET_ROLE_LIST_SUCCESS: "GET_ROLE_LIST_SUCCESS",
  GET_ROLE_LIST_FAILURE: "GET_ROLE_LIST_FAILURE",
};

export const VIEW_EX_USER_ACTIONS = {
  VIEW_EX_USER: "VIEW_EX_USER",
  VIEW_EX_USER_SUCCESS: "VIEW_EX_USER_SUCCESS",
  VIEW_EX_USER_FAILURE: "VIEW_EX_USER_FAILURE",
};

export const EDIT_EX_USER_ACTIONS = {
  EDIT_EX_USER: "EDIT_EX_USER",
  EDIT_EX_USER_SUCCESS: "EDIT_EX_USER_SUCCESS",
  EDIT_EX_USER_FAILURE: "EDIT_EX_USER_FAILURE",
};

export const IMAGE_UPLOAD_ACTIONS = {
  GET_PRESIGNED_URL: "GET_PRESIGNED_URL",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  IMAGE_UPLOAD_SUCCESS: "IMAGE_UPLOAD_SUCCESS",
  IMAGE_UPLOAD_FAILURE: "IMAGE_UPLOAD_FAILURE",
};

export const GET_CHILD_LIST_ACTIONS = {
  GET_CHILD_LIST: "GET_CHILD_LIST",
  GET_CHILD_LIST_SUCCESS: "GET_CHILD_LIST_SUCCESS",
  GET_CHILD_LIST_FAILURE: "GET_CHILD_LIST_FAILURE",
};

export const GET_CHILD_ACTIONS = {
  GET_CHILD: "GET_CHILD",
  GET_CHILD_SUCCESS: "GET_CHILD_SUCCESS",
  GET_CHILD_FAILURE: "GET_CHILD_FAILURE",
};

export const GET_CHILD_ACT_TRACKING_ACTIONS = {
  GET_CHILD_ACT_TRACKING: "GET_CHILD_ACT_TRACKING",
  GET_CHILD_ACT_TRACKING_SUCCESS: "GET_CHILD_ACT_TRACKING_SUCCESS",
  GET_CHILD_ACT_TRACKING_FAILURE: "GET_CHILD_ACT_TRACKING_FAILURE",
};

export const EDIT_CHILD_ACTIONS = {
  EDIT_CHILD: "EDIT_CHILD",
  EDIT_CHILD_SUCCESS: "EDIT_CHILD_SUCCESS",
  EDIT_CHILD_FAILURE: "EDIT_CHILD_FAILURE",
  RESET_EDIT_CHILD_DATA: "RESET_EDIT_CHILD_DATA",
};

export const CONFIRM_EXTERNAL_USER_ACTIONS = {
  CONFIRM_EXTERNAL_USER: "CONFIRM_EXTERNAL_USER",
  CONFIRM_EXTERNAL_USER_SUCCESS: "CONFIRM_EXTERNAL_USER_SUCCESS",
  CONFIRM_EXTERNAL_USER_FAILURE: "CONFIRM_EXTERNAL_USER_FAILURE",
};

export const EXTERNAL_USER_INVITE_CODE_ACTIONS = {
  SAVE_INVITE_CODE: "SAVE_INVITE_CODE",
  REMOVE_INVITE_CODE: "REMOVE_INVITE_CODE",
};

export const GET_EMAIL_FROM_INVITE_CODE_ACTIONS = {
  GET_EMAIL_FROM_INVITE_CODE: "GET_EMAIL_FROM_INVITE_CODE",
  GET_EMAIL_FROM_INVITE_CODE_SUCCESS: "GET_EMAIL_FROM_INVITE_CODE_SUCCESS",
  GET_EMAIL_FROM_INVITE_CODE_FAILURE: "GET_EMAIL_FROM_INVITE_CODE_FAILURE",
};

export const UPDATE_EXTERNAL_USER_STATUS_ACTIONS = {
  UPDATE_EXTERNAL_USER_STATUS: "UPDATE_EXTERNAL_USER_STATUS",
  UPDATE_EXTERNAL_USER_STATUS_DETAIL: "UPDATE_EXTERNAL_USER_STATUS_DETAIL",
  UPDATE_EXTERNAL_USER_STATUS_SUCCESS: "UPDATE_EXTERNAL_USER_STATUS_SUCCESS",
  UPDATE_EXTERNAL_USER_STATUS_FAILURE: "UPDATE_EXTERNAL_USER_STATUS_FAILURE",
};

export const GET_IMAGE_CATEGORIES_ACTIONS = {
  GET_IMAGE_CATEGORIES: "GET_IMAGE_CATEGORIES",
  GET_IMAGE_CATEGORIES_SUCCESS: "GET_IMAGE_CATEGORIES_SUCCESS",
  GET_IMAGE_CATEGORIES_FAILURE: "GET_IMAGE_CATEGORIES_FAILURE",
};

export const GET_IMAGE_SKINTONES_ACTIONS = {
  GET_IMAGE_SKINTONES: "GET_IMAGE_SKINTONES",
  GET_IMAGE_SKINTONES_SUCCESS: "GET_IMAGE_SKINTONES_SUCCESS",
  GET_IMAGE_SKINTONES_FAILURE: "GET_IMAGE_SKINTONES_FAILURE",
};

export const GET_IMAGE_LIBRARY_ACTIONS = {
  GET_IMAGE_LIBRARY: "GET_IMAGE_LIBRARY",
  GET_IMAGE_LIBRARY_FULL_LOAD: "GET_IMAGE_LIBRARY_FULL_LOAD",
  GET_IMAGE_LIBRARY_CLEAR_DATA: "GET_IMAGE_LIBRARY_CLEAR_DATA",
  GET_IMAGE_LIBRARY_SUCCESS: "GET_IMAGE_LIBRARY_SUCCESS",
  GET_IMAGE_LIBRARY_FAILURE: "GET_IMAGE_LIBRARY_FAILURE",
};

export const SET_CURRENT_CHILD_ACTIONS = {
  SET_CURRENT_CHILD: "SET_CURRENT_CHILD",
  SET_CURRENT_CHILD_TIMEZONE: "SET_CURRENT_CHILD_TIMEZONE",
  SET_SINGLE_USER_CHILD: "SET_SINGLE_USER_CHILD",
};

export const CHILD_EXISTS_ACTIONS = {
  CHILD_EXISTS: "CHILD_EXISTS",
  CHILD_EXISTS_ONLY: "CHILD_EXISTS_ONLY",
};

export const RESEND_CONFIRMATION_EMAIL_ACTIONS = {
  RESEND_CONFIRMATION_EMAIL: "RESEND_CONFIRMATION_EMAIL",
  RESEND_CONFIRMATION_EMAIL_SUCCESS: "RESEND_CONFIRMATION_EMAIL_SUCCESS",
  RESEND_CONFIRMATION_EMAIL_FAILURE: "RESEND_CONFIRMATION_EMAIL_FAILURE",
};

export const IMAGE_LIBRARY_LOADER_ACTIONS = {
  SHOW_LOADER: "SHOW_LOADER",
};

export const IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS = {
  GET_PRE_SIGNED_URL: "GET_PRE_SIGNED_URL",
  GET_PRE_SIGNED_URL_SUCCESS: "GET_PRE_SIGNED_URL_SUCCESS",
  GET_PRE_SIGNED_URL_FAILURE: "GET_PRE_SIGNED_URL_FAILURE",
};

export const IMAGE_LIBRARY_UPLOAD_ACTIONS = {
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
};

export const IMAGE_LIBRARY_ADD_IMAGE_ACTIONS = {
  ADD_IMAGE: "ADD_IMAGE",
  ADD_IMAGE_SUCCESS: "ADD_IMAGE_SUCCESS",
  ADD_IMAGE_FAILURE: "ADD_IMAGE_FAILURE",
};

export const IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS = {
  UPDATE_IMAGE: "UPDATE_IMAGE",
  UPDATE_IMAGE_SUCCESS: "UPDATE_IMAGE_SUCCESS",
  UPDATE_IMAGE_FAILURE: "UPDATE_IMAGE_FAILURE",
};

export const ACTIVITIES_SELECT_IMAGE_ACTIONS = {
  SELECT_ACTIVITY_IMAGE: "SELECT_ACTIVITY_IMAGE",
  SELECT_SUBTASK_IMAGE: "SELECT_SUBTASK_IMAGE",
  CLEAR_SUBTASK_IMAGE: "CLEAR_SUBTASK_IMAGE",
  SELECT_EDIT_ACTIVITY_IMAGE: "SELECT_EDIT_ACTIVITY_IMAGE",
  SELECT_EDIT_SUBTASK_IMAGE: "SELECT_EDIT_SUBTASK_IMAGE",
  CLEAR_EDIT_SUBTASK_IMAGE: "CLEAR_EDIT_SUBTASK_IMAGE",
};

export const ADD_ACTIVITY_ACTIONS = {
  ADD_ACTIVITY: "ADD_ACTIVITY",
  ADD_ACTIVITY_SUCCESS: "ADD_ACTIVITY_SUCCESS",
  ADD_ACTIVITY_FAILURE: "ADD_ACTIVITY_FAILURE",
};

export const SAVE_ACTIVITY_ACTIONS = {
  SAVE_ACTIVITY: "SAVE_ACTIVITY",
  SAVE_EDIT_ACTIVITY: "SAVE_EDIT_ACTIVITY",
  CLEAR_ACTIVITY: "CLEAR_ACTIVITY",
  CLEAR_EDIT_ACTIVITY: "CLEAR_EDIT_ACTIVITY",
};

export const GET_ACTIVITY_LIST_ACTIONS = {
  GET_ACTIVITY_LIST: "GET_ACTIVITY_LIST",
  GET_ACTIVITY_LIST_SUCCESS: "GET_ACTIVITY_LIST_SUCCESS",
  GET_ACTIVITY_LIST_FAILURE: "GET_ACTIVITY_LIST_FAILURE",
  SAVE_CURRENT_PAGE: "SAVE_CURRENT_PAGE",
  CLEAR_CURRENT_PAGE: "CLEAR_CURRENT_PAGE",
};

export const GET_ACTIVITY_ACTIONS = {
  GET_ACTIVITY: "GET_ACTIVITY",
  GET_ACTIVITY_SUCCESS: "GET_ACTIVITY_SUCCESS",
  GET_ACTIVITY_FAILURE: "GET_ACTIVITY_FAILURE",
};

export const GET_COLOR_LIST_ACTIONS = {
  GET_COLOR_LIST: "GET_COLOR_LIST",
  GET_COLOR_LIST_SUCCESS: "GET_COLOR_LIST_SUCCESS",
  GET_COLOR_LIST_FAILURE: "GET_COLOR_LIST_FAILURE",
};

export const SEARCH_ACTIVITY_ACTIONS = {
  SEARCH_ACTIVITY_LIST: "SEARCH_ACTIVITY_LIST",
  SEARCH_ACTIVITY_LIST_SUCCESS: "SEARCH_ACTIVITY_LIST_SUCCESS",
  SEARCH_ACTIVITY_LIST_FAILURE: "SEARCH_ACTIVITY_LIST_FAILURE",
};

export const UPDATE_ACTIVITY_ACTIONS = {
  UPDATE_ACTIVITY: "UPDATE_ACTIVITY",
  UPDATE_ACTIVITY_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE: "UPDATE_ACTIVITY_FAILURE",
};

export const DELETE_CHILD_ACTIONS = {
  DELETE_CHILD: "DELETE_CHILD",
  DELETE_CHILD_SUCCESS: "DELETE_CHILD_SUCCESS",
  DELETE_CHILD_FAILURE: "DELETE_CHILD_FAILURE",
};

export const LOGOUT_WATCH_CHILD_ACTIONS = {
  LOGOUT_WATCH_CHILD: "LOGOUT_WATCH_CHILD",
  LOGOUT_WATCH_CHILD_SUCCESS: "LOGOUT_WATCH_CHILD_SUCCESS",
  LOGOUT_WATCH_CHILD_FAILURE: "LOGOUT_WATCH_CHILD_FAILURE",
};

export const DELETE_EXTERNAL_USER_ACTIONS = {
  DELETE_EXTERNAL_USER: "DELETE_EXTERNAL_USER",
  DELETE_EXTERNAL_USER_SUCCESS: "DELETE_EXTERNAL_USER_SUCCESS",
  DELETE_EXTERNAL_USER_FAILURE: "DELETE_EXTERNAL_USER_FAILURE",
};

export const DELETE_LIST_EXTERNAL_USER_ACTIONS = {
  DELETE_LIST_EXTERNAL_USER: "DELETE_LIST_EXTERNAL_USER",
  DELETE_LIST_EXTERNAL_USER_SUCCESS: "DELETE_LIST_EXTERNAL_USER_SUCCESS",
  DELETE_LIST_EXTERNAL_USER_FAILURE: "DELETE_LIST_EXTERNAL_USER_FAILURE",
};

export const SCHEDULE_GET_ACTIVITIES_ACTIONS = {
  SCHEDULE_GET_ACTIVITIES: "SCHEDULE_GET_ACTIVITIES",
  SCHEDULE_GET_ACTIVITIES_CLEAR_OLD_DATA:
    "SCHEDULE_GET_ACTIVITIES_CLEAR_OLD_DATA",
  SCHEDULE_GET_ACTIVITIES_SUCCESS: "SCHEDULE_GET_ACTIVITIES_SUCCESS",
  SCHEDULE_GET_ACTIVITIES_FAILURE: "SCHEDULE_GET_ACTIVITIES_FAILURE",
};

export const SCHEDULE_ACTIVITY_ACTIONS = {
  SCHEDULE_ACTIVITY: "SCHEDULE_ACTIVITY",
  SCHEDULE_ACTIVITY_SUCCESS: "SCHEDULE_ACTIVITY_SUCCESS",
  SCHEDULE_ACTIVITY_FAILURE: "SCHEDULE_ACTIVITY_FAILURE",
};

export const UPDATE_SCHEDULE_ACTIVITY_ACTIONS = {
  UPDATE_SCHEDULE_ACTIVITY: "UPDATE_SCHEDULE_ACTIVITY",
  UPDATE_SCHEDULE_ACTIVITY_SUCCESS: "UPDATE_SCHEDULE_ACTIVITY_SUCCESS",
  UPDATE_SCHEDULE_ACTIVITY_FAILURE: "UPDATE_SCHEDULE_ACTIVITY_FAILURE",
};

export const GET_SCHEDULE_LIST_ACTIONS = {
  GET_SCHEDULE_LIST: "GET_SCHEDULE_LIST",
  GET_SCHEDULE_LIST_SUCCESS: "GET_SCHEDULE_LIST_SUCCESS",
  GET_SCHEDULE_LIST_FAILURE: "GET_SCHEDULE_LIST_FAILURE",
};

export const DELETE_SCHEDULE_ACTIVITY_ACTIONS = {
  DELETE_SCHEDULE_ACTIVITY: "DELETE_SCHEDULE_ACTIVITY",
  DELETE_SCHEDULE_ACTIVITY_SUCCESS: "DELETE_SCHEDULE_ACTIVITY_SUCCESS",
  DELETE_SCHEDULE_ACTIVITY_FAILURE: "DELETE_SCHEDULE_ACTIVITY_FAILURE",
};

export const SAVE_TOOL_ACTIONS = {
  SAVE_TOOL: "SAVE_TOOL",
  SAVE_EDIT_TOOL: "SAVE_EDIT_TOOL",
  CLEAR_TOOL: "CLEAR_TOOL",
  CLEAR_EDIT_TOOL: "CLEAR_EDIT_TOOL",
};

export const TOOLS_SELECT_IMAGE_ACTIONS = {
  SELECT_TOOL_IMAGE: "SELECT_TOOL_IMAGE",
  SELECT_SUBTASK_IMAGE: "SELECT_SUBTASK_IMAGE",
  CLEAR_SUBTASK_IMAGE: "CLEAR_SUBTASK_IMAGE",
  SELECT_EDIT_TOOL_IMAGE: "SELECT_EDIT_TOOL_IMAGE",
  SELECT_EDIT_SUBTASK_IMAGE: "SELECT_EDIT_SUBTASK_IMAGE",
  CLEAR_EDIT_SUBTASK_IMAGE: "CLEAR_EDIT_SUBTASK_IMAGE",
};

export const ARCHIVE_ACTIVITY_ACTIONS = {
  ARCHIVE_ACTIVITY: "ARCHIVE_ACTIVITY",
  ARCHIVE_ACTIVITY_SUCCESS: "ARCHIVE_ACTIVITY_SUCCESS",
  ARCHIVE_ACTIVITY_FAILURE: "ARCHIVE_ACTIVITY_FAILURE",
};

export const ADD_SELF_REGULATION_TOOL_ACTIONS = {
  ADD_TOOL: "ADD_TOOL",
  ADD_TOOL_SUCCESS: "ADD_TOOL_SUCCESS",
  ADD_TOOL_FAILURE: "ADD_TOOL_FAILURE",
  CLEAR_ADDED_TOOL: "CLEAR_ADDED_TOOL",
};

export const GET_TOOLS_LIST_ACTIONS = {
  GET_TOOLS_LIST: "GET_TOOLS_LIST",
  GET_TOOLS_LIST_SUCCESS: "GET_TOOLS_LIST_SUCCESS",
  GET_TOOLS_LIST_FAILURE: "GET_TOOLS_LIST_FAILURE",
  SAVE_CURRENT_PAGE: "SAVE_CURRENT_PAGE",
  CLEAR_CURRENT_PAGE: "CLEAR_CURRENT_PAGE",
};

export const ARCHIVE_SELF_REGULATION_TOOL_ACTIONS = {
  ARCHIVE_TOOL: "ARCHIVE_TOOL",
  ARCHIVE_TOOL_SUCCESS: "ARCHIVE_TOOL_SUCCESS",
  ARCHIVE_TOOL_FAILURE: "ARCHIVE_TOOL_FAILURE",
};

export const GET_SELF_REGULATION_TOOL_ACTIONS = {
  GET_TOOL: "GET_TOOL",
  GET_TOOL_SUCCESS: "GET_TOOL_SUCCESS",
  GET_TOOL_FAILURE: "GET_TOOL_FAILURE",
};

export const UPDATE_SELF_REGULATION_TOOL_ACTIONS = {
  UPDATE_TOOL: "UPDATE_TOOL",
  UPDATE_TOOL_SUCCESS: "UPDATE_TOOL_SUCCESS",
  UPDATE_TOOL_FAILURE: "UPDATE_TOOL_FAILURE",
};

export const GET_NOTIFICATIONS_ACTIONS = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_CLEAR_OLD_DATA: "GET_NOTIFICATIONS_CLEAR_OLD_DATA",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",
};

export const MARK_NOTIFICATION_AS_READ_ACTIONS = {
  MARK_NOTIFICATION_AS_READ_SUCCESS: "MARK_NOTIFICATION_AS_READ_SUCCESS",
  MARK_ALL_READ_SUCCESS: "MARK_ALL_READ_SUCCESS",
};

export const CREATE_PAYMENT_SESSION_ACTIONS = {
  CREATE_PAYMENT_SESSION: "CREATE_PAYMENT_SESSION",
  CREATE_PAYMENT_SESSION_SUCCESS: "CREATE_PAYMENT_SESSION_SUCCESS",
  CREATE_PAYMENT_SESSION_FAILURE: "CREATE_PAYMENT_SESSION_FAILURE",
};

export const GET_SUBSCRIPTION_LIST_ACTIONS = {
  GET_SUBSCRIPTION_LIST: "GET_SUBSCRIPTION_LIST",
  GET_SUBSCRIPTION_LIST_SUCCESS: "GET_SUBSCRIPTION_LIST_SUCCESS",
  GET_SUBSCRIPTION_LIST_FAILURE: "GET_SUBSCRIPTION_LIST_FAILURE",
};

export const CANCEL_SUBSCRIPTION_ACTIONS = {
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_FAILURE: "CANCEL_SUBSCRIPTION_FAILURE",
};

export const UPDATE_PAYMENT_CARD_ACTIONS = {
  UPDATE_PAYMENT_CARD: "UPDATE_PAYMENT_CARD",
  UPDATE_PAYMENT_CARD_SUCCESS: "UPDATE_PAYMENT_CARD_SUCCESS",
  UPDATE_PAYMENT_CARD_FAILURE: "UPDATE_PAYMENT_CARD_FAILURE",
};

export const RESUBSCRIBE_PAYMENT_ACTIONS = {
  RESUBSCRIBE_PAYMENT: "RESUBSCRIBE_PAYMENT",
  RESUBSCRIBE_PAYMENT_SUCCESS: "RESUBSCRIBE_PAYMENT_SUCCESS",
  RESUBSCRIBE_PAYMENT_FAILURE: "RESUBSCRIBE_PAYMENT_FAILURE",
};

export const UPDATE_USER_ACTIONS = {
  UPDATE_USER: "UPDATE_USER",
  SAVE_EMAIL: "SAVE_EMAIL",
  UPDATE_STATUS: "UPDATE_STATUS",
};

export const REGENERATE_WATCH_CODE_ACTIONS = {
  REGENERATE_WATCH_CODE: "REGENERATE_WATCH_CODE",
  REGENERATE_WATCH_CODE_SUCCESS: "REGENERATE_WATCH_CODE_SUCCESS",
  REGENERATE_WATCH_CODE_FAILURE: "REGENERATE_WATCH_CODE_FAILURE",
};

export const SIDEBAR_ACTIONS = {
  COLLAPSED: "COLLAPSED",
};

export const CREATE_CONVERSATION_ACTIONS = {
  CREATE_CONVERSATION: "CREATE_CONVERSATION",
  CREATE_CONVERSATION_SUCCESS: "CREATE_CONVERSATION_SUCCESS",
  CREATE_CONVERSATION_FAILURE: "CREATE_CONVERSATION_FAILURE",
};

export const GET_ALL_CONVERSATIONS_ACTIONS = {
  GET_ALL_CONVERSATIONS: "GET_ALL_CONVERSATIONS",
  GET_ALL_CONVERSATIONS_SUCCESS: "GET_ALL_CONVERSATIONS_SUCCESS",
  GET_ALL_CONVERSATIONS_FAILURE: "GET_ALL_CONVERSATIONS_FAILURE",
};

export const GET_CONVERSATION_MESSAGES_ACTIONS = {
  GET_CONVERSATION_MESSAGES_CLEAR_OLD_DATA:
    "GET_CONVERSATION_MESSAGES_CLEAR_OLD_DATA",
  GET_CONVERSATION_MESSAGES: "GET_CONVERSATION_MESSAGES",
  GET_CONVERSATION_MESSAGES_SUCCESS: "GET_CONVERSATION_MESSAGES_SUCCESS",
  GET_CONVERSATION_MESSAGES_FAILURE: "GET_CONVERSATION_MESSAGES_FAILURE",
};

export const CREATE_NEW_MESSAGE_ACTIONS = {
  CREATE_NEW_MESSAGE: "CREATE_NEW_MESSAGE",
  CREATE_NEW_MESSAGE_SUCCESS: "CREATE_NEW_MESSAGE_SUCCESS",
  CREATE_NEW_MESSAGE_FAILURE: "CREATE_NEW_MESSAGE_FAILURE",
};

export const CREATE_NEW_GROUP_ACTIONS = {
  CREATE_NEW_GROUP: "CREATE_NEW_GROUP",
  CREATE_NEW_GROUP_SUCCESS: "CREATE_NEW_GROUP_SUCCESS",
  CREATE_NEW_GROUP_FAILURE: "CREATE_NEW_GROUP_FAILURE",
};

/** CHAT ACTIONS */

export const CONVERSATIONS = {
  CONVERSATIONS_FETCH: "conversations/fetch",
  CONVERSATIONS_FETCH_PENDING: "conversations/fetch/pending",
  CONVERSATIONS_FETCH_SUCCESS: "conversations/fetch/success",

  CONVERSATIONS_CREATE: "conversations/create",
  CONVERSATIONS_CREATE_SUCCESS: "conversations/create/success",
  CONVERSATIONS_CREATE_FAILURE: "conversations/create/failure",

  CONVERSATIONS_DELETE: "conversations/delete",
  CONVERSATIONS_DELETE_SUCCESS: "conversations/delete/success",
  CONVERSATIONS_DELETE_FAILURE: "conversations/delete/failure",

  CONVERSATION_ADD: "CONVERSATION_ADD",
  CONVERSATION_UPDATE: "CONVERSATION_UPDATE",
  CONVERSATION_REMOVE: "CONVERSATION_REMOVE",

  CONVERSATION_RESET: "CONVERSATION_RESET",

  CONVERSATION_MARKED_READ: "CONVERSATION_MARKED_READ",
};

export const CONVERSATION_MESSAGES = {
  FETCH_MESSAGES: "FETCH_MESSAGES",
  FETCH_MESSAGES_SUCCESS: "FETCH_MESSAGES_SUCCESS",
  FETCH_MESSAGES_FAILURE: "FETCH_MESSAGES_FAILURE",

  ADD_MESSAGE: "ADD_MESSAGE",

  SET_SEARCH_TERM: "SET_SEARCH_TERM",

  GET_CHAT_PRE_SIGNED_URL: "GET_CHAT_PRE_SIGNED_URL",
  GET_CHAT_PRE_SIGNED_URL_SUCCESS: "GET_CHAT_PRE_SIGNED_URL_SUCCESS",
  GET_CHAT_PRE_SIGNED_URL_FAILURE: "GET_CHAT_PRE_SIGNED_URL_FAILURE",

  UPLOAD_CHAT_IMAGE: "UPLOAD_CHAT_IMAGE",
  UPLOAD_CHAT_IMAGE_SUCCESS: "UPLOAD_CHAT_IMAGE_SUCCESS",
  UPLOAD_CHAT_IMAGE_FAILURE: "UPLOAD_CHAT_IMAGE_FAILURE",

  RESET_MESSAGE: "RESET_MESSAGE",
};

export const GET_ALL_GROUPS_ACTIONS = {
  GET_ALL_GROUPS: "GET_ALL_GROUPS",
  GET_ALL_GROUPS_SUCCESS: "GET_ALL_GROUPS_SUCCESS",
  GET_ALL_GROUPS_FAILURE: "GET_ALL_GROUPS_FAILURE",

  GROUP_CREATE: "group/create",
  GROUP_CREATE_SUCCESS: "group/create/success",
  GROUP_CREATE_FAILURE: "group/create/failure",

  GROUP_DELETE: "group/delete",
  GROUP_DELETE_SUCCESS: "group/delete/success",
  GROUP_DELETE_FAILURE: "group/delete/failure",

  ADD_USER_IN_GROUP: "group/user/add",
  ADD_USER_IN_GROUP_SUCCESS: "group/user/add/success",
  ADD_USER_IN_GROUP_FAILURE: "group/user/add/failure",

  REMOVE_USER_IN_GROUP: "group/user/remove",
  REMOVE_USER_IN_GROUP_SUCCESS: "group/user/remove/success",
  REMOVE_USER_IN_GROUP_FAILURE: "group/user/remove/failure",

  FETCH_GROUP_MESSAGES: "FETCH_GROUP_MESSAGES",
  FETCH_GROUP_MESSAGES_SUCCESS: "FETCH_GROUP_MESSAGES_SUCCESS",
  FETCH_GROUP_MESSAGES_FAILURE: "FETCH_GROUP_MESSAGES_FAILURE",

  GROUP_ADD: "GROUP_ADD",
  GROUP_UPDATE: "GROUP_UPDATE",
  GROUP_REMOVE: "GROUP_REMOVE",

  GROUP_RESET: "GROUP_RESET",

  ADD_GROUP_MESSAGES: "ADD_GROUP_MESSAGES",
  RESET_GROUP_MESSAGE: "RESET_GROUP_MESSAGE",

  SET_SEARCH_TERM: "SET_SEARCH_TERM",
  
  GROUP_MARKED_READ: "GROUP_MARKED_READ"
};

export const MESSAGE_PANEL = {
  ADD_ATTACHMENT: "ADD_ATTACHMENT",
  REMOVE_ATTACHMENT: "REMOVE_ATTACHMENT",
  INCREMENT_ATTACHMENT_COUNTER: "INCREMENT_ATTACHMENT_COUNTER",
  REMOVE_ALL_ATTACHMENTS: "REMOVE_ALL_ATTACHMENTS",
};

export const RECIPIENT = {
  FETCH_RECIPIENT_REQUEST: "FETCH_RECIPIENT_REQUEST",
  FETCH_RECIPIENT_SUCCESS: "FETCH_RECIPIENT_SUCCESS",
  FETCH_RECIPIENT_FAILURE: "FETCH_RECIPIENT_FAILURE",
};

export const FILTER = {
  SEARCH_FILTER: "SEARCH_FILTER",
};
