import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../store/actions/Notifications";

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNotifications(1, () => { }));
    }, [Component]);

    return (
        <Route
            {...rest}
            render={props => {
                return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN) && localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) && localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ?
                    <Component {...props} {...rest} /> :
                    <Redirect to={{ pathname: RouteConstants.LOGIN, state: { from: props.location } }} />;
            }}
        />
    );
};