import { failure, request, success } from "..";
import { CONVERSATIONS } from "../../../constants/ActionConstants";
import RouteConstants from "../../../constants/RouteConstants";
import ChatService from "../../../services/api/ChatService";

export function fetchConversations() {
  return async (dispatch) => {
    dispatch(request(CONVERSATIONS.CONVERSATIONS_FETCH_PENDING, {}));
    try {
      const conversations = await ChatService.getAllConversations();

      dispatch(
        success(CONVERSATIONS.CONVERSATIONS_FETCH_SUCCESS, {
          data: conversations.data.data,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}

export const createConversation = (payload, history) => {
  return async (dispatch) => {
    dispatch(request(CONVERSATIONS.CONVERSATIONS_CREATE, {}));
    try {
      const conversation = await ChatService.createConversation(payload);

      dispatch(
        success(CONVERSATIONS.CONVERSATIONS_CREATE_SUCCESS, {
          data: conversation.data,
        })
      );
      history.replace({ pathname: `/chat/${conversation.data.id}` });
    } catch (error) {
      console.log(error?.response);
      alert(error?.response?.data?.message || "Unable to create conversation");
      dispatch(
        failure(CONVERSATIONS.CONVERSATIONS_CREATE_FAILURE, error?.response)
      );
    }
  };
};

export const deleteConversation = (conversationId, history) => {
  return async (dispatch) => {
    dispatch(request(CONVERSATIONS.CONVERSATIONS_DELETE, {}));
    try {
      const conversation = await ChatService.deleteConversation(conversationId);

      await dispatch(
        success(CONVERSATIONS.CONVERSATIONS_DELETE_SUCCESS, {
          data: conversation.data,
        })
      );

      history.replace({ pathname: RouteConstants.CHAT });
    } catch (error) {
      console.log(error?.response);
      alert(error?.response?.data?.message || "Unable to delete conversation");
      dispatch(
        failure(CONVERSATIONS.CONVERSATIONS_DELETE_FAILURE, error?.response)
      );
    }
  };
};

export const conversationMarkedAsRead = (conversationId) => {
  return async () => {
    try {
      await ChatService.conversationMarkedAsRead(conversationId);
    } catch (error) {
      console.log(error?.response);
    }
  };
};


export const addConversation = (payload) => {
  return request(CONVERSATIONS.CONVERSATION_ADD, payload);
};

export const updateConversation = (payload) => {
  return request(CONVERSATIONS.CONVERSATION_UPDATE, payload);
};

export const removeConversation = (payload) => {
  return request(CONVERSATIONS.CONVERSATION_REMOVE, payload);
};

export const markedAsReadConversation = (payload) => {
  return request(CONVERSATIONS.CONVERSATION_MARKED_READ, payload);
};

export const resetConversation = () => {
  return request(CONVERSATIONS.CONVERSATION_RESET, {});
};

export const filteredConversations = (state) => {
  const {
    conversation: { conversations },
    filters: { search },
  } = state;

  return conversations.filter((conversation) => {
    const fullName = `${conversation?.recipient?.firstName} ${conversation?.recipient?.lastName}`;
    return fullName?.toLowerCase().includes(search?.toLowerCase());
  });
};

export const selectConversations = (state) => state.conversation.conversations;
export const selectConversationById = (state, id) => {
  return state.conversation.conversations.find((c) => c.id === id);
};
