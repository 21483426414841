import { Badge, withStyles } from "@material-ui/core";

export const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#02C8A7",
    color: "#02C8A7",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    position: "absolute",
    bottom: 0,
    right: 0,
    // "&::after": {
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   width: "100%",
    //   height: "100%",
    //   borderRadius: "50%",
    //   animation: "$ripple 1.2s infinite ease-in-out",
    //   border: "1px solid currentColor",
    //   content: '""',
    // },
  },
  // "@keyframes ripple": {
  //   "0%": {
  //     transform: "scale(.8)",
  //     opacity: 1,
  //   },
  //   "100%": {
  //     transform: "scale(2.4)",
  //     opacity: 0,
  //   },
  // },
}))(Badge);
