import { request } from "..";
import { GET_ALL_GROUPS_ACTIONS } from "../../../constants/ActionConstants";

export const addGroupMessage = (payload) => {
  return request(GET_ALL_GROUPS_ACTIONS.ADD_GROUP_MESSAGES, payload);
};

export const setGroupMessageSearchTerm = (searchTerm) => {
  return request(GET_ALL_GROUPS_ACTIONS.SET_SEARCH_TERM, searchTerm);
};


export const resetGroupMessages = () => {
  return request(GET_ALL_GROUPS_ACTIONS.RESET_GROUP_MESSAGE, {});
};