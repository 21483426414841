import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Card, LinearProgress, Badge } from "@material-ui/core";
import { Link } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import { useDispatch, useSelector } from "react-redux";
import { getImageURL as getImageURLChild, setCurrentChild } from "../../store/actions/Child";
import { getImageURL as getImageURLUser } from "../../store/actions/Authentication";
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from "../../helpers/Validation";
import { ROLE_TYPES, USER_TYPE } from "../../constants/GenericConstants";

const ChildProfileItem = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { child } = props;
    const dispatch = useDispatch();
    const [childImage, setChildImage] = useState("https://i.stack.imgur.com/l60Hf.png");
    const [childActProgress, setChildActProgress] = useState(0);
    const [childActCompleted, setChildActCompleted] = useState(0);
    const [childActTotal, setChildActTotal] = useState(0);
    const [progressLoad, setProgressLoad] = useState(false);
    const childState = useSelector((state) => state.child);
    const userState = useSelector((state) => state.authentication);

    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        setProgressLoad(true)
        if (child.childPhoto && child.childPhoto.includes(".")) {
            if(child.userType === USER_TYPE.SINGLE) {
                dispatch(getImageURLUser(child.childPhoto, onGetUrl, onFailedUrl))
            } else {
                dispatch(getImageURLChild(child.childPhoto, onGetUrl, onFailedUrl))
            }
        }
        else {
            setLoaded(true)
        }


    }, [])


    useEffect(() => {
        if (childState.activityProgress && childState.activityProgress.length !== 0) {
            const childProgressArray = childState.activityProgress.filter(c => c.childId === child.childId);
            if (childProgressArray.length > 0) {
                const childProgress = childProgressArray[0];

                setChildActProgress(childProgress.progress)
                setChildActCompleted(childProgress.completedActivities)
                setChildActTotal(childProgress.totalActivities)
            }
            setProgressLoad(false)
        }

    }, [childState.activityProgress])


    const onGetUrl = (res) => {
        setChildImage(res.data.url)
        setLoaded(false)
    }
    const onFailedUrl = (err) => {
        // alert(JSON.stringify(err))
    }

    const moveToChildActivitiesScreen = () => {
        dispatch(setCurrentChild(child.childId));
        history.push({ pathname: RouteConstants.CHILD_ACTIVITIES.replace(':childId', child.childId) });
    }

    const getUserName = () => {
        if(userState.isSingleUser && child.userRole === ROLE_TYPES.PARENT) {
            return child.parentFirstName + " " + child.parentLastName;
        }
        return child.childFirstName + " " + child.childLastName;
    }

    const getImageSrc = () => {
        if(userState.isSingleUser && child.userRole === ROLE_TYPES.PARENT) {
            return (!isEmpty(userState.user) 
            && (userState.user.photo 
            && userState.user.photo.includes(".")) 
            ? userState.user.photoURL 
            : "https://i.stack.imgur.com/l60Hf.png")
        }
        else return childImage;
    }


    return (
        <Card className="child-card">
            <ReactTooltip />
            <div className="child-profile-row">
                <div className="imgLoader">
                    <figure>
                        {/* <img src={child && child.photoURL? child.photoURL : "https://cdn2.stylecraze.com/wp-content/uploads/2013/10/2361-Top-10-Beauty-Tips-For-Kids-ss.jpg"} alt="Child Profile Picture" /> */}
                        {!loaded ? <p className="loader"></p> : null}

                        <img
                            onLoad={() => {
                                setLoaded(true)
                            }}
                            src={getImageSrc()} 
                            alt="Child Profile" />
                    </figure>
                </div>
                <div className="child-name-status">
                    
                    <h5>{getUserName()}</h5>
                    
                    <div>
                        <i className={child.deviceStatus ? "ticon-sync-on" : "ticon-sync-off"}>
                        <span className="iwatch-status">{child.deviceStatus ? "Connected" : "Not Connected"}</span>
                            </i> {/* DEV to do: please handle the sync icon dynamically */}
                        {/* <i className="ticon-sync-on"></i> */}
                    </div>
                    {!child.deviceStatus && 
                    <div className="code-label-align">
                        <span className="code-label">{child.childCode}
                        </span>
                        {/* <InfoIcon data-tip="This is the access code for iwatch"></InfoIcon> */}
                        <i className="ticon-info" data-tip="This is the access code for Apple Watch"></i>
                    </div>}
                </div>
                
                <Link className="child-card-more" onClick={moveToChildActivitiesScreen}>
                    <i className="ticon-arrow-right"></i>
                </Link>
            </div>

            


            <div className="progress-label">
                {progressLoad ?
                    <p style={{ width: 2, height: 2 }} className="loader"></p> :
                    <span>{childActProgress !== null ? Math.round(childActProgress) : "--"} %</span>
                }
                {/* <span></span>

                {!progressLoad && child.deviceStatus && childActTotal>0 ?<span>{t("childTasksCompletedMessage").replace(/{tasksCompleted}/, childActCompleted).replace(/{totalTasks}/, childActTotal)}</span>:
                         !progressLoad && child.deviceStatus ? <span>{t("noActivityFoundMessage")}</span> :null
                } */}
            </div>
            <div className="progress-bar">
                <div className="progress-separator">
                    <em></em>
                    <em></em>
                    <em></em>
                    <em></em>
                    <em></em>
                    <em></em>
                    <em></em>
                    <em></em>
                </div>
                <LinearProgress variant="determinate" value={childActProgress !== null ? childActProgress : 0} />
            </div>
        </Card>
    );
};

export default ChildProfileItem;