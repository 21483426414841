import React from "react";

function ChatIcon({ children,...props }) {
  return (
    <button
      style={{
        border: "1px solid #E0E0E0",
        width: "46px",
        height: "40px",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        top: "180px",
        left: "1220px",
        cursor: "pointer",
      }}
      {...props}
    >
      {children}
    </button>
  );
}

export default ChatIcon;
