import { request, success, failure } from ".";
import { TOOLS_SELECT_IMAGE_ACTIONS, ADD_SELF_REGULATION_TOOL_ACTIONS, SAVE_TOOL_ACTIONS, GET_TOOLS_LIST_ACTIONS, GET_SELF_REGULATION_TOOL_ACTIONS, UPDATE_SELF_REGULATION_TOOL_ACTIONS, ARCHIVE_SELF_REGULATION_TOOL_ACTIONS } from "../../constants/ActionConstants";
import { IMAGE_TYPES, SCREEN_TYPE } from "../../constants/GenericConstants";
import SelfRegulationService from "../../services/api/SelfRegulationService";

export const clearImage = (imageType, screenType) => {
    return (dispatch) => {
        if (screenType === SCREEN_TYPE.ADD) {
            if (imageType === IMAGE_TYPES.TOOL_SUBTASK_IMAGE) {
                dispatch(request(TOOLS_SELECT_IMAGE_ACTIONS.CLEAR_SUBTASK_IMAGE, {}));
            }
        } else if (screenType === SCREEN_TYPE.EDIT) {
            if (imageType === IMAGE_TYPES.TOOL_SUBTASK_IMAGE) {
                dispatch(request(TOOLS_SELECT_IMAGE_ACTIONS.CLEAR_EDIT_SUBTASK_IMAGE, {}));
            }
        }
    };
}


export const saveData = (payload) => {
    return (dispatch) => {
        dispatch(request(SAVE_TOOL_ACTIONS.SAVE_TOOL, { data: payload }));
    };
}

export const clearToolData = () => {
    return (dispatch) => {
        dispatch(request(SAVE_TOOL_ACTIONS.CLEAR_TOOL, {}));
    };
}

export const addTool = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ADD_SELF_REGULATION_TOOL_ACTIONS.ADD_TOOL, {}));
        try {
            await SelfRegulationService.addTool(payload);
            dispatch(success(ADD_SELF_REGULATION_TOOL_ACTIONS.ADD_TOOL_SUCCESS, {}));
            onSuccess(payload);
        } catch (error) {
            dispatch(failure(ADD_SELF_REGULATION_TOOL_ACTIONS.ADD_TOOL_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
}

export const getAllTools = (childId, page, isArchive, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_TOOLS_LIST_ACTIONS.GET_TOOLS_LIST, {}));
        try {
            const response = await SelfRegulationService.getAllTools(childId, page, isArchive);
            setTimeout(() => {
                dispatch(success(GET_TOOLS_LIST_ACTIONS.GET_TOOLS_LIST_SUCCESS, { data: response.data }));
                onSuccess(response.data.data);
            }, 1000);
        } catch (error) {
            dispatch(failure(GET_TOOLS_LIST_ACTIONS.GET_TOOLS_LIST_FAILURE, {}));
        }
    };
}

export const clearAddedTool = () => {
    return (dispatch) => {
        dispatch(request(ADD_SELF_REGULATION_TOOL_ACTIONS.CLEAR_ADDED_TOOL, {}));
    };
}

export const archiveTool = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ARCHIVE_SELF_REGULATION_TOOL_ACTIONS.ARCHIVE_TOOL, {}));
        try {
            await SelfRegulationService.archiveTool(payload);
            dispatch(success(ARCHIVE_SELF_REGULATION_TOOL_ACTIONS.ARCHIVE_TOOL_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(ARCHIVE_SELF_REGULATION_TOOL_ACTIONS.ARCHIVE_TOOL_FAILURE, {}));
            onFailure(error);
        }
    };
}

export const getTool = (toolId, childId, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_SELF_REGULATION_TOOL_ACTIONS.GET_TOOL, {}));
        try {
            const response = await SelfRegulationService.getTool(toolId, childId);
            console.log("Get Tool Response:");
            console.log(response);
            dispatch(success(GET_SELF_REGULATION_TOOL_ACTIONS.GET_TOOL_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_SELF_REGULATION_TOOL_ACTIONS.GET_TOOL_FAILURE, {}));
        }
    };
}

export const saveEditData = (payload) => {
    return (dispatch) => {
        dispatch(request(SAVE_TOOL_ACTIONS.SAVE_EDIT_TOOL, { data: payload }));
    };
}

export const updateTool = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_SELF_REGULATION_TOOL_ACTIONS.UPDATE_TOOL, {}));
        try {
            const response = await SelfRegulationService.updateTool(payload);
            dispatch(success(UPDATE_SELF_REGULATION_TOOL_ACTIONS.UPDATE_TOOL_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(UPDATE_SELF_REGULATION_TOOL_ACTIONS.UPDATE_TOOL_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
}

export const clearEditToolData = () => {
    return (dispatch) => {
        dispatch(request(SAVE_TOOL_ACTIONS.CLEAR_EDIT_TOOL, {}));
    };
}

export const saveCurrentPage = (data) => {
    return (dispatch) => {
        dispatch(request(GET_TOOLS_LIST_ACTIONS.SAVE_CURRENT_PAGE, { data }));
    };
}

export const clearCurrentPage = () => {
    return (dispatch) => {
        dispatch(request(GET_TOOLS_LIST_ACTIONS.CLEAR_CURRENT_PAGE, {}));
    };
}