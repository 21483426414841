import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box, InputLabel, Input, FormHelperText, Button, Select, MenuItem, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, InputAdornment, IconButton, makeStyles } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import { validateEmail, validateMandatoryValue, validatePasswordLength, validateZipLength } from "../../helpers/Validation";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input'
import TimezonePicker from 'react-timezone';
import 'react-phone-number-input/style.css';
import AssetConstants from "../../constants/AssetConstants";
import { APP_CONSTANTS } from "../../constants/GenericConstants";
import RouteConstants from "../../constants/RouteConstants";
import { signUp, getEmailFromInviteCode, getAllCountries, getStatesOfCountry, getCitiesOfState, getHearAboutUsOtions } from "../../store/actions/Authentication";
import { removeInviteCode } from "../../store/actions/ExternalUser";
import Loader from "../Loader/Loader";
import TermsConditions from "../../components/TermsConditions/TermsConditions";
import Link from '@material-ui/core/Link';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import OnboardingCarousel from "./OnboardingCarousel";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";
import { getTimeDifference } from "../../helpers/GenericHelper";
 
const useStyles = makeStyles((theme) => ({
    select: {
        "& ul": {
            height: "181px",
            paddingTop: 0,
        },
    },
}));


const SignUp = (props) => {

    const classes = useStyles();

    const startTime = useRef(new Date().getTime());
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.authentication);
    const externalUserState = useSelector((state) => state.externalUser);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailFieldDisabled, disableEmailField] = useState(false);
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryState, setCountryState] = useState("");
    const [city, setCity] = useState("");
    const [timezone, setTimezone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [countryStateError, setCountryStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [timezoneError, setTimezoneError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [apiError, setApiError] = useState("");
    const [signUpSuccess, setSignUpSuccess] = useState(false);

    const [showTermsDialog, setShowTermsDialog] = useState(false);
    const [iAgree, setIagree] = useState(false);
    const [iAgreeError, setIagreeError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [hearAboutUs, setHearAboutUs] = useState("");
    const [hearAboutUsError, setHearAboutUsError] = useState("");
    const [hearAboutUsFeedback, setHearAboutUsFeedback] = useState("");
    const [hearAboutUsFeedbackErorr, setHearAboutUsFeedbackErorr] = useState("");



    var phoneCode = ""

    const handleCloseDialog = () => {
        setShowTermsDialog(false);
    }

    const onGetEmailSuccess = (emailAddress) => {
        setEmail(emailAddress);
        disableEmailField(true);
    }

    const onGetEmailFailure = (errorMessage) => {
        setApiError(errorMessage);
    }

    useEffect(() => {
        document.title = "Thrive App - Signup"
        dispatch(getAllCountries());
        dispatch(getHearAboutUsOtions())
        if (externalUserState.inviteCode.length > 0) {
            dispatch(getEmailFromInviteCode(externalUserState.inviteCode, onGetEmailSuccess, onGetEmailFailure));
        }
    }, []);

    const handleZipCodeChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setZipCode(input);
        if (formSubmitted) {
            validateZipCode(input);
        }
    }

    const handleFirstNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setFirstName(input);
        if (formSubmitted) {
            validateFirstName(input);
        }
    }

    const handleLastNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setLastName(input);
        if (formSubmitted) {
            validateLastName(input);
        }
    }

    const handleEmailChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setEmail(input);
        if (formSubmitted) {
            validateEmailAddress(input);
        }
    }

    const handlePhoneChange = (value) => {
        if (value === undefined) {
            // setPhone(phoneCode)
        }
        else {
            setPhone(value);
        }
        if (formSubmitted) {
            validatePhone(value);
        }
    }

    const getCountryNameOptions = () => {
        return userState.countriesList.sort(function (x, y) { return x.name == "United States" ? -1 : y.name == "United States" ? 1 : 0; }).map((countryData, index) => {
            return <MenuItem key={index} value={countryData.isoCode}>{countryData.name}</MenuItem>;
        });
    }

    const getHearAboutUsOptions = () => {
        return userState.hearAboutUsList.map((data, index) => {
            return <MenuItem key={index} value={data.id}>{data.title}</MenuItem>;
        });
    }

    const getStateNameOptions = () => {
        return userState.statesList.map((stateData, index) => {
            return <MenuItem key={index} value={stateData.isoCode}>{stateData.name}</MenuItem>;
        });
    }

    const getCityNameOptions = () => {
        return userState.citiesList.map((cityData, index) => {
            return <MenuItem key={index} value={cityData.name}>{cityData.name}</MenuItem>;
        });
    }

    const handleCountryChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setCountryCode(input);
        dispatch(getStatesOfCountry(input));
        setCountryState("");
        setCity("");
        setPhone("")
        if (formSubmitted) {
            validateCountry(input);
            validateCountryState("");
            validateCity("");
        }
    }

    const handleCountryStateChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setCountryState(input);
        dispatch(getCitiesOfState(countryCode, input, (res) => {
            if (res.length === 0) {
            }
        }));
        setCity("");
        if (formSubmitted) {
            validateCountryState(input);
            validateCity("");
        }
    }

    const handleCityChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setCity(input);
        if (formSubmitted) {
            validateCity(input);
        }
    }

    const handleTimezoneChange = (input) => {
        setTimezone(input);
        if (formSubmitted) {
            validateTimezone(input);
        }
    }

    const handlePasswordChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setPassword(input);
        if (formSubmitted) {
            validatePassword(input);
            validateConfirmPassword(confirmPassword, input);
        }
    }

    const handleConfirmPasswordChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setConfirmPassword(input);
        if (formSubmitted) {
            validateConfirmPassword(input);
        }
    }

    const handleHearAboutUsChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setHearAboutUs(input);
        validateHearAboutUs(input);
    }

    const handleFeedbackHearAboutUs = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setHearAboutUsFeedback(input);
        validateFeedbackHearAboutUs(input);
    }

    const handleRememberMeCheckbox = (event) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        setRememberMe(input);
    }

    const handleSubscribeCheckbox = (event) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        setSubscribe(input);
    }

    const handleTermsCheckbox = (event) => {
        const input = event.target && event.target.checked ? event.target.checked : false;
        setIagree(input);
        validateAgreeTerms(input);
    }

    const validateFeedbackHearAboutUs = (feedback) => {
        if (!validateMandatoryValue(feedback)) {
            setHearAboutUsFeedbackErorr(t("feedbackHearAboutUs"));
            return false;
        } else {
            setHearAboutUsFeedbackErorr("");
            return true;
        }
    }

    const validateFirstName = (firstName) => {
        if (!validateMandatoryValue(firstName)) {
            setFirstNameError(t("firstNameRequired"));
            return false;
        } else {
            setFirstNameError("");
            return true;
        }
    }
    const validateZipCode = (zipCode) => {
        if (!validateMandatoryValue(zipCode)) {
            setZipCodeError(t("zipCodeRequiredError"));
            return false;
        } else if (!validateZipLength(zipCode)) {
            setZipCodeError(t("zipCodeLengthError"));
            return false;
        }
        else {
            setZipCodeError("");
            return true;
        }
        return true;
    }

    const validateLastName = (lastName) => {
        if (!validateMandatoryValue(lastName)) {
            setLastNameError(t("lastNameRequired"));
            return false;
        } else {
            setLastNameError("");
            return true;
        }
    }

    const validateHearAboutUs = (hearAboutUs) => {
        if (!hearAboutUs) {
            setHearAboutUsError(t("hearAboutUsRequired"));
            return false;
        } else {
            setHearAboutUsError("");
            return true;
        }
    }

    const validateEmailAddress = (email) => {
        if (!validateMandatoryValue(email)) {
            setEmailError(t("emailRequired"));
            return false;
        } else if (!validateEmail(email)) {
            setEmailError(t("invalidEmailError"));
            return false;
        } else {
            setEmailError("");
            return true;
        }
    }

    const validatePhone = (phone) => {
        if (!validateMandatoryValue(phone)) {
            setPhoneError(t("phoneRequired"));
            return false;
        } else if (!isPossiblePhoneNumber(phone)) {
            setPhoneError(t("invalidPhone"));
            return false;
        } else {
            setPhoneError("");
            return true;
        }
    }

    const validateCountry = (country) => {
        if (!validateMandatoryValue(country)) {
            setCountryError(t("countryRequired"));
            return false;
        } else {
            setCountryError("");
            return true;
        }
    }

    const validateCountryState = (countryState) => {
        if (!validateMandatoryValue(countryState)) {
            setCountryStateError(t("stateRequiredError"));
            return false;
        } else {
            setCountryStateError("");
            return true;
        }
    }

    const validateCity = (city) => {
        if (!validateMandatoryValue(city)) {
            setCityError(t("cityRequired"));
            return false;
        }
        else {
            setCityError("");
            return true;
        }
    }

    const validateTimezone = (timezone) => {
        if (!validateMandatoryValue(timezone)) {
            setTimezoneError(t("timezoneRequiredError"));
            return false;
        } else {
            setTimezoneError("");
            return true;
        }
    }

    const validatePassword = (password) => {
        if (!validateMandatoryValue(password)) {
            setPasswordError(t("passwordRequiredError"));
            return false;
        } else if (!validatePasswordLength(password)) {
            setPasswordError(t("passwordLengthError").replace(/{minLength}/, APP_CONSTANTS.MIN_PASSWORD_LENGTH).replace(/{maxLength}/, APP_CONSTANTS.MAX_PASSWORD_LENGTH));
            return false;
        } else {
            setPasswordError("");
            return true;
        }
    }

    const validateConfirmPassword = (confirmPassword, inputPassword = password) => {
        if (!validateMandatoryValue(confirmPassword)) {
            setConfirmPasswordError(t("confirmPasswordRequired"));
            return false;
        } else if (inputPassword !== confirmPassword) {
            setConfirmPasswordError(t("passwordMismatchError"));
            return false;
        } else {
            setConfirmPasswordError("");
            return true;
        }
    }

    const validateAgreeTerms = (iAgree) => {
        if (iAgree) {
            setIagreeError("");
        } else {
            setIagreeError(t("termsAgreeErrorMessage"));
        }
        return iAgree;
    }

    const showInputError = (field) => {
        return field.length > 0 && formSubmitted;
    }

    const onSignupSuccess = (isSingleUser = false, user) => {
        if (externalUserState.inviteCode.length > 0) {
            setSignUpSuccess(true);
            dispatch(removeInviteCode());
            setTimeout(() => {
                history.push({ pathname: RouteConstants.LOGIN });
            }, 2000);
        } else {
            history.replace({ pathname: RouteConstants.VERIFY_EMAIL });
        }

            //mixpanel
            const endTime = new Date().getTime();
            const duration = getTimeDifference(startTime.current, endTime);
    
            Mixpanel.identify(user.id);
            //   Mixpanel.track(MIX_PANEL_EVENTS.SIGNUP);
            Mixpanel.track(MIX_PANEL_EVENTS.SIGNUP, {
                [MIX_PANEL_EVENTS.SIGNUP_TIME_SPENT]: duration,
                [MIX_PANEL_EVENTS.USER_TYPE]: user.userType,
            });
            Mixpanel.user.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $email: user.emailAddress,
                $created: (new Date()).toISOString(),
            });
  
    }

    const onSignupFailure = (errorMessage) => {
        setApiError(errorMessage);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setFormSubmitted(true);
        setApiError("");
        if (isFormValid()) {
            const country = userState.countriesList.find((currentCountry) => currentCountry.isoCode === countryCode);
            let index = userState.statesList.findIndex((currentState) => currentState.isoCode === countryState);
            const data = {
                "firstName": firstName,
                "lastName": lastName,
                "emailAddress": email,
                "password": password,
                "confirmPassword": confirmPassword,
                "city": city,
                "country": country.name,
                "state": userState.statesList[index].name,
                "phoneNumber": phone,
                "countryPhoneCode": country.phoneCode,
                // "timezone": timezone,
                "isAcceptedTnC": iAgree,
                "zipCode": zipCode,
                "userType": location.userType,
                "hearAboutUs": hearAboutUs,
                "hearAboutUsFeedback": hearAboutUsFeedback
            };
            if (externalUserState.inviteCode.length > 0) {
                data.inviteCode = externalUserState.inviteCode;
            }
            dispatch(signUp(data, onSignupSuccess, onSignupFailure));
        }
    }

    const isFormValid = () => {
        return validateFirstName(firstName) & validateLastName(lastName) & validateEmailAddress(email) &
            validatePhone(phone) & validateCountry(countryCode) & validateCountryState(countryState) & (validateCity(city) || (countryState !== "" && userState.citiesList.length === 0)) &
            validatePassword(password) & validateConfirmPassword(confirmPassword) & validateZipCode(zipCode) & validateAgreeTerms(iAgree) & validateHearAboutUs(hearAboutUs) &
            (isHearAboutUsOptionOther() ? validateFeedbackHearAboutUs(hearAboutUsFeedback) : true);
    }

    const handleClose = () => {
        setSignUpSuccess(false);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickConfirmShowPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const isHearAboutUsOptionOther = () => {
        const findOtherItem = userState?.hearAboutUsList.find(hearAboutUsItem => hearAboutUsItem.id === hearAboutUs);
        return (findOtherItem?.title.toLowerCase() !== 'other') ? false : true;
    }

    const renderHearAboutUsFeedbackInput = () => {
        if (!isHearAboutUsOptionOther()) { return null; }
        return (
            <Grid item md={12} xs={12}>
                <Input
                    id="other"
                    name="other"
                    required
                    type="text"
                    value={hearAboutUsFeedback || ""}
                    placeholder={t("enterText")}
                    onChange={handleFeedbackHearAboutUs}
                    error={showInputError(hearAboutUsFeedbackErorr)}
                    />
                    {
                        showInputError(hearAboutUsFeedbackErorr) && <FormHelperText error={showInputError(hearAboutUsFeedbackErorr)}>{hearAboutUsFeedbackErorr}</FormHelperText>
                    }
            </Grid>
        )
    };

    return (
        <div className="is-login page-signup">
            {userState.loading && <Loader />}
            <SnackbarAlert show={signUpSuccess} message={t("signUpSuccessMessage")} duration={5000} onCloseAlert={handleClose} />

            <Dialog className="AddPopup-Box termsConditionPopup" disableBackdropClick disableEscapeKeyDown maxWidth="md" open={showTermsDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title"> {t("termsHeading")} <CloseIcon onClick={handleCloseDialog} /> </DialogTitle>
                <DialogContent>
                    <TermsConditions />
                </DialogContent>
            </Dialog>

            <Grid container>

                <Grid item sm={6} className="login-form">
                    <div className="signup-form-content">
                        <img className="login-logo" src={AssetConstants.logo} />
                        <form>
                            <h1>{t("createProfile")}</h1>
                            {
                                apiError && <Alert severity="error">{apiError}</Alert>
                            }
                            <Grid container spacing={2}>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="firstName" required>{t("firstName")}</InputLabel>
                                        <Input id="firstName" name="firstName" required type="text" value={firstName || ""} placeholder={t("firstNamePlaceholder")} onChange={handleFirstNameChange} error={showInputError(firstNameError)} />
                                        {
                                            showInputError(firstNameError) && <FormHelperText error={showInputError(firstNameError)}>{firstNameError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="lastName" required>{t("lastName")}</InputLabel>
                                        <Input id="lastName" name="lastName" required type="text" value={lastName || ""} placeholder={t("lastNamePlaceholder")} onChange={handleLastNameChange} error={showInputError(lastNameError)} />
                                        {
                                            showInputError(lastNameError) && <FormHelperText error={showInputError(lastNameError)}>{lastNameError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel id="country" required>{t("country")}</InputLabel>
                                        <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        labelId="country" id="countrySelect" name="countrySelect" required value={countryCode} onChange={handleCountryChange} error={showInputError(countryError)}>
                                            {userState.countriesList.length === 0 ? <MenuItem value="" disabled>{t("loading")}</MenuItem> : getCountryNameOptions()}
                                        </Select>
                                        {
                                            showInputError(countryError) && <FormHelperText error={showInputError(countryError)}>{countryError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel id="countryState" required>{t("state")}</InputLabel>
                                        <Select labelId="countryState" id="countryStateSelect" name="countryStateSelect" required value={countryState} onChange={handleCountryStateChange} error={showInputError(countryStateError)}>
                                            {userState.statesLoading ? <MenuItem value="" disabled>{t("loading")}</MenuItem> :
                                                userState.countriesList.length !== 0 && userState.statesList.length === 0 ? <MenuItem value="" disabled>{t("pleaseSelectCountry")}</MenuItem> :

                                                    userState.statesList.length === 0 ? <MenuItem value="" disabled>{t("pleaseSelectCountry")}</MenuItem> : getStateNameOptions()}
                                        </Select>
                                        {
                                            showInputError(countryStateError) && <FormHelperText error={showInputError(countryStateError)}>{countryStateError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel id="city" required>{t("city")}</InputLabel>
                                        <Select labelId="city" id="citySelect" name="citySelect" required value={city} onChange={handleCityChange} error={showInputError(cityError)}>
                                            {userState.citiesLoading ? <MenuItem value="" disabled>{t("loading")}</MenuItem> :
                                                userState.statesList.length !== 0 && userState.citiesList.length === 0 ? <MenuItem value="" disabled>{t("noCityF")}</MenuItem> :

                                                    userState.citiesList.length === 0 ? <MenuItem value="" disabled>{t("pleaseSelectState")}</MenuItem> : getCityNameOptions()}
                                        </Select>
                                        {
                                            showInputError(cityError) && !(countryState !== "" && userState.citiesList.length === 0) && <FormHelperText error={showInputError(cityError)}>{cityError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="zipCode" required >{t("zipCode")}</InputLabel>
                                        <Input id="zipCode" name="zipCode" type="text" value={zipCode || ""} placeholder="e.g. 8896" onChange={handleZipCodeChange} error={showInputError(zipCodeError)} />
                                        {
                                            showInputError(zipCodeError) && <FormHelperText error={showInputError(zipCodeError)}>{zipCodeError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>



                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="email" required>{t("email")}</InputLabel>
                                        <Input disabled={emailFieldDisabled} id="email" name="email" required type="email" value={email || ""} placeholder={t("emailPlaceholder")} onChange={handleEmailChange} error={showInputError(emailError)} />
                                        {
                                            showInputError(emailError) && <FormHelperText error={showInputError(emailError)}>{emailError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel required>{t("phone")}</InputLabel>
                                        <PhoneInput
                                            country={countryCode}
                                            defaultCountry={countryCode !== "" ? countryCode : "US"}
                                            countryCallingCodeEditable={false}
                                            value={phone}
                                            placeholder={t("phonePlaceholder")}
                                            displayInitialValueAsLocalNumber={true}
                                            withCountryCallingCode
                                            international
                                            onCountryChange={(e) => {
                                                try {
                                                    phoneCode = "+" + getCountryCallingCode(e)
                                                }
                                                catch (err) {
                                                }
                                            }}
                                            countryOptionsOrder={["US", "..."]}
                                            countries={["US"]}
                                            onChange={handlePhoneChange} />


                                        {
                                            showInputError(phoneError) && <FormHelperText error={showInputError(phoneError)}>{phoneError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="password" required>{t("password")}</InputLabel>
                                        <Input
                                            id="password"
                                            name="password"
                                            required
                                            type={showPassword ? "text" : "password"}
                                            value={password || ""}
                                            placeholder={t("passwordPlaceholder")}
                                            onChange={handlePasswordChange}
                                            error={showInputError(passwordError)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>} />
                                        {
                                            showInputError(passwordError) && <FormHelperText error={showInputError(passwordError)}>{passwordError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <div className="form-field">
                                        <InputLabel htmlFor="confirmPassword" required>{t("confirmPassword")}</InputLabel>
                                        <Input
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            required
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={confirmPassword || ""}
                                            placeholder={t("passwordPlaceholder")}
                                            onChange={handleConfirmPasswordChange}
                                            error={showInputError(confirmPasswordError)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickConfirmShowPassword}>
                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>} />
                                        {
                                            showInputError(confirmPasswordError) && <FormHelperText error={showInputError(confirmPasswordError)}>{confirmPasswordError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                <Box className="hint" display="flex" justifyContent="space-between" flexWrap="wrap">
                                    <span>{t("passwordLengthMessage").replace(/{length}/, APP_CONSTANTS.MIN_PASSWORD_LENGTH)}</span>
                                    {/* <span><em>*</em> {t("requiredFields")}</span> */}
                                </Box>

                                <Grid item md={12} xs={12}>
                                    <div className="form-field">
                                    <InputLabel id="hearAboutUs" required> {t("howDidYouHearAboutUs")} </InputLabel>                                        
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        labelId="hearAboutUs" id="hearAboutUsSelect" 
                                        name="hearAboutUsSelect" required 
                                        value={hearAboutUs} onChange={handleHearAboutUsChange} 
                                        error={showInputError(hearAboutUsError)}>
                                            {userState.hearAboutUsList.length === 0 ? 
                                            <MenuItem value="" disabled>{t("loading")}</MenuItem> : 
                                            getHearAboutUsOptions()}
                                        </Select>
                                        {
                                            showInputError(hearAboutUsError) && 
                                                <FormHelperText error={showInputError(hearAboutUsError)}>
                                                    {hearAboutUsError}</FormHelperText>
                                        }
                                    </div>
                                </Grid>

                                {renderHearAboutUsFeedbackInput()}

                            </Grid>
                            

                            {/* <div className="form-field keep-login">
                                <FormControlLabel
                                    control={<Checkbox checked={rememberMe} onChange={handleRememberMeCheckbox} name="rememberMeCheckBox" />}
                                    label={t("rememberMe")}
                                />
                            </div> */}

                            <div className="form-field login-button mt-15">
                                <Button color="primary"
                                    type="submit" variant="contained" disableElevation
                                    onClick={(e) =>
                                        onSubmit(e)
                                        // setShowTermsDialog(true)
                                    }>{t("signUpWithEmail")}</Button>
                            </div>

                            {
                                showInputError(iAgreeError) && <FormHelperText error={showInputError(iAgreeError)}>{iAgreeError}</FormHelperText>
                            }

                            {/* <div className="keep-login">
                                <FormControlLabel className="subscribe-offer"
                                    control={<Checkbox checked={subscribe} onChange={handleSubscribeCheckbox} name="subscribeCheckBox" />}
                                    label={t("subscribeMessage")}
                                />
                            </div> */}

                            <div className="keep-login">
                                <div className="terms-privacy-container">
                                    <Checkbox checked={iAgree} onChange={handleTermsCheckbox} name="agreeCheckBox" />
                                    {t("termsAgreeMessage3")} <Link><span className="terms-agree" onClick={() => { setShowTermsDialog(true) }}>{t("termsAgreeMessage2")}</span></Link>
                                </div>
                            </div>

                            <span className="title-has-icon signin-to-your-account">
                                <a onClick={() => history.push({ pathname: RouteConstants.LOGIN })}>
                                    <span>Or</span> Sign in to your account</a>
                            </span>
                        </form>
                    </div>
                </Grid>
                
                <OnboardingCarousel />
            </Grid>
        </div>
    );
};


export default SignUp;