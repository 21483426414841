import React from "react";
import "./styles.scss";
import SelectUserType from "../../components/SignUp/SelectUserType";

const SignupUserTypeScreen = () => {
    return (
        <div>
            <SelectUserType />
        </div>
    );
};

export default SignupUserTypeScreen;