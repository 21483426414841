import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { InputLabel, Input, FormHelperText, Select, MenuItem, Grid, Dialog, DialogTitle, DialogContent, Snackbar, DialogContentText, makeStyles, DialogActions } from "@material-ui/core";
import { validateEmail, validateMandatoryValue, validateMaximumLength } from "../../../helpers/Validation";
import { differenceYears, formatTimerNumber } from "../../../helpers/GenericHelper";
import { APP_CONSTANTS, GENDER } from "../../../constants/GenericConstants";
import CropperComponent from "../../../components/CropperComponent/CropperComponent";
import Loader from "../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getGenderList } from "../../../store/actions/Authentication";

const useStyles = makeStyles((theme) => ({
    select: {
        "& ul": {
            height: "181px",
            paddingTop: 0,
        },
    },
}));

const today = new Date();
const minDate = "1970-01-01";
const maxDate = (today.getFullYear() - APP_CONSTANTS.MIN_CHILD_AGE) + "-" + formatTimerNumber((today.getMonth() + 1)) + "-" + formatTimerNumber(today.getDate());

const PersonalInformationSection = (props) => {

    const classes = useStyles();
    const userState = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data, setData, errors, setErrors, disabled } = props;
    const { firstName, lastName, dateOfBirth, age, email, gender, childImage, photoURL } = data;
    const { firstNameError, lastNameError, ageError, emailError, childImageError } = errors;

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [compressing, setCompressing] = useState(false);

    const [showAddImageDialog, setShowAddImageDialog] = useState(false);

    const handleCloseDialog = () => {
        setData({ ...data, childImage: {} });
        setShowAddImageDialog(false);
    }

    const onImageSave = (img) => {
        const fileURL = URL.createObjectURL(img);
        let imageObject = new Image();
        imageObject.src = fileURL;

        setData({ ...data, childImage: { file: img, image: imageObject } });
        setErrors({ ...errors, childImageError: "" });

        setShowAddImageDialog(false);

    }



    // const today = new Date();
    // const [maxDate, setMaxDate] = useState((today.getFullYear() - APP_CONSTANTS.MIN_CHILD_AGE) + "-" + formatTimerNumber((today.getMonth() + 1)) + "-" + formatTimerNumber(today.getDate()));
    // const [minDate, setMinDate] = useState((today.getFullYear() - APP_CONSTANTS.MAX_CHILD_AGE) + "-" + formatTimerNumber((today.getMonth() + 1)) + "-" + formatTimerNumber(today.getDate()));

    useEffect(() => {
        dispatch(getGenderList());
    }, []);

    const handleFirstNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, firstName: input });
        validateFirstName(input);
    }

    const handleLastNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, lastName: input });
        validateLastName(input);
    }

    const handleDateOfBirthChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        let ageInYears = calculateAge(input);
        setData({ ...data, dateOfBirth: input, age: ageInYears });
        validateAge(ageInYears);
    }

    const calculateAge = (input) => {
        const year = input.substring(0, 4);
        const month = input.substring(5, 7);
        const date = input.substring(8);
        const currentDate = new Date();
        const inputDate = new Date(year, month - 1, date);
        let ageInYears = differenceYears(inputDate, new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
        return ageInYears;
    }

    const handleEmailChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, email: input });
        validateEmailAddress(input);
    }

    const handleGenderChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setData({ ...data, gender: input });
    }

    const handleImageUpload = (event) => {
        event.preventDefault();
        let imageObject = null;
        let fileObject = null;



        if (event.target.files.length > 0) {
            fileObject = event.target.files[0];
            let imgSize = Math.round((fileObject.size / 1024 / 1024) * 10) / 10;
            // if (imgSize <= 5) {


            let fileName = fileObject.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                setCompressing(true)

                const fileURL = URL.createObjectURL(fileObject);
                imageObject = new Image();
                imageObject.src = fileURL;
                imageObject.onload = () => {
                    setCompressing(false)
                }
                setData({ ...data, childImage: { file: fileObject, image: imageObject }, photoURL: "" });
                setErrors({ ...errors, childImageError: "" });
                setShowAddImageDialog(true)

            }
            else {
                setData({ ...data, childImage: {} });
                setErrors({ ...errors, childImageError: t("imageTypeError") });
            }


            // }
            // else {
            //     setErrors({ ...errors, childImageError: t("imageSizeError") });
            // }
        }
        event.target.value = null;


    }

    const validateFirstName = (firstName) => {
        if (!validateMandatoryValue(firstName)) {
            setErrors({ ...errors, firstNameError: t("firstNameRequired") });
        } else if (!validateMaximumLength(firstName, APP_CONSTANTS.MAX_NAMES_LENGTH)) {
            setErrors({ ...errors, firstNameError: t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_NAMES_LENGTH) });
        } else {
            setErrors({ ...errors, firstNameError: "" });
        }
    }

    const validateLastName = (lastName) => {
        if (!validateMandatoryValue(lastName)) {
            setErrors({ ...errors, lastNameError: t("lastNameRequired") });
        } else if (!validateMaximumLength(lastName, APP_CONSTANTS.MAX_NAMES_LENGTH)) {
            setErrors({ ...errors, lastNameError: t("nameLengthErrorMessage").replace(/{maxLength}/, APP_CONSTANTS.MAX_NAMES_LENGTH) });
        } else {
            setErrors({ ...errors, lastNameError: "" });
        }
    }

    const validateAge = (age) => {
        if (age < 3) {
            setErrors({ ...errors, ageError: t("minAgeError").replace(/{minAge}/, APP_CONSTANTS.MIN_CHILD_AGE) });
        } else {
            setErrors({ ...errors, ageError: "" });
        }
    }

    const validateEmailAddress = (email) => {
        if (!validateMandatoryValue(email) || !validateEmail(email)) {
            setErrors({ ...errors, emailError: t("invalidEmailError") });
        } else {
            setErrors({ ...errors, emailError: "" });
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const getGenderDropdownList = () => {
        return userState.genderList.map((gender, index) => {
            return <MenuItem key={index} value={gender.name}>{gender.label}</MenuItem>;
        });
    }


    return (
        <div className="view-edit-section view-edit-profile">
            <h4>{t("personalInformation")}</h4>


            {compressing && <Loader />}


            <Dialog className="AddPopup-Box" disableBackdropClick disableEscapeKeyDown maxWidth="md" open={showAddImageDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">{t("addNewImage")}</DialogTitle>
                <DialogContent>
                    <CropperComponent addedImage={childImage} closeDialog={handleCloseDialog} onSave={onImageSave} compressing={compressing} setCompressing={setCompressing}/>
                </DialogContent>
            </Dialog>


            <form>
                <Grid container spacing={3}>
                    <Grid item md={8} sm={6} xs={12} className="grid-fields">
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <div className="form-field">
                                    <InputLabel htmlFor="firstName" required>{t("firstName")}</InputLabel>
                                    <Input id="firstName" disabled={disabled} name="firstName" required type="text" value={firstName || ""} onChange={handleFirstNameChange} error={showInputError(firstNameError)} />
                                    {
                                        showInputError(firstNameError) && <FormHelperText error={showInputError(firstNameError)}>{firstNameError}</FormHelperText>
                                    }
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className="form-field">
                                    <InputLabel htmlFor="lastName" required>{t("lastName")}</InputLabel>
                                    <Input id="lastName" name="lastName" disabled={disabled} required type="text" value={lastName || ""} onChange={handleLastNameChange} error={showInputError(lastNameError)} />
                                    {
                                        showInputError(lastNameError) && <FormHelperText error={showInputError(lastNameError)}>{lastNameError}</FormHelperText>
                                    }
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className="form-field">
                                    <InputLabel required>{t("dateOfBirth")}</InputLabel>
                                    <Input disabled={disabled} className="date-picker" id="dateOfBirth" name="dateOfBirth" required type="date" value={dateOfBirth || ""} onChange={handleDateOfBirthChange} inputProps={{ min: minDate, max: maxDate }} />
                                </div>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <div className="form-field">
                                    <InputLabel htmlFor="age" required>{t("age")}</InputLabel>
                                    <Input id="age" name="age" disabled={disabled} required type="text" value={age || ""} error={showInputError(ageError)}/>
                                    {
                                        showInputError(ageError) && <FormHelperText error={showInputError(ageError)}>{ageError}</FormHelperText>
                                    }
                                </div>
                            </Grid>

                            {/* <Grid item md={6} xs={12}>
                                <div className="form-field">
                                    <InputLabel htmlFor="email" required>{t("email")}</InputLabel>
                                    <Input id="email" name="email" disabled={disabled} type="email" value={email || ""} onChange={handleEmailChange} error={showInputError(emailError)} />
                                    {
                                        showInputError(emailError) && <FormHelperText error={showInputError(emailError)}>{emailError}</FormHelperText>
                                    }
                                </div>
                            </Grid> */}

                            <Grid item md={6} xs={12}>
                                <div className="form-field">
                                    <InputLabel htmlFor="gender" required>{t("gender")}</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        id="gender" name="gender" disabled={disabled} value={gender} onChange={handleGenderChange} >
                                        {userState.genderList.length === 0 ? <MenuItem value="" disabled>{t("loading")}</MenuItem> : getGenderDropdownList()}
                                    </Select>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12} className="grid-photo">
                        <div className="view-edit-profile-photo">

                            <figure>
                                <Input id="childImage" name="childImage" disabled={disabled} type="file" inputProps={{ accept: 'image/png, image/jpeg' }} onChange={handleImageUpload} />
                                <i className="ticon-camera">
                                    {!loaded ? <p className="loader"></p> : null}
                                </i>
                                {
                                    photoURL !== "" ?
                                        <img alt="Child Profile Picture"
                                            src={photoURL}
                                            onLoad={() => {
                                                setLoaded(true)
                                            }}
                                            style={{ maxWidth: 200, maxHeight: 200 }} />
                                        :
                                        childImage.image ? <img alt="Child Profile Picture" src={childImage.image.src} style={{ maxWidth: 200, maxHeight: 200 }} /> : <img alt="Child Profile Picture" src="https://i.stack.imgur.com/l60Hf.png" style={{ maxWidth: 200, maxHeight: 200 }} />
                                }

                            </figure>
                            {
                                showInputError(childImageError) && <FormHelperText error={showInputError(childImageError)}>{childImageError}</FormHelperText>
                            }
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div >
    );
};

export default PersonalInformationSection;