import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import RouteConstants from "../../constants/RouteConstants";
import AssetConstants from "../../constants/AssetConstants";
import { confirmExternalUserInvite, saveInviteCode } from '../../store/actions/ExternalUser';
import Loader from '../../components/Loader/Loader';
import Footer from "../../components/Footer/Footer";
import PageNotFound from "../../components/PageNotFound/PageNotFound";

const ExternalUserConfirmAccountScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const externalUserState = useSelector((state) => state.externalUser);

    const [accountActivated, setAccountActivated] = useState(false);
    const [invalidURL, setInvalidURL] = useState(false);
    const [apiError, setApiError] = useState("");

    const onActivationSuccess = (response) => {
        if (response.statusCode === 1000) {
            setAccountActivated(true);
        } else if (response.statusCode === 1001) {
            const params = new URLSearchParams(props.location.search);
            var code = params.get('invitationCode');

            dispatch(saveInviteCode(code));
            history.replace({ pathname: RouteConstants.SIGN_UP, userType: 'multi' });
        }
    }

    const onActivationFailure = (errorMessage) => {
        setAccountActivated(false);
        setApiError(errorMessage);
    }

    useEffect(() => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        var code = params.get('invitationCode');

        if (code) {
            dispatch(confirmExternalUserInvite(code, onActivationSuccess, onActivationFailure));
        } else {
            setInvalidURL(true);
        }
    }, []);

    const moveToLoginScreen = () => {
        localStorage.clear();
        history.push({ pathname: RouteConstants.LOGIN });
    }

    return (
        <div className="is-login">
            <Grid container>
                <Grid item sm={6} className="login-form">
                    <div className="congrats-msg-block">
                        <div className="congrats-msg-container">
                            <img className="app-logo" src={AssetConstants.logo} />
                            {externalUserState.loading && <Loader />}
                            {
                                invalidURL ?
                                    <PageNotFound /> :
                                    (
                                        !externalUserState.loading && accountActivated ?
                                            <div className="congrats-msg">
                                                <h1>{t("congratulations")}</h1>
                                                <p>{t("accountActivated")}</p>
                                                <img src={AssetConstants.congratscheck} />
                                            </div> :
                                            <div>
                                                {
                                                    apiError && <div>
                                                        <h2>{apiError}</h2>
                                                    </div>
                                                }
                                                {
                                                    !apiError && <div style={{
                                                        marginTop:'30px',
                                                        marginBottom: '30px'
                                                    }}>
                                                        {accountActivated ? <h3>{t("accountAlreadyActivated")}</h3> : null}
                                                    </div>
                                                }
                                            </div>
                                    )
                            }
                            <Button color="primary" variant="contained" disableElevation onClick={moveToLoginScreen}>{t("goToLogin")}</Button>
                        </div>
                        <div className="conrats-msg-footer">
                            <Footer />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={6} className="screen-half-splash">

                </Grid>
            </Grid>
        </div>
    );
};

export default ExternalUserConfirmAccountScreen;