import React from "react";
import { makeStyles, Modal, Backdrop } from "@material-ui/core";
import NewGroupChat from "../../../../components/Chat/NewGroupChat";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "4rem 2rem",
    display: "flex",
    flexDirection: "column",
    outline: "none",
    maxWidth: "765px",
    maxHeight: "517px",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  textArea: {
    display: "flex",
    flexDirection: "column",
    rowGap: "2rem",
  },
  createButton: {
    borderRadius: "20px",
    backgroundColor: "#032C61",
    color: "white",
    padding: "0.8rem 2.8rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  cancelButton: {
    borderRadius: "25.5px",
    backgroundColor: "white",
    border: "1px solid #979797",
    color: "#9E9E9E",
    padding: "0.8rem 2rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  buttonsDiv: {
    flexDirection: "row-reverse",
    display: "flex",
    columnGap: "10px",
    marginTop: "auto",
  },
  spaceTitle: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "24px",
    color: "#003471",
  },
}));

export default function CreateGroupModal({ open, setOpen }) {
  const classes = useStyles();

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <NewGroupChat setOpen={setOpen} /> */}
      </Modal>
    </>
  );
}
