import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, InputLabel, Input, FormHelperText, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Checkbox, FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from "@material-ui/core";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import { IMAGE_TYPES, SCHEDULE_TYPE, SCREEN_TYPE, TOOL_ACTIVATED } from "../../constants/GenericConstants";
import { validateMandatoryValue, validateLength18C } from "../../helpers/Validation";
import { clearImage, saveData, clearToolData, addTool } from "../../store/actions/SelfRegulation";
import Loader from '../../components/Loader/Loader';
import { getTimeDifference, getUniqueTimeDropdownOptions } from "../../helpers/GenericHelper";
import DateHelper from "../../helpers/DateHelper";
import moment from "moment";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";

const AddRegulationTool = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const startTime = useRef(new Date().getTime());

    const selfRegulationState = useSelector((state) => state.selfRegulation);
    const childState = useSelector((state) => state.child);

    const [toolName, setToolName] = useState("");
    const [toolNameError, setToolNameError] = useState("");
    const [toolImageError, setToolImageError] = useState("");
    const [selfTriggered, setSelfTriggered] = useState(false);
    const [toolActive, setToolActive] = useState(TOOL_ACTIVATED.YES);
    const [indexGot, setIndexGot] = useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);


    const [toolTaskName, setToolTaskName] = useState("");
    const [toolTaskNameError, setToolTaskNameError] = useState("");
    const [toolTaskImageError, setToolTaskImageError] = useState("");
    const [subTaskAdded, setSubTaskAdded] = useState(false);

    const [subTasksList, setSubTasksList] = useState([]);

    const [addError, setAddError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
    const [notificationTime, setNotificationTime] = useState("06:00:00");
    const [recurrence, setRecurrence] = useState(SCHEDULE_TYPE.WEEKLY);

    const [selectDaysArray, setSelectDaysArray] = useState([
        { label: t("monday").toUpperCase().substring(0, 3), value: 1, selected: false },
        { label: t("tuesday").toUpperCase().substring(0, 3), value: 2, selected: false },
        { label: t("wednesday").toUpperCase().substring(0, 3), value: 3, selected: false },
        { label: t("thursday").toUpperCase().substring(0, 3), value: 4, selected: false },
        { label: t("friday").toUpperCase().substring(0, 3), value: 5, selected: false },
        { label: t("saturday").toUpperCase().substring(0, 3), value: 6, selected: false },
        { label: t("sunday").toUpperCase().substring(0, 3), value: 0, selected: false },
    ]);
    const [daysError, setDaysError] = useState("");

    useEffect(() => {
        const data = selfRegulationState.tempToolData;
        if (Object.keys(data).length > 0) {
            setToolName(data.toolName);
            setToolNameError(data.toolNameError);
            setToolTaskName(data.toolTaskName);
            setToolTaskNameError(data.toolTaskNameError);
            setSubTasksList(data.subTasksList);
            setToolActive(data.toolActive);
            setNotificationTime(data.notificationTime);
            setRecurrence(data.recurrence);
            setSelectDaysArray(data.selectDaysArray);
            setSelfTriggered(data.selfTriggered);
        }
    }, []);

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }

    const handleToolNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setToolName(input);
        validateToolName(input);
    }

    const validateToolName = (toolName) => {
        if (!validateMandatoryValue(toolName)) {
            setToolNameError(t("toolNameRequiredError"));
            return false;
        } else if (!validateLength18C(toolName)) {
            setToolNameError(t("toolNameLengthError"));
            return false;
        } else {
            setToolNameError("");
            return true;
        }
    }

    const validateToolImage = () => {
        if (!validateMandatoryValue(selfRegulationState.toolImage.imageURL)) {
            setToolImageError(t("toolImageRequiredError"));
            return false;
        } else {
            setToolImageError("");
            return true;
        }
    }

    const handleToolActiveChange = (event) => {
        if (selfTriggered) return;
        const input = event.target && event.target.value ? event.target.value : "";
        setToolActive(input);
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const handleToolTaskNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setToolTaskName(input);
        validateToolTaskName(input);
    }

    const validateToolTaskName = (toolTaskName) => {
        if (!validateMandatoryValue(toolTaskName)) {
            setToolTaskNameError(t("toolInstructionNameRequiredError"));
            return false;
        } else if (!validateLength18C(toolTaskName)) {
            setToolTaskNameError(t("toolInstructionNameLimitError"));
            return false;
        } else {
            setToolTaskNameError("");
            return true;
        }
    }

    const validateSubTaskImage = () => {
        if (!validateMandatoryValue(selfRegulationState.subTaskImage.imageURL)) {
            setToolTaskImageError(t("toolInstructionImageRequiredError"));
            return false;
        } else {
            setToolTaskImageError("");
            return true;
        }
    }

    const moveToImageLibrary = (imageType) => {
        const dataObject = {
            toolName: toolName,
            toolNameError: toolNameError,
            toolTaskName: toolTaskName,
            toolTaskNameError: toolTaskNameError,
            subTasksList: subTasksList,
            toolActive: toolActive,
            notificationTime: notificationTime,
            recurrence: recurrence,
            selectDaysArray: selectDaysArray,
            selfTriggered: selfTriggered,
        };
        dispatch(saveData(dataObject));
        history.push({ pathname: RouteConstants.IMAGE_LIBRARY, imageType: imageType, screenType: SCREEN_TYPE.ADD });
    }

    const clearSubTaskData = () => {
        setToolTaskName("");
        setToolTaskNameError("");
        setToolTaskImageError("");
        dispatch(clearImage(IMAGE_TYPES.TOOL_SUBTASK_IMAGE, SCREEN_TYPE.ADD));
    }

    const addSubTask = () => {
        setSubTaskAdded(true);
        if (validateToolTaskName(toolTaskName) & validateSubTaskImage()) {
            const item = {
                name: toolTaskName,
                image: selfRegulationState.subTaskImage,
                order: subTasksList.length === 0 ? 1 : subTasksList[subTasksList.length - 1].order + 1,
            };
            setSubTasksList([...subTasksList, item]);
            clearSubTaskData();
            setSubTaskAdded(false);
        }
    }

    const deleteSubtask = (index) => {
        const subtasks = [...subTasksList];
        subtasks.splice(index, 1);
        setSubTasksList(subtasks);
    }

    const isFormValid = () => {
        return validateToolName(toolName) & validateToolImage();
    }

    const onSuccess = (data) => {
        //mixpanel
        const endTime = new Date().getTime();
        const duration = getTimeDifference(startTime.current, endTime);

        Mixpanel.track(MIX_PANEL_EVENTS.EMOTION_REGULATION_TOOL_CREATED, {
          [MIX_PANEL_EVENTS.TOOL_CREATION_TIME]: duration,
          [MIX_PANEL_EVENTS.INSTRUCTIONS_COUNT]: data.subTasks.length,
        });

        history.goBack();
        dispatch(clearToolData());
    }

    const onFailure = (errorMessage) => {
        setAddError(true);
        setErrorMessage(errorMessage);
    }

    const onCreatePress = () => {
        if (isFormValid()) {
            const payload = {
                name: toolName,
                imageId: selfRegulationState.toolImage.id,
                accountId: childState.selectedChild.accountId,
                childId: childState.selectedChild.childId,
                isActivated: toolActive === TOOL_ACTIVATED.YES ? 1 : 0,
                subTasks: subTasksList.map((subtask, index) => { return { name: subtask.name, imageId: subtask.image.id, order: index + 1 }; }),
            };
            if (selfTriggered) {
                if (recurrence === SCHEDULE_TYPE.DAILY || recurrence === SCHEDULE_TYPE.WEEKLY || recurrence === SCHEDULE_TYPE.SELECTED_DAYS) {
                    payload.startDate = DateHelper.formatDate(new Date(), "yyyy-MM-dd");
                } else if (recurrence === SCHEDULE_TYPE.ONE_TIME) {
                    const selectedDay = (selectDaysArray.findIndex((selectDay) => selectDay.selected) + 1) % 7;
                    const today = moment(DateHelper.formatDate(new Date(), "yyyy-MM-dd"));
                    const dayN = +today.day();
                    const adder = (selectedDay - dayN + 7) % 7;
                    const sDate = moment().clone().add(adder, 'days').format("YYYY-MM-DD");
                    payload.startDate = sDate;
                }
                payload.startTime = notificationTime;
                payload.scheduleType = recurrence;
                payload.scheduleDay = selectDaysArray.filter((day) => day.selected).map((day) => day.label);
            }
            dispatch(addTool(payload, onSuccess, onFailure));
        }
    }

    const handleAddErrorClose = () => {
        setAddError(false);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const goToToolsList = () => {
        history.goBack();
        dispatch(clearToolData());
    }


    const checkChanged = () => {
        let changed = false;

        if (toolName !== "" || subTasksList.length !== 0 || selfRegulationState.toolImage.imageURL !== undefined || selfRegulationState.subTaskImage.imageURL !== undefined || toolTaskName !== "") {
            changed = true
        }
        return changed;
    }

    const openNotificationModal = () => {
        setNotificationDialogOpen(true);
    }

    const openNotificationDetailModal = () => {
        if (selfTriggered) {
            setSelfTriggered(false);
        } else {
            openNotificationModal();
        }
    }

    const handleNotificationCloseDialog = () => {
        if (!isNotifDialogValid(selectDaysArray)) {
            setSelfTriggered(false);
        }
        setNotificationDialogOpen(false);
    }

    const handleNotificationTimeChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setNotificationTime(input);
    }

    const handleRecurrenceChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setRecurrence(input);
        const newArray = selectDaysArray.map((item) => {
            return {
                ...item,
                selected: input === SCHEDULE_TYPE.DAILY ? true : false,
            };
        });
        setSelectDaysArray(newArray);
        isNotifDialogValid(newArray);
    }

    const selectDay = (index) => {
        if (recurrence === SCHEDULE_TYPE.DAILY) return;
        if (recurrence === SCHEDULE_TYPE.ONE_TIME || recurrence === SCHEDULE_TYPE.WEEKLY) {
            const newArray = selectDaysArray.map((day, dayIndex) => {
                return {
                    label: day.label,
                    value: day.value,
                    selected: dayIndex === index ? true : false,
                };
            });
            setSelectDaysArray(newArray);
            isNotifDialogValid(newArray);
        } else {
            const newArray = [...selectDaysArray];
            newArray[index].selected = !newArray[index].selected;
            setSelectDaysArray(newArray);
            isNotifDialogValid(newArray);
        }
    }

    const isNotifDialogValid = (selectDaysArray) => {
        for (let i = 0; i < selectDaysArray.length; i++) {
            if (selectDaysArray[i].selected) {
                setDaysError("");
                return true;
            }
        }
        setDaysError(t("selectDaysError"));
        return false;
    }

    const saveNotificationTime = () => {
        if (isNotifDialogValid(selectDaysArray)) {
            setNotificationDialogOpen(false);
            setSelfTriggered(true);
            setToolActive(TOOL_ACTIVATED.YES);
        }
    }

    return (
        <div>
            {selfRegulationState.loading && <Loader />}

            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeleteDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => { deleteSubtask(indexGot); handleCloseDeleteDialog(); }} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>


            <SnackbarAlert isError={true} show={addError} message={errorMessage.length > 0 ? errorMessage : t("unknownError")} duration={5000} onCloseAlert={handleAddErrorClose} />
            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("informationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={goToToolsList} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog disableBackdropClick disableEscapeKeyDown className="snd-popup" maxWidth="xs" open={notificationDialogOpen} onClose={handleNotificationCloseDialog} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleNotificationCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("setNotificationDetails")}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <div>
                                <InputLabel htmlFor="notificationTime" required>{t("setTime")}</InputLabel>
                                <Select labelId="notificationTime" id="notificationTimeSelect" name="notificationTimeSelect" required value={notificationTime} onChange={handleNotificationTimeChange}>
                                    {
                                        getUniqueTimeDropdownOptions().map((timeObj) => {
                                            return <MenuItem key={timeObj.value} value={timeObj.value}>{timeObj.label}</MenuItem>;
                                        })
                                    }
                                </Select>
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <div>
                                <InputLabel id="repeat" required>{t("repeat")}</InputLabel>
                                <Select labelId="repeat" id="repeatSelect" name="repeatSelect" required value={recurrence} onChange={handleRecurrenceChange}>
                                    <MenuItem key={SCHEDULE_TYPE.DAILY} value={SCHEDULE_TYPE.DAILY}>{t("everyDay")}</MenuItem>
                                    <MenuItem key={SCHEDULE_TYPE.WEEKLY} value={SCHEDULE_TYPE.WEEKLY}>{t("thisDayEveryWeek")}</MenuItem>
                                    <MenuItem key={SCHEDULE_TYPE.ONE_TIME} value={SCHEDULE_TYPE.ONE_TIME}>{t("dontRepeat")}</MenuItem>
                                    <MenuItem key={SCHEDULE_TYPE.SELECTED_DAYS} value={SCHEDULE_TYPE.SELECTED_DAYS}>{t("repeatOnSelectDays")}</MenuItem>
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div disabled={true}>
                                <InputLabel id="selectDaysLabel">{t("selectDays")}</InputLabel>
                                <div className="selectDays">
                                    {
                                        selectDaysArray.map((dayObj, index) => {
                                            return dayObj.selected ?
                                                <div key={dayObj.label} style={{ backgroundColor: '#02c8a7', color: 'white' }} onClick={() => selectDay(index)}>
                                                    <span>{dayObj.label[0]}</span>
                                                </div> :
                                                <div key={dayObj.label} style={{ backgroundColor: '#e0e0e0', color: '#9e9e9e' }} onClick={() => selectDay(index)}>
                                                    <span>{dayObj.label[0]}</span>
                                                </div>
                                        })
                                    }
                                </div>
                                {
                                    showInputError(daysError) && <FormHelperText error={showInputError(daysError)}>{daysError}</FormHelperText>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={saveNotificationTime} color="primary">{t("save")}</Button>
                </DialogActions>
            </Dialog>

            <Card className="no-style activity-section self-regulation-section">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="generic-card">
                            <div className="self-reg-two-box-container">
                                <div className="col-left">
                                    <h3>{t("createNewSelfRegulationTool")}</h3>
                                    <div className="form-field">
                                        <InputLabel htmlFor="toolName" required>{t("toolName")}</InputLabel>
                                        <Input id="toolName" name="toolName" required type="text" value={toolName || ""} placeholder={t("activityNamePlaceholder")} onChange={handleToolNameChange} error={showInputError(toolNameError)} />
                                        {
                                            showInputError(toolNameError) && <FormHelperText error={showInputError(toolNameError)}>{toolNameError}</FormHelperText>
                                        }
                                    </div>
                                    {
                                        selfRegulationState.toolImage.imageURL ?
                                            <div className="ImgBox">
                                                <span className="camera-icon"><CameraAltIcon onClick={() => moveToImageLibrary(IMAGE_TYPES.TOOL_IMAGE)} /></span>
                                                <img src={AssetConstants.applewatch} alt="Apple Watch Image" />
                                                {
                                                    selfRegulationState.toolImage.imageURL &&
                                                    <img alt="Tool Image" src={selfRegulationState.toolImage.imageURL} className="m-icon" />
                                                }
                                            </div> :
                                            <div className="ImgBox">
                                                <img src={AssetConstants.applewatchgray} alt="Apple Watch Image" />
                                                <Button onClick={() => moveToImageLibrary(IMAGE_TYPES.TOOL_IMAGE)} className="selectImageBtn">{t("selectImage")}</Button>
                                            </div>

                                    }
                                    {
                                        showInputError(toolImageError) && <FormHelperText error={showInputError(toolImageError)}>{toolImageError}</FormHelperText>
                                    }
                                    <div className="mt-15 enableCheck d-flex align-items-center">
                                        <Checkbox checked={selfTriggered} onChange={openNotificationDetailModal} />
                                        <span onClick={openNotificationModal}>{t("enableSelfTriggered")}</span>
                                    </div>
                                </div>
                                <div className="col-right self-regulation-rs">
                                    {subTasksList.length < 4 && <div>
                                        <h3>{t("addInstructionsToToolHeading")}<span> - {t("optional")}</span></h3>

                                        <div className="activityTask">
                                            {
                                                selfRegulationState.subTaskImage.imageURL ?
                                                    <img src={selfRegulationState.subTaskImage.imageURL} alt="Sub task image" onClick={() => moveToImageLibrary(IMAGE_TYPES.TOOL_SUBTASK_IMAGE)} /> :
                                                    <img src={AssetConstants.oval} alt="Sub task image" onClick={() => moveToImageLibrary(IMAGE_TYPES.TOOL_SUBTASK_IMAGE)} />
                                            }
                                            <div className="form-field">
                                                <InputLabel htmlFor="toolTaskName">{t("toolInstructionName")}</InputLabel>
                                                <Input id="toolTaskName" name="toolTaskName" required type="text" value={toolTaskName || ""} placeholder={t("activityTaskNamePlaceholder")} onChange={handleToolTaskNameChange} error={showInputError(toolTaskNameError)} />
                                                {
                                                    showInputError(toolTaskNameError) && <FormHelperText error={showInputError(toolTaskNameError)}>{toolTaskNameError}</FormHelperText>
                                                }
                                            </div>
                                        </div>
                                        {
                                            showInputError(toolTaskImageError) && <FormHelperText error={showInputError(toolTaskImageError)}>{toolTaskImageError}</FormHelperText>
                                        }
                                        <div className="addBtn">
                                            <Button onClick={addSubTask}>{t("add")}</Button>
                                        </div>
                                    </div>
                                    }
                                    <div className={subTasksList.length == 0 ? "sub-tasks-list-added height-auto" : subTasksList.length == 1 ? "sub-tasks-list-added height-one" : "sub-tasks-list-added"}>
                                        <h4>{t("addedInstructionsList")}</h4>
                                        {
                                            subTasksList.length > 0 ?
                                                <div className="scroll-div">
                                                    {
                                                        subTasksList.sort((a, b) => a.order - b.order).map((subTask, index) => {
                                                            return <div className="d-flex align-items-center mb-10">
                                                                <img src={subTask.image.imageURL} alt="Sub task image" />
                                                                <span>{subTask.name}</span>
                                                                <Button className="arrow-btn"
                                                                    disabled={index === 0}
                                                                    onClick={() => {
                                                                        let tempSubtasks = subTasksList;
                                                                        let oldOrder = tempSubtasks[index].order
                                                                        tempSubtasks[index].order = tempSubtasks[index - 1].order
                                                                        tempSubtasks[index - 1].order = oldOrder
                                                                        // alert("Old " + tempSubtasks[index].order + "-- New" + tempSubtasks[index - 1].order)
                                                                        let onlyorders = subTasksList.map(s => { return { name: s.name, order: s.order } })
                                                                        // alert(JSON.stringify(onlyorders))
                                                                        setSubTasksList([...tempSubtasks])

                                                                    }}><KeyboardArrowUpIcon /></Button>
                                                                <Button className="arrow-btn"
                                                                    disabled={(subTasksList.length !== 0 && index === subTasksList.length - 1) || subTasksList.length === 0}
                                                                    onClick={() => {
                                                                        let tempSubtasks = subTasksList;
                                                                        let oldOrder = tempSubtasks[index].order
                                                                        tempSubtasks[index].order = tempSubtasks[index + 1].order
                                                                        tempSubtasks[index + 1].order = oldOrder
                                                                        // alert("Old " + tempSubtasks[index].order + "-- New" + tempSubtasks[index + 1].order)
                                                                        let onlyorders = subTasksList.map(s => { return { name: s.name, order: s.order } })
                                                                        // alert(JSON.stringify(onlyorders))
                                                                        setSubTasksList([...tempSubtasks])
                                                                    }}
                                                                ><KeyboardArrowDownIcon /></Button>
                                                                <div className="edit-delete">
                                                                    <i className="ticon-trash-line" onClick={() => { setIndexGot(index); setDeleteDialogOpen(true) }}></i>
                                                                </div>
                                                            </div>;
                                                        })
                                                    }
                                                </div> :
                                                <div>
                                                    <span>{t("noInstructionsAdded")}</span>
                                                </div>
                                        }
                                    </div>

                                    <div className="activateThisTool">
                                        <h4>{t("activateThisTool")}</h4>
                                        <p>{t("activateThisToolMessage")}</p>
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="toolActivation" name="toolActivation" value={toolActive} onChange={handleToolActiveChange}>
                                                <FormControlLabel value={TOOL_ACTIVATED.YES} control={<Radio />} label={t("yesActivateIt")} />
                                                <FormControlLabel value={TOOL_ACTIVATED.NO} control={<Radio />} label={t("keepDisabled")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    <div className="activityFooterBtns">
                                        <div className="buttons-footer">
                                            <Button onClick={() => {
                                                if (checkChanged()) {
                                                    setDialogOpen(true)
                                                }
                                                else {
                                                    goToToolsList()
                                                }
                                            }}>{t("cancel")}</Button>
                                            <Button onClick={onCreatePress} color="primary" variant="contained">{t("save")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default AddRegulationTool;