import React, { useState } from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";

import {
  isGroupOwner,
  selectGroupById,
} from "../../../../../helpers/GenericHelper";

const useStyles = makeStyles(() => ({
  inline: {
    display: "inline",
    color: "#02C8A7",
  },
  secondaryText: {
    display: "flex",
    alignItems: "center",
  },
}));

export const GroupRecipients = ({ recipients = [] }) => {
  const classes = useStyles();
  const { id: groupId } = useParams();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const group = useSelector((state) => selectGroupById(state, groupId));

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List sx={{ pt: 0 }}>
        {recipients?.map((recipient) => {
          const isOwner = isGroupOwner(recipient, group);
          let recipientName = `${recipient?.firstName} ${recipient?.lastName}`;

          if (!recipient) {
            recipientName = t("deletedUser");
          }

          return (
            <ListItem button>
              <ListItemAvatar>
                <Avatar alt={recipientName} src={recipient?.photo || ""} />
              </ListItemAvatar>
              <ListItemText
                primary={recipientName}
                secondary={isOwner ? <Subtitle text="Group Owner" /> : ""}
              />

              {/* <ListItemSecondaryAction>
                <MoreVertIcon onClick={handleOpenMenu} />
                <Menu
                  id={`menu__${recipient.id}`}
                  anchorEl={anchorEl}
                  keepMounted={false}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleCloseMenu}>Remove User</MenuItem>
                </Menu>
              </ListItemSecondaryAction> */}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

GroupRecipients.propTypes = {
  recipients: PropTypes.array.isRequired,
};

export const Subtitle = ({ text }) => {
  const classes = useStyles();

  return (
    <Box component={"p"} className={classes.secondaryText}>
      <Typography
        component="span"
        variant="subtitle2"
        className={classes.inline}
      >
        {text}
      </Typography>
    </Box>
  );
};
