import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Container, Button } from "@material-ui/core";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { getEditPermValueIndividual } from "../../helpers/PermissionCalculator";
import { PRIV_CONSTS } from "../../constants/StaticData";
import EditRegulationTool from "../../components/EditRegulationTool/EditRegulationTool";
import MenuIcon from '@material-ui/icons/Menu';
import { collapseSideBar } from "../../store/actions/SideBar";

const EditRegulationToolScreen = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const childState = useSelector((state) => state.child);
    const sideBarState = useSelector((state) => state.sideBar);

    const [childIdGot, setChildIdGot] = useState(false);

    const [editSch, setEditSch] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(true);

    const [sidebarCollapsed, setSidebarCollapsed] = useState(sideBarState.collapsed);

    useEffect(() => {
        const childId = decodeURIComponent(props.match.params.childId);
        if (childId) {
            let permEdit = checkParentAndPermEdit(childId, PRIV_CONSTS.EMOT_REG_PRIV)
            setEditSch(permEdit)
        }
        setChildIdGot(childId)
    }, [])

    const sideBarClose = () => {
        setSideBarOpen(true)
    }

    const checkParentAndPermEdit = (childId, priv_const) => {

        let permGot = checkOwnChild(childId)
        if (!permGot && childState.otherChildrenList) {
            childState.otherChildrenList.map(clientData => {
                if (clientData.childProfiles) {
                    clientData.childProfiles.map(c => {
                        if (c.childId == childId) {
                            let perm = getEditPermValueIndividual(c.userPermissions, priv_const);
                            permGot = perm
                        }
                    })
                }
            })
        }
        return permGot;
    }

    const checkOwnChild = (childId) => {
        let ownChild = false
        if (childId) { //TO CHECK IF OWN CHILD
            if (childState.childrenList) {
                childState.childrenList.map(c => {
                    if (c.childId == childId) {
                        ownChild = true
                    }
                })

            }
        }
        return ownChild;
    }

    const handleSidebarCollapse = () => {
        setSidebarCollapsed(!sidebarCollapsed);
        dispatch(collapseSideBar(!sidebarCollapsed));
    }

    return (
        <Grid container className="app">
            <Grid xs={12} item className="app-header">
                <Header />
            </Grid>
            <Grid item xs={12} className="grid-body">
                <Container maxWidth="xl">
                    <Button className="navBtn" variant="contained"
                        onClick={() => {
                            setSideBarOpen(prev => !prev)
                        }}
                        color="primary">
                        <MenuIcon />
                    </Button>
                    <Grid container spacing={3}>
                        <Grid item xs={sidebarCollapsed? 1: 2} 
                            onClick={() => {
                                sideBarClose()
                            }} 
                            className={!sideBarOpen ? "generic-card-full-height boxopen" : "generic-card-full-height boxclose"}>
                            <Sidebar 
                                selectedItem={t("selfRegulation")} 
                                childIdGot={childIdGot} 
                                sideBarClose={sideBarClose} 
                                onCollapse={handleSidebarCollapse}
                                collapseOnLaunch={sidebarCollapsed}
                            />
                        </Grid>
                        <Grid item xs={sidebarCollapsed? 11: 10} className="generic-card-full-height box-full-width">
                            <EditRegulationTool editAccess={editSch} toolId={decodeURIComponent(props.match.params.toolId)} childId={decodeURIComponent(props.match.params.childId)} />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="xl">
                    <Grid container spacing={6} className="my-0">
                        <Grid item xs={12}>
                            <Footer />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );

};

export default EditRegulationToolScreen;