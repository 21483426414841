import { request, success, failure } from ".";
import { ADD_CHILD_ACTIONS, GET_CHILD_LIST_ACTIONS, GET_CHILD_ACTIONS, EDIT_CHILD_ACTIONS, IMAGE_UPLOAD_ACTIONS, SET_CURRENT_CHILD_ACTIONS, DELETE_CHILD_ACTIONS, LOGOUT_WATCH_CHILD_ACTIONS, CHILD_EXISTS_ACTIONS, GET_CHILD_ACT_TRACKING_ACTIONS, REGENERATE_WATCH_CODE_ACTIONS } from "../../constants/ActionConstants";
import { ROLE_TYPES, LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import ChildService from "../../services/api/ChildService";


// export const addChild = (status, step, stepOneData, stepTwoData, stepThreeData, stepFourData, onSuccess, onFailure) => {
export const addChild = (status, step, stepOneData, stepTwoData, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ADD_CHILD_ACTIONS.ADD_CHILD, {}));
        try {
            const response = await ChildService.addChild(status, step, stepOneData, stepTwoData);
            dispatch(success(ADD_CHILD_ACTIONS.ADD_CHILD_SUCCESS, { data: response.data }));
            dispatch(getChildrenList())
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(ADD_CHILD_ACTIONS.ADD_CHILD_FAILURE, {}));
            onFailure(error.data? error.data.message: (error.message || ""));
        }
    };
}

export const getChildrenList = (updated = false, childId = null, onSuccess = null) => {
    return async (dispatch) => {
        dispatch(request(GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST, {}));
        try {
            const response = await ChildService.getChildren();
            const parentChildren = [];
            const coParentChildren = {};
            const therapistChildren = {};
            const teacherChildren = {};
            const othersChildren = {};
            const academicChildren = {};
            const partnerSpouse = {};

            for (let i = 0; i < response.data.length; i++) {
                const currentChild = response.data[i];
                if (currentChild.userRole === ROLE_TYPES.PARENT) {
                    parentChildren.push(currentChild);
                } else if (currentChild.userRole === ROLE_TYPES.THERAPIST) {
                    if (currentChild) {
                        if (therapistChildren[currentChild.parentId]) {
                            therapistChildren[currentChild.parentId].push(currentChild);
                        } else {
                            therapistChildren[currentChild.parentId] = [currentChild];
                        }
                    }
                } else if (currentChild.userRole === ROLE_TYPES.TEACHER) {
                    if (currentChild) {
                        if (teacherChildren[currentChild.parentId]) {
                            teacherChildren[currentChild.parentId].push(currentChild);
                        } else {
                            teacherChildren[currentChild.parentId] = [currentChild];
                        }
                    }
                } else if (currentChild.userRole === ROLE_TYPES.CO_PARENT_GUARDIAN) {
                    if (currentChild) {
                        if (coParentChildren[currentChild.parentId]) {
                            coParentChildren[currentChild.parentId].push(currentChild);
                        } else {
                            coParentChildren[currentChild.parentId] = [currentChild];
                        }
                    }
                } else if (currentChild.userRole === ROLE_TYPES.OTHER) {
                    if (currentChild) {
                        if (othersChildren[currentChild.parentId]) {
                            othersChildren[currentChild.parentId].push(currentChild);
                        } else {
                            othersChildren[currentChild.parentId] = [currentChild];
                        }
                    }
                }  else if (currentChild.userRole === ROLE_TYPES.ACADEMIC_ADVISOR) {
                    if (currentChild) {
                        if (academicChildren[currentChild.parentId]) {
                            academicChildren[currentChild.parentId].push(currentChild);
                        } else {
                            academicChildren[currentChild.parentId] = [currentChild];
                        }
                    }
                }  else if (currentChild.userRole === ROLE_TYPES.PARTNER_SPOUSE) {
                    if (currentChild) {
                        if (partnerSpouse[currentChild.parentId]) {
                            partnerSpouse[currentChild.parentId].push(currentChild);
                        } else {
                            partnerSpouse[currentChild.parentId] = [currentChild];
                        }
                    }
                }
            }

            const otherChildren = [];
            for (let key in coParentChildren) {
                const childArray = coParentChildren[key];
                const obj = {
                    clientName: childArray[0].parentFirstName + " " + childArray[0].parentLastName,
                    isActive: childArray[0].isActive,
                    role: childArray[0].userRole,
                    childProfiles: childArray,
                };
                otherChildren.push(obj);
            }
            for (let key in therapistChildren) {
                const childArray = therapistChildren[key];
                const obj = {
                    clientName: childArray[0].parentFirstName + " " + childArray[0].parentLastName,
                    isActive: childArray[0].isActive,
                    role: childArray[0].userRole,
                    childProfiles: childArray,
                };
                otherChildren.push(obj);
            }
            for (let key in teacherChildren) {
                const childArray = teacherChildren[key];
                const obj = {
                    clientName: childArray[0].parentFirstName + " " + childArray[0].parentLastName,
                    isActive: childArray[0].isActive,
                    role: childArray[0].userRole,
                    childProfiles: childArray,
                };
                otherChildren.push(obj);
            }
            for (let key in othersChildren) {
                const childArray = othersChildren[key];
                const obj = {
                    clientName: childArray[0].parentFirstName + " " + childArray[0].parentLastName,
                    isActive: childArray[0].isActive,
                    role: childArray[0].userRole,
                    childProfiles: childArray,
                };
                otherChildren.push(obj);
            }
            for (let key in academicChildren) {
                const childArray = academicChildren[key];
                const obj = {
                    clientName: childArray[0].parentFirstName + " " + childArray[0].parentLastName,
                    isActive: childArray[0].isActive,
                    role: childArray[0].userRole,
                    childProfiles: childArray,
                };
                otherChildren.push(obj);
            }
            for (let key in partnerSpouse) {
                const childArray = partnerSpouse[key];
                const obj = {
                    clientName: childArray[0].parentFirstName + " " + childArray[0].parentLastName,
                    isActive: childArray[0].isActive,
                    role: childArray[0].userRole,
                    childProfiles: childArray,
                };
                otherChildren.push(obj);
            }
            localStorage.setItem(LOCAL_STORAGE_KEYS.CHILDREN_LIST, JSON.stringify(parentChildren));
            localStorage.setItem(LOCAL_STORAGE_KEYS.OTHER_CHILDREN_LIST, JSON.stringify(otherChildren));
            dispatch(success(GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST_SUCCESS, { parentData: parentChildren, otherData: otherChildren }));
            if (updated) {
                dispatch(setCurrentChild(childId));
            }
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess({ parentData: parentChildren, otherData: otherChildren });
                }, 1000);
            }
        } catch (error) {
            dispatch(failure(GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST_FAILURE, {}));
        }
    };
}

export const getChildrenListForEx = (onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST, {}));
        try {
            const response = await ChildService.getChildren();
            dispatch(success(GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data)
        } catch (error) {
            dispatch(failure(GET_CHILD_LIST_ACTIONS.GET_CHILD_LIST_FAILURE, {}));
            onFailure(error)
        }
    };
}

export const getChildrenActivityProgress = (startDate, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_CHILD_ACT_TRACKING_ACTIONS.GET_CHILD_ACT_TRACKING, {}));
        try {
            const response = await ChildService.getChildActivityTracking(startDate);
            dispatch(success(GET_CHILD_ACT_TRACKING_ACTIONS.GET_CHILD_ACT_TRACKING_SUCCESS, { data: response.data }));
            onSuccess(response.data)
        } catch (error) {
            dispatch(failure(GET_CHILD_ACT_TRACKING_ACTIONS.GET_CHILD_ACT_TRACKING_FAILURE, {}));
            onFailure(error)
        }
    };
}

export const getChild = (childId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_CHILD_ACTIONS.GET_CHILD, {}));
        try {
            const response = await ChildService.getChild(childId);
            dispatch(success(GET_CHILD_ACTIONS.GET_CHILD_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_CHILD_ACTIONS.GET_CHILD_FAILURE, {}));
            onFailure(error);
        }
    }
}

export const editChild = (childId, status, step, profile, address, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(EDIT_CHILD_ACTIONS.EDIT_CHILD, {}));
        try {
            const response = await ChildService.editChild(childId, status, step, profile, address); //(, medical, info)
            // dispatch(success(EDIT_CHILD_ACTIONS.EDIT_CHILD_SUCCESS, { data: response.data }));
            dispatch(getChildrenList(true, childId));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(EDIT_CHILD_ACTIONS.EDIT_CHILD_FAILURE, {}));
            onFailure();
        }
    };
}

export const resetEditChildData = () => {
    return async (dispatch) => {
        dispatch(request(EDIT_CHILD_ACTIONS.RESET_EDIT_CHILD_DATA, {}));

    }
}

export const getPresignedURL = (fileName, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));
        try {
            const response = await ChildService.getPresignedURL(fileName);
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}

export const getImageURL = (fileName, onSuccess, onFailure) => {
    return async (dispatch) => {
        // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));
        try {
            const response = await ChildService.getImageURL(fileName);
            // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}

export const uploadImage = (data, urlAws, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));

        try {
            const response = await ChildService.uploadImagePut(data, urlAws)
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}

export const setCurrentChild = (childId) => {
    return (dispatch) => {
        dispatch(request(SET_CURRENT_CHILD_ACTIONS.SET_CURRENT_CHILD, { id: childId }));
    };
}

export const setCurrentChildForSingleUser = (childObject) => {
    return (dispatch) => {
        dispatch(request(SET_CURRENT_CHILD_ACTIONS.SET_SINGLE_USER_CHILD, { child: childObject }));
    };
}

export const childExists = (childId, onSuccess) => {
    return (dispatch) => {
        let response = dispatch(request(CHILD_EXISTS_ACTIONS.CHILD_EXISTS, { id: childId }));
        onSuccess(response.payload.exists);
    };
}

export const childExistsOnly = (childId, onSuccess) => {
    return (dispatch) => {
        let response = dispatch(request(CHILD_EXISTS_ACTIONS.CHILD_EXISTS_ONLY, { id: childId }));
        // alert(JSON.stringify(response))
        onSuccess(response.payload.exists);
    };
}

export const deleteChild = (childId, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(DELETE_CHILD_ACTIONS.DELETE_CHILD, {}));
        try {
            await ChildService.deleteChild(childId);
            dispatch(success(DELETE_CHILD_ACTIONS.DELETE_CHILD_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(DELETE_CHILD_ACTIONS.DELETE_CHILD_FAILURE, {}));
        }
    };
}

export const logoutChildFromWatch = (childId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(LOGOUT_WATCH_CHILD_ACTIONS.LOGOUT_WATCH_CHILD, {}));
        try {
            const response = await ChildService.logoutChildFromWatch(childId);
            dispatch(success(LOGOUT_WATCH_CHILD_ACTIONS.LOGOUT_WATCH_CHILD_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(LOGOUT_WATCH_CHILD_ACTIONS.LOGOUT_WATCH_CHILD_FAILURE, {}));
            onFailure(error)
        }
    };
}

export const regenerateWatchCode = (childId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(REGENERATE_WATCH_CODE_ACTIONS.REGENERATE_WATCH_CODE, {}));
        try {
            const response = await ChildService.regenerateWatchCode(childId);
            dispatch(success(REGENERATE_WATCH_CODE_ACTIONS.REGENERATE_WATCH_CODE_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(REGENERATE_WATCH_CODE_ACTIONS.REGENERATE_WATCH_CODE_FAILURE, {}));
            onFailure(error.data.message)
        }
    };
}