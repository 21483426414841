import React from "react";
import PropTypes from "prop-types";
import {
  Backdrop,
  Box,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { GroupRecipients } from "./GroupRecipients";
import { CloseOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: "30%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0),
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  heading: {
    flex: 1,
    fontWeight: 600,
  },
  message: {
    padding: theme.spacing(2),
  },
}));

const GroupRecipientsModal = ({ showModal, setShowModal, recipients = [] }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <Box className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h5" className={classes.heading}>
              Users
            </Typography>
            <CloseOutlined
              style={{ cursor: "pointer" }}
              onClick={() => setShowModal(false)}
            />
          </Box>

          {recipients.length ? (
            <GroupRecipients recipients={recipients} />
          ) : (
            <Typography variant="subtitle2" className={classes.message}>
              Unable to load the user in this group
            </Typography>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

GroupRecipientsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
};

export default GroupRecipientsModal;
