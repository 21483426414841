import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { useTimer } from 'react-timer-hook';
import { formatTimerNumber } from "../../helpers/GenericHelper";
import Footer from "../../components/Footer/Footer";
import AssetConstants from "../../constants/AssetConstants";
import { resendConfirmationEmail } from "../../store/actions/Authentication";
import Loader from "../../components/Loader/Loader";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";
import { useHistory, useLocation } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";

const EmailVerificationScreen = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const userState = useSelector((state) => state.authentication);
    const [buttonDisabled, setButtonDisabledStatus] = useState(true);
    const [statusUpdateAlert, setStatusUpdateAlert] = useState(false);
    const [statusUpdateErrorAlert, setStatusUpdateErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const getTime = () => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 120);
        return time;
    }

    const stopTimer = () => {
        setButtonDisabledStatus(false);
    }

    const onSuccessResendLink = () => {
        setStatusUpdateAlert(true);
        setButtonDisabledStatus(true);
        restart(getTime());
    }

    const onFailureResendLink = (error) => {
        if (error.statusCode === 17) {
            setErrorMessage(t("alreadyActivatedMessage"));
        } else {
            setErrorMessage(error.message);
        }
        setStatusUpdateErrorAlert(true);
    }

    const resendLink = () => {
        dispatch(resendConfirmationEmail(userState.user.emailAddress, onSuccessResendLink, onFailureResendLink));
    }

    const handleClose = () => {
        setStatusUpdateAlert(false);
    }

    const handleErrorClose = () => {
        setStatusUpdateErrorAlert(false);
    }

    const { seconds, minutes, restart } = useTimer({ expiryTimestamp: getTime(), onExpire: stopTimer });

    return (
        <div className="is-login">
            {userState.loading && <Loader />}
            <SnackbarAlert show={statusUpdateAlert} message={t("confirmationEmailSentSuccess")} duration={3000} onCloseAlert={handleClose} />
            <SnackbarAlert isError={true} show={statusUpdateErrorAlert} message={errorMessage.length > 0 ? errorMessage : t("confirmationEmailSentError")} duration={3000} onCloseAlert={handleErrorClose} />
            <Grid container>

                <Grid item sm={6} className="success-msg">
                    <div className="success-msg-content">
                        <div className="center-plot">
                            <img className="login-logo" src={AssetConstants.logo} />
                            {/* <span className="title-has-icon">
                                <i class="ticon-arrow-right back-arrow" onClick={() => history.goBack()}></i>
                                Go to login
                            </span> */}
                            <h1>{location.alreadyCreatedUser ? t("activateAccountHeading") : t("registrationSuccessfulHeading")}</h1>
                            <p>{location.alreadyCreatedUser ? t("activateAccountMessage") : t("verifyEmailMessage").replace(/{emailAddress}/, userState.user.emailAddress)}</p>
                            <Button color="primary" className="btn-rounded my-10" 
                            variant="contained" disableElevation disabled={buttonDisabled} onClick={resendLink}>
                                {t("resendLink")}
                            </Button>
                            {
                                buttonDisabled &&
                                <div className="activation-timer">
                                    <span>{formatTimerNumber(minutes)}</span>:<span>{formatTimerNumber(seconds)}</span>
                                </div>
                            }

                            <span className="title-has-icon signin-to-your-account">
                                <a onClick={() => history.push({ pathname: RouteConstants.LOGIN })}>
                                    <span>Or</span> Sign in to your account</a>
                            </span>
                        </div>
                    </div>
                    <div className="success-msg-footer">
                        <Footer />
                    </div>
                </Grid>
                <Grid item sm={6} className="screen-half-splash">

                </Grid>
            </Grid>
        </div>
    );
};

export default EmailVerificationScreen;