import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Container, Card, Link, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PersonalInformationSection from "../../components/EditChild/PersonalInformationSection/PersonalInformationSection";
import ContactInformationSection from "../../components/EditChild/ContactInformationSection/ContactInformationSection";
import MedicalConditionSection from "../../components/EditChild/MedicalConditionSection/MedicalConditionSection";
import OtherSection from "../../components/EditChild/OtherSection/OtherSection";
import { getChild, editChild, resetEditChildData, getPresignedURL as getPresignedURLChild , uploadImage, deleteChild, logoutChildFromWatch, regenerateWatchCode } from "../../store/actions/Child";
import { getPresignedURL as getPresignedURLUser } from "../../store/actions/Authentication";
import { differenceYears, equalArrays, equalMedicationArrays, getTimeInMS, getValidTags, sameArray } from "../../helpers/GenericHelper";
import { ADD_CHILD_STATUS, CHILD_DEVICE_STATUS } from "../../constants/GenericConstants";
import { getEditPermValueIndividual } from "../../helpers/PermissionCalculator";
import { PRIV_CONSTS } from "../../constants/StaticData";
import AssetConstants from "../../constants/AssetConstants";
import SnackbarAlert from "../../components/SnackbarAlert/SnackbarAlert";
import DateHelper from "../../helpers/DateHelper";
import momentTz from 'moment-timezone';
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";

const EditChildScreen = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const childState = useSelector((state) => state.child);

    const [dataGot, setDataGot] = useState({});

    const [childId, setChildId] = useState("");
    const [childCode, setChildCode] = useState("");
    const [editSuccess, setEditSuccess] = useState(false);
    const [logoutWatchSuccess, setLogoutWatchSuccess] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [logoutWatchDialogOpen, setLogoutWatchDialogOpen] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [regenerateCodeDialogOpen, setRegenerateCodeDialogOpen] = useState(false);
    const [regenerateError, setRegenerateError] = useState(false);
    const [regenerateErrorMessage, setRegenerateErrorMessage] = useState("");
    const [regenerateSuccess, setRegenerateSuccess] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);


    const [childEditAccess, setChildEditAccess] = useState(false)


    const [personalInfoData, setPersonalInfoData] = useState(
        {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            age: "",
            email: "",
            gender: "",
            childImage: {},
            photoURL: "",
            photo: "",
        }
    );

    const [personalInfoErrors, setPersonalInfoErrors] = useState(
        {
            firstNameError: "",
            lastNameError: "",
            ageError: "",
            emailError: "",
            genderError: "",
            childImageError: "",
        }
    );

    const [contactInfoData, setContactInfoData] = useState(
        {
            homeAddress: "",
            schoolName: "",
            schoolAddress: "",
            contactName1: "",
            contactPhone1: "",
            contactName2: "",
            contactPhone2: "",
            newsletterSubscribed: false
        }
    );

    const [contactInfoErrors, setContactInfoErrors] = useState(
        {
            schoolNameError: "",
            contactName1Error: "",
            contactPhone1Error: "",
            contactName2Error: "",
            contactPhone2Error: "",
        }
    );

    // const [medicalConditionData, setMedicalConditionData] = useState(
    //     {
    //         pastMedicalConditions: "",
    //         specialEquipment: "",
    //         medications: [],
    //         allergies: [],
    //     }
    // );

    // const [medicalConditionErrors, setMedicalConditionErrors] = useState(
    //     {
    //         pastMedicalConditionsError: "",
    //         specialEquipmentError: "",
    //         medicationsError: "",
    //         allergiesError: "",

    //     }
    // );

    // const [otherData, setOtherData] = useState(
    //     {
    //         visualHearingDifficulties: "",
    //         communicationNeeds: "",
    //         therapyInfo: "",
    //         specialNeeds: "",
    //         likesDislikes: "",
    //     }
    // );

    // const [otherErrors, setOtherErrors] = useState(
    //     {
    //         visualHearingDifficultiesError: "",
    //         communicationNeedsError: "",
    //         therapyInfoError: "",
    //         specialNeedsError: "",
    //         likesDislikesError: "",
    //     }
    // );

    const [watchInfo, setWatchInfo] = useState({});

    const calculateAge = (input) => {
        const year = input.substring(0, 4);
        const month = input.substring(5, 7);
        const date = input.substring(8);
        const currentDate = new Date();
        const inputDate = new Date(year, month - 1, date);
        let ageInYears = differenceYears(inputDate, new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
        return ageInYears;
    }
    const getTodayChildTimezone = (tz) => {
        var tzGot = checkTimezoneExist(tz) ? momentTz.tz(new Date(), tz).toString().substring(25, 33) : ""
        if (tzGot.length !== 0) {
            tzGot = "(" + tzGot.substring(0, 6) + ":" + tzGot.substring(6) + ")"
        } else {
            tzGot = "(Invalid Format)"
        }
        return tzGot;
    }
    const checkTimezoneExist = (tz) => {
        let exist = false
        let childTz = tz
        if (childTz && childTz.length !== 0) {
            let arr = momentTz.tz.names().map(i => {
                if (i === childTz) {
                    exist = true;
                }
            })
        }
        return exist
    }
    const onLoadSuccess = (childData) => {

        const { id, childCode, profile, address, childDevice, newsletterSubscribed } = childData;
        const DOB = profile.dateOfBirth.substring(0, 10);
        const ageInYears = calculateAge(DOB);

        setDataGot(childData)
        // let childEditPerm = getEditPermValueIndividual(3, PRIV_CONSTS.CHILDREN_PRIV)
        // setChildEditAccess(childEditPerm)

        setPersonalInfoData({
            ...personalInfoData,
            firstName: profile.firstName,
            lastName: profile.lastName,
            dateOfBirth: profile.dateOfBirth.substring(0, 10),
            age: ageInYears,
            email: profile.emailAddress,
            gender: profile.gender,
            photoURL: profile?.photo?.includes(".") ? profile.photoURL : "https://i.stack.imgur.com/l60Hf.png",
            photo: profile?.photo
        });

        setContactInfoData({
            homeAddress: address.homeAddress,
            schoolName: address.schoolName,
            schoolAddress: address.schoolAddress,
            contactName1: address.contactName1,
            contactPhone1: address.contactPhone1,
            contactName2: address.contactName2,
            contactPhone2: address.contactPhone2,
            newsletterSubscribed: newsletterSubscribed
        });

        // Remove getValidTags login when fixed at back-end
        // setMedicalConditionData({
        //     pastMedicalConditions: medical.pastConditions,
        //     specialEquipment: medical.medicalEquipments,
        //     medications: medical.medications,
        //     allergies: getValidTags(medical.allergies),
        // });

        // setOtherData({
        //     visualHearingDifficulties: info.difficulties,
        //     communicationNeeds: info.communication,
        //     therapyInfo: info.therapy,
        //     specialNeeds: info.specialNeeds,
        //     likesDislikes: info.likeDislike,
        // });

        if (childDevice) {
            setWatchInfo({ ...childDevice, deviceTimezone: profile.deviceTimezone ? profile.deviceTimezone : "--" });
        } else {
            setWatchInfo({});
        }

        setChildId(id);
        setChildCode(childCode);
    }

    const onLoadFailure = (err) => {
        // setErrorAlert(true);
        setErrorMsg(err.data ? err.data.message : t("unknownError"))
        setTimeout(() => {
            history.replace({ pathname: RouteConstants.DASHBOARD });
        }, 2000);
    }

    useEffect(() => {
        const childId = decodeURIComponent(props.match.params.childId);
        if (childId) {
            dispatch(getChild(childId, onLoadSuccess, onLoadFailure));
        }


        if (childId) {
            let ownChild = checkOwnChild(childId)
            if (!ownChild && childState.otherChildrenList) {
                childState.otherChildrenList.map(clientData => {
                    if (clientData.childProfiles) {
                        clientData.childProfiles.map(c => {
                            if (c.childId == childId) {
                                let perm = getEditPermValueIndividual(c.userPermissions, PRIV_CONSTS.CHILDREN_PRIV);
                                setChildEditAccess(perm)
                            }
                        })
                    }
                })
            }
        }




    }, [shouldUpdate]);

    useEffect(() => {
        setShouldUpdate(prev=>!prev)
    }, [childState.childrenList])

    const checkOwnChild = (childId) => {
        let ownChild = false
        if (childId) { //TO CHECK IF OWN CHILD
            if (childState.childrenList) {
                childState.childrenList.map(c => {
                    if (c.childId == childId) {
                        setChildEditAccess(true)
                        ownChild = true
                    }
                })

            }
        }
        return ownChild;
    }

    const goToDashboard = () => {
        resetEditChildData();
        history.goBack();
    }

    const goBackToDashboard = () => {
        resetEditChildData();
        history.replace({ pathname: RouteConstants.DASHBOARD });

    }

    const onEditSuccess = (data) => {
        //mixpanel
        Mixpanel.track(MIX_PANEL_EVENTS.USER_PROFILE_UPDATED, {
            profileId: childId,
            newsletterSubscribed: data.newsletterSubscribed,
            firstName: data.profile.firstName,
            lastName: data.profile.lastName,
            emailAddress: data.profile.emailAddress,
            dateOfBirth: data.profile.dateOfBirth,
            status: data.status
        });

        setEditSuccess(true);
        setTimeout(() => {
            goToDashboard();
        }, 3000);
    };

    const onEditFailure = () => {
        setErrorAlert(true);
    };

    const isFormValid = () => {
        const { firstNameError, lastNameError, ageError, emailError, genderError, childImageError } = personalInfoErrors;
        const { schoolNameError, contactName1Error, contactPhone1Error, contactName2Error, contactPhone2Error } = contactInfoErrors;
        // const { pastMedicalConditionsError, specialEquipmentError, medicationsError, allergiesError } = medicalConditionErrors;
        // const { visualHearingDifficultiesError, communicationNeedsError, therapyInfoError, specialNeedsError, likesDislikesError } = otherErrors;

        if (firstNameError === "" && lastNameError === "" && ageError === "" && emailError === "" &&
            genderError === "" && childImageError === "" && schoolNameError === "" && contactName1Error === "" &&
            contactPhone1Error === "" && contactName2Error === "" &&
            contactPhone2Error === "" 
            // && pastMedicalConditionsError === "" && specialEquipmentError === "" &&
            // visualHearingDifficultiesError === "" &&
            // communicationNeedsError === "" && therapyInfoError === "" && specialNeedsError === "" && likesDislikesError === ""
            ) {
            return true;
        }
        return false;
    }

    const saveChanges = () => {
        setFormSubmitted(true)
        if (isFormValid()) {
            if (Object.keys(personalInfoData.childImage).length !== 0) {
                personalInfoData.childImage.file.name = getTimeInMS(personalInfoData.childImage.file.name);
                dispatch(getPresignedURLChild(personalInfoData.childImage.file.name, onGetUrl, onFailedUrl))
                dispatch(getPresignedURLUser(personalInfoData.childImage.file.name, onGetUrl, onFailedUrl))
            }
            else {
                dispatch(editChild(childId, ADD_CHILD_STATUS.ACTIVE, 4, personalInfoData, contactInfoData, onEditSuccess, onEditFailure));
            }
        }
    }

    const onGetUrl = (res) => {

        dispatch(uploadImage(personalInfoData.childImage.file, res.data.url,
            (res) => {
                console.log("Success Called", res);
                dispatch(editChild(childId, ADD_CHILD_STATUS.ACTIVE, 4, personalInfoData, contactInfoData, onEditSuccess, onEditFailure));
            }
            ,
            (err) => {
                console.log("Error Called", err);
            }
        ))
    }

    const onFailedUrl = (err) => {

    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleClose = () => {
        if (editSuccess) {
            setEditSuccess(false);
        }
        else if (!!logoutWatchSuccess) {
            setLogoutWatchSuccess(null)
        }
        else {
            setDeleteSuccess(false);
        }
    }

    const handleErrorAlertClose = () => {
        setErrorAlert(false);
    }

    const goToPreviousScreen = () => {
        // Current Data
        if (childEditAccess) {
            if (!checkChanged()) {
                history.goBack();
            } else {
                setDialogOpen(true);
            }
        }
        else {
            history.goBack();
        }
    }

    const checkChanged = () => {
        let changed = false;
        const { firstName, lastName, dateOfBirth, email, gender } = personalInfoData;
        const { homeAddress, schoolName, schoolAddress, contactName1, contactPhone1, contactName2, contactPhone2, newsletterSubscribed } = contactInfoData;
        // const { pastMedicalConditions, specialEquipment, medications, allergies } = medicalConditionData;
        // const { visualHearingDifficulties, communicationNeeds, therapyInfo, specialNeeds, likesDislikes } = otherData;

        // Old Data stored in DB
        const { profile, address } = dataGot;


        // let tempM = [...medical.medications]
        if (firstName !== profile.firstName || lastName !== profile.lastName ||
            dateOfBirth !== profile.dateOfBirth.substring(0, 10)
            || email !== profile.emailAddress ||
            gender !== profile.gender ||
            homeAddress !== address.homeAddress ||
            schoolName !== address.schoolName || schoolAddress !== address.schoolAddress ||
            contactName1 !== address.contactName1 || contactPhone1 !== address.contactPhone1 ||
            contactName2 !== address.contactName2 || contactPhone2 !== address.contactPhone2 || newsletterSubscribed !== address.newsletterSubscribed
            // || pastMedicalConditions !== medical.pastConditions || specialEquipment !== medical.medicalEquipments ||
            // !sameArray(medications, medical.medications) ||
            // !sameArray(allergies, medical.allergies.length === 1 && medical.allergies[0] === "" ? [] : medical.allergies) ||
            // visualHearingDifficulties !== info.difficulties || communicationNeeds !== info.communication ||
            // therapyInfo !== info.therapy || specialNeeds !== info.specialNeeds || likesDislikes !== info.likeDislike
        ) {
            resetEditChildData();
            changed = true

        }
        return changed;

    }


    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }
    const handleCloseLogoutDialog = () => {
        setLogoutWatchDialogOpen(false);
    }

    const onDeleteSuccess = () => {
         //mixpanel
         Mixpanel.track(MIX_PANEL_EVENTS.USER_PROFILE_DELETED, {
            profileId: childId
        });

        setDeleteSuccess(true);
        setTimeout(() => {
            history.push({ pathname: RouteConstants.DASHBOARD });
        }, 2000);
    }

    const deleteProfile = () => {
        dispatch(deleteChild(childId, onDeleteSuccess));
        handleCloseDeleteDialog();
    }

    const logoutFromWatch = () => {
        dispatch(logoutChildFromWatch(childId, (res) => {
            if(watchInfo?.deviceType === 'mobile') {
                setLogoutWatchSuccess(t("logoutMobileSuccessMessage"));
            } else {
                setLogoutWatchSuccess(t("logoutWatchSuccessMessage"));
            }
            handleCloseLogoutDialog()
            setTimeout(() => {
                dispatch(getChild(childId, onLoadSuccess, onLoadFailure));
            }, 3000);
        }, (err) => {
            // alert(err.data.message)
            setErrorMsg(err.data.message);
            handleCloseLogoutDialog()

        }))
    }

    const handleCloseRegenerateDialog = () => {
        setRegenerateCodeDialogOpen(false);
    }

    const onSuccessRegenerate = () => {
        const childId = decodeURIComponent(props.match.params.childId);
        if (childId) {
            dispatch(getChild(childId, (data) => { onLoadSuccess(data); setRegenerateSuccess(true); }, onLoadFailure));
        } else {
            setRegenerateSuccess(true);
        }
    }

    const onFailureRegenerate = (errorMessage) => {
        setRegenerateError(true);
        setRegenerateErrorMessage(errorMessage);
    }

    const regenerateChildCode = () => {
        handleCloseRegenerateDialog();
        dispatch(regenerateWatchCode(childId, onSuccessRegenerate, onFailureRegenerate));
    }

    const renderDeviceStatus = ({deviceStatus, deviceType}) => {
        if (deviceStatus === CHILD_DEVICE_STATUS.SYNCHED) {
            return t('watchSynced')
        }

        if (deviceType === 'mobile') {
            return t('mobileSynced')
        }

        return t('notSynced')

    }

    return (
        <div>
            {childState.loading && <Loader />}
            <SnackbarAlert show={editSuccess} message={t("editSuccessMessage")} duration={3000} onCloseAlert={handleClose} />
            <SnackbarAlert show={Boolean(logoutWatchSuccess)} message={logoutWatchSuccess} duration={3000} onCloseAlert={handleClose} />
            {/* <SnackbarAlert show={logoutWatchSuccess} message={t("logoutWatchSuccessMessage")} duration={3000} onCloseAlert={handleClose} /> */}
            <SnackbarAlert show={deleteSuccess} message={t("childDeleteSuccessMessage")} duration={3000} onCloseAlert={handleClose} />
            <SnackbarAlert isError={true} show={errorMsg !== ""} message={errorMsg} duration={5000} onCloseAlert={() => setErrorMsg("")} />
            <SnackbarAlert show={regenerateSuccess} message={t("regenerateCodeSuccessMessage")} duration={3000} onCloseAlert={() => setRegenerateSuccess(false)} />
            <SnackbarAlert isError={true} show={regenerateError} message={regenerateErrorMessage} duration={4000} onCloseAlert={() => setRegenerateError(false)} />

            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("updatedInformationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={goToDashboard} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>

                </DialogActions>
            </Dialog>
            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("confirmDeleteChildProfile")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("confirmDeleteChildProfileMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeleteDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={deleteProfile} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="xs" open={logoutWatchDialogOpen} onClose={handleCloseLogoutDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseLogoutDialog} />
                <DialogTitle id="alert-dialog-title">{t("confirmLogoutChildProfile")}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">{t("confirmDeleteChildProfileMessage")}</DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseLogoutDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={logoutFromWatch} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="xs" open={regenerateCodeDialogOpen} onClose={handleCloseRegenerateDialog} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleCloseRegenerateDialog} />
                <DialogTitle id="alert-dialog-title">{t("watchCodeRegenerateMessage")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseRegenerateDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={regenerateChildCode} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>
            <Grid container className="app">
                <Grid xs={12} item className="app-header">
                    <Header />
                </Grid>
                <Grid item xs={12} className="grid-body">
                    <Container maxWidth="false">
                        <div className="view-edit-child">
                            {
                                <Container maxWidth="lg">
                                    <h1 className="title-has-icon mb-15">
                                        <i class="ticon-arrow-right back-arrow" onClick={goToPreviousScreen}></i>
                                        {t("editChildProfile")}</h1>


                                    <Grid container spacing={3} className="mb-15">
                                        <Grid item xs={9}>
                                            <Card className="generic-card">
                                                <div className="d-flex ml-55">
                                                    <div class="wi-left" style={{ position: 'relative' }}>
                                                        <figure>
                                                            <img src={watchInfo.deviceType === 'mobile' ? AssetConstants.mobile : AssetConstants.thrivewatch} alt=" " style={{ width: '98px', height: '200px' }}/>
                                                        </figure>

                                                       {watchInfo.deviceType === 'mobile' ? 
                                                            <div style={{ position: 'absolute', top: "50%", left: "53%", transform: "translate(-50%, -50%)" }}>
                                                                <img src={AssetConstants.logo} alt="logo" style={{ width: '50px', height: '50px' }}  />
                                                             </div> 
                                                        : null}
                                                    </div>
                                                    <div className="wi-right">
                                                        <h4>{t("connectedDevice")}</h4>
                                                        {
                                                            watchInfo && Object.keys(watchInfo).length > 0 ?
                                                                <>
                                                                    {/* <div className="watch-status">{t("status")}: <span>{watchInfo.deviceStatus === CHILD_DEVICE_STATUS.SYNCHED ? t("watchSynced") : t("notSynced")}</span></div> */}
                                                                    <div className="watch-status">{t("status")}: <span>{renderDeviceStatus(watchInfo)}</span></div>
                                                                    {watchInfo.lastSynced && <div className="date-synced">{t("dateSynched")}: <span>{DateHelper.formatDate(new Date(watchInfo.lastSynced), "dd MMM, yyyy")}</span></div>}
                                                                    <div className="device-info">{t("deviceInformation")}: <span>{`${watchInfo.deviceModel} (${watchInfo.deviceOS} ${watchInfo.deviceVersion})`}</span></div>
                                                                    <div className="device-info">{t("deviceTimezone")}: <span>{`${watchInfo.deviceTimezone}`} {`${getTodayChildTimezone(watchInfo.deviceTimezone)}`}</span></div>
                                                                    <Link className="logout-watch" onClick={() => setLogoutWatchDialogOpen(true)}>{watchInfo.deviceType === 'mobile' ? t("logoutMobile"): t("logoutWatch")}</Link>
                                                                </> :
                                                                !childState.loading &&
                                                                <p>No device connected.</p>
                                                        }
                                                    </div>
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={3} className="pos-relative">
                                            <Card className="generic-card profile-code-container">
                                                <div className="profilecode">
                                                    <h4>{t("profileCode")}</h4>
                                                    <div className="child-code">
                                                        <span className="c--childcode">{childCode}</span>
                                                        {childEditAccess && <Link onClick={() => { setRegenerateCodeDialogOpen(true); }}>{t("regenerateCode")}</Link>}
                                                    </div>
                                                </div>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Card className="generic-card">
                                        <PersonalInformationSection
                                            disabled={!childEditAccess}
                                            data={personalInfoData}
                                            setData={setPersonalInfoData}
                                            errors={personalInfoErrors}
                                            setErrors={setPersonalInfoErrors} />
                                        <hr className="section-hr" />
                                        <ContactInformationSection
                                            formSubmitted={formSubmitted}
                                            disabled={!childEditAccess}
                                            data={contactInfoData}
                                            personalInfoData={personalInfoData}
                                            personalInfoErrors={personalInfoErrors}
                                            setData={setContactInfoData}
                                            errors={contactInfoErrors}
                                            setErrors={setContactInfoErrors} />
                                        {/* <hr className="section-hr" />
                                        <MedicalConditionSection
                                            disabled={!childEditAccess}
                                            data={medicalConditionData}
                                            setData={setMedicalConditionData}
                                            errors={medicalConditionErrors}
                                            setErrors={setMedicalConditionErrors} />
                                        <hr className="section-hr" />
                                        <OtherSection
                                            disabled={!childEditAccess}
                                            data={otherData}
                                            setData={setOtherData}
                                            errors={otherErrors}
                                            setErrors={setOtherErrors} /> */}

                                    </Card>
                                    <div className="add-child-form-footer">

                                        <div className="btn-delete-profile">
                                            {childEditAccess ? <Link onClick={() => { setDeleteDialogOpen(true) }}>{t("deleteThisProfile")}</Link> : null}
                                        </div>

                                        <Button variant="contained" color="secondary" disableElevation onClick={goToPreviousScreen}>{t("cancel")}</Button>
                                        {childEditAccess && <Button color="primary" variant="contained" disableElevation onClick={saveChanges}
                                        // disabled={!isFormValid()}
                                        >{t("saveChanges")}</Button>}

                                    </div>
                                </Container>
                            }
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl">
                        <Grid container spacing={6} className="my-0">
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
};

export default EditChildScreen;