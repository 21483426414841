export const APP_CONSTANTS = {
    MIN_PASSWORD_LENGTH: 8,
    MAX_PASSWORD_LENGTH: 30,

    MAX_NAMES_LENGTH: 30,
    MAX_SCHOOL_NAME_LENGTH: 50,

    MAX_NAMES_LENGTH300: 300,
    MAX_NAMES_LENGTH100: 100,
    MAX_NAMES_LENGTH50: 50,
    MAX_NAMES_LENGTH30: 30,
    MAX_NAMES_LENGTH18: 18,
    MAX_NAMES_LENGTH15: 15,

    MAX_LENGTH_CONTACT: 45,
    MAX_TAGS_LENGTH: 10,


    MIN_CHILD_AGE: 2,
    MAX_CHILD_AGE: 25,
    PHOTO_SIZE_LIMIT: 2,

    INITIAL_IMAGES: 48,

    MY_UPLOADS_KEY: 'my-uploads',

    EMPTY_STRING: "",

    // STRIPE_PUBLISHABLE_KEY: "pk_test_51J2wXXFKFc9MSMhnf2Dw2cRCYsZwo2fUzkCmgvIOTE4UcPDmQ2MzvUGukEz1tBaTqb6iGlys6NOCTfzgbAWVP0Rx00ZdLiClE2",
   // PROD
    STRIPE_PUBLISHABLE_KEY: "pk_live_51HJ4D4KUFvmgkRIyh4DweVBE0TrhSqCP8ox5421KGkOnIHsYTianueNSlWuDc7sMkp4cNiImE0aPO5CyQk3t4hbK00lwUfMSdY",
    SUPPORT_EMAIL_ADDRESS: "support@thrivingonthespectrum.org",
};

export const REGEX_CONSTANTS = {
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: "accessToken",
    USER: "user",
    IS_EXTERNAL_USER: "isExternalUser",
    CHILDREN_LIST: "childrenList",
    OTHER_CHILDREN_LIST: "otherChildrenList",
    SELECTED_CHILD: "selectedChild",
    IS_PARENT: "isParent",
    IS_LOGGED_IN: "isLoggedIn",
    SHOW_EXPIRED_TOKEN_ERROR: "showExpiredTokenError",
    IS_SINGLE_USER: "isSingleUser",
};

export const ADD_CHILD_STATUS = {
    IN_ACTIVE: 0,
    DRAFT: 1,
    ACTIVE: 2,
};

export const EXTERNAL_USER_STATUS = {
    IN_ACTIVE: 1,
    ACTIVE: 2,
};

export const ROLE_TYPES = {
    PARENT: "PARENT",
    THERAPIST: "THERAPIST",
    TEACHER: "TEACHER",
    OTHER: "OTHER",
    CO_PARENT_GUARDIAN: "CO_PARENT_GUARDIAN",
    ACADEMIC_ADVISOR: "ACADEMIC_ADVISOR",
    PARTNER_SPOUSE: "PARTNER_SPOUSE",
};

export const USER_TYPE = {
    SINGLE: "single",
    MULTI: "multi", // also called team, group
    KEY: "userType" // field name in user object
};

export const IMAGE_TYPES = {
    ACTIVITY_IMAGE: "ACTIVITY_IMAGE",
    ACTIVITY_SUBTASK_IMAGE: "ACTIVITY_SUBTASK_IMAGE",
    TOOL_IMAGE: "TOOL_IMAGE",
    TOOL_SUBTASK_IMAGE: "TOOL_SUBTASK_IMAGE",
};

export const ActivityColor = {
    RED: 1,
    YELLOW: 2,
    BLUE: 3,
    GREEN: 4,
    BLACK: 5,
    GREY: 6,
    ORANGE: 7,
    SEA_GREEN: 8,
    NAVY_BLUE: 9,
    LIGHT_GREEN: 10,
    SKY_BLUE: 11,

    DARK_BLUE: 12,
    GREEN_BLUE: 13,
};

export const GENERIC_STATUS = {
    IN_ACTIVE: 1,
    ACTIVE: 2,
    DISABLE: 3,
};

export const SCREEN_TYPE = {
    ADD: "ADD",
    EDIT: "EDIT",
};

export const GENDER = {
    MALE: "MALE",
    FEMALE: "FEMALE",
    NO_ANSWER: "NO_ANSWER",
};

export const SCHEDULE_TYPE = {
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    ONE_TIME: "ONE_TIME",
    SELECTED_DAYS: "SELECTED_DAYS",
    MONTHLY: "MONTHLY",
    ANNUALLY: "ANNUALLY",
    WEEKDAY: "WEEK_DAYS",
    CUSTOM: "CUSTOM"
};

export const REMINDER_TIME = {
    MINUTES_5: "MINUTES_5",
    MINUTES_10: "MINUTES_10",
    MINUTES_15: "MINUTES_15"
}

export const CALENDER = {
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
    MONTHLY_BY_DAY: "MONTHLY_BY_DAY",
    MONTHLY_BY_DATE: "MONTHLY_BY_DATE"
}

export const CALENDAR_VIEW = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    AGENDA: "agenda"
};

export const ACTIVITY_TOOL_TYPE = {
    ACTIVE: "ACTIVE",
    ARCHIVED: "ARCHIVED",
};

export const ACTIVITY_TYPE = {
    ACTIVE: 0,
    ARCHIVED: 1,
};

export const TOOL_TYPE = {
    ACTIVE: 0,
    ARCHIVED: 1,
};

export const TOOL_ACTIVATED = {
    YES: "yes",
    NO: "no",
};

export const CHILD_DEVICE_STATUS = {
    SYNCHED: 1,
    UN_SYNCHED: 0,
};

export const NOTIFICATION_TYPE = {
    CHILD_SCHEDULED_ACTIVITY: "CHILD_SCHEDULED_ACTIVITY",
    ACTIVITY_CREATION: "ACTIVITY_CREATION",
    SCHEDULE_UPDATE: "SCHEDULE_UPDATE",
    PARENT_PORTAL_SIGNUP: "PARENT_PORTAL_SIGNUP",
    WATCH_ADD: "WATCH_ADD",
    MOBILE_ADD: "MOBILE_ADD",
    EXTERNAL_USER_STATUS: "EXTERNAL_USER_STATUS",
    EXTERNAL_USER_INVITE: "EXTERNAL_USER_INVITE",
    PAYMENT_FAIL_PARENT: "PAYMENT_FAIL_PARENT",
    PAYMENT_REMINDER: "PAYMENT_REMINDER",
    PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
    GENERAL_NOTIF_TIMEZONE: "GENERAL_NOTIF_TIMEZONE",
    GENERAL_NOTIF_STATUS_CHANGE:"GENERAL_NOTIF_STATUS_CHANGE",

    CHAT_NEW_MESSAGE: "CHAT_NEW_MESSAGE",
    GROUP_NEW_MESSAGE: "GROUP_NEW_MESSAGE",
};

export const PAYMENT_METHOD = {
    STRIPE: "STRIPE",
};

export const PAYMENT_PLAN = {
    MONTHLY: "MONTHLY",
    ANNUAL: "ANNUAL",
    DAILY: "DAILY",
};

export const SUBSCRIPTION_STATUS = {
    UN_SUBSCRIBED: 0,
    SUBSCRIBED: 1,
    CANCELLED: 2,
    EXPIRED: 3,
};

export const FEATURE_FLAGS = {
  CHAT: "chat",
  ADD_RECIPIENT_ICON: "add_group_recipient_modal_icon",
  ACTIVITY_TEMPLATE: "show_activity_template",
  PROFILE_PAYMENT: "show_profile_payment",
  ACADEMIC_ADVISOR: "show_academic_advisor",
};


export const TEST_CHILD_OBJECT = {
    "userRole":"PARENT",
    "isActive":true,
    "userPermissions":"4095",
    "accountId":"64fb9a4d-f09d-4f54-858b-43fb7557bf5a","childId":"a15cb69c-93bd-4d7d-a85b-025a30e9fed9",
    "childFirstName":"Test1",
    "childLastName":"User1",
    "dateOfBirth":"2009-12-31T19:00:00.000Z","childPhoto":"fairy-meadows-trek-2_1649332413000.jpg",
    "status":2,
    "deviceTimezone":null,
    "userFirstName":"Test",
    "userLastName":"User1",
    "parentId":"64fb9a4d-f09d-4f54-858b-43fb7557bf5a",
    "deviceStatus":null,
    "parentFirstName":"Test",
    "parentLastName":"User1",
    "childCode":42671,
    "newsletterSubscribed":true
};