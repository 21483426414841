import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, InputLabel, FormHelperText, Button, Select, MenuItem, makeStyles } from "@material-ui/core";
import { validateMandatoryValue } from "../../helpers/Validation";
import 'react-phone-number-input/style.css';
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../Loader/Loader";
import OnboardingCarousel from './OnboardingCarousel';



const useStyles = makeStyles((theme) => ({
    select: {
        "& ul": {
            height: 76,
            paddingTop: 0,
        },
    }
}));

const SelectUserType = () => {
    
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    
    const userState = useSelector((state) => state.authentication);
    
    const [userType, setUserType] = useState('');
    const [userTypeError, setUserTypeError] = useState('');

    const validateUserType = (ut) => {
        if (!validateMandatoryValue(ut)) {
            setUserTypeError(t("userTypeRequired"));
            return false;
        }
        else {
            setUserTypeError("");
            return true;
        }
    }

    const handleUserTypeChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setUserType(input);
        validateUserType(input);
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(validateUserType(userType)) {
            moveToSignUpProfileScreen();
        }
    }

    const moveToSignUpProfileScreen = () => {
        history.push({ pathname: RouteConstants.SIGN_UP, userType });
    }

    return (
        <div className="is-login page-signup">
            {userState.loading && <Loader />}
            
            <Grid container>
                <Grid item sm={6} className="login-form">
                    <div className="signup-form-content">
                        <img className="login-logo" src={AssetConstants.logo} />
                        <form>
                            <h1>{t("createProfile")}</h1>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <div className="form-field">
                                        
                                        <InputLabel 
                                            id="userTypeLabel" 
                                            required> {t("selectUserTypeLabel")}
                                        </InputLabel>
                                        
                                        <Select 
                                            labelId="userType" 
                                            id="userTypeSelect" 
                                            name="userTypeSelect" required 
                                            value={userType} 
                                            onChange={handleUserTypeChange}
                                            MenuProps={{ classes: { paper: classes.select } }}>
                                                <MenuItem value={'single'}>{t("userTypeSingleLabel")}</MenuItem>
                                                <MenuItem value={'multi'}>{t("userTypeMultiLabel")}</MenuItem>
                                        </Select>
                                        <FormHelperText error={showInputError(userTypeError)}>{userTypeError}</FormHelperText>
                                        
                                    </div>
                                    <div>
                                        <span className="title-has-icon signin-to-your-account my-10">
                                            {userType == 'single'? t("userTypeSingleDescription") : userType == 'multi' ? t("userTypeMultiDescription") : ''}
                                        </span>
                                    </div>
                                    <div className="form-field login-button">
                                        <Button color="primary"
                                            type="submit" variant="contained" disableElevation
                                            onClick={(e) =>
                                                onSubmit(e)
                                            }>{t("continue")}</Button>
                                    </div>
                                </Grid>
                                
                            </Grid>
                            <span className="title-has-icon signin-to-your-account">
                                <a onClick={() => history.push({ pathname: RouteConstants.LOGIN })}>
                                    <span>Or</span> Sign in to your account</a>
                            </span>
                        </form>
                    </div>
                </Grid>
                <OnboardingCarousel />
                {/* <Grid item sm={6} className="screen-half-splash">
                </Grid> */}
            </Grid>
        </div>
    );
};

export default SelectUserType;