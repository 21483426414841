import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { TextField, Link, Input, InputAdornment, InputLabel, Select, MenuItem, Button, Grid, FormHelperText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, Fab } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { getScheduleActivities, scheduleActivity, getScheduleList, updateScheduleActivity, deleteScheduleActivity, searchScheduledActivities } from "../../store/actions/Schedule";
import { getActivity } from "../../store/actions/Activities";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/sass/styles.scss";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import { getColorForSchedule } from "../../helpers/GenericHelper";
import { SCHEDULE_TYPE, CALENDAR_VIEW } from "../../constants/GenericConstants";
import AssetConstants from "../../constants/AssetConstants";
import DateHelper from "../../helpers/DateHelper";
import Loader from '../../components/Loader/Loader';
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import DayHeaderBar from "./DayHeaderBar";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getColorList } from "../../store/actions/Activities";

import moment from "moment";
import momentTz from 'moment-timezone';

import DatePicker from "react-datepicker";

import ActivityPreviewPanel from "./ActivityPreviewPanel";
import ActivityListPanel from "./ActivityListPanel";
import ScheduledActivityItem from "./ScheduledActivityItem";

import "react-datepicker/dist/react-datepicker.css"

const localizer = momentLocalizer(momentTz);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const timeZone = momentTz.tz.guess()
// const timeZone= "US/Alaska"
// momentTz.tz.setDefault('US/Alaska');
// const timeZone= "Pacific/Pago_Pago"

const ScheduleActivity = (props) => {

    const { t } = useTranslation();
    const childState = useSelector((state) => state.child);
    const scheduleState = useSelector((state) => state.schedule);
    const userState = useSelector((state) => state.authentication);
    const activitiesState = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const location = useLocation();

    const { editAccess } = props
    const editAcc = useRef(editAccess)

    // const [events, setEvents] = useState([[], [], [], [], [], [], []]);
    const [calendarEvents, setCalendarEvents] = useState([]);  

    const [searchTextActivities, setSearchTextActivities] = useState("");
    
    const [shouldUpdate, setShouldUpdate] = useState(false);

    const [previewMode, setPreviewMode] = useState(false);
    const [previewActivity, setPreviewActivity] = useState({});
    const [previewScheduledActivity, setPreviewScheduledActivity] = useState({});
    const [previewActivityDate, setPreviewActivityDate] = useState("");
    
    const [activityScheduledSuccess, setActivityScheduledSuccess] = useState(false);
    const [activityUpdateSuccess, setActivityUpdateSuccess] = useState(false);
    const [activityDeleteSuccess, setActivityDeleteSuccess] = useState(false);
    const [activityScheduledError, setActivityScheduledError] = useState(false);
    
    const [scheduleActivityErrorMessage, setScheduleActivityErrorMessage] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activityForDelete, setActivityForDelete] = useState({});
    const [displayTZ, setDisplayTZ] = useState("");
    const [hoverTZ, setHoverTZ] = useState(false);
    
    const [open, setOpen] = useState(false);
    const [selectedView, setSelectedView] = useState(CALENDAR_VIEW.DAY);
    
    // date settings
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(0);
    
    // show / hide activities panel on the right
    const [showActivitiesPanel, setShowActivitiesPanel] = useState(false);
    
    const [draggedEvent, setDraggedEvent] = useState(null);

    const [searchMode, setSearchMode] = useState(false);
    const [searchModeExitDate, setSearchModeExitDate] = useState(new Date());
    const [searchModeExitView, setSearchModeExitView] = useState(CALENDAR_VIEW.DAY);


    const viewDropdown = [ 
        {key: CALENDAR_VIEW.DAY, value: CALENDAR_VIEW.DAY, name: "Daily"}, 
        {key: CALENDAR_VIEW.WEEK, value: CALENDAR_VIEW.WEEK, name: "Weekly"},
        {key: CALENDAR_VIEW.MONTH, value: CALENDAR_VIEW.MONTH, name: "Monthly"},
        {key: CALENDAR_VIEW.AGENDA, value: CALENDAR_VIEW.AGENDA, name: "Schedule"} ];

    // date formats
    const serverDateFormat = "YYYY-MM-DD";
    const dateHelperServerDateFormat = "yyyy-MM-dd";

    const ScheduleAgendaTime = ({event}) => {
        return <span></span>
    }

    useEffect(() => {   
        
        if(!searchMode) {
            setCalendarEvents([]);
            fetchScheduledActivities(populateScheduleData);
        }     

    }, [startDate, selectedView, shouldUpdate, searchMode]);

    useEffect(() => {
        setShouldUpdate(prev => !prev)
    }, [childState.selectedChild.deviceStatus]);

    useEffect(() => {
        editAcc.current = editAccess
    }, [editAccess]);

    useEffect(() => {
        if(searchTextActivities !== "") {
            setCalendarEvents([]);
            searchScheduledActivitiesList(searchTextActivities, searchPopulateData);
            setSearchTextActivities("");
        }
    }, [searchTextActivities]);

    useEffect(() => {
        dispatch(getColorList());
    }, [dispatch]);
    
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    /**
     * Populates the events array used to display the scheduled activities on calendar
     */
    const populateScheduleData = useCallback((data) => {
        // Array of Arrays where each index represents a seperate day
        const daysEventArray = [];
        
        for (let i = 0; i < data.length; i++) {
            const current = data[i];
            const date = moment(current.date + " 00:00:00");
            const dayN = +date.day();
            const daysArray = getDaysArray();
            const index = daysArray.findIndex((day) => {
                return day.value === dayN;
            });
            
            const dayFound = location.additionalData ? true : false;
            let notificationSchId;
            if (dayFound) {
                notificationSchId = JSON.parse(location.additionalData).scheduleId;
            }

            const scheduleArray = current.schedule.map((scheduleItem) => {
                if (dayFound) {
                    if (scheduleItem.id === notificationSchId) {
                        selectDisplayDay(index);
                    }
                }
                const obj = {
                    id: scheduleItem.id,
                    activity: scheduleItem,
                    start: DateHelper.getDate(current.date, scheduleItem.startTime),
                    end: DateHelper.getDate(current.date, scheduleItem.endTime),
                    view: selectedView
                };
                return obj;
            });
            
            daysEventArray.push(scheduleArray);
        }

        // Flattening the days event array
        let calendarEventsList = [];
        daysEventArray.map(dayEventArray => {
            calendarEventsList = [...calendarEventsList, ...dayEventArray];
        });
        
        setCalendarEvents(calendarEventsList);
    })

    const selectDisplayDay = (index) => {
        setSelectedDay(index);
    }

    const getDaysArray = () => {
        const daysArray = [
            { label: t("sunday"), value: 0 },
            { label: t("monday"), value: 1 },
            { label: t("tuesday"), value: 2 },
            { label: t("wednesday"), value: 3 },
            { label: t("thursday"), value: 4 },
            { label: t("friday"), value: 5 },
            { label: t("saturday"), value: 6 }
        ];
        return daysArray;
    }

    const getTransformedDaysArray = () => {
        const timezone = getTodayChildTimezone().toString().substring(25, 33);
        let isChildTz = childState.selectedChild.deviceStatus && checkTimezoneExist(childState.selectedChild.deviceTimezone) ? true : checkTimezoneExist(userState.user.timezone) ? false : false
        setHoverTZ(isChildTz)
        setDisplayTZ(timezone.substring(0, 6) + ":" + timezone.substring(6));
        const dArray = getDaysArray().map((dayObj) => {
            return {
                ...dayObj,
                label: dayObj.label.toUpperCase().substring(0, 3),
                selected: false,
            };
        });
        return dArray;
    }

    // ---------------- Timezone Helper functions --------------------

    const checkTimezoneExist = (tz) => {
        let exist = false
        let childTz = tz
        if (childTz && childTz.length !== 0) {
            let arr = momentTz.tz.names().map(i => {
                if (i === childTz) {
                    exist = true;
                }
            })
        }
        return exist
    }

    const getChildTimeZone = () => {
        // child time zone
        return childState.selectedChild.deviceTimezone 
        && childState.selectedChild.deviceStatus 
        && checkTimezoneExist(childState.selectedChild.deviceTimezone) ? 
        childState.selectedChild.deviceTimezone : 
        // user time zone
        userState.user.timezone 
        && checkTimezoneExist(userState.user.timezone) ? 
        userState.user.timezone 
        // guess time zone
        : timeZone;
    }

    const getTodayChildTimezoneString = () => {
        return momentTz.tz(new Date(), getChildTimeZone()).format(serverDateFormat)
    }

    const getTodayChildTimezone = () => {
        return momentTz.tz(new Date(), getChildTimeZone());
    }

    const getDateWithChildTZ = (date) => {
        let momentDate = momentTz.tz(date, getChildTimeZone());
        momentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return momentDate;
    } 

    const getTodayChildTimezoneWithoutGMT = () => {
        let dateGot = momentTz.tz(new Date(), getChildTimeZone());
        dateGot.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        let dateGotString = dateGot.format("YYYY-MM-DD hh:mm").replace(/-/g, "/")
        let newDateGot = moment(new Date(`${dateGotString}`))
        return newDateGot;
    }

    

    
    
    // ----------------Event Drag and Drop -----------------------

    const onEventResize = (data) => {
        const { start, end, event } = data;
        const date1 = moment(start).add(1, 'm').toDate();
        const endTime = date1 > end ? date1 : end;

        
        const payload = {
            // ...event.activity,
            "id": event.activity.id,
            "activityColor": event.activity.activityColor,
            // "startDate": event.activity.startDate,
            // "startDate": data.start.toISOString().split('T')[0],
            "startDate": moment(start).format(serverDateFormat),
            "startTime": DateHelper.formatDate(start, "HH:mm:ss"),
            "endTime": DateHelper.formatDate(endTime, "HH:mm:ss"),
            "scheduleType": event.activity.scheduleType,
        };
        if (event.activity.scheduleType === SCHEDULE_TYPE.CUSTOM) {
            payload = {
                ...payload,
                'recurrence': event.activity.recurrence,
            };
            if (event.activity.reminderBefore) {
                 payload = {
                    ...payload,
                    'reminderBefore': event.activity.reminderBefore,
                };
            }
        }
        if (event.activity.scheduleType === SCHEDULE_TYPE.SELECTED_DAYS) {
            payload.scheduleDay = event.activity.scheduleDay;
        }

        // const updatedEvents = events[selectedDay].map(existingEvent => {
        const updatedEvents = calendarEvents.map(existingEvent => {
            return existingEvent.id === event.id
                ? { ...existingEvent, start, end }
                : existingEvent;
        });
        
        // const mainArray = [...events];
        // mainArray[selectedDay] = updatedEvents;
        // setEvents(mainArray);
        
        setCalendarEvents(updatedEvents);
        dispatch(updateScheduleActivity(payload, onDirectUpdateSuccess, onUpdateFailure));
    };

    const onEventDrop = (data) => {
        const { start, end, event } = data;
        const date1 = moment(start).add(1, 'm').toDate();
        const endTime = date1 > end ? date1 : end;
        // if(event.activity.)
        const payload = {
            "id": event.activity.id,
            "activityColor": event.activity.activityColor,
            // "startDate": event.activity.startDate,
            // "startDate": data.start.toISOString().split('T')[0],
            "startDate": moment(start).format(serverDateFormat),
            "startTime": DateHelper.formatDate(start, "HH:mm:ss"),
            "endTime": DateHelper.formatDate(endTime, "HH:mm:ss"),
            "scheduleType": event.activity.scheduleType,
        };

        if (event.activity.scheduleType === SCHEDULE_TYPE.CUSTOM) {
            payload = {
                ...payload,
                'recurrence': event.activity.recurrence,
            };
            if (event.activity.reminderBefore) {
                 payload = {
                    ...payload,
                    'reminderBefore': event.activity.reminderBefore,
                };
            }
        }
        // const updatedEvents = events[selectedDay].map(existingEvent => {
        const updatedEvents = calendarEvents.map(existingEvent => {
            return existingEvent.id === event.id
                ? { ...existingEvent, start, end }
                : existingEvent;
        });
        // const mainArray = [...events];
        // mainArray[selectedDay] = updatedEvents;

        //setEvents(mainArray);
        setCalendarEvents(updatedEvents);
        dispatch(updateScheduleActivity(payload, onDirectUpdateSuccess, onUpdateFailure));
    }

    const onDropFromOutside = ({ start, end }) => {
        // alert(getColorForSchedule(draggedEvent.color))
        const payload = {
            childId: childState.selectedChild.childId,
            schedule: {
                ...draggedEvent,
                activityId: draggedEvent.id,
                activityColor: getColorForSchedule(draggedEvent.color),
                startDate: DateHelper.formatDate(start, dateHelperServerDateFormat),
                startTime: DateHelper.formatDate(start, "HH:mm:ss"),
                endTime: DateHelper.formatDate(moment(start).add(1, 'm').toDate(), "HH:mm:ss"),
                scheduleType: SCHEDULE_TYPE.ONE_TIME,
            }
        };
        const event = {
            activity: draggedEvent,
            start,
            end,
        };
        setDraggedEvent(null);
        newEvent(payload, event);
    }

    const dragFromOutsideItem = () => {
        return draggedEvent;
    }

    const customOnDragOver = (event) => {
        event.preventDefault();
    }

    const handleDraggedActivtiy = (event) => {
        console.log("ACTV:: " + event);
        setDraggedEvent(event);
    }


    // ----------- Scheduled Activity Component -----------------
    
    const removeActivity = (event) => {
        setDeleteDialogOpen(true);
        setActivityForDelete(event.activity);
    }

    const previewActivityLoaded = (activityData) => {
        setPreviewActivity(activityData);
        setShowActivitiesPanel(!showActivitiesPanel);
    }

    const displayPreviewMode = (event) => {
        setPreviewMode(true);
        setPreviewScheduledActivity(event.activity);
        // calendar event date based on which it displays the scheduled activity on calendar.
        let eventDate = momentTz(event.start).format(serverDateFormat);
        setPreviewActivityDate(eventDate);
        dispatch(getActivity(event.activity.activityId, previewActivityLoaded));
    }

    const exitPreviewMode = () => {
        setPreviewMode(false);
        setPreviewActivity({});
        setPreviewScheduledActivity({});
        setPreviewActivityDate("");
    }

    const ScheduledActivity = ({ event }) => {
       
        return <ScheduledActivityItem 
            event={event}
            removeActivity={removeActivity}
            displayPreviewMode={displayPreviewMode}
            editAcc={editAcc}
        />
    }

    const HeaderViewDay = (props) => {
        const { date, label } = props;
        const dateStr = moment(date).format("DD");
        const dayStr = moment(date).format("dddd");
        
        return <div>
            <span>{dateStr}</span>
            <span>{dayStr}</span>
        </div>;
    }

    const HeaderViewMonth = (props) => {
        const { date, label } = props;
        const dateStr = moment(date).format("DD");
        const dayStr = moment(date).format("ddd");
        
        return <div>
            <span style={{ color: '#02c8a7' }}>
                {dayStr}
            </span>
        </div>;
    }

    const ScheduleViewDateLabel = ({day, label}) => {
        let isToday = moment().isSame(day, "day");
        let labelColor = isToday? "#02c8a7": "auto";
        let labelDateColor = isToday? "#ffffff": "auto";

        return <div>
            
            <span 
                style={{
                    color: labelDateColor,
                    backgroundColor: labelColor,
                    borderRadius: "50%",
                    fontSize: "16px",
                    fontWeight: "600"
                }}>
                    {moment(day).format("DD")}
            </span>
            <span
                style={{
                    marginLeft: "10px",
                    color: labelColor,
                    fontSize: "14px",
                    fontWeight: "400"
                }}>
                    {" " + moment(day).format("MMM, ddd")}
            </span>
        </div>;
    }

    // --------------- Schedule Activity Event Handlers -----------------
    
    const repopulateData = (data) => {
        setActivityScheduledSuccess(true);
        populateScheduleData(data);
    }

    const onScheduleSuccess = (response) => {
        // let todaysDate = moment(new Date()).format("YYYY-MM-DD")
        let todaysDate = getTodayChildTimezoneString()

        fetchScheduledActivities(repopulateData);
        // dispatch(getScheduleList(childState.selectedChild.childId, todaysDate, getScheduleType, repopulateData));
    }

    const onScheduleFailure = (errorMessage) => {
        setActivityScheduledError(true);
        setScheduleActivityErrorMessage(errorMessage);
    }

    const newEvent = (payload, event) => {
        // if (events[selectedDay].length === 0) {
        //     event.id = 1;
        // } else {
        //     event.id = events[selectedDay][events[selectedDay].length - 1].id + 1;
        // }
        // const updatedArray = [...events[selectedDay], event];
        // const mainArray = [...events];
        // mainArray[selectedDay] = updatedArray;
        // setEvents(mainArray);
        dispatch(scheduleActivity(payload, onScheduleSuccess, onScheduleFailure));
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }

    const deletePopulateData = (data) => {
        setActivityDeleteSuccess(true);
        exitPreviewMode();
        populateScheduleData(data);
    }

    const onDeleteSuccess = () => {
        fetchScheduledActivities(deletePopulateData);
        
        // let todaysDate = getTodayChildTimezoneString()
        // dispatch(getScheduleList(childState.selectedChild.childId, todaysDate, getScheduleType, deletePopulateData));
    }

    const onDeleteFailure = (errorMessage) => {
        setActivityScheduledError(true);
        setScheduleActivityErrorMessage(errorMessage);
    }

    const deleteActivity = () => {
        setDeleteDialogOpen(false);
        dispatch(deleteScheduleActivity(activityForDelete.id, onDeleteSuccess, onDeleteFailure));
    }

    const updatePopulateData = (data) => {
        setActivityUpdateSuccess(true);
        populateScheduleData(data);
    }

    const onUpdateSuccess = () => {
        setActivityUpdateSuccess(true);

        // let todaysDate = getTodayChildTimezoneString()
        // dispatch(getScheduleList(childState.selectedChild.childId, todaysDate, getScheduleType, updatePopulateData));

        fetchScheduledActivities(updatePopulateData);
        
        exitPreviewMode();
    }

    const onUpdateFailure = (errorMessage) => {
        // let todaysDate = getTodayChildTimezoneString()
        // dispatch(getScheduleList(childState.selectedChild.childId, todaysDate, getScheduleType, populateScheduleData));

        fetchScheduledActivities(populateScheduleData);
        
        setActivityScheduledError(true);
        setScheduleActivityErrorMessage(errorMessage);
    }

    const onDirectUpdateSuccess = (res) => {
        setActivityUpdateSuccess(true);
        
        // let todaysDate = getTodayChildTimezoneString()
        // dispatch(getScheduleList(childState.selectedChild.childId, todaysDate, getScheduleType, updatePopulateData));

        fetchScheduledActivities(updatePopulateData);
        
        if (previewMode) { displayPreviewMode({ activity: res.data }); }
    }

    //--------- Schedule Activity API Calls ---------------------------

    // update the day, week, month key word to daily, monthly, weekly.
    const getScheduleType = () => {
        switch (selectedView) {
            case CALENDAR_VIEW.DAY:
                return 'daily';
            case CALENDAR_VIEW.AGENDA:
                return 'schedule';
            default:
                return selectedView.concat('ly');
        }
    }

    const fetchScheduledActivities = useCallback((onFetchedCallback) => {
        let viewStartDate = getStartDateBasedOnView();

        let queryDateTZ = momentTz.tz(startDate, getChildTimeZone()).format(serverDateFormat);
        
        let queryDate = momentTz(viewStartDate).format(serverDateFormat);
        
        console.log("query date: ", queryDate);

        dispatch(getScheduleList(
            childState.selectedChild.childId, 
            queryDate, 
            getScheduleType(), 
            onFetchedCallback));
    })

    const searchPopulateData = (data) => {
        if(data && data.length > 0) {
            setStartDate(new Date(data[0].date));
        }
        populateScheduleData(data);
    }

    const searchScheduledActivitiesList = (searchTerm, onSearchedCallback) => {
        let queryDate = momentTz(startDate).format(serverDateFormat);
        
        console.log("query date: ", queryDate);

        dispatch(searchScheduledActivities(
            childState.selectedChild.childId, 
            queryDate, 
            getScheduleType(),
            searchTerm, 
            onSearchedCallback));
    }

    
    //--------- Snack bar alert close handlers ------------------------

    const handleActivityScheduledSuccessClose = () => {
        setActivityScheduledSuccess(false);
    }

    const handleActivityUpdatedSuccessClose = () => {
        setActivityUpdateSuccess(false);
    }

    const handleActivityDeletedSuccessClose = () => {
        setActivityDeleteSuccess(false);
    }

    const handleActivityScheduledErrorClose = () => {
        setActivityScheduledError(false);
    }
    
    // ----------- Calendar Controls ----------------

    const getStartDateBasedOnView = () => {
        // selected view, start date
        // increment decrement
        // today
        // view change
        // date picker
        // let date = new Date(momentTz(startDate, getChildTimeZone()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
        
        let date = new Date(momentTz(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));

        switch(selectedView) {
            case CALENDAR_VIEW.DAY:
                return date;
            case CALENDAR_VIEW.WEEK:
                return new Date(momentTz(date).startOf('week'));
            case CALENDAR_VIEW.MONTH:
                return new Date(momentTz(date).startOf('month'));
            default: 
                return date;
        }
    }
    
    // Handle Calender view dropdown change and add count of days on the view
    const handleSelectedView = (event) => {
        console.log(event.target.value);
        setSelectedView(event.target.value);
    }

    // Setting up Calender View dropdown
    const getCalenderDropDownViews = () => {
        return viewDropdown.map((category, index) => {
            return <MenuItem key={index} value={category.value}>{category.name}</MenuItem>;
        });
    }

    const onDateIncrement = () => {
        switch(selectedView) {
            case CALENDAR_VIEW.DAY:
                setStartDate(dt => new Date(moment(dt).add(1,'days')));
                break;
            case CALENDAR_VIEW.WEEK:
                setStartDate(dt => new Date(moment(dt).add(7,'days').startOf('week')));
                break;
            case CALENDAR_VIEW.MONTH:
                setStartDate(dt => new Date(moment(dt).add(1,'months').startOf('month')));
                break;
            default:
                setStartDate(dt => new Date(moment(dt).add(1,'days')));
                break;
        }
    }

    const onDateDecrement = () => {
        switch(selectedView) {
            case CALENDAR_VIEW.DAY:
                setStartDate(dt => new Date(moment(dt).add(-1,'days')));
                break;
            case CALENDAR_VIEW.WEEK:
                setStartDate(dt => new Date(moment(dt).add(-7,'days').startOf('week')));
                break;
            case CALENDAR_VIEW.MONTH:
                setStartDate(dt => new Date(moment(dt).add(-1,'months').startOf('month')));
                break;
            default:
                setStartDate(dt => new Date(moment(dt).add(-1,'days')));
                break;
        }
    }

    const disableIncrementDate = () => {
        // let today = moment(new Date());
        // let start = moment(startDate);
        // let diff = start.diff(today, 'days');
        // return diff >= 4;
        return false;
    }

    const disableDecrementDate = () => {
        // let today = moment(new Date());
        // let start = moment(startDate);
        // let diff = today.diff(start, 'days');
        // return diff >= 4;
        return false;
    }

    const toggleSearchMode = () => {
        if(searchMode) {
            setStartDate(new Date(searchModeExitDate));
            setSelectedView(searchModeExitView);
        } else {
            setSearchModeExitView(selectedView);
            setSearchModeExitDate(new Date(startDate));
        }
        setSearchMode(!searchMode);
    }

    const searchActivitiesTextKeyUp = (event) => {
        const value = event.target && event.target.value ? event.target.value : "";
        if (event.key === 'Enter' && value !== "") {
            setSearchTextActivities(value);
            setSelectedView(CALENDAR_VIEW.AGENDA);
        }
    }

    const onSelectDayFromHeader = (momentDate) => {
        setSelectedView(CALENDAR_VIEW.DAY);
        setStartDate(momentDate.toDate());
    }

    const onMonthViewShowMore = (events, date) => {
        setStartDate(date);
        setSelectedView(CALENDAR_VIEW.DAY);
    }

    const handleActivitiesPanel = (isPanelOpen) => {
        if(isPanelOpen) { exitPreviewMode() }
        setShowActivitiesPanel(isPanelOpen);
    }

    // ----------------------------------------------


    const handleNavigate = (date, view) => {
        console.log('handleNavigate =======> ');
        if (view === 'day') {
          const now = new Date();
          const current = localizer.getNow();
          if (now.getDate() === current.getDate()) {
            const scrollElement = document.querySelector('.rbc-time-view');
            console.log({scrollElement})
            if (scrollElement) {
              scrollElement.scrollTop = current.getHours() * 60 + current.getMinutes();
            }
          }
        }
    };

    return (
        <div className="schedule-screen">
            {scheduleState.loading && <Loader />}
            
            <SnackbarAlert show={activityScheduledSuccess} message={t("activityScheduled")} duration={3000} onCloseAlert={handleActivityScheduledSuccessClose} />
            <SnackbarAlert show={activityUpdateSuccess} message={t("activityUpdated")} duration={3000} onCloseAlert={handleActivityUpdatedSuccessClose} />
            <SnackbarAlert show={activityDeleteSuccess} message={t("activityDeleted")} duration={3000} onCloseAlert={handleActivityDeletedSuccessClose} />
            <SnackbarAlert isError={true} show={activityScheduledError} message={scheduleActivityErrorMessage || t("unknownError")} duration={5000} onCloseAlert={handleActivityScheduledErrorClose} />

            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("deleteScheduledActivityConfirmationHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("deleteScheduledActivityConfirmationMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="no-button-text" variant="contained" onClick={handleCloseDeleteDialog}>{t("no")}</Button>
                    <Button variant="contained" onClick={deleteActivity} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>


            <div className="activityBtn">
                <Fab
                    style={{}}
                    size="small"
                    variant="extended" 
                    onClick={() => handleActivitiesPanel(!showActivitiesPanel)}>
                        {showActivitiesPanel? <ArrowForwardIosIcon /> :
                        <div>
                            <ArrowBackIosIcon />
                            Activities
                        </div>
                        }
                </Fab>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={showActivitiesPanel? 8: 12} className="pr-0">
                    <div className={selectedView==CALENDAR_VIEW.MONTH || selectedView==CALENDAR_VIEW.AGENDA ? "generic-card calender-header ml-0" : "generic-card calender-header"}>
                        {
                            !searchMode &&
                            <div className="calendar-head-container">
                                <div className="navBtns">
                                    <Button className="arrow-btn" variant="contained" disabled={disableDecrementDate()} onClick={onDateDecrement}>
                                        <ArrowBackIosIcon />
                                    </Button>
                                    <Button className="text-btn" variant="contained" onClick={(date) => setStartDate(new Date())}>
                                        Today
                                    </Button>
                                    <Button className="arrow-btn" variant="contained" disabled={disableIncrementDate()} onClick={onDateIncrement}>
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </div>
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    dateFormat={selectedView === CALENDAR_VIEW.MONTH? "MMMM, yyyy" : "MMMM dd, yyyy"}
                                />
                                <div className="searchActivity">
                                    <Button className="arrow-btn" variant="contained" onClick={toggleSearchMode}>
                                        <SearchIcon />
                                    </Button>
                                </div>
                                <Select className="select-btn" disabled={!editAccess} labelId="repeat" id="repeatSelect" name="repeatSelect" required value={selectedView} onChange={handleSelectedView}>
                                    {getCalenderDropDownViews()}                    
                                </Select>
                            </div>
                        }
                        {
                            searchMode &&
                            <div className="searchBarActive">
                                <Button className="arrow-btn" variant="contained" onClick={toggleSearchMode}>
                                    <ArrowBackIcon />
                                </Button>
                                <Input
                                    id="searchInputAct"
                                    name="searchInputAct"
                                    type="text"
                                    className="searchActivityBar"
                                    // value={searchTextActivities || ""}
                                    placeholder={t("searchActivityHere")}
                                    onKeyUp={searchActivitiesTextKeyUp}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        }
                    </div>
                
                {
                    (selectedView == CALENDAR_VIEW.DAY || selectedView == CALENDAR_VIEW.WEEK) && 
                    <div className="week-days">

                        <div className="gmt-container">
                            {hoverTZ && <p title="Watch timezone"><img src={AssetConstants.gmtlogo} /></p>}
                            <span className="gmt-ui">{displayTZ}</span>
                        </div>
                        <div className={selectedView==CALENDAR_VIEW.MONTH || selectedView==CALENDAR_VIEW.AGENDA ? "generic-card week-dates-day ml-0": "generic-card week-dates-day"}>
                            <DayHeaderBar 
                                selectedView={selectedView}
                                selectedDate={momentTz(startDate)}
                                onSelectDay={onSelectDayFromHeader}
                            />
                        </div>
                    </div>
                }
                
                    <div className="schedule-time-line">
                        <DragAndDropCalendar
                            selectable
                            resizable={editAccess}
                            localizer={localizer}
                            // events={events.length > 0 ? events[selectedDay] : []}
                            events={calendarEvents}
                            // views={{ day: true, week: true, month: true, agenda: true }}
                            // defaultView="day"
                            date={startDate}
                            dragFromOutsideItem={editAccess && dragFromOutsideItem}
                            onDropFromOutside={editAccess && onDropFromOutside}
                            onDragOver={customOnDragOver}
                            onEventDrop={onEventDrop}
                            onEventResize={editAccess && onEventResize}
                            step={30}
                            length={200}
                            toolbar={false}
                            view={selectedView}
                            components={{ 
                                event: ScheduledActivity,
                                // day: { header: HeaderViewDay },
                                // week: { header: HeaderViewDay },
                                month: { header: HeaderViewMonth },
                                agenda: { date: ScheduleViewDateLabel,
                                        time:ScheduleAgendaTime 
                                }
                            }}
                            scrollToTime={moment('5:55am', 'h:mma').toDate()}
                            onShowMore={onMonthViewShowMore}
                        />
                    </div>
                </Grid>
                
                {
                    showActivitiesPanel && 
                    <Grid item xs={4} className="pl-0">
                    <div className="schedule-activity generic-card">
                        {
                            !previewMode &&
                            <ActivityListPanel 
                                onDragEvent={handleDraggedActivtiy} 
                                editAccess={editAccess} />
                        }
                        {
                            previewMode && previewActivity.name ?
                            <ActivityPreviewPanel
                                activityColorList={activitiesState.activityColorList}
                                previewScheduledActivity={previewScheduledActivity}
                                previewActivity={previewActivity}
                                previewActivityDate={previewActivityDate}
                                onActivityUpdateSuccess={onUpdateSuccess}
                                onActivityUpdateFailure={onUpdateFailure}
                                exitPreviewMode={exitPreviewMode}
                                editAccess={editAccess}
                                selectedDate={momentTz(startDate).format(serverDateFormat)}
                            />

                                : null
                        }
                    </div>
                    </Grid>
                }

            </Grid>
            
        </div>
    );
}

// ---------------------------------------------------------------------

export default ScheduleActivity;


// timezone issues: https://github.com/jquense/react-big-calendar/issues/118