import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImageURL } from "../../store/actions/Child";
import { ROLE_TYPES } from "../../constants/GenericConstants";
import { isEmpty } from "../../helpers/Validation";

const ChildListItem = (props) => {

    const dispatch = useDispatch();
    const { child, moveToChildActivitiesScreen } = props;

    const [childImage, setChildImage] = useState("https://i.stack.imgur.com/l60Hf.png");
    const [loaded, setLoaded] = useState(false);

    const userState = useSelector((state) => state.authentication);


    useEffect(() => {
        if (child.childPhoto && child.childPhoto.includes(".")) {
            dispatch(getImageURL(child.childPhoto, onGetUrl, onFailedUrl));
        }
        else {
            setLoaded(true);
        }
    }, []);

    const onGetUrl = (res) => {
        setChildImage(res.data.url);
        setLoaded(false);
    }

    const onFailedUrl = (err) => {
        console.log(err);
    }

    const getUserName = () => {
        if(userState.isSingleUser && child.userRole === ROLE_TYPES.PARENT) {
            return child.parentFirstName + " " + child.parentLastName;
        }
        return child.childFirstName + " " + child.childLastName;
    }

    const getImageSrc = () => {
        if(userState.isSingleUser && child.userRole === ROLE_TYPES.PARENT) {
            return (!isEmpty(userState.user) 
            && (userState.user.photo 
            && userState.user.photo.includes(".")) 
            ? userState.user.photoURL 
            : "https://i.stack.imgur.com/l60Hf.png")
        }
        else return childImage;
    }

    return (
        <div onClick={() => moveToChildActivitiesScreen(child.childId)}>
            <figure>
                {!loaded && <p className="loader"></p>}
                <img
                    onLoad={() => {
                        setLoaded(true)
                    }}
                    src={getImageSrc()} alt="User Profile Picture" />
            </figure>
            <h5>{getUserName()}</h5>
        </div>
    );
}

export default ChildListItem;