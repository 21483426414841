import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Grid,
  Container,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  PAYMENT_METHOD,
  PAYMENT_PLAN,
  ROLE_TYPES,
} from "../../constants/GenericConstants";
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import { validateMandatoryValue } from "../../helpers/Validation";
import { getPaymentAdverb } from "../../helpers/GenericHelper";
import {
  getSubscriptionList,
  createPaymentSession,
  getUser,
} from "../../store/actions/Authentication";
import Loader from "../Loader/Loader";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

const PaymentSignup = ({ editAct }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.authentication);

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.STRIPE);
  const [user, setUser] = useState(null);
  const [paymentPlan, setPaymentPlan] = useState("");
  const [paymentPlanError, setPaymentPlanError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getSubscriptionList());
  }, []);

  const handlePaymentPlanChange = (event) => {
    setPaymentPlan(event.target.value);
    validatePaymentPlan(event.target.value);
  };

  const validatePaymentPlan = (paymentPlan) => {
    if (!validateMandatoryValue(paymentPlan)) {
      setPaymentPlanError(t("paymentPlanRequiredError"));
      return false;
    } else {
      setPaymentPlanError("");
      return true;
    }
  };

  const onCancelPress = () => {
    console.log("Cancelling...");
    setDialogOpen(true);
  };

  const onProceed = () => {
    if (!validatePaymentPlan(paymentPlan)) {
      return;
    }
    dispatch(getUser(setUser, (error) => console.log(error)));
    console.log(userState);
    
  };
  useEffect(() => {
    if (user) {
      let accountId = "";
    const parentArray = user.accounts.filter(
      (account) => account.role === ROLE_TYPES.PARENT
    );
    const externalUserArray = user.accounts.filter(
      (account) => account.role !== ROLE_TYPES.PARENT
    );
    if (parentArray.length > 0) {
      accountId = parentArray[0].id;
    } else {
      accountId = externalUserArray[0].id;
    }
    console.log("Account Id:");
    console.log(accountId);
    dispatch(createPaymentSession(+paymentPlan, accountId));
    }
  },[user])

  const showInputError = (field) => {
    return field.length > 0;
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const goToLogin = () => {
    if (
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
    ) {
        history.replace({
        pathname: RouteConstants.ACCOUNT_SETTINGS.replace(":userId",`${userState.user.id}`)
      });
      history.goBack()
    } else {
      localStorage.clear();
      history.push({ pathname: RouteConstants.LOGIN });
    }
  };

  return (
    <Grid container className="app payment-method-outer">
      {userState.loading && <Loader />}
      <Dialog
        maxWidth="xs"
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon className="popup-close" onClick={handleCloseDialog} />
        <DialogTitle id="alert-dialog-title">
          {t("performActionConfirmHeading")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("informationLostMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
          >
            {t("no")}
          </Button>
          <Button variant="contained" onClick={goToLogin} color="primary">
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} className="grid-body">
        <Container maxWidth="xl" className="paymentmethod">
          <Grid container spacing={3}>
            <h1 className="title-has-icon mb-15">{t("payment")}</h1>
            <Grid
              item
              xs={12}
              className="generic-card generic-card-full-height"
            >
              <div>
                <Card>
                  <div className="plr-78 sep">
                    <h4>{t("paymentMethod")}</h4>
                    <div>
                      <div className="radio-block">
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label=""
                            name=""
                            value={paymentMethod}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label={
                                <p>
                                  <figure>
                                    <img src={AssetConstants.cclogo} alt=" " />
                                  </figure>
                                  <strong>{t("creditdebit")}</strong>
                                </p>
                              }
                              value={PAYMENT_METHOD.STRIPE}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="select-payment-plan plr-78">
                    <h4>{t("selectPaymentPlan")}</h4>
                    <div className="radio-block">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label=""
                          name=""
                          value={paymentPlan}
                          onChange={handlePaymentPlanChange}
                          row
                        >
                          {userState.subscriptionList.map(
                            (subscription, index) => {
                              return (
                                <FormControlLabel
                                  control={<Radio />}
                                  label={
                                    <p>
                                      {subscription.discountedPrice > 0 && (
                                        <span className="savetag">
                                          {t("save") +
                                            " " +
                                            Math.round(
                                              (((subscription.price -
                                                subscription.discountedPrice) *
                                                100) /
                                                subscription.price) *
                                                10
                                            ) /
                                              10 +
                                            "%"}
                                        </span>
                                      )}
                                      <span>{subscription.name}</span>
                                      <strong>
                                        {subscription.discountedPrice > 0
                                          ? "$" + subscription.discountedPrice
                                          : "$" + subscription.price}
                                      </strong>
                                      <span className="billed-dur">
                                        {(subscription.discountedPrice > 0
                                          ? "$" + subscription.price + " "
                                          : "") +
                                          t("billed") +
                                          " " +
                                          getPaymentAdverb(subscription.name)}
                                      </span>
                                    </p>
                                  }
                                  value={"" + subscription.id}
                                />
                              );
                            }
                          )}
                        </RadioGroup>
                      </FormControl>
                      {showInputError(paymentPlanError) && (
                        <FormHelperText
                          error={showInputError(paymentPlanError)}
                        >
                          {paymentPlanError}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
            <div className="add-child-form-footer box-full-width">
              <Button
                variant="contained"
                disableElevation
                onClick={onCancelPress}
              >
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={onProceed}
              >
                {t("proceedToPayment")}
              </Button>
            </div>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default PaymentSignup;
