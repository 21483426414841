import { isTomorrow } from "date-fns";
import { GET_NOTIFICATIONS_ACTIONS, MARK_NOTIFICATION_AS_READ_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    notificationList: [],
    notificationListMeta: {},
};

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
            };

        case GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_CLEAR_OLD_DATA:
            return {
                ...state,
                loading: true,
                notificationList: [],
            };

        case GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: [...state.notificationList, ...action.payload.data.data],
                notificationListMeta: action.payload.data.meta,
            };

        case GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case MARK_NOTIFICATION_AS_READ_ACTIONS.MARK_NOTIFICATION_AS_READ_SUCCESS:
            const newList = [...state.notificationList];
            newList[action.payload.index].isRead = true;
            return {
                ...state,
                notificationList: newList,
            };
            
        case MARK_NOTIFICATION_AS_READ_ACTIONS.MARK_ALL_READ_SUCCESS:
            let tempList = [...state.notificationList];
            tempList= tempList.map((item)=>{
                return {...item, isRead:true}
            })
            return {
                ...state,
                notificationList: [...tempList],
            };

        default:
            return state;
    }
};

export default notifications;
