import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { getImageURL } from "../../store/actions/Child";


const ChildSelectItem = ({ child, formData, setFormData }) => {


    const { children } = formData;
    const dispatch = useDispatch();
    const [childImage, setChildImage] = useState("https://i.stack.imgur.com/l60Hf.png");

    useEffect(() => {
        if (child?.childPhoto?.includes(".")) {
            dispatch(getImageURL(child?.childPhoto, onGetUrl, onFailedUrl))
        }
    }, [])

    const onGetUrl = (res) => {
        setChildImage(res.data.url)
    }
    const onFailedUrl = (err) => {
        // alert(JSON.stringify(err))
    }
    const checkStatus = () => {
        var checkStatusGot = false;
        children.map(ch => {
            if (child.childId === ch) {
                checkStatusGot = true;
                // alert(child.childId + "--- "+ch)
                return;

            }
        })
        return checkStatusGot;
    }

    return (
        <div className="check-block">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkStatus()}
                        onChange={(e) => {
                            const input = e.target && e.target.checked ? e.target.checked : false
                            if (input) {
                                var arr = children;
                                arr.push(child.childId)
                                setFormData({ ...formData, children: arr });

                            }
                            else {
                                var arr = children;
                                arr = arr.filter(item => item !== child.childId)
                                setFormData({ ...formData, children: arr });
                            }
                        }} />
                }
                label={
                    // <React.Fragment>
                    <p>
                        <figure>
                            <img src={childImage} className="profile-img" />
                        </figure>
                        <strong>{child.childFirstName}</strong>
                    </p>
                    // </React.Fragment>
                }
            />
        </div>
    )
}
export default ChildSelectItem;