import React from "react";

import { MessageLeft } from "./bubbles/MessageLeft";
import { MessageRight } from "./bubbles/MessageRight";

export const MessageItemContainerBody = ({ isAuthor, ...props }) => {
  const isAttachmentType = props?.message.attachments?.length !== 0;

  return isAuthor ? (
    <MessageRight {...props} isAttachmentType={isAttachmentType} />
  ) : (
    <MessageLeft {...props} isAttachmentType={isAttachmentType} />
  );
};
