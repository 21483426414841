import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, makeStyles } from "@material-ui/core";

import { useSocket } from "../../hooks/useSocket";
import { MessagePanel } from "./components/messages/MessagePanel";
import { fetchMessages, updateType } from "../../store/actions/Chat";
import { resetMessages } from "../../store/actions/Chat/Messages";

const useStyles = makeStyles(() => ({
  conversationChannelPageStyle: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#FFF",
  },
}));

export const ConversationChannelPage = () => {
  const classes = useStyles();
  const { id: conversationId } = useParams();
  const { socket } = useSocket();

  const dispatch = useDispatch();

  const { searchTerm } = useSelector((state) => state.messages);

  useEffect(() => {
    dispatch(updateType("private"));
  }, [conversationId, dispatch]);

  useEffect(() => {
    dispatch(fetchMessages(conversationId, 1, searchTerm));
  }, [conversationId, dispatch, searchTerm]);

  useEffect(() => {
    console.log('socket ConversationChannelPage =>', socket)
    if (socket) {
      socket.emit("onConversationJoin", { conversationId });
      socket.on("userJoin", (data) => {
        console.log("userJoin ==> ", data);
      });
      socket.on("userLeave", () => {
        console.log("userLeave");
      });
    }

    return () => {
      if (socket) {
        socket.emit("onConversationLeave", { conversationId });
        socket.off("userJoin");
        socket.off("userLeave");
        dispatch(resetMessages());
      }
    };
  }, [conversationId, dispatch, socket]);

  return (
    <Box className={classes.conversationChannelPageStyle}>
      <MessagePanel />
    </Box>
  );
};
