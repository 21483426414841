import { Box, makeStyles } from "@material-ui/core";
import React, { forwardRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../../../../hooks/useToast";
import {
  addAttachment,
  incrementAttachmentCounter,
} from "../../../../store/actions/Chat/MessagePanel";

const useStyles = makeStyles(() => ({
  messageTextarea: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    minHeight: "40px",
    overflow: "scroll",
    outline: "none",
    boxSizing: "border-box",
    fontSize: "14px",
    width: "93%",
    padding: "10px 20px",
    margin: "0",
    resize: "none",
    height: "40px",
    maxHeight: "80px",
    flex: "0 0 auto",
    fontFamily: "OpenSans",
    fontStyle: "normal",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const MessageTextField = forwardRef(
  (
    {
      message,
      setMessage,
      maxLength,
      setIsMultiLine,
      sendMessage,
      onUserClickInput,
      onUserClickOutsideInput,
    },
    ref
  ) => {
    const DEFAULT_TEXTAREA_HEIGHT = 21;

    const classes = useStyles();

    // const ref = useRef(null);
    const dispatch = useDispatch();
    const { error } = useToast();

    const { attachments, attachmentCounter } = useSelector(
      (state) => state.messagePanel
    );

    const onMessageChange = (e) => {
      e.preventDefault();
      setMessage(e.target.value);
      const { current } = ref;
      if (current) {
        const height = parseInt(current.style.height);
        current.style.height = "5px";
        current.style.height = current.scrollHeight + "px";
        height > DEFAULT_TEXTAREA_HEIGHT
          ? setIsMultiLine(true)
          : setIsMultiLine(false);
      }
    };

    const onKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        console.log({ e });
        e.preventDefault();
        sendMessage();
        setIsMultiLine(false);
        if (ref.current) ref.current.style.height = "21px";
      }
    };

    const handleFileAdd = (files) => {
      const maxFilesDropped = 5 - attachments.length;
      if (maxFilesDropped === 0) return error("Max files reached");
      const filesArray = Array.from(files);
      let localCounter = attachmentCounter;

      for (let i = 0; i < filesArray.length; i++) {
        if (i === maxFilesDropped) break;
        dispatch(addAttachment({ id: localCounter++, file: filesArray[i] }));
        dispatch(incrementAttachmentCounter());
      }
    };

    const onDrop = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      handleFileAdd(files);
    };

    const onPaste = (e) => {
      const { files } = e.clipboardData;
      console.log("pasting...");
      console.log(files);
      handleFileAdd(files);
    };

    return (
      <Box
        component={"textarea"}
        className={classes.messageTextarea}
        ref={ref}
        value={message}
        onChange={onMessageChange}
        onFocus={onUserClickInput}
        onBlur={onUserClickOutsideInput}
        placeholder="Type any..."
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onDrop={onDrop}
        onPaste={onPaste}
      />
    );
  }
);
