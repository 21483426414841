import { Avatar, ListItemAvatar } from "@material-ui/core";
import React from "react";

import GroupIcon from "@material-ui/icons/Group";

import styles from "./index.module.scss";

const GroupAvatar = ({ users = [] }) => {
  const list = users
    .filter((user) => Boolean(user))
    .filter((user) => user?.photo?.includes("http"))
    .slice(0, 3);

  if (!list.length) {
    return (
      <ListItemAvatar>
        <Avatar alt={"Group Avatar"}>
          <GroupIcon />
        </Avatar>
      </ListItemAvatar>
    );
  }

  return (
    <div className={styles["image-grid"]}>
      {list.map((user, index) => {
        return (
          <img
            key={`${index}_${user}`}
            alt={`${user.firstName}_${user.lastName}_${user.id}`}
            width="10px"
            height="10px"
            src={user.photo}
          />
        );
      })}
    </div>
  );
};
export default GroupAvatar;
