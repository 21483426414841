import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const UserRoles = ({ makePermZero, name, image, formData, setFormData, rolesList }) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState('TEACHER');
    const { role } = formData;
    const handleChange = (event) => {
        console.log("target",event.target.value);
        setValue(event.target.value);
        // alert(event.target.value)
        setFormData({ ...formData, role: rolesList.filter((r) => r.roleName === event.target.value)[0] });
        makePermZero()
    };
    useEffect(() => {
        setValue(name)
        return () => {

        }
    }, [name])

    return (
        <div className="radio-block">
            <FormControl component="fieldset">
                <RadioGroup aria-label="" name="" value={value} onChange={handleChange} row >
                    {rolesList?.map(role => {

                        return (
                            <FormControlLabel
                                control={<Radio />}
                                label={
                                    <p>
                                        <i className={role.icon}></i>
                                        <strong>{role.label}</strong>
                                    </p>
                                }
                                value={role.roleName}
                            />

                        )
                    })}

                    {/* <FormControlLabel
                        control={<Radio />}
                        label={
                            <p>
                                <i className="ticon-teacher"></i>
                                <strong>{t("teacher")}</strong>
                            </p>
                        }
                        value="TEACHER"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label={
                            <p>
                                <i className="ticon-therapist"></i>
                                <strong>{t("therapist")}</strong>
                            </p>
                        }
                        value="THERAPIST"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label={
                            <p>
                                <i className="ticon-therapist"></i>
                                <strong>{t("coParentGuardian")}</strong>
                            </p>
                        }
                        value="CO_PARENT_GUARDIAN"
                    /> */}
                </RadioGroup>
            </FormControl>
        </div>
    )
}
export default UserRoles;