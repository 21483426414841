import { failure, request, success } from "..";
import { CONVERSATION_MESSAGES } from "../../../constants/ActionConstants";
import ChatService from "../../../services/api/ChatService";

export const getChatPresignedURL = (fileName, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(CONVERSATION_MESSAGES.GET_CHAT_PRE_SIGNED_URL, {}));
    try {
      const response = await ChatService.getChatPresignedURL(fileName);
      dispatch(
        success(CONVERSATION_MESSAGES.GET_CHAT_PRE_SIGNED_URL_SUCCESS, {})
      );
      onSuccess(response);
    } catch (error) {
      dispatch(
        failure(CONVERSATION_MESSAGES.GET_CHAT_PRE_SIGNED_URL_FAILURE, {})
      );
      onFailure(error);
    }
  };
};

export const uploadChatImage = (data, urlAws, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(CONVERSATION_MESSAGES.UPLOAD_CHAT_IMAGE, {}));
    try {
      const response = await ChatService.uploadChatImagePut(data, urlAws);
      dispatch(success(CONVERSATION_MESSAGES.UPLOAD_CHAT_IMAGE_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      dispatch(failure(CONVERSATION_MESSAGES.UPLOAD_CHAT_IMAGE_FAILURE, {}));
      onFailure(error);
    }
  };
};

export const getChatImageURL = (fileName, onSuccess, onFailure) => {
  return async () => {
    try {
      const response = await ChatService.getChatImageURL(fileName);
      onSuccess(response);
    } catch (error) {
      onFailure(error);
    }
  };
};

export const addMessage = (payload) => {
  return request(CONVERSATION_MESSAGES.ADD_MESSAGE, payload);
};

export const resetMessages = () => {
  return request(CONVERSATION_MESSAGES.RESET_MESSAGE, {});
};

export const setSearchTerm = (searchTerm) => {
  return request(CONVERSATION_MESSAGES.SET_SEARCH_TERM, searchTerm);
};
