import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Input, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { getScheduleActivities } from "../../store/actions/Schedule";
import { getActivity } from "../../store/actions/Activities";
import InfiniteScroll from 'react-infinite-scroller';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/sass/styles.scss";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import { getActivityBackgroundColor } from "../../helpers/GenericHelper";
import AssetConstants from "../../constants/AssetConstants";


const ActivityListPanel = ({editAccess, onDragEvent}) => {
    const { t } = useTranslation();
    const childState = useSelector((state) => state.child);
    const scheduleState = useSelector((state) => state.schedule);
    const userState = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    const location = useLocation();

    const [draggedEvent, setDraggedEvent] = useState(null);
    const [ref, setRef] = useState(null);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        dispatch(getScheduleActivities(childState.selectedChild.childId, 1, searchText));
    }, []);
    /**
     * Provides a list of activity rows for the activities panel on the right
     */
     const getActivitiesList = () => {

        return getUnique(scheduleState.activityList, 'id').map((activity, index) => {
            return <div 
            className={!editAccess ? 'Mui-disabled' : ""} 
            key={index} 
            draggable={editAccess} 
            onDragStart={() => editAccess && handleDragStart(activity)} 
            style={{ backgroundColor: getActivityBackgroundColor(activity.color) }}>
                <span>
                    <img alt={activity.name} 
                    src={activity.imageURL} 
                    style={{ height: 40, width: 40 }} />
                </span>
                <span style={{ color: activity.color }}>{activity.name}</span>
            </div>
        });
    }

    const fetchMore = () => {
        if (!scheduleState.activityLoading && scheduleState.activityListMeta.hasNextPage) {
            dispatch(getScheduleActivities(childState.selectedChild.childId, scheduleState.activityListMeta.page + 1, searchText));
        }
    }

    const handleSearchTextChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        // console.log(event.key+"---"+event.target.value);
        if (event.key === 'Enter') {
            setSearchText(input)
            dispatch(getScheduleActivities(childState.selectedChild.childId, 1, input));
        }
        if (event.key === 'Backspace') {
            if (input === "") {
                setSearchText(input)
                dispatch(getScheduleActivities(childState.selectedChild.childId, 1, input));
            }
        }

    }

    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    const handleDragStart = (activity) => {
        setDraggedEvent(activity);
        onDragEvent(activity);
    }

    return <div>
        <h2 className="mt-0 fw-500">{t("scheduleActivity")}</h2>
        <p>{t("dragDropActivity")}</p>
        <div className="schedule-search">
            <Input
                id="searchInput"
                name="searchInput"
                type="text"
                // value={searchText || ""}
                placeholder={t("searchActivityHere")}
                onKeyUpCapture={handleSearchTextChange}
                endAdornment={
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                } />
        </div>
        <div ref={ref => { setRef(ref); }} className="schedule-activity-dragable">
            <InfiniteScroll
                pageStart={1}
                loadMore={fetchMore}
                hasMore={scheduleState.activityListMeta.hasNextPage}
                useWindow={false}
                initialLoad={true}
                threshold={50}
                getScrollParent={() => ref}>
                {getActivitiesList()}
                {
                    scheduleState.activityLoading &&
                    <div className="loader mr-auto"></div>
                }
            </InfiniteScroll>
            {
                !scheduleState.activityLoading && (scheduleState.activityList.length === 0) &&
                <div className="noActivityScheduleListing">
                    <img src={AssetConstants.emptylist} />
                    <span>{t("noActivityFound")}</span>
                </div>
            }
        </div>
    </div>
}

export default ActivityListPanel;