import { GET_ALL_GROUPS_ACTIONS } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  loading: false,
  groups: [],
  search: "",
  error: null,
};

export const UpdateGroupAction = {
  NEW_MESSAGE: "newMessage",
};

const groups = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS_ACTIONS.GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload.data.data,
      };

    // case GET_ALL_GROUPS_ACTIONS.GROUP_CREATE_SUCCESS:
    //   return {
    //     ...state,
    //     groups: [action.payload.data, ...state.groups],
    //   };

    case GET_ALL_GROUPS_ACTIONS.GROUP_DELETE:
      return { ...state, loading: true };

    case GET_ALL_GROUPS_ACTIONS.GROUP_DELETE_SUCCESS:
      // const groupToRemove = state.groups.find(
      //   (g) => g.id === action.payload.data.id
      // );

      // if (!groupToRemove)
      //   return {
      //     ...state,
      //   };

      // const allGroups = state.groups.filter(
      //   (group) => group.id !== action.payload.id
      // );

      return {
        ...state,
        // groups: [...allGroups],
        loading: false,
      };

    case GET_ALL_GROUPS_ACTIONS.GROUP_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error:
          action?.payload?.data?.message || "Unable to delete the conversation",
      };

    case GET_ALL_GROUPS_ACTIONS.GROUP_ADD:
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };

    case GET_ALL_GROUPS_ACTIONS.GROUP_UPDATE:
      const { type, group: updateGroup } = action.payload;
      const existingGroup = state.groups.find((g) => g.id === updateGroup.id);
      const existingIndex = state.groups.findIndex(
        (g) => g.id === updateGroup.id
      );

      if (!existingGroup) return;

      switch (type) {
        case UpdateGroupAction.NEW_MESSAGE: {
          const newGroups = state.groups.filter((g) => g.id !== updateGroup.id);
          return {
            ...state,
            groups: [updateGroup, ...newGroups],
          };
        }

        default: {
          const updatedGroups = [...state.groups];
          updatedGroups[existingIndex] = updateGroup;
          return {
            ...state,
            groups: updatedGroups,
          };
        }
      }

    case GET_ALL_GROUPS_ACTIONS.GROUP_REMOVE:
      const group = state.groups.find((g) => g.id === action.payload.id);
      // const index = state.groups.findIndex(
      //   (g) => g.id === action.payload.id
      // );

      if (!group)
        return {
          ...state,
        };

      const groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );

      return {
        ...state,
        groups: groups,
      };

    case GET_ALL_GROUPS_ACTIONS.GROUP_MARKED_READ:
      const updatedGroupsMarkedRead = state.groups.map((group) => {
        if (group.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }

        return group;
      });

      return { ...state, groups: updatedGroupsMarkedRead };

    case GET_ALL_GROUPS_ACTIONS.GROUP_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default groups;
