import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import { io } from "socket.io-client";

export const apiClient = () => {
  const baseUrl = AppEnvironment.config().baseURL;
  const socketUrl = AppEnvironment.config().socketURL;
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const defaultOptions = {
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      "Content-Type": "application/json",
    },
  };

  const sendMessageHeaders = {
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      "Content-Type": "multipart/form-data",
    },
  };

  const imageUploadHeaders = {
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      "Content-Type": "image/*",
    },
  };

  return {
    get: (url, options = {}) =>
      axios.get(`${baseUrl}${url}`, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(`${baseUrl}${url}`, data, { ...defaultOptions, ...options }),
    put: (url, data = {}, options = {}) =>
      axios.put(`${baseUrl}${url}`, data, { ...defaultOptions, ...options }),
    uploadImage: (url, data, options = {}) =>
      axios.put(url, data, { ...imageUploadHeaders, ...options }),
    delete: (url, options = {}) =>
      axios.delete(`${baseUrl}${url}`, { ...defaultOptions, ...options }),
    sendMessage: (url, data, options = {}) =>
      axios.post(`${baseUrl}${url}`, data, {
        ...sendMessageHeaders,
        ...options,
      }),
    connectSocket: (url) =>
      io(AppEnvironment.config().socketURL, {
        withCredentials: false,
        extraHeaders: { Authorization: token ? `${token || null}` : "" },
      }),
    // connectSocket: (url) =>
    //   socketClient(`${socketUrl}`, {
    //     withCredentials: false,
    //     extraHeaders: { Authorization: token ? `${token || null}` : "" },
    //   }),
  };
};

export const BuildMode = {
  BUILD_MODE_LOCAL: "local",
  BUILD_MODE_DEV: "dev",
  BUILD_MODE_QA: "qa",
  BUILD_MODE_STAGING: "staging",
  BUILD_MODE_PROD: "prod",
  BUILD_MODE_DEMO: "demo",
};

export const AppEnvironment = {
  // comment out one from following buildMode from which server you want to get data

  // buildMode: BuildMode.BUILD_MODE_LOCAL,
  // buildMode: BuildMode.BUILD_MODE_DEV,
  //  buildMode: BuildMode.BUILD_MODE_QA,
  // buildMode: BuildMode.BUILD_MODE_DEMO,
  // buildMode: BuildMode.BUILD_MODE_STAGING,
  buildMode: BuildMode.BUILD_MODE_PROD,

  /* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

  allConfigurations: {
    local: {
      baseURL: "http://localhost:3000",
      socketURL: "http://localhost:3000",
      webURL: "http://localhost:3000",
      oneSignal: {
        appId: "0b941212-bff2-4207-b632-bd1a416f3cc4",
        safari_web_id:
          "web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174",
      },
      old_oneSignal: {
        appId: "832dbf79-7fba-4b5e-967f-2211f9a77316",
        safariId: "web.onesignal.auto.54d60a3d-c0d7-4ae1-b490-c29a9cfa7b70",
      },
      flagsmithEnvironmentId: "nhZZnfnSJBLPfoJV3Gtdio", //Feature Flags: used for Toggling chat feature
      mixpanelToken: '5d58d28d6f794c283abb16dfe2bce5fe',
    },
    dev: {
      baseURL: "https://demo4.folio3.com:3280",
      socketURL: "https://demo4.folio3.com:3280",
      oneSignal: {
        appId: "0b941212-bff2-4207-b632-bd1a416f3cc4",
        safari_web_id:
          "web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174",
      },
      old_oneSignal: {
        appId: "832dbf79-7fba-4b5e-967f-2211f9a77316",
        safariId: "web.onesignal.auto.54d60a3d-c0d7-4ae1-b490-c29a9cfa7b70",
      },
      flagsmithEnvironmentId: "nhZZnfnSJBLPfoJV3Gtdio",
      mixpanelToken: '5d58d28d6f794c283abb16dfe2bce5fe',
    },
    qa: {
      baseURL: "https://demo4.folio3.com:4000",
      socketURL: "https://demo4.folio3.com:4000",
      webURL: "https://demo4.folio3.com:7076",
      oneSignal: {
        appId: "123a231a-b9eb-494e-9dc2-a8e2ce5947f7",
        safari_web_id:
          "web.onesignal.auto.50dac47a-d084-4e57-bf73-88f5ab7beb6e",
      },
      old_oneSignal: {
        appId: "03b5a876-5c7f-4075-88d3-d33c674eab40",
        safariId: "web.onesignal.auto.512e7622-1d45-4df4-947f-a5316fda3b8e",
      },
      flagsmithEnvironmentId: "Rr9At8VX7bKoWDQ3raLugn",
      mixpanelToken: '5d58d28d6f794c283abb16dfe2bce5fe',
    },
    staging: {
      baseURL: "https://thrive.folio3.com:3028",
      socketURL: "https://thrive.folio3.com:3028",
      webURL: "https://thrive.folio3.com",
      oneSignal: {
        appId: "355c93b8-8ee7-4fdb-b803-820ba6d4f3c1",
        safari_web_id:
          "web.onesignal.auto.3cd6b41f-0715-4da8-9007-02ca4af2dc44",
      },
      old_oneSignal: {
        appId: "5b4db160-47cb-469d-92d5-3ba382e4a384",
        safariId: "web.onesignal.auto.6401d2fc-b951-4213-a02c-03159c046b78",
      },
      flagsmithEnvironmentId: "gJSxtiGPgdJtnyYvpJQ55s",
      mixpanelToken: '497409bbeb225fd5a97894d1c567f2e1',
    },
    prod: {
      baseURL: "https://api.thrivingonthespectrum.org",
      socketURL: "https://api.thrivingonthespectrum.org",
      webURL: "https://web.thrivingonthespectrum.org",
      oneSignal: {
        appId: "c3c763db-fbb6-44bf-8e52-cdad6ea7a3d9",
        safari_web_id:
          "web.onesignal.auto.18c6dc90-7633-4ce6-8875-ae2763214094",
      },
      old_oneSignal: {
        appId: "d0070993-a84f-496b-8064-df439d780cd5",
        safariId: "web.onesignal.auto.4132c962-fb08-4b74-acbc-06682d034170",
      },
      flagsmithEnvironmentId: "78o48n2PCqef2UNTQyi8N4",
      mixpanelToken: '497409bbeb225fd5a97894d1c567f2e1',
    },
    demo: {
      baseURL: "",
      oneSignal: { appId: "", safariId: "" },
    },
  },

  config(buildMode) {
    const mode = buildMode || this.buildMode;
    return this.allConfigurations[mode];
  },
};
