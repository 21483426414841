import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class ActivityService {

    addActivity(payload) {
        let url = UrlConstants.ADD_ACTIVITY;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getAllActivities(childId, page, isArchive) {
        let url = UrlConstants.GET_ACTIVITY_LIST.replace(/{childId}/, childId);
        url = url + '&page=' + page;
        url = url + '&isArchive=' + isArchive;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getActivity(id) {
        let url = UrlConstants.GET_ACTIVITY.replace(/{activityId}/, id);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    updateActivity(payload) {
        let url = UrlConstants.ADD_ACTIVITY;

        return apiClient()
            .put(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    archiveActivity(payload) {
        let url = UrlConstants.ARCHIVE_ACTIVITY;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getColorList(id) {
        let url = UrlConstants.GET_META_DATA;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new ActivityService();