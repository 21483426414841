import mixpanel from "mixpanel-browser";
import { AppEnvironment } from "../services/api/ApiClient";
import { appVersion } from "../components/Footer/Footer";

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },

  alias: (id) => {
    mixpanel.alias(id);
  },

  track: (name, props) => {
    const allProps = {
      ...props,
      app_environment: AppEnvironment.buildMode,
      app_version: appVersion[AppEnvironment.buildMode]
    };
    mixpanel.time_event(name);
    mixpanel.track(name, allProps);
  },

  timeEvent: (name) => {
    mixpanel.time_event(name);
  },

  user: {
    set: (key, value) => {
      mixpanel.people.set(`$${key}`, value);
    },
  },

  setPeople: (key, value) => {
    mixpanel.people.set(`$${key}`, value);
  },

  increment: (object = {}) => {
    mixpanel.people.increment(object);
  },
};

export const Mixpanel = actions;
