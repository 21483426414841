import React, { useState } from "react";
// import { MdClose } from 'react-icons/md';
// import { CDN_URL } from '../../../utils/constants';
// import { useKeydown } from '../../../utils/hooks';
// import { OverlayStyle } from '../../../utils/styles';
// import { GroupMessageType, MessageType } from '../../../utils/types';
import ImageGallery from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MessageAttachmentPreview from "./MessageAttachmentPreview";
import MessageItemAttachment from "./MessageItemAttachment";

export const MessageItemAttachmentContainer = ({ message }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const onClick = (url) => {
    setShowOverlay(true);
    setImageUrl(url);
  };

  // const handleKeydown = (e) => e.key === "Escape" && setShowOverlay(false);
  // useKeydown(handleKeydown);

  return (
    <>
      {showOverlay && (
        <ImageGallery
          mainSrc={imageUrl}
          onCloseRequest={() => setShowOverlay(false)}
        />
      )}

      <div>
        {message.attachments?.map((attachment) => {
          if (!attachment.url) return null;

          return (
            <MessageItemAttachment
              key={attachment.key}
              attachment={attachment}
              onClick={onClick}
            />
          );
        })}
      </div>
    </>
  );
};
