import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { TextField, InputLabel, Select, MenuItem, Button, Grid, FormHelperText, makeStyles } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import moment from "moment";
import { updateScheduleActivity } from "../../store/actions/Schedule";

import { getColor, getColorForSchedule, extractColorFromSchdule, timeDropDownSetting, getTimeDropdownOptions, compareTimes, getTimeLabelByValue, getTimeValueByLabel, isValidTimeFormat, isValidTime, getTimeDifferenceInMinutes } from "../../helpers/GenericHelper";
import { ActivityColor, SCHEDULE_TYPE, CALENDAR_VIEW, CALENDER, REMINDER_TIME } from "../../constants/GenericConstants";
import momentTz from 'moment-timezone';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import { momentLocalizer } from "react-big-calendar";
import DatePicker from "react-datepicker";
import { RRule, RRuleSet, rrulestr } from 'rrule'

const timeZone = momentTz.tz.guess()
const localizer = momentLocalizer(momentTz);

const useStyles = makeStyles((theme) => ({
    select: {
        "& ul": {
            height: 110,
            paddingTop: 0,
        },
    },
    MonthSelect: {
        "& ul": {
            height: 75,
            paddingTop: 0,
        },
    }
}));

const ActivityPreviewPanel = ({
    activityColorList, editAccess, previewScheduledActivity, previewActivity, previewActivityDate, onActivityUpdateSuccess, onActivityUpdateFailure, exitPreviewMode, selectedDate
}) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [startEndTimeError, setStartEndTimeError] = useState("");
    const [startTimeError, setStartTimeError] = useState("");
    const [endTimeError, setEndTimeError] = useState("");
    const [daysError, setDaysError] = useState("");
    const [selectDaysArray, setSelectDaysArray] = useState([]);
    const [recurrence, setRecurrence] = useState("");
    const [sendReminder, setSendReminder] = useState(false);
    const [reminderBefore, setReminderBefore] = useState(5);
    
    // custom recurrence setting
    const [count, setCount] = useState(13);
    const [interval, setInterval] = useState(1);
    const [until, setUntil] = useState();
    const [frequency, setFrequency] = useState(CALENDER.DAY);
    const [dayNumber, setDayNumber] = useState(1); // dayNumber=2 that is second tuesday of month
    const [customeRepeatEndOption, setCustomeRepeatEndOption] = useState("never"); // never, on and after
    const [showCustomRecurrence, setShowCustomeRecurrence] = useState(false); // check to show hide custom setting
    const [customRecuurenceSetting, setCustomRecuurenceSetting] = useState({}); // save custom object
    const [showCustomMonthSetting, setShowCustomMonthSetting] = useState(false);
    const [customMonthSetting, setCustomMonthSetting] = useState(CALENDER.MONTHLY_BY_DATE);

    const [activityColors, setActivityColors] = useState(activityColorList);
    const [activityColorsError, setactivityColorsError] = useState('');

    useEffect(() => {
      initView(previewScheduledActivity);
    }, [previewScheduledActivity])
    
    const initView = (activity) => {

        setRecurrence(activity.scheduleType);
        setCustomMonthSettingValue(CALENDER.MONTHLY_BY_DATE);
        setUntil(momentTz(previewActivityDate, moment.defaultFormat).toDate());
        setSendReminder(activity.sendReminder);

        // to set occurence number of day of month e.g second tuesday of month
        // const date = momentTz(previewActivityDate);
        // const firstDay = momentTz(date.format('YYYY'), date.month(), 1);
        // const diff = date.day() < firstDay.day() ? 1 : 0;
        // const weekOfMonth = ((0 | date.date() / 7)+1) - diff;

        const date = momentTz(previewActivityDate); //saturday, the next one
        const weekOfMonth = Math.ceil(date.date() / 7)
        setDayNumber(weekOfMonth);

        if (activity.reminderBefore) { setReminderBefore(activity.reminderBefore); }

        // setting selected days control data
        if (activity.scheduleType === SCHEDULE_TYPE.CUSTOM) {

            
            setShowCustomeRecurrence(true);
            setCustomSettingInitialValues(activity.recurrenceValue);

        } else {
            setShowCustomeRecurrence(false);
            const dArray = getAllDaysArray().map((item) => {
                let dayLabel = item.label.toUpperCase().substring(0, 2); // SA, SU, MO
                return {...item, label: dayLabel, selected: false };
            });
            setSelectDaysArray(dArray);
        }

        setStartTime(getTimeLabelByValue(activity.startTime));
        setEndTime(getTimeLabelByValue(activity.endTime));

        const colorCode = extractColorFromSchdule(activity.activityColor);
        const colorIndex = activityColors.findIndex((activityColor) => activityColor.value == colorCode);

        selectColor(colorIndex);
        
        // dispatch(getActivity(event.activity.activityId, previewActivityLoaded));
    }

    const setCustomSettingInitialValues = (customOptions) => {
        const CustomSetting = rrulestr(customOptions).options;
        setInterval(CustomSetting.interval ? CustomSetting.interval : 1);

        switch(CustomSetting.freq) {
            case 0:
                setFrequency(CALENDER.YEAR);
                break;
            case 1:
                setFrequency(CALENDER.MONTH);
                setShowCustomMonthSetting(true);
                break;
            case 2:
                setFrequency(CALENDER.WEEK);
                break;
            default:
                setFrequency(CALENDER.DAY);
        }

        if (CustomSetting.until) {
            let un = CustomSetting.until;
            un.setDate(un.getDate() -1)
            setUntil(un);
            setCustomRepeatEndOptionValue("on");
        }

        if (CustomSetting.count) {
            setCount(CustomSetting.count);
            setCustomRepeatEndOptionValue("after");
        }

        if (CustomSetting.bysetpos) {
            setCustomMonthSetting(CALENDER.MONTHLY_BY_DAY);
        }

        const newArray = getAllDaysArray().map((item) => {
            let dayLabel = item.label.toUpperCase().substring(0, 2); // SA, SU, MO
            let isSelectedDay = CustomSetting.byweekday?.includes(item.value) ? true : false;
            return {...item, label: dayLabel, selected:isSelectedDay};
        });
        setSelectDaysArray(newArray);
        selectCurrentDay(newArray);


    }

    const handleStartTimeDropDownChange = (event, value) => {
        value = value.substring(0,8);
        if (!event) return;
        setStartTime(value);
        setStartTimeError("");
        setStartEndTimeError("");
    }

    const handleStartTimeChange = (event, value) => {
        value = value.substring(0,8);
        if (!event) return;
        setStartTime(value);
        
        const time = addOneHourInTime(value);
        handleEndTimeChange(time, time);

        setStartEndTimeError("");
        if (value.length === 0) {
            setStartTimeError(t("requiredField"));
        } else {
            if (isValidTimeFormat(value)) {
                setStartTimeError("");
            } else {
                setStartTimeError(t("invalidTimeError"));
            }
        }
    }

    const addOneHourInTime = (value) => {
        const time = value.split(":");

        let hours = parseInt(time[0]);
        let mins = time[1].split(" ")[0];
        let amPm = time[1].split(" ")[1];

        // If hour is 12 or greater than reset to 1 - 12hr Clock
        if (hours === 12 || hours >= 12) {
            hours =  1;
            amPm = (amPm === "PM") ? "AM" : "PM";
        } else {
            hours = parseInt(time[0]) + 1;
        }

        if (hours < 10) { hours = `0${hours}` }

        return `${hours}:${mins} ${amPm}`;
    }

    const handleEndTimeChange = (event, value) => {
        value = value.substring(0,8);
        if (!event) return;
        setEndTime(value);
        setStartEndTimeError("");
        if (value.length === 0) {
            setEndTimeError(t("requiredField"));
        } else {
            if (isValidTimeFormat(value)) {
                setEndTimeError("");
            } else {
                setEndTimeError(t("invalidTimeError"));
            }
        }
    }

    const handleEndTimeDropDownChange = (event, value) => {
        value = value.substring(0,8);
        if (!event) return;
        setEndTime(value);
        setEndTimeError("");
        setStartEndTimeError("");
    }

    const sortSubtasks = (subTasksList) => {
        return subTasksList.sort((task1, task2) => (task1.order > task2.order) ? 1 : -1);
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const selectDay = (index) => {
        const newArray = [...selectDaysArray];
        newArray[index].selected = !newArray[index].selected;
        setSelectDaysArray(newArray);
        validateSelectDays(newArray);
    }

    const validateSelectDays = (selectDaysArray) => {
        if (frequency === CALENDER.WEEK) {
            for (let i = 0; i < selectDaysArray.length; i++) {
                if (selectDaysArray[i].selected) {
                    setDaysError("");
                    return true;
                }
            }
            setDaysError(t("selectDaysError"));
            return false;
        } else {
            setDaysError("");
            return true;
        }
    }

    const selectColor = (index) => {
        const colorsArray = activityColors.map((color, currentIndex) => {
            let selected = false;
            if (currentIndex === index) {
                selected = true;
            }
            return {
                ...color,
                selected: selected,
            };
        });
        setActivityColors(colorsArray);
        setactivityColorsError('');
    }

    const updatePreviewActivity = () => {

        if (!validateColor(activityColors))  return;
        
        if (isValidTimeFormat(startTime) && isValidTimeFormat(endTime)) {
            
            const startTimeValid = isValidTime(startTime);
            const endTimeValid = isValidTime(endTime);
            
            if (startTimeValid && endTimeValid) {
                console.log({startTime: getTimeValueByLabel(startTime), endTime: getTimeValueByLabel(endTime)})

                let comparison = compareTimes(getTimeValueByLabel(startTime), getTimeValueByLabel(endTime));

                if (comparison === 0) {
                    setStartEndTimeError(t("startEndTimeSameError"));
                } else if (comparison === -1) {
                    setStartEndTimeError(t("endTimeLessError"));
                } else {
                    setStartEndTimeError("");

                    const minutesDiff = getTimeDifferenceInMinutes(startTime, endTime);
                    if (minutesDiff < 1) {
                        setStartEndTimeError(t("activityLengthError"));
                    } else {
                        setStartEndTimeError("");
                        if (validateSelectDays(selectDaysArray)) {

                            let startTime12Hours = getTimeValueByLabel(startTime);
                            let endTime12Hours = getTimeValueByLabel(endTime)
                            if (endTime === '12:00 AM') {
                                endTime12Hours = '23:59:59';
                            }   
                            
                            let payload = {
                                "id": previewScheduledActivity.id,
                                "activityColor": getColorForSchedule(activityColors.filter((color) => color.selected)[0].value),
                                "startDate": previewActivityDate,// previewScheduledActivity.startDate,
                                "startTime": startTime12Hours,
                                "endTime": endTime12Hours,
                                "scheduleType": recurrence,
                                "sendReminder": sendReminder,
                                "reminderBefore": sendReminder ? reminderBefore : null
                            };
                            if (recurrence === SCHEDULE_TYPE.CUSTOM) {
                                const obj = saveCustomRecurrenceSetting();
                                // payload.scheduleDay = selectDaysArray.filter((day) => day.selected).map((day) => day.label);
                                console.log(obj)
                                payload = {...payload, recurrenceValue: new RRule(obj).toString()}
                            }
                            dispatch(updateScheduleActivity(payload, onActivityUpdateSuccess, onActivityUpdateFailure));
                        }
                    }
                }
            } else {
                if (!startTimeValid) {
                    setStartTimeError(t("invalidTime"));
                }
                if (!endTimeValid) {
                    setEndTimeError(t("invalidTime"));
                }
            }
        }
    }

    const handleRecurrenceChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setRecurrence(input);
        setShowCustomeRecurrence(input === SCHEDULE_TYPE.CUSTOM ? true : false);
        selectCurrentDay(selectDaysArray);
    }

    const getAllDaysArray = () => {
        const daysArray = [
            { label: t("sunday"), value: 6 },
            { label: t("monday"), value: 0 },
            { label: t("tuesday"), value: 1 },
            { label: t("wednesday"), value: 2 },
            { label: t("thursday"), value: 3 },
            { label: t("friday"), value: 4 },
            { label: t("saturday"), value: 5 }
        ];

        return daysArray;
    }

    // selected date Day name handling for "repeat on" dropdown
    const getSelectedDayName = () => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = momentTz(previewActivityDate);
        return days[date.day()];
    }

    // selected date Month name handling for "repeat on" dropdown
    const getSelectedMonthName = () => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        const date = momentTz(previewActivityDate);
        return monthNames[date.month()];
    }

    const setCustomRepeatEndOptionValue = (event) => {
        setCustomeRepeatEndOption(event);
        if (event !== "on") { setUntil(momentTz(previewActivityDate, moment.defaultFormat).toDate()); }
        if (event !== "after") { setCount(13); }
    }

    // return custom setting obj containing all selected attributes
    const saveCustomRecurrenceSetting = () => {
        const currDate = momentTz(selectedDate, moment.defaultFormat).toDate();

        const selectedDays = selectDaysArray.filter(day => day.selected);
        
        let obj = {
            freq: getRRuleFrequency(frequency),
            interval: parseInt(interval),
            dtstart: currDate
        };

        if (frequency === CALENDER.MONTH) { 
            obj = {...obj, ...customRecuurenceSetting}}

        if (customeRepeatEndOption === "on") {
            var un = until;
            un.setDate(un.getDate() + 1);
            obj = { ...obj, until: un.toISOString() }
        }
        if (customeRepeatEndOption === "after") { obj = {...obj, count: count} }
        if (selectedDays.length && 
            (customMonthSetting === CALENDER.MONTHLY_BY_DAY || frequency === CALENDER.WEEK)) { 
                obj = {...obj, byweekday: getSelectedDays(selectedDays)}}

        setCustomRecuurenceSetting(obj);
        return obj;
    }

    // calender view conversion as per RRule Library
    const getRRuleFrequency = (type) => {
        switch(type){
            case CALENDER.WEEK:
                return RRule.WEEKLY;
            case CALENDER.MONTH:
                return RRule.MONTHLY;
            case CALENDER.YEAR:
                return RRule.YEARLY;
            default:
                return RRule.DAILY
        }
    } 

    // calender days conversion as per RRule Library
    const getSelectedDays = (days) => {
        const selectedDaysList = [];
        days.forEach(day => {
            switch(day.label){
                case 'SU':
                    selectedDaysList.push(RRule.SU);
                    break;
                case 'MO':
                    selectedDaysList.push(RRule.MO);
                    break;
                case 'TU':
                    selectedDaysList.push(RRule.TU);
                    break;
                case 'WE':
                    selectedDaysList.push(RRule.WE);
                    break;
                case 'TH':
                    selectedDaysList.push(RRule.TH);
                    break;
                case 'FR':
                    selectedDaysList.push(RRule.FR); 
                    break;               
                default:
                    selectedDaysList.push(RRule.SA);
            }
        });
        return selectedDaysList;
    }

    // calender Month conversion as per RRule Library
    const getWeekOfMonth = () => {
        const dayNumberString = ['first', 'second', 'third', 'fourth', 'fifth'];
        return dayNumberString[dayNumber > 0 ? (dayNumber-1) : dayNumber];
    }

    // set selected date, day name(e.g monday, tuesday) selected
    const selectCurrentDay = (selectDaysArray) => {
        const newArray = [...selectDaysArray];
        const dayIndex = momentTz(previewActivityDate).day();
        newArray[dayIndex].selected = true;
        setSelectDaysArray(newArray);
    }

    // custom recurrence monthly dropdowm setting 
    const setCustomMonthSettingValue = (event) => {
        setCustomMonthSetting(event);
        let obj = { bymonthday: momentTz(previewActivityDate).date() };
        if (event === CALENDER.MONTHLY_BY_DAY) { obj = { bysetpos: dayNumber }; } // number of isTuesday.
        setCustomRecuurenceSetting(obj);
    }

    // set frequency e.g (every day, month, year etc)
    const setFrequencyValue = (event) => {
        let isMonth = false;

        if (event === CALENDER.MONTH){
            isMonth = true;
            selectCurrentDay(selectDaysArray.map(day => {return {...day, selected: false} }));
        }
        setFrequency(event);
        setShowCustomMonthSetting(isMonth)
    }

    const validateColor = (colorsArray) => {
        const selectedColor = colorsArray.filter((color) => color.selected);
        if (selectedColor.length > 0) {
            setactivityColorsError("");
            return true;
        } else {
            setactivityColorsError(t("activityColorRequiredError"));
            return false;
        }
    }

    return <div>
        <h2 className="d-flex align-items-center justify-content-between mt-0 fw-500">
            {previewActivity.name}
            <span class="sa-closeIcon"><CloseRoundedIcon onClick={exitPreviewMode} /></span>
        </h2>
        <h3>{t("activityTasks")}</h3>
        <div className={previewActivity.subTasks.length == 0 ? "activity-task no-record" : previewActivity.subTasks.length == 1 ? "activity-task record-one" : "activity-task"}>
            {
                previewActivity.subTasks.length > 0 ?
                    <>
                        {
                            sortSubtasks(previewActivity.subTasks).map((subTask, index) => {
                                return <div>
                                    <img src={subTask.imageURL} alt={subTask.name} />
                                    <span>{subTask.name}</span>
                                </div>;
                            })
                        }
                    </> :
                    <div>
                        <span>{t("noSubtasksAdded")}</span>
                    </div>
            }
        </div>

        <div className="selectRow">
            {/* START TIME DROPDOWN STARTED*/}
            <div className="startTime">
                <InputLabel id="startTime">{t("startTime")}</InputLabel>
                <Autocomplete
                    id="startTimeSelect"
                    labelId="startTime"
                    style={{ width: 300 }}
                    disabled={!editAccess}
                    name="startTimeSelect"
                    required
                    disableClearable
                    value={startTime}
                    onChange={handleStartTimeDropDownChange}
                    onInputChange={handleStartTimeChange}
                    freeSolo
                    options={getTimeDropdownOptions().map((timeObj) => timeObj.label)} // 
                    renderInput={(params) => (
                        <TextField {...params} margin="normal" />
                    )}
                />
                {
                    showInputError(startTimeError) && <FormHelperText error={showInputError(startTimeError)}>{startTimeError}</FormHelperText>
                }
            </div>
            <span className="timeSep"></span>
            {/* START TIME DROPDOWN ENDED*/}

            {/* END TIME DROPDOWN STARTED*/}
            <div className="endTime">
                <InputLabel id="endTime">{t("endTime")}</InputLabel>
                <Autocomplete
                    id="endTimeSelect"
                    labelId="endTime"
                    style={{ width: 300 }}
                    disabled={!editAccess}
                    name="endTimeSelect"
                    required
                    disableClearable
                    value={endTime}
                    onChange={handleEndTimeDropDownChange}
                    onInputChange={handleEndTimeChange}
                    freeSolo
                    options={timeDropDownSetting(startTime).map((timeObj) => timeObj.label)}
                    renderInput={(params) => (
                        <TextField {...params} margin="normal" />
                    )}
                />
                {
                    showInputError(endTimeError) && <FormHelperText error={showInputError(endTimeError)}>{endTimeError}</FormHelperText>
                }
            </div>
            {/* END TIME DROPDOWN ENDED*/}
        </div>
        {
            showInputError(startEndTimeError) && <FormHelperText error={showInputError(startEndTimeError)}>{startEndTimeError}</FormHelperText>
        }

        {/* REPEAT ON STARTED*/}
        <div className="selectRow2">
            <InputLabel id="repeat">{t("repeatOn")}</InputLabel>
            <Select disabled={!editAccess} labelId="repeat" id="repeatSelect" name="repeatSelect" required value={recurrence} onChange={handleRecurrenceChange}>
                <MenuItem key={SCHEDULE_TYPE.ONE_TIME} value={SCHEDULE_TYPE.ONE_TIME}>{t("dontRepeat")}</MenuItem>
                <MenuItem key={SCHEDULE_TYPE.DAILY} value={SCHEDULE_TYPE.DAILY}>{t("daily")}</MenuItem>
                <MenuItem key={SCHEDULE_TYPE.WEEKLY} value={SCHEDULE_TYPE.WEEKLY}>{`${t("weeklyOn")} ${getSelectedDayName()}`}</MenuItem>
                <MenuItem key={SCHEDULE_TYPE.MONTHLY} value={SCHEDULE_TYPE.MONTHLY}>{`${t("monthlyOnThe")} ${getWeekOfMonth()} ${getSelectedDayName()}`}</MenuItem>
                <MenuItem key={SCHEDULE_TYPE.ANNUALLY} value={SCHEDULE_TYPE.ANNUALLY}>{`${t("annuallyOn")} ${getSelectedMonthName()} ${momentTz(previewActivityDate).date()}`}</MenuItem>
                <MenuItem key={SCHEDULE_TYPE.WEEKDAY} value={SCHEDULE_TYPE.WEEKDAY}>{t("weekday")}</MenuItem>
                <MenuItem key={SCHEDULE_TYPE.CUSTOM} value={SCHEDULE_TYPE.CUSTOM}>{t("custom")}</MenuItem>
            </Select>
        </div>
        {/* REPEAT ON ENDED*/}

        {/* REMINDER SETTING STARTED*/}
        <div className="selectRowCheckbox" >
            <Checkbox
                color="primary"
                className="check1"
                disabled={!editAccess}
                checked={sendReminder}
                onChange={(e) => {
                    setSendReminder(e.target.checked)
                }}
            />
            <InputLabel id="repeat">{t("reminderCheckText")}</InputLabel>
            
            { sendReminder && 
                <Select disabled={!editAccess} labelId="repeatTime" id="repeatSelect" name="repeatTimeSelect" 
                    required value={reminderBefore} 
                    onChange={event => setReminderBefore(event.target.value)}
                    MenuProps={{ classes: { paper: classes.select } }}>
                    <MenuItem key={REMINDER_TIME.MINUTES_5} value={5}>{t("5minutes")}</MenuItem>
                    <MenuItem key={REMINDER_TIME.MINUTES_10} value={10}>{t("10minutes")}</MenuItem>
                    <MenuItem key={REMINDER_TIME.MINUTES_15} value={15}>{t("15minutes")}</MenuItem>
                </Select>
            }
        </div>
        {/* REMINDER SETTING ENDED*/}

        {/* CUSTOM RECURRENCE SETTING STARTED*/}
        {
            showCustomRecurrence &&
            <div className="r-select-days">
                <InputLabel id="customRecuurenceLable">{t("customRecurrence")}</InputLabel>

                {/* REPEAT EVERY STARTED*/}
                <InputLabel id="repeatEveryLabel">{t("repeatEvery")}
                    <input type="number" min="1" value={interval} onChange={event => setInterval(event.target.value)}></input>
                    <Select disabled={!editAccess} labelId="repeat" id="repeatSelect" name="repeatSelect" required 
                        value={frequency} onChange={event => setFrequencyValue(event.target.value)}>
                        <MenuItem key={CALENDER.DAY} value={CALENDER.DAY}>{ interval > 1 ? t("days") : t("day")}</MenuItem>
                        <MenuItem key={CALENDER.WEEK} value={CALENDER.WEEK}>{interval > 1 ? t("weeks") : t("week")}</MenuItem>
                        <MenuItem key={CALENDER.MONTH} value={CALENDER.MONTH}>{interval > 1 ? t("months") : t("month")}</MenuItem>
                        <MenuItem key={CALENDER.YEAR} value={CALENDER.YEAR}>{interval > 1 ? t("years") : t("year")}</MenuItem>
                    </Select>
                </InputLabel>
                {/* REPEAT EVERY ENDED*/}

                { 
                    showCustomMonthSetting && 
                    <div className="mb-15">
                    <Select disabled={!editAccess} labelId="monthView" id="monthViewSelect" name="monthViewSelect" required 
                        value={customMonthSetting} 
                        onChange={event => setCustomMonthSettingValue(event.target.value)}
                        MenuProps={{ classes: { paper: classes.MonthSelect } }}>
                        <MenuItem key={CALENDER.MONTHLY_BY_DATE} value={CALENDER.MONTHLY_BY_DATE}>{`${t("monthlyOnDay")} ${momentTz(previewActivityDate).date()}`}</MenuItem>
                        <MenuItem key={CALENDER.MONTHLY_BY_DAY} value={CALENDER.MONTHLY_BY_DAY}>{`${t("monthlyOnThe")} ${getWeekOfMonth()} ${getSelectedDayName()}`}</MenuItem>
                    </Select>
                    </div>
                }
                
                {
                    (frequency === CALENDER.WEEK) &&
                    <div>
                        {/* REPEAT ON STARTED*/}
                        <InputLabel id="selectDaysLabel">{t("repeatOn")}</InputLabel>
                        <div className="selectDays">
                            {
                                selectDaysArray.map((dayObj, index) => {
                                    return dayObj.selected ?
                                        <div key={dayObj.label} style={{ backgroundColor: '#02c8a7', color: 'white' }} onClick={() => selectDay(index)}>
                                            <span>{dayObj.label[0]}</span>
                                        </div> :
                                        <div key={dayObj.label} style={{ backgroundColor: '#e0e0e0', color: '#9e9e9e' }} onClick={() => selectDay(index)}>
                                            <span>{dayObj.label[0]}</span>
                                        </div>
                                })
                            }
                        </div>
                        {
                            showInputError(daysError) && <FormHelperText error={showInputError(daysError)}>{daysError}</FormHelperText>
                        }
                        {/* REPEAT ON ENDED*/}
                    </div>
                }

                {/* END SETTING STARTED*/}
                <InputLabel id="endLabel">{t("end")}</InputLabel>
                <InputLabel id="neverLabel">
                    <input type="radio" name="end" id="never"
                    value={customeRepeatEndOption}
                    checked={customeRepeatEndOption === "never"}
                    onClick={event => setCustomRepeatEndOptionValue("never")}></input>
                    {t("never")}
                </InputLabel>

                <InputLabel id="onLabel">
                    <input type="radio" name="end" id="on"
                        value={customeRepeatEndOption}
                        checked={customeRepeatEndOption === "on"}
                        onClick={event => setCustomRepeatEndOptionValue("on")}>
                    </input>
                    {t("on")}
                    <DatePicker 
                        selected={until} 
                        onChange={date => setUntil(date)} 
                        disabled={customeRepeatEndOption === "on" ? false : true}/>
                </InputLabel>

                <InputLabel id="afterLabel">
                    <input type="radio" name="end" id="after"
                        value={customeRepeatEndOption}
                        checked={customeRepeatEndOption === "after"}
                        onClick={event => setCustomRepeatEndOptionValue("after")}>
                    </input>
                    {t("after")}
                    <input type="number" min="1" value={count} 
                        onChange={event => setCount(event.target.value)} 
                        disabled={customeRepeatEndOption === "after" ? false : true}>
                    </input>
                    <label>{t("occurrences")}</label>
                </InputLabel>
                {/* END SETTING ENDED*/}

                {/* CUSTOM SETTING BUTTONS STARTED*/}
                {/* <div className="button-row button-text">
                    <Button onClick={event => resetCustomEndOperators(true)}>{t("cancel")}</Button>
                </div> */}
                {/* CUSTOM SETTING BUTTONS ENDED*/}
            </div>
        }

        {/* CHANGE ACTIVITY COLOR STARTED*/}
        <div className="selectRow3">
            <span>{t("changeActivityColor")}</span>
            <div className="colorChanger">
                {
                    activityColors.map((color, index) => {
                        if (color.selected) {
                            return <div className={!editAccess ? 'Mui-disabled cc-oval-selected' : "cc-oval-selected"} style={{ backgroundColor: color.value }} onClick={() => editAccess && selectColor(index)}>
                                <span></span>
                            </div>;
                        }
                        return <div className={!editAccess ? 'Mui-disabled noColorChange cc-oval' : "cc-oval"} style={{ backgroundColor: color.value }} onClick={() => editAccess && selectColor(index)}></div>;
                    })
                }
                {
                    showInputError(activityColorsError) && <FormHelperText error={showInputError(activityColorsError)}>{activityColorsError}</FormHelperText>
                }
            </div>
        </div>
        {/* CHANGE ACTIVITY COLOR STARTED*/}

        {/* UPDATE ACTIVITY BUTTON*/}
        <div className="button-row"><Button disabled={!editAccess} onClick={updatePreviewActivity}>{t("updateActivity")}</Button> </div>
    </div>
}

export default ActivityPreviewPanel;