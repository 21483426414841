
import { LOCAL_STORAGE_KEYS } from '../constants/GenericConstants';
import { PERMISSIONS } from '../constants/PermissionConstants';
import { USER_PRIVILEGES } from '../constants/StaticData';


export function calculatePermissionOfView(status, object) {

    var value = 0;
    switch (object.id) {

        case 1:
            value = PERMISSIONS.ADMIN_VIEW
            break;

        case 2:
            value = PERMISSIONS.ACTIVITY_VIEW
            break;

        case 3:
            value = PERMISSIONS.SCHEDULE_VIEW
            break;
        case 4:
            value = PERMISSIONS.EMO_REGULATION_VIEW

            break;

        case 5:
            value = PERMISSIONS.CHILD_VIEW

            break;

        default:

            break;
    }

    console.log("value return view", value);
    return value;

}

export function calculatePermissionOfEdit(status, object) {

    var value = 0;
    switch (object.id) {

        case 1:
            value = PERMISSIONS.ADMIN_CREATE;
            break;

        case 2:
            value = PERMISSIONS.ACTIVITY_CREATE

            break;

        case 3:
            value = PERMISSIONS.SCHEDULE_CREATE;
            break;
        case 4:
            value = PERMISSIONS.EMO_REGULATION_CREATE
            break;

        case 5:
            value = PERMISSIONS.CHILD_CREATE
            break;

        default:

            break;
    }

    console.log("value return", value);

    return value;

}


export function getPermissionOfView(permission, object) {

    var value = false;
    var result;

    switch (object.id) {

        case 1:
            result = permission | PERMISSIONS.ADMIN_VIEW;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        case 2:
            result = permission | PERMISSIONS.ACTIVITY_VIEW;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        case 3:
            result = permission | PERMISSIONS.SCHEDULE_VIEW;
            if (result == 0 || result == permission) {
                value = true
            }
            break;
        case 4:
            result = permission | PERMISSIONS.EMO_REGULATION_VIEW;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        case 5:
            result = permission | PERMISSIONS.CHILD_VIEW;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        default:

            break;
    }

    return value;

}


export function getPermissionOfEdit(permission, object) {

    var value = false;
    var result;
    switch (object.id) {

        case 1:
            result = permission | PERMISSIONS.ADMIN_CREATE;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        case 2:
            result = permission | PERMISSIONS.ACTIVITY_CREATE;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        case 3:
            result = permission | PERMISSIONS.SCHEDULE_CREATE;
            if (result == 0 || result == permission) {
                value = true
            }
            break;
        case 4:
            result = permission | PERMISSIONS.EMO_REGULATION_CREATE;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        case 5:
            result = permission | PERMISSIONS.CHILD_CREATE;
            if (result == 0 || result == permission) {
                value = true
            }
            break;

        default:

            break;
    }
    return value;

}


export function getEditPermValue(PRIV_TYPE) {
    const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) : null
    var permissionGot = 0;
    if (user !== null) {
        permissionGot = user.accounts[0].permissions
    }
    let userEditPerm = getPermissionOfEdit(permissionGot, USER_PRIVILEGES[PRIV_TYPE])
    return userEditPerm;
}

export function getViewPermValue(PRIV_TYPE) {
    const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) : null
    var permissionGot = 0;
    if (user !== null) {
        permissionGot = user.accounts[0].permissions
    }

    let userViewPerm = getPermissionOfView(permissionGot, USER_PRIVILEGES[PRIV_TYPE])
    return userViewPerm;
}

export function getEditPermValueIndividual(permissionGot, PRIV_TYPE) {

    let userEditPerm = getPermissionOfEdit(permissionGot, USER_PRIVILEGES[PRIV_TYPE])
    return userEditPerm;
}

export function getViewPermValueIndividual(permissionGot, PRIV_TYPE) {

    let userViewPerm = getPermissionOfView(permissionGot, USER_PRIVILEGES[PRIV_TYPE])
    return userViewPerm;
}