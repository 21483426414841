export default {
  SIGN_UP: "/auth/register",
  GET_ALL_COUNTRIES: "/auth/countries",
  GET_COUNTRY_BY_CODE: "/auth/getCountryByIsoCode?code={countryCode}",
  GET_STATES_OF_COUNTRY: "/auth/getStateByIsoCode?code={countryCode}",
  GET_CITIES_OF_STATE: "/auth/getCities?countryCode={countryCode}&stateCode={stateCode}",
  LOGIN: "/auth/login",
  FIRST_LOGIN: "/auth/firstLogging",
  LOGOUT: "/auth/logout",

  HEAR_ABOUT_US: "/user/hearAboutUs",
  VIEW_USER: "/user/userProfile",
  EDIT_USER: "/user/userProfile",
  CONFIRM_EMAIL: "/auth/confirmEmail",
  RESET_PASSWORD: "/auth/resetPassword",
  FORGOT_PASSWORD: "/auth/forgotPassword",
  RESET_FORGOTTEN_PASSWORD: "/auth/setNewPassword",
  VERIFY_FORGOTTEN_CODE: "/auth/verifyForgotPasswordCode",
  RESEND_CONFIRMATION_EMAIL: "/auth/resendConfirmation",
  GET_SUBSCRIPTION_LIST: "/subscription",
  CREATE_PAYMENT_SESSION: "/subscription",
  CANCEL_SUBSCRIPTION: "/subscription/cancel",
  UPDATE_PAYMENT_CARD: "/subscription/card",
  RESUBSCRIBE_PAYMENT: "/subscription/resubscribe/{planId}",

  CHILD_UNSYNC: "/auth/childUnsync",
  CHILD: "/child",
  VIEW_CHILD: "/child/{childId}",
  DELETE_CHILD: "/child/{childId}",
  GET_ACT_TRACKING: "/activity/schedule/getActivityProgress?startDate={startDate}",
  REGENERATE_CHILD_CODE: "/child/regenerateCode",


  GET_PRESIGNED_URL: "/image/",

  GET_ROLES_LIST: "/user/roles?type=3",
  ADD_EX_USER: "/user/external/invite",
  GET_EX_USER_LIST: "/user/external",
  VIEW_EX_USER: "/user/external/",
  EDIT_EX_USER: "/user/external",
  DELETE_EX_USER: "/user/external/{userId}",
  USER_GENDER: "/user/gender",

  CONFIRM_EXTERNAL_USER: "/user/external/confirmEmail",
  GET_EMAIL_FROM_INVITE_CODE: "/user/external/getEmailAddress",

  UPDATE_EXTERNAL_USER_STATUS: "/user/external/updateStatus",

  GET_IMAGE_CATEGORIES: "/image/library/categories",
  GET_IMAGE_SKINTONES: "/image/library/skin-tone",
  GET_IMAGE_LIBRARY: "/image/library?childId={childId}",
  ADD_IMAGE_IN_IMAGE_LIBRARY: "/image/library",

  ADD_ACTIVITY: "/activity",
  GET_ACTIVITY_LIST: "/activity/allActivities?childId={childId}",
  GET_ACTIVITY: "/activity/?id={activityId}",
  ARCHIVE_ACTIVITY: "/activity/activityArchive",
  SEARCH_SCHEDULED_ACTIVITIES: "/activity/schedule?childId={childId}&startDate={startDate}&type={type}&q={search}",
  GET_META_DATA: "/activity/meta_data?key=activityColor",

  SCHEDULE_ACTIVITY: "/activity/schedule",
  GET_SCHEDULE_LIST: "/activity/schedule?childId={childId}&startDate={startDate}&type={type}",
  DELETE_SCHEDULE_ACTIVITY: "/activity/schedule/{scheduleId}",

  ADD_SELF_REGULATION_TOOL: "/selfRegulation",
  SELF_REGULATION_LISTING: "/selfRegulation/allSelfRegulation?childId={childId}&isArchive={isArchive}",
  ARCHIVE_TOOL: "/selfRegulation/activityArchive",
  GET_SELF_REGULATION_TOOL: "/selfRegulation/?id={toolId}&childId={childId}",

  GET_NOTIFICATIONS: "/notification?page={page}",
  REGISTER_DEVICE_FOR_NOTIFICATIONS: "/auth/registerDevice",
  UNREGISTER_DEVICE_FOR_NOTIFICATIONS: "/auth/unRegisterDevice",
  MARK_NOTIFICATION_AS_READ: "/notification/{id}/read",
  MARK_ALL_READ: "/notification/read",

  CREATE_CONVERSATION: "/conversations",
  CREATE_CONVERSATION_V1: "/conversations/v1",
  DELETE_CONVERSATION: '/conversations/{conversationId}',
  GET_ALL_CONVERSATIONS: "/conversations",
  CREATE_NEW_MESSAGE: "/conversations/{conversationId}/messages",
  GET_CONVERSATION_MESSAGES: "/conversations/{conversationId}/messages",
  CONVERSATION_MARKED_READ: "/conversations/read/{conversationId}",
  CREATE_GROUP: "/groups",
  DELETE_GROUP: '/groups/{groupId}',
  GET_ALL_GROUPS: "/groups",
  FETCH_GROUP_BY_ID: "/groups/{groupId}",
  FETCH_GROUP_MESSAGES: "/groups/{groupId}/messages",
  POST_GROUP_MESSAGE: "/groups/{groupId}/messages",
  ADD_GROUP_RECIPIENT: "/groups/{groupId}/recipients",
  REMOVE_GROUP_RECIPIENT: "/groups/{groupId}/recipients/{userId}",
  LEAVE_GROUP: "/groups/{groupId}/recipients/leave",
  GROUP_MARKED_READ: "/groups/read/{groupId}",
  FETCH_RECIPIENT: "/conversations/recipients",
  FETCH_RECIPIENT_BY_ID: "/conversations/recipient/{recipientId}",
};
