import React from "react";
import { Redirect } from "react-router-dom";

import RouteConstants from "../../constants/RouteConstants";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import ChatScreen from "./ChatScreen";

const MainChatScreen = () => {
  const { chat } = useFeatureFlags();
  // if we have a chat object with some value set the respective value otherwise set to true
  const isChatEnabled = chat?.value !== null ? chat?.enabled : true;

  if (!isChatEnabled) {
    return <Redirect to={RouteConstants.DASHBOARD} />;
  }

  return <ChatScreen />;
};

export default MainChatScreen;
