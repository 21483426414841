import { TOOLS_SELECT_IMAGE_ACTIONS, SAVE_TOOL_ACTIONS, ADD_SELF_REGULATION_TOOL_ACTIONS, GET_TOOLS_LIST_ACTIONS, ARCHIVE_SELF_REGULATION_TOOL_ACTIONS, GET_SELF_REGULATION_TOOL_ACTIONS, UPDATE_SELF_REGULATION_TOOL_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    toolImage: {},
    subTaskImage: {},
    tempToolData: {},
    toolAdded: false,
    toolsList: [],
    toolsListMeta: {},
    currentTool: {},
    editToolImage: {},
    editSubTaskImage: {},
    tempEditToolData: {},
    listingCurrentPageData: {},
};

const selfRegulation = (state = initialState, action) => {
    switch (action.type) {
        case TOOLS_SELECT_IMAGE_ACTIONS.SELECT_TOOL_IMAGE:
            return {
                ...state,
                toolImage: action.payload.image,
            };

        case TOOLS_SELECT_IMAGE_ACTIONS.SELECT_SUBTASK_IMAGE:
            return {
                ...state,
                subTaskImage: action.payload.image,
            };

        case TOOLS_SELECT_IMAGE_ACTIONS.CLEAR_SUBTASK_IMAGE:
            return {
                ...state,
                subTaskImage: {},
            };

        case SAVE_TOOL_ACTIONS.SAVE_TOOL:
            return {
                ...state,
                tempToolData: action.payload.data,
            };

        case SAVE_TOOL_ACTIONS.CLEAR_TOOL:
            return {
                ...state,
                toolImage: {},
                subTaskImage: {},
                tempToolData: {},
            };

        case TOOLS_SELECT_IMAGE_ACTIONS.SELECT_EDIT_TOOL_IMAGE:
            return {
                ...state,
                editToolImage: action.payload.image,
            };

        case TOOLS_SELECT_IMAGE_ACTIONS.SELECT_EDIT_SUBTASK_IMAGE:
            return {
                ...state,
                editSubTaskImage: action.payload.image,
            };

        case TOOLS_SELECT_IMAGE_ACTIONS.CLEAR_EDIT_SUBTASK_IMAGE:
            return {
                ...state,
                editSubTaskImage: {},
            };

        case ADD_SELF_REGULATION_TOOL_ACTIONS.ADD_TOOL:
            return {
                ...state,
                loading: true,
                toolAdded: false,
            };

        case ADD_SELF_REGULATION_TOOL_ACTIONS.ADD_TOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                toolAdded: true,
            };

        case ADD_SELF_REGULATION_TOOL_ACTIONS.ADD_TOOL_FAILURE:
            return {
                ...state,
                loading: false,
                toolAdded: false,
            };

        case ADD_SELF_REGULATION_TOOL_ACTIONS.CLEAR_ADDED_TOOL:
            return {
                ...state,
                toolAdded: false,
            };

        case GET_TOOLS_LIST_ACTIONS.GET_TOOLS_LIST:
            return {
                ...state,
                loading: true,
            };

        case GET_TOOLS_LIST_ACTIONS.GET_TOOLS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                toolsList: action.payload.data.data,
                toolsListMeta: action.payload.data.meta,
            };

        case GET_TOOLS_LIST_ACTIONS.GET_TOOLS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case ARCHIVE_SELF_REGULATION_TOOL_ACTIONS.ARCHIVE_TOOL:
            return {
                ...state,
                loading: true,
            };

        case ARCHIVE_SELF_REGULATION_TOOL_ACTIONS.ARCHIVE_TOOL_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case ARCHIVE_SELF_REGULATION_TOOL_ACTIONS.ARCHIVE_TOOL_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_SELF_REGULATION_TOOL_ACTIONS.GET_TOOL:
            return {
                ...state,
                loading: true,
                currentTool: {},
                editToolImage: {},
            };

        case GET_SELF_REGULATION_TOOL_ACTIONS.GET_TOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                currentTool: action.payload.data,
                editToolImage: action.payload.data.image,
            };

        case GET_SELF_REGULATION_TOOL_ACTIONS.GET_TOOL_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case SAVE_TOOL_ACTIONS.SAVE_EDIT_TOOL:
            return {
                ...state,
                tempEditToolData: action.payload.data,
            };

        case UPDATE_SELF_REGULATION_TOOL_ACTIONS.UPDATE_TOOL:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_SELF_REGULATION_TOOL_ACTIONS.UPDATE_TOOL_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_SELF_REGULATION_TOOL_ACTIONS.UPDATE_TOOL_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case SAVE_TOOL_ACTIONS.CLEAR_EDIT_TOOL:
            return {
                ...state,
                currentTool: {},
                editToolImage: {},
                editSubTaskImage: {},
                tempEditToolData: {},
            };

        case GET_TOOLS_LIST_ACTIONS.SAVE_CURRENT_PAGE:
            return {
                ...state,
                listingCurrentPageData: action.payload.data,
            };

        case GET_TOOLS_LIST_ACTIONS.CLEAR_CURRENT_PAGE:
            return {
                ...state,
                listingCurrentPageData: {},
            };

        default:
            return state;
    }
};

export default selfRegulation;
