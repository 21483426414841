import React from "react";

import { Tooltip } from "@material-ui/core";
import moment from "moment";

const MessageTime = ({ message }) => {
  return (
    <Tooltip title={moment(message.createdAt).format("yyyy-MM-DD, HH:mm")}>
      <div
        style={{
          fontSize: 12,
          color: "#666666",
          marginBottom: "10px",
          alignSelf: "flex-end",
        }}
      >
        <span>{moment(message.createdAt).format("HH:mm")}</span>
      </div>
    </Tooltip>
  );
};

export default MessageTime;
