import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError, handleS3ImageUploadResponse } from "./GenericResponseHandler";
import { getMonthsDays, formatTimerNumber } from "../../helpers/GenericHelper";

class ChildService {

    getChildren() {
        let url = UrlConstants.CHILD;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getChild(childId) {
        let url = UrlConstants.VIEW_CHILD.replace(/{childId}/, childId);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getChildActivityTracking(startDate) {
        let url = UrlConstants.GET_ACT_TRACKING.replace(/{startDate}/, startDate);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    addChild(status, step, stepOneData, stepTwoData) { //, stepThreeData, stepFourData) {
        let url = UrlConstants.CHILD;

        let contactName1 = stepTwoData.contactName1
        let contactPhone1 = stepTwoData.contactPhone1
        let contactName2 = stepTwoData.contactName2
        let contactPhone2 = stepTwoData.contactPhone2

        if (contactName1 === "" && contactName2 !== "" && contactPhone1 === "" && contactPhone2 !== "") {
            contactName1 = contactName2;
            contactPhone1 = contactPhone2;
            contactName2 = ""
            contactPhone2 = ""
        }

        const payload = {
            "status": status,
            "steps": step,
            "newsletterSubscribed": stepTwoData.newsletterSubscribed,
            "profile": {
                "firstName": stepOneData.firstName,
                "lastName": stepOneData.lastName,
                "dateOfBirth": stepOneData.year + "-" + (formatTimerNumber(getMonthsDays()[stepOneData.month].order + 1)) + "-" + formatTimerNumber(+stepOneData.date),
                "emailAddress": stepOneData.email,
                "gender": stepOneData.gender,
                "photo": stepOneData.childImage.file.name
            },
            "address": {
                "homeAddress": stepTwoData.homeAddress,
                "schoolName": stepTwoData.schoolName,
                "schoolAddress": stepTwoData.schoolAddress,
                "contactName1": contactName1,
                "contactPhone1": contactPhone1,
                "contactName2": contactName2,
                "contactPhone2": contactPhone2,
            }
            // "medical": {
            //     "pastConditions": stepThreeData.pastMedicalConditions,
            //     "medicalEquipments": stepThreeData.specialEquipment,
            //     "medications": stepThreeData.medications,
            //     "allergies": stepThreeData.allergies,
            // },
            // "info": {
            //     "difficulties": stepFourData.visualHearingDifficulties,
            //     "communication": stepFourData.communicationNeeds,
            //     "therapy": stepFourData.therapyInfo,
            //     "specialNeeds": stepFourData.specialNeeds,
            //     "likeDislike": stepFourData.likesDislikes,
            // },
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    editChild(childId, status, step, profile, address) { //(, medical, info) {
        let url = UrlConstants.CHILD;

        let contactName1 = address.contactName1
        let contactPhone1 = address.contactPhone1
        let contactName2 = address.contactName2
        let contactPhone2 = address.contactPhone2

        if (contactName1 === "" && contactName2 !== "" && contactPhone1 === "" && contactPhone2 !== "") {
            contactName1 = contactName2;
            contactPhone1 = contactPhone2;
            contactName2 = ""
            contactPhone2 = ""
        }

        console.log("editChild url", url);
        const payload = {
            "id": childId,
            "status": status,
            "steps": step,
            "newsletterSubscribed": address.newsletterSubscribed,
            "profile": {
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "dateOfBirth": profile.dateOfBirth,
                "emailAddress": profile.email,
                "gender": profile.gender,
                "photo": Object.entries(profile.childImage).length !== 0 ? profile.childImage.file.name : profile.photo
            },
            "address": {
                "homeAddress": address.homeAddress,
                "schoolName": address.schoolName,
                "schoolAddress": address.schoolAddress,
                "contactName1": contactName1,
                "contactPhone1": contactPhone1,
                "contactName2": contactName2,
                "contactPhone2": contactPhone2,
            },
            // "medical": {
            //     "pastConditions": medical.pastMedicalConditions,
            //     "medicalEquipments": medical.specialEquipment,
            //     "medications": medical.medications,
            //     "allergies": medical.allergies,
            // },
            // "info": {
            //     "difficulties": info.visualHearingDifficulties,
            //     "communication": info.communicationNeeds,
            //     "therapy": info.therapyInfo,
            //     "specialNeeds": info.specialNeeds,
            //     "likeDislike": info.likesDislikes,
            // },
        };

        return apiClient()
            .put(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }


    getPresignedURL(file) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=child&op=upload&file=" + file;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getImageURL(fileName) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=child&op=get&file=" + fileName;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }


    uploadImagePut(imageData, urlAws) {

        let url = urlAws
        var options = {
            headers: {
                'Content-Type': "image/*"
            }
        };

        return apiClient()
            .uploadImage(url, imageData, options)
            .then(handleS3ImageUploadResponse)
            .catch(handleError);
    }

    deleteChild(childId) {
        let url = UrlConstants.DELETE_CHILD.replace(/{childId}/, childId);

        return apiClient()
            .delete(url)
            .then(handleResponse)
            .catch(handleError);
    }

    logoutChildFromWatch(childId) {
        let url = UrlConstants.CHILD_UNSYNC;
        const payload = {
            "childId": childId,
        }
        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    regenerateWatchCode(childId) {
        let url = UrlConstants.REGENERATE_CHILD_CODE;
        const payload = {
            "childId": childId,
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new ChildService();