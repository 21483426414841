import { request, success, failure } from ".";
import { GET_IMAGE_CATEGORIES_ACTIONS, GET_IMAGE_SKINTONES_ACTIONS, GET_IMAGE_LIBRARY_ACTIONS, IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS, IMAGE_LIBRARY_UPLOAD_ACTIONS, IMAGE_LIBRARY_ADD_IMAGE_ACTIONS, IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS, IMAGE_LIBRARY_LOADER_ACTIONS } from "../../constants/ActionConstants";
import ImageLibraryService from "../../services/api/ImageLibraryService";

export const getImageCategoriesList = () => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_CATEGORIES_ACTIONS.GET_IMAGE_CATEGORIES, {}));
        try {
            const response = await ImageLibraryService.getCategories();
            dispatch(success(GET_IMAGE_CATEGORIES_ACTIONS.GET_IMAGE_CATEGORIES_SUCCESS, { data: response.data }));
        } catch (error) {
            dispatch(failure(GET_IMAGE_CATEGORIES_ACTIONS.GET_IMAGE_CATEGORIES_FAILURE, {}));
        }
    };
}

export const getImageSkintoneList = () => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES, {}));
        try {
            const response = await ImageLibraryService.getSkintones();
            dispatch(success(GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_SUCCESS, { data: response.data }));
        } catch (error) {
            dispatch(failure(GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_FAILURE, {}));
        }
    };
}


export const getImages = (childId, page, searchTerm, category, fullLoad, skintoneId) => {
    return async (dispatch) => {
        if (fullLoad) {
            dispatch(request(GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_FULL_LOAD, {}));
        } else {
            dispatch(request(GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY, {}));
        }
        if (page === 1) {
            dispatch(request(GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_CLEAR_DATA, {}));
        }
        try {
            const response = await ImageLibraryService.getImages(childId, page, searchTerm, category, skintoneId);
            dispatch(success(GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_SUCCESS, { data: response.data }));
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_FAILURE, {}));
        }
    };
}

export const showLoader = () => {
    return (dispatch) => {
        dispatch(request(IMAGE_LIBRARY_LOADER_ACTIONS.SHOW_LOADER, {}));
    };
}

export const getPresignedURL = (fileName, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS.GET_PRE_SIGNED_URL, {}));
        try {
            const response = await ImageLibraryService.getPresignedURL(fileName);
            dispatch(success(IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS.GET_PRE_SIGNED_URL_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS.GET_PRE_SIGNED_URL_FAILURE, {}));
            onFailure(error);
        }
    };
}

export const uploadImage = (data, urlAws, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_LIBRARY_UPLOAD_ACTIONS.UPLOAD_IMAGE, {}));
        try {
            const response = await ImageLibraryService.uploadImagePut(data, urlAws);
            dispatch(success(IMAGE_LIBRARY_UPLOAD_ACTIONS.UPLOAD_IMAGE_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(IMAGE_LIBRARY_UPLOAD_ACTIONS.UPLOAD_IMAGE_FAILURE, {}));
            onFailure(error);
        }
    };
}

export const addImageInImageLibrary = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_LIBRARY_ADD_IMAGE_ACTIONS.ADD_IMAGE, {}));
        try {
            const response = await ImageLibraryService.addImage(payload);
            dispatch(success(IMAGE_LIBRARY_ADD_IMAGE_ACTIONS.ADD_IMAGE_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(IMAGE_LIBRARY_ADD_IMAGE_ACTIONS.ADD_IMAGE_FAILURE, {}));
            onFailure(error);
        }
    };
}

export const updateImageInImageLibrary = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS.UPDATE_IMAGE, {}));
        try {
            const response = await ImageLibraryService.updateImage(payload);
            dispatch(success(IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS.UPDATE_IMAGE_SUCCESS, {}));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS.UPDATE_IMAGE_FAILURE, {}));
            onFailure();
        }
    };
}