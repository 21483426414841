export const PERMISSIONS = {

    CHILD_VIEW: 1,
    CHILD_CREATE: 2,

    ADMIN_VIEW: 4, // USERMGT (DEPRECATED)
    ADMIN_CREATE: 8,

    PARENT_VIEW: 16, //PARENTVIEW(DEPRECATED) NEVER USED
    PARENT_CREATE: 32,


    ACTIVITY_VIEW: 64,
    ACTIVITY_CREATE: 128,
    SCHEDULE_VIEW: 256,
    SCHEDULE_CREATE: 512,
    EMO_REGULATION_VIEW: 1024,
    EMO_REGULATION_CREATE: 2048   //4035 without USERMGT, PARENTVIEW(DEPRECATED)
};

