import React from "react";
import { Link } from "@material-ui/core";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import BlockIcon from '@material-ui/icons/Block';
import DoubleArrowSharpIcon from '@material-ui/icons/DoubleArrowSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';

const Pagination = (props) => {

    const { pageCount, current, fetch } = props;

    const getPageElements = () => {
        const elementsArray = [];
        if (pageCount <= 5) {
            for (let i = 1; i <= pageCount; i++) {
                if (current === i) {
                    elementsArray.push(<Link onClick={() => fetch(i)}><span key={i} className="active">{i}</span></Link>);
                } else {
                    elementsArray.push(<Link onClick={() => fetch(i)}><span key={i} >{i}</span></Link>);
                }
            }
            return elementsArray;
        } else {
            let start = current - 2;
            let end = current + 2;
            if (start < 1) {
                const offset = 1 - start;
                start += offset;
                end += offset;
            } else if (end > pageCount) {
                const offset = end - pageCount;
                end -= offset;
                start -= offset;
            }
            if (start > 1) {
                elementsArray.push(<span key={start - 1} className="active">...</span>);
            }
            for (let i = start; i <= end; i++) {
                if (current === i) {
                    elementsArray.push(<Link onClick={() => fetch(i)}><span key={i} className="active">{i}</span></Link>);
                } else {
                    elementsArray.push(<Link onClick={() => fetch(i)}><span key={i} >{i}</span></Link>);
                }
            }
            if (end < pageCount) {
                elementsArray.push(<span key={end + 1} className="active">...</span>);
            }
            return elementsArray;
        }
    }

    const oneStepBack = () => {
        if (current === 1) {
            return;
        }
        fetch(current - 1);
    }

    const fastBack = () => {
        fetch(1);
    }

    const oneStepForward = () => {
        if (current === pageCount) {
            return;
        }
        fetch(current + 1);
    }

    const fastForward = () => {

        fetch(pageCount);
    }

    return (
        <div>
            {current !== 1 ?
                <Link onClick={fastBack}>
                    <DoubleArrowSharpIcon className="arrowbackward" />
                </Link>
                : <DoubleArrowSharpIcon className="disabled arrowbackward" />}

            {current !== 1 ?
                <Link onClick={oneStepBack}>
                    <KeyboardArrowLeftSharpIcon />
                </Link>
                : <KeyboardArrowLeftSharpIcon className="disabled" />}

            {
                getPageElements()
            }

            {current !== pageCount ?
                <Link onClick={oneStepForward}>
                    <KeyboardArrowRightSharpIcon />
                </Link>
                : <KeyboardArrowRightSharpIcon className="disabled" />}
            {current !== pageCount ?
                <Link onClick={fastForward}>
                    <DoubleArrowSharpIcon />
                </Link>
                : <DoubleArrowSharpIcon className="disabled" />}
        </div>
    );
};

export default Pagination;