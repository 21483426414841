import { toast } from "react-toastify";

const useToast = (defaultOptions) => {
  const success = (data) => toast(data, { ...defaultOptions, type: "success" });

  const error = (data, options) =>
    toast(data, { ...defaultOptions, ...options, type: "error" });

  const info = (data, options) =>
    toast(data, { ...defaultOptions, ...options, type: "info" });

  return { success, error, info };
};

export default useToast;
