import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Grid, Container } from "@material-ui/core";

const SidebarScreen = () => {
    return (
        // <div className="App">
        //     <div className="App-header">
        //         <Header />
        //         <Sidebar />
        //         <Footer />
        //     </div>
        // </div>

        <Grid container className="app">
            <Grid xs={12} item className="app-header">
                <Header />
            </Grid>
            <Grid item xs={12} className="grid-body">
                <Container maxWidth="xl">
                    <Sidebar />
                    {/* <Dashboard /> */}
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth="xl">
                    <Grid container spacing={6} className="my-0">
                        <Grid item xs={12}>
                            <Footer />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
};

export default SidebarScreen;
