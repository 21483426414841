import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, InputLabel, Input, FormHelperText, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from "@material-ui/core";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import { IMAGE_TYPES, ActivityColor, SCREEN_TYPE } from "../../constants/GenericConstants";
import { validateMandatoryValue, validateLength18C } from "../../helpers/Validation";
import { parentAddedEntity, isArchivedEntity } from "../../helpers/GenericHelper";
import { clearImage, saveEditData, getActivity, updateActivity, addActivity, clearEditActivityData, getColorList } from "../../store/actions/Activities";
import Loader from '../../components/Loader/Loader';
import { PRIV_CONSTS } from "../../constants/StaticData";
import { getEditPermValueIndividual } from "../../helpers/PermissionCalculator";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";

const EditActivity = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const activitiesState = useSelector((state) => state.activities);
    const childState = useSelector((state) => state.child);

    const [editAct, setEditAct] = useState(false);
    const [dataGot, setDataGot] = useState({});


    const [activityName, setActivityName] = useState("");
    const [activityNameError, setActivityNameError] = useState("");

    const [activityColors, setActivityColors] = useState([]);
    const [activityColorsError, setactivityColorsError] = useState('');

    const [activityTaskName, setActivityTaskName] = useState("");
    const [activityTaskNameError, setActivityTaskNameError] = useState("");
    const [activityTaskImageError, setActivityTaskImageError] = useState("");
    const [subTaskAdded, setSubTaskAdded] = useState(false);

    const [subTasksList, setSubTasksList] = useState([]);

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [indexGot, setIndexGot] = useState();
    const isTemplate = activitiesState.currentActivity.createdBy === 'Thrive'

    const handleCloseDeleteDialog = (index) => {
        setDeleteDialogOpen(false);
    }

    const onActivityLoadSuccess = (data) => {
        setDataGot(data)
        setActivityName(data.name);
        // let onlyorders = data.subTasks.map(s => { return { name: s.name, order: s.order } })
        // alert(JSON.stringify(onlyorders))
        setSubTasksList(data.subTasks);
    }

    useEffect(() => {
        const updatedColors = activitiesState.activityColorList.map(color => {
            return {
                colorCode: color.value,
                selected: dataGot.color === color.value,
                code: color.value
            }
        });
        setActivityColors(updatedColors);
    },[dataGot])

    useEffect(() => {
        dispatch(getColorList());
        if (childState.selectedChild.childId) {
            let permEdit = checkParentAndPermEdit(childState.selectedChild.childId, PRIV_CONSTS.ACTIVITY_PRIV)
            setEditAct(permEdit)

        }
        const data = activitiesState.tempEditActivityData;
        if (Object.keys(data).length > 0 && activitiesState.currentActivity.id == props.activityId) {
            setActivityName(data.activityName);
            setActivityNameError(data.activityNameError);
            setActivityColors(data.activityColors);
            setActivityTaskName(data.activityTaskName);
            setSubTasksList(sortSubtasks(data.subTasksList));
            setDataGot(data.dataGot);
        } else {
            dispatch(getActivity(props.activityId, onActivityLoadSuccess));
        }
    }, []);
    
    const checkParentAndPermEdit = (childId, priv_const) => {

        let permGot = checkOwnChild(childId)
        if (!permGot && childState.otherChildrenList) {
            childState.otherChildrenList.map(clientData => {
                if (clientData.childProfiles) {
                    clientData.childProfiles.map(c => {
                        if (c.childId == childId) {
                            let perm = getEditPermValueIndividual(c.userPermissions, priv_const);
                            permGot = perm
                        }
                    })
                }
            })
        }
        return permGot;
    }

    const checkOwnChild = (childId) => {
        let ownChild = false
        if (childId) { //TO CHECK IF OWN CHILD
            if (childState.childrenList) {
                childState.childrenList.map(c => {
                    if (c.childId == childId) {
                        ownChild = true
                    }
                })

            }
        }
        return ownChild;
    }

    const handleActivityNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setActivityName(input);
        validateActivityName(input);
    }

    const validateActivityName = (activityName) => {
        if (!validateMandatoryValue(activityName)) {
            setActivityNameError(t("activityNameRequiredError"));
            return false;
        } else if (!validateLength18C(activityName)) {
            setActivityNameError(t("activityNameLengthError"));
            return false;
        } else {
            setActivityNameError("");
            return true;
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const selectColor = (index) => {
        if (parentAddedEntity(dataGot) || !isArchivedEntity(dataGot) || isTemplate){;
        const colorsArray = activityColors.map((color, currentIndex) => {
            let selected = false;
            if (currentIndex === index) {
                selected = true;
            }
            return {
                colorCode: color.colorCode,
                selected: selected,
                code: color.code,
            };
        });
        setActivityColors(colorsArray);
        setactivityColorsError('');
        }else{
            return
        }
    }

    const handleActivityTaskNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setActivityTaskName(input);
        if (subTaskAdded) {
            validateActivityTaskName(input);
        }
    }

    const validateActivityTaskName = (activityTaskName) => {
        if (!validateMandatoryValue(activityTaskName)) {
            setActivityTaskNameError(t("activityTaskNameRequiredError"));
            return false;
        } else if (!validateLength18C(activityTaskName)) {
            setActivityTaskNameError(t("activityTaskNameLengthError"));
            return false;
        } else {
            setActivityTaskNameError("");
            return true;
        }
    }

    const validateSubTaskImage = () => {
        if (!validateMandatoryValue(activitiesState.editSubTaskImage.imageURL)) {
            setActivityTaskImageError(t("activityTaskImageRequiredError"));
            return false;
        } else {
            setActivityTaskImageError("");
            return true;
        }
    }

    const moveToImageLibrary = (imageType) => {
        const dataObject = {
            activityName: activityName,
            activityNameError: activityNameError,
            activityColors: activityColors,
            activityTaskName: activityTaskName,
            subTasksList: subTasksList,
            dataGot: dataGot,
        };
        dispatch(saveEditData(dataObject));
        history.push({ pathname: RouteConstants.IMAGE_LIBRARY, imageType: imageType, screenType: SCREEN_TYPE.EDIT });
    }

    const clearSubTaskData = () => {
        setActivityTaskName("");
        setActivityTaskNameError("");
        setActivityTaskImageError("");
        dispatch(clearImage(IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE, SCREEN_TYPE.EDIT));
    }

    const addSubTask = () => {
        setSubTaskAdded(true);
        if (validateActivityTaskName(activityTaskName) & validateSubTaskImage()) {
            const item = {
                name: activityTaskName,
                image: activitiesState.editSubTaskImage,
                order: subTasksList.length === 0 ? 1 : subTasksList[subTasksList.length - 1].order + 1,
            };
            setSubTasksList([...subTasksList, item]);
            clearSubTaskData();
        }
    }

    const deleteSubtask = (index) => {
        const subtasks = [...subTasksList];
        subtasks.splice(index, 1);
        setSubTasksList(subtasks);
    }

    const isFormValid = () => {
        return validateActivityName(activityName) && validateColor(activityColors);
        ;
    }

    const onSuccess = () => {
        setUpdateSuccess(true);
        setTimeout(() => {
            history.goBack();
            dispatch(clearEditActivityData());
        }, 3000);
    }

    const onFailure = () => {
        setUpdateError(true);
    }

    const getSubTasksForUpdate = () => {
        return subTasksList.map((subtask, index) => {
            if (subtask.id) {
                return { id: subtask.id, name: subtask.name, imageId: subtask.imageId, order: index + 1 };
            } else {
                return { name: subtask.name, imageId: subtask.image.id, order: index + 1 };
            }
        });
    }

    const onUpdatePress = () => {
        if (isFormValid()) {
            const payload = {
                id: activitiesState.currentActivity.id,
                name: activityName,
                imageId: activitiesState.editActivityImage.id,
                color: activityColors.filter((color) => color.selected)[0].colorCode,
                accountId: childState.selectedChild.accountId,
                subTasks: getSubTasksForUpdate(subTasksList),
            };
            dispatch(updateActivity(payload, onSuccess, onFailure));
        }
    }

    const onCreatePress = () => {
        if (isFormValid()) {
            const payload = {
                id: activitiesState.currentActivity.id,
                name: activityName,
                imageId: activitiesState.editActivityImage.id,
                color: activityColors.filter((color) => color.selected)[0].colorCode,
                accountId: childState.selectedChild.accountId,
                childId: childState.selectedChild.childId,
                subTasks: getSubTasksForUpdate(subTasksList),
            };
            dispatch(addActivity(payload, onSuccess, onFailure));
        }
    }

    const handleUpdateSuccessClose = () => {
        setUpdateSuccess(false);
    }

    const handleUpdateErrorClose = () => {
        setUpdateError(false);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const goToActivitiesList = () => {
        history.goBack();
        dispatch(clearEditActivityData());
    }

    const sortSubtasks = (subTasksList) => {
        return subTasksList.sort((a, b) => a.order - b.order);
    }

    const checkChanged = () => {
        let changed = false;
        let colorChanged = false;
        activityColors.map(col => {
            if (col.code === dataGot.color && col.selected !== true) {
                colorChanged = true
            }
        })

        if (activityName !== dataGot.name || subTasksList.length !== dataGot.subTasks.length || colorChanged || activitiesState.editSubTaskImage.imageURL !== undefined || activityTaskName !== "") {
            changed = true
        }
        return changed;
    }

    const validateColor = (colorsArray) => {
        const selectedColor = colorsArray.filter((color) => color.selected);
        if (selectedColor.length > 0) {
            setactivityColorsError("");
            return true;
        } else {
            setactivityColorsError(t("activityColorRequiredError"));
            return false;
        }
    }


    return (
        <div>
            {activitiesState.loading && <Loader />}
            <SnackbarAlert show={updateSuccess} message={t("activityUpdatedSuccessMessage")} duration={3000} onCloseAlert={handleUpdateSuccessClose} />
            <SnackbarAlert isError={true} show={updateError} message={t("unknownError")} duration={5000} onCloseAlert={handleUpdateErrorClose} />

            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeleteDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => { deleteSubtask(indexGot); handleCloseDeleteDialog(); }} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>



            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("updatedInformationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={goToActivitiesList} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>
                </DialogActions>
            </Dialog>
            <Card className="no-style activity-section">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="generic-card">
                            <div className="activity-listing-two-box-container">
                                <div className="col-left">
                                    <h3>{parentAddedEntity(dataGot) && !isArchivedEntity(dataGot) ? t("editActivity") : t("viewActivity")}</h3>
                                    <div className="form-field">
                                        <InputLabel htmlFor="activityName" required>{t("activityName")}</InputLabel>
                                        <Input disabled={!editAct || !parentAddedEntity(dataGot) || isArchivedEntity(dataGot)} id="activityName" name="activityName" required type="text" value={activityName || ""}
                                            placeholder={t("activityNamePlaceholder")} onChange={handleActivityNameChange} error={showInputError(activityNameError)} />
                                        {
                                            showInputError(activityNameError) && <FormHelperText error={showInputError(activityNameError)}>{activityNameError}</FormHelperText>
                                        }
                                    </div>
                                    <div className="ImgBox">
                                        {((parentAddedEntity(dataGot) && !isArchivedEntity(dataGot)) || isTemplate) && <span className={editAct || isTemplate ? "camera-icon" : 'Mui-disabled camera-icon'}><CameraAltIcon onClick={() => {
                                            if
                                                (editAct || isTemplate) { moveToImageLibrary(IMAGE_TYPES.ACTIVITY_IMAGE) }
                                        }} /></span>}
                                        <img src={AssetConstants.applewatch} alt="Apple Watch Image" />
                                        {
                                            activitiesState.editActivityImage.imageURL &&
                                            <img alt="Activity Image" src={activitiesState.editActivityImage.imageURL} className="m-icon" />
                                        }
                                    </div>
                                    <div>
                                        <InputLabel required>{t("assignColorToActivity")}</InputLabel>
                                        <div className="colorChanger">
                                            {
                                                activityColors.map((color, index) => {
                                                    if (color.selected) {
                                                        return <div className={editAct || parentAddedEntity(dataGot) || !isArchivedEntity(dataGot) || isTemplate ? "cc-oval-selected" : 'Mui-disabled cc-oval-selected'} style={{ backgroundColor: color.colorCode }} onClick={() => selectColor
                                                            (index)}>
                                                            <span></span>
                                                        </div>;
                                                    }
                                                    return <div className={editAct || parentAddedEntity(dataGot) || !isArchivedEntity(dataGot) || isTemplate ? "cc-oval-selected" : 'Mui-disabled cc-oval-selected'} style={{ backgroundColor: color.colorCode }} onClick={() => {
                                                        if (editAct || isTemplate) {
                                                            selectColor(index)
                                                        }
                                                    }}></div>;
                                                })
                                            }
                                        </div>
                                        {
                                            showInputError(activityColorsError) && <FormHelperText error={showInputError(activityColorsError)}>{activityColorsError}</FormHelperText>
                                        }
                                    </div>
                                </div>
                                <div className="col-right">
                                    {
                                        ((parentAddedEntity(dataGot) && !isArchivedEntity(dataGot) && subTasksList.length < 4) || (isTemplate)) &&
                                        <div>
                                            <h3>{t("addSubTasksToActivityHeading")}<span> - {t("optional")}</span></h3>
                                            <div className="activityTask">
                                                {
                                                    activitiesState.editSubTaskImage.imageURL ?
                                                        <img src={activitiesState.editSubTaskImage.imageURL} alt="Sub task image" className={editAct || isTemplate ? "" : 'Mui-disabled imgBlock'} onClick={() => {
                                                            if (editAct) {
                                                                moveToImageLibrary(IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE)
                                                            }
                                                        }} /> :
                                                        <img src={AssetConstants.oval} alt="Sub task image" className={editAct || isTemplate ? "" : 'Mui-disabled imgBlock'} onClick={() => {
                                                            if (editAct || isTemplate) {
                                                                moveToImageLibrary
                                                                    (IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE)
                                                            }
                                                        }} />
                                                }
                                                <div className="form-field">
                                                    <InputLabel htmlFor="activityTaskName">{t("activityTaskName")}</InputLabel>
                                                    <Input disabled={!editAct} id="activityTaskName" name="activityTaskName" required type="text" value={activityTaskName || ""} placeholder={t
                                                        ("activityTaskNamePlaceholder")} onChange={handleActivityTaskNameChange} error={showInputError(activityTaskNameError)} />
                                                    {
                                                        showInputError(activityTaskNameError) && <FormHelperText error={showInputError(activityTaskNameError)}>{activityTaskNameError}</FormHelperText>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                showInputError(activityTaskImageError) && <FormHelperText error={showInputError(activityTaskImageError)}>{activityTaskImageError}</FormHelperText>
                                            }
                                            <div className="addBtn">
                                                <Button className={!editAct ? 'Mui-disabled linkBlock' : ""} disabled={!editAct} onClick={addSubTask}>{t("add")}</Button>
                                            </div>
                                        </div>
                                    }
                                    <div className="sub-tasks-list-added">
                                        <h4>{t("addedSubTasksList")}</h4>
                                        {
                                            subTasksList.length > 0 ?
                                                <div className="scroll-div">
                                                    {
                                                        subTasksList.sort((a, b) => a.order - b.order).map((subTask, index) => {
                                                            return <div className="d-flex align-items-center mb-10">
                                                                <img src={subTask.imageURL ? subTask.imageURL : subTask.image.imageURL} alt={subTask.name} />
                                                                <span>{subTask.name}</span>
                                                                {
                                                                    ((parentAddedEntity(dataGot) && !isArchivedEntity(dataGot)) || isTemplate) &&
                                                                    <div className="edit-delete">
                                                                        <i className={!editAct ? 'Mui-disabled linkBlock ticon-trash-line' : "ticon-trash-line"} onClick={() => {
                                                                            if (editAct || isTemplate) {
                                                                                setIndexGot(index);
                                                                                setDeleteDialogOpen(true)
                                                                            }
                                                                        }}></i>
                                                                    </div>
                                                                }
                                                                <Button className="arrow-btn"
                                                                    disabled={!editAct || !parentAddedEntity(dataGot) || index === 0}
                                                                    onClick={() => {
                                                                        let tempSubtasks = subTasksList;
                                                                        let oldOrder = tempSubtasks[index].order
                                                                        tempSubtasks[index].order = tempSubtasks[index - 1].order
                                                                        tempSubtasks[index - 1].order = oldOrder
                                                                        // alert("Old " + tempSubtasks[index].order + "-- New" + tempSubtasks[index - 1].order)
                                                                        let onlyorders = subTasksList.map(s => { return { name: s.name, order: s.order } })
                                                                        // alert(JSON.stringify(onlyorders))
                                                                        setSubTasksList([...tempSubtasks])

                                                                    }}><KeyboardArrowUpIcon /></Button>
                                                                <Button className="arrow-btn"
                                                                    disabled={!editAct || !parentAddedEntity(dataGot) || (subTasksList.length !== 0 && index === subTasksList.length - 1) || subTasksList.length === 0}
                                                                    onClick={() => {
                                                                        let tempSubtasks = subTasksList;
                                                                        let oldOrder = tempSubtasks[index].order
                                                                        tempSubtasks[index].order = tempSubtasks[index + 1].order
                                                                        tempSubtasks[index + 1].order = oldOrder
                                                                        // alert("Old " + tempSubtasks[index].order + "-- New" + tempSubtasks[index + 1].order)
                                                                        let onlyorders = subTasksList.map(s => { return { name: s.name, order: s.order } })
                                                                        // alert(JSON.stringify(onlyorders))
                                                                        setSubTasksList([...tempSubtasks])
                                                                    }}
                                                                ><KeyboardArrowDownIcon /></Button>
                                                            </div>;
                                                        })
                                                    }
                                                </div> :
                                                <div>
                                                    <span>{t("noSubtasksAdded")}</span>
                                                </div>
                                        }
                                    </div>
                                    <div className="activityFooterBtns">
                                        {
                                            ((parentAddedEntity(dataGot) && !isArchivedEntity(dataGot)) || isTemplate)?
                                                <div className="buttons-footer">
                                                    <Button onClick={() => {
                                                        if (checkChanged()) {
                                                            setDialogOpen(true)
                                                        }
                                                        else {
                                                            goToActivitiesList()
                                                        }
                                                    }}>{t("cancel")}</Button>
                                                    {(editAct || isTemplate) && <Button disabled={!(editAct || isTemplate)} onClick={isTemplate ? onCreatePress : onUpdatePress} color="primary" variant="contained">{isTemplate ? t("create") : t("update")}</Button>}
                                                </div> :
                                                <div className="buttons-footer">
                                                    <Button onClick={goToActivitiesList} color="primary" variant="contained">{t("back")}</Button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default EditActivity;