import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MessagePanelConversationHeader } from "./headers/MessagePanelConversationHeader";
import { MessagePanelGroupHeader } from "./headers/MessagePanelGroupHeader";

export const MessagePanelHeader = () => {
  const { id: routeId } = useParams();

  const selectedType = useSelector(
    (state) => state.selectedConversationType.type
  );

  return selectedType === "private" ? (
    <MessagePanelConversationHeader />
  ) : (
    <MessagePanelGroupHeader />
  );
};
