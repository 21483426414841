import { MESSAGE_PANEL } from "../../../constants/ActionConstants";

const INITIAL_STATE = {
  attachments: [],
  attachmentCounter: 0,
};

const messagePanel = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MESSAGE_PANEL.ADD_ATTACHMENT:
      return {
        ...state,
        // attachments: [...state.attachments, action.payload], // for multiple files
        attachments: [action.payload], // for only single file upload at a time
      };

    case MESSAGE_PANEL.REMOVE_ATTACHMENT:
      const updatedAttachments = state.attachments.filter(
        (file) => file.id !== action.payload.id
      );
      return {
        ...state,
        attachments: updatedAttachments,
      };

    case MESSAGE_PANEL.INCREMENT_ATTACHMENT_COUNTER:
      return {
        ...state,
        attachmentCounter: state.attachmentCounter + 1,
      };

    case MESSAGE_PANEL.REMOVE_ALL_ATTACHMENTS:
      return {
        ...state,
        attachments: [],
      };

    default:
      return { ...state };
  }
};

export default messagePanel;
