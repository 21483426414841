import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Link, Select, MenuItem, Input, InputAdornment, Grid, GridList, GridListTile, Button, Dialog, DialogTitle, DialogContent, FormHelperText, DialogContentText, DialogActions } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import InfiniteScroll from 'react-infinite-scroller';
import AddNewImage from "../AddNewImage/AddNewImage";
import Loader from '../../components/Loader/Loader';
import { getImageCategoriesList, getImageSkintoneList, getImages, updateImageInImageLibrary } from "../../store/actions/ImageLibrary";
import { selectImage } from "../../store/actions/Activities";
import { getDropdownCategories, equalArrays, getDropdownSkintones } from "../../helpers/GenericHelper";
import { validateMandatoryValue, validateLength100C } from "../../helpers/Validation";
import AssetConstants from "../../constants/AssetConstants";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import {CirclePicker} from 'react-color';
import ArrowDropDownIcon from '@material-ui/icons//ArrowDropDown';

const ImageLibrary = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const imageLibraryState = useSelector((state) => state.imageLibrary);
    const childState = useSelector((state) => state.child);
    const dispatch = useDispatch();
    const [currentCategory, setCurrentCategory] = useState("");
    const [currentSkintone, setCurrentSkintone] = useState(7); // default skin tone id 7
    const [searchText, setSearchText] = useState("");
    const [previewMode, setPreviewMode] = useState(false);
    const [previewImageIndex, setPreviewImageIndex] = useState(-1);
    const [newAddedImage, setNewAddedImage] = useState({});
    const [showAddImageDialog, setShowAddImageDialog] = useState(false);
    const [ref, setRef] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);

    const [imageTitle, setImageTitle] = useState("");
    const [imageTitleError, setImageTitleError] = useState("");
    const [updateCategories, setUpdateCategories] = useState([]);
    const [updateCategoriesError, setUpdateCategoriesError] = useState("");

    const [addSuccess, setAddSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        dispatch(getImageCategoriesList());
        dispatch(getImageSkintoneList());
        dispatch(getImages(childState.selectedChild.childId, 1, searchText, currentCategory, true, currentSkintone));
    }, []);

    const getCategories = () => {
        return getDropdownCategories(imageLibraryState.imageCategories).map((category, index) => {
            return <MenuItem key={index} value={category.name}>{category.value}</MenuItem>;
        });
    }

    const clearPreviewAndUpdateData = () => {
        setPreviewMode(false);
        setPreviewImageIndex(-1);
        setImageTitle("");
        setImageTitleError("");
        setUpdateCategories([]);
        setUpdateCategoriesError("");
    }

    const handleCurrentCategoryChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        let forAll = []
        if (input === "all") {
            // imageLibraryState.imageCategories.map(cat => {
            //     forAll.push(cat.name)
            // })
            setCurrentCategory("");
            clearPreviewAndUpdateData();
            
            dispatch(getImages(childState.selectedChild.childId, 1, searchText, "", false, currentSkintone));
        }
        else {
            setCurrentCategory(input);
            clearPreviewAndUpdateData();
            dispatch(getImages(childState.selectedChild.childId, 1, searchText, input, false, currentSkintone));
        }
    }

    // ---------------- Skin Tone Selector ----------------------

    const getSkintonesForPicker = () => {
        // const colorArr = ['#FFC017', '#F8DCBC', '#CFA77F', '#B98C67', '#A46B42', '#694D44'];

        return imageLibraryState.imageSkintones;
    }

    const handleCurrentSkintoneChange = (skinToneId) => {
        const input = skinToneId;
        
        if (input === "none") {
            setCurrentSkintone("");
            clearPreviewAndUpdateData();
            dispatch(getImages(childState.selectedChild.childId, 1, searchText, currentCategory, false));
        }
        else {
            setCurrentSkintone(input);
            clearPreviewAndUpdateData();
            dispatch(getImages(childState.selectedChild.childId, 1, searchText, currentCategory, false, input));
        }
    }

    // --------------------------------------------------------

    const handleSearchTextChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setSearchText(input);
        clearPreviewAndUpdateData();
        dispatch(getImages(childState.selectedChild.childId, 1, input, currentCategory, false, currentSkintone));
    }

    const getImagesGrid = () => {
        return imageLibraryState.imagesList.map((image, index) => (
            <GridListTile key={index}>
                <div style={{ height: 120, width: 140, backgroundColor: '#ededed', cursor: "pointer" }} className={previewImageIndex === index ? "highlighted" : ""} onClick={() => openImagePreview(index)}>
                    <img src={image.imageURL} alt={image.imageTitle} style={{ height: 67, width: 67 }} />
                </div>
            </GridListTile>
        ));
    }
    const checkChanged = () => {
        let changed = false;
        if (imageLibraryState.imagesList[previewImageIndex].imageTitle !== imageTitle || imageLibraryState.imagesList[previewImageIndex].categories.length !== updateCategories.filter(i => i.selected === true).length) {
            changed = true
        }
        return changed;
    }

    const openImagePreview = (index) => {
        setPreviewImageIndex(index);
        const currentItem = imageLibraryState.imagesList[index];
        setImageTitle(currentItem.imageTitle);
        setImageTitleError("");
        const categoriesObject = imageLibraryState.imageCategories.map((category, index) => {
            let selected = false;

            if (currentItem.categories.indexOf(category.name) > -1) {
                selected = true;
            }
            return { ...category, selected: selected };
        });
        setUpdateCategories(categoriesObject);
        setUpdateCategoriesError("");
        setPreviewMode(true);
    }

    const addImagePreview = (index, currentItem) => {
        setPreviewImageIndex(index);
        setImageTitle(currentItem.imageTitle);
        setImageTitleError("");
        const categoriesObject = imageLibraryState.imageCategories.map((category, index) => {
            let selected = false;

            if (currentItem.categories.indexOf(category.name) > -1) {
                selected = true;
            }
            return { ...category, selected: selected };
        });
        setUpdateCategories(categoriesObject);
        setUpdateCategoriesError("");
        setPreviewMode(true);
    }

    const getCategoryTags = (categoryArray) => {
        const categoryObjects = categoryArray.map((categoryId) => {
            return imageLibraryState.imageCategories.find((category) => category.name === categoryId);
        });
        return categoryObjects.map((category, index) => {
            if (category) {
                return <div key={index}>{category.value}</div>
            }
            });
    }

    const getUpdateCategoryTags = (categoryArray) => {
        return categoryArray.map((category, index) => {
            if (category.selected)
                return <div className="handCursor" style={{ backgroundColor: '#02c8a7', color: '#FFFFFF' }} key={index} onClick={() => updateImageCategory(index, false)}>{category.value}</div>;
            else
                return <div className="handCursor" key={index} onClick={() => updateImageCategory(index, true)}>{category.value}</div>;
        })
    }

    const updateImageCategory = (index, value) => {
        updateCategories[index].selected = value;
        setUpdateCategories([...updateCategories]);
        validateCategories(updateCategories);
    }

    const validateCategories = (categories) => {
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].selected) {
                setUpdateCategoriesError("");
                return true;
            }
        }
        setUpdateCategoriesError(t("categoryRequiredError"));
        return false;
    }

    const handleImageTitleChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setImageTitle(input);
        validateImageTitle(input);
    }

    const validateImageTitle = (imageTitle) => {
        if (!validateMandatoryValue(imageTitle)) {
            setImageTitleError(t("imageTitleRequired"));
            return false;
        } else if (!validateLength100C(imageTitle)) {
            setImageTitleError(t("imageTitleLengthError"));
            return false;
        } else {
            setImageTitleError("");
            return true;
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const onCancelPress = () => {
        setPreviewMode(false);
        setDialogOpen(false)
        setPreviewImageIndex(-1);
    }

    const updateOnSuccess = (updatedImage) => {
        setUpdateSuccess(true);
        dispatch(selectImage(updatedImage, location.imageType, location.screenType));
        setTimeout(() => {
            history.goBack();
        }, 3000);
    }

    const handleUpdateSuccessClose = () => {
        setUpdateSuccess(false);
    }
    const handleAddSuccessClose = () => {
        setAddSuccess(false);
    }

    const updateOnFailure = () => {
        setUpdateError(true);
    }

    const onUploadPress = () => {
        const currentItem = imageLibraryState.imagesList[previewImageIndex];
        if (!currentItem.isMyUpload) {
            dispatch(selectImage(currentItem, location.imageType, location.screenType));
            history.goBack();
        } else {
            const selectedCategories = updateCategories.filter((category) => category.selected);
            const categoryNames = selectedCategories.map((category) => category.name);

            if (currentItem.imageTitle === imageTitle && equalArrays(currentItem.categories, categoryNames)) {
                dispatch(selectImage(currentItem, location.imageType, location.screenType));
                history.goBack();
            } else {
                if (validateImageTitle(imageTitle) && validateCategories(updateCategories)) {
                    const payload = {
                        id: currentItem.id,
                        imagePath: currentItem.imagePath,
                        imageTitle: imageTitle,
                        categories: categoryNames,
                        childId: childState.selectedChild.childId,
                        accountId: currentItem.accountId,
                        skinToneId: currentSkintone
                    };
                    dispatch(updateImageInImageLibrary(payload, updateOnSuccess, updateOnFailure));
                }
            }
        }
    }

    const handleImageUpload = (event) => {
        event.preventDefault();
        let imageObject = null;
        let fileObject = null;

        if (event.target.files.length > 0) {
            fileObject = event.target.files[0];

            let fileName = fileObject.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {

                const fileURL = URL.createObjectURL(fileObject);
                imageObject = new Image();
                imageObject.src = fileURL;

                setNewAddedImage({ file: fileObject, image: imageObject });
                setShowAddImageDialog(true);
                event.target.value = null;
                setImageError(false)


            }
            else {
                setImageError(true)
            }

        }


    }

    const handleCloseDialog = () => {
        setShowAddImageDialog(false);
    }

    const handleCloseDialogLeave = () => {
        setDialogOpen(false);
    }

    const onImageSave = (data) => {
        setShowAddImageDialog(false);
        setAddSuccess(true);
        addImagePreview(0, data);
    }

    const fetchMoreImages = () => {
        if (!imageLibraryState.loading && !imageLibraryState.secondLoading && imageLibraryState.metaImagesList.hasNextPage) {
            dispatch(getImages(childState.selectedChild.childId, imageLibraryState.metaImagesList.page + 1, searchText, currentCategory, false, currentSkintone));
        }
    }

    const handleUpdateErrorClose = () => {
        setUpdateError(false);
    }
    
    return (
        <div>
            {imageLibraryState.loading && <Loader />}


            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialogLeave} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialogLeave} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("updatedInformationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onCancelPress} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialogLeave} color="primary">{t("cancel")}</Button>
                </DialogActions>
            </Dialog>


            <SnackbarAlert show={updateSuccess} message={t("imageUpdateSuccessMessage")} duration={3000} onCloseAlert={handleUpdateSuccessClose} />
            <SnackbarAlert show={addSuccess} message={t("imageAddSuccessMessage")} duration={5000} onCloseAlert={handleAddSuccessClose} />
            <SnackbarAlert isError={true} show={updateError} message={t("unknownError")} duration={5000} onCloseAlert={handleUpdateErrorClose} />
            <SnackbarAlert isError={true} show={imageError} message={t("imageTypeError")} duration={3000} onCloseAlert={() => setImageError(false)} />

            <Dialog className="AddPopup-Box" disableBackdropClick disableEscapeKeyDown maxWidth="md" open={showAddImageDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">{t("addNewImage")}</DialogTitle>
                <DialogContent>
                    <AddNewImage addedImage={newAddedImage} closeDialog={handleCloseDialog} onSave={onImageSave} />
                </DialogContent>
            </Dialog>
            <Card className="no-style">
                <Grid container spacing={3}>
                    <Grid item lg={9} md={6} xs={12}>
                        <div className="generic-card">
                            <Grid container>
                                <Grid item xs={6}>
                                    <h1>{t("imageLibrary")}</h1>
                                </Grid>
                                <Grid item xs={6} className="d-flex justify-content-end">
                                    <div class="AddNew-Image">
                                        <Input id="newImage" name="newImage" type="file" inputProps={{ accept: 'image/*' }} onChange={handleImageUpload} />
                                        <Link>+ {t("addNewImage")}</Link>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container className="mb-40">
                                <Grid item xs={8} className="ImageLib-Select ImageLib-Select-dropdown">
                                    <Select id="categorySelector" name="categorySelector" value={currentCategory === "" ? "all" : currentCategory} onChange={handleCurrentCategoryChange}>
                                        {getCategories()}
                                    </Select>
                                    <SkinTonePicker 
                                        skinToneData={getSkintonesForPicker()} 
                                        skinToneId={currentSkintone}
                                        onSkinToneChange={handleCurrentSkintoneChange}
                                    />
                                </Grid>
                                <Grid item xs={4} className="d-flex justify-content-end searchInput ImageLib-Search">
                                    <Input
                                        id="searchInput"
                                        name="searchInput"
                                        type="text"
                                        value={searchText || ""}
                                        placeholder={t("searchImage")}
                                        onChange={handleSearchTextChange}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        } />
                                </Grid>
                            </Grid>
                            {
                                !imageLibraryState.loading && !imageLibraryState.secondLoading && imageLibraryState.imagesList.length === 0 ?
                                    <div className="noActivityListing">
                                        <img src={AssetConstants.emptylist} />
                                        <span>{t("noImageFound")}</span>
                                    </div> :
                                    imageLibraryState.imagesList.length > 0 ?
                                        <div ref={ref => { setRef(ref); }} className="imageLibraryScroll">
                                            <InfiniteScroll
                                                pageStart={1}
                                                loadMore={fetchMoreImages}
                                                hasMore={imageLibraryState.metaImagesList.hasNextPage}
                                                loader={<div className="loader mr-auto"></div>}
                                                useWindow={false}
                                                initialLoad={true}
                                                threshold={10}
                                                getScrollParent={() => ref}>
                                                <GridList cellHeight="auto" cols={4} className="ImageLibrary-Listing">
                                                    {getImagesGrid()}
                                                </GridList>
                                            </InfiniteScroll>
                                        </div> :
                                        !imageLibraryState.loading && <div className="loader mr-auto"></div>
                            }
                        </div>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        {
                            previewMode &&
                            <div className="generic-card px-0">
                                <div className="preview-box">

                                    <Card className="no-style">
                                        <figure>
                                            <img src={imageLibraryState.imagesList[previewImageIndex].imageURL} alt={imageLibraryState.imagesList[previewImageIndex].imageTitle} style={{ height: 163, width: 163 }} />
                                        </figure>
                                        {
                                            imageLibraryState.imagesList[previewImageIndex].isMyUpload ?
                                                <div className="form-field">
                                                    <Input id="imageTitle" name="imageTitle" required type="text" className="w-100" value={imageTitle || ""} placeholder={t("exampleToothbrushes")} onChange={handleImageTitleChange} error={showInputError(imageTitleError)} />
                                                    {
                                                        showInputError(imageTitleError) && <FormHelperText error={showInputError(imageTitleError)}>{imageTitleError}</FormHelperText>
                                                    }
                                                </div> :
                                                <h2>{imageLibraryState.imagesList[previewImageIndex].imageTitle}</h2>
                                        }
                                        <h5>{t("categories")}</h5>
                                        <div>
                                            {
                                                imageLibraryState.imagesList[previewImageIndex].isMyUpload ?
                                                    <>
                                                        <div className="preview-box-list">{getUpdateCategoryTags(updateCategories)}</div>
                                                        {
                                                            showInputError(updateCategoriesError) && <FormHelperText error={showInputError(updateCategoriesError)}>{updateCategoriesError}</FormHelperText>
                                                        }
                                                    </> :
                                                    <div className="preview-box-list">
                                                        {getCategoryTags(imageLibraryState.imagesList[previewImageIndex].categories)}
                                                    </div>
                                            }
                                        </div>
                                        <div className="buttons-footer">
                                            <Button variant="contained" disableElevation onClick={() => !checkChanged() ? onCancelPress() : setDialogOpen(true)}>{t("cancel")}</Button>
                                            <Button color="primary" variant="contained" disableElevation onClick={onUploadPress}>{t("upload")}</Button>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

const SkinTonePicker = ({skinToneData, skinToneId, onSkinToneChange }) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState("");
    const [colors, setColors] = useState([]);
    const [selectedSkinToneId, setSelectedSkinToneId] = useState(0);

    const popover = {
        position: 'absolute',
        zIndex: '2',
    };
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };
    
    useEffect(() => {
      let colors = skinToneData.map((skintone, index) => skintone.skinTone);
      setColors(colors);
      if(skinToneId) {
          setSelectedSkinToneId(skinToneId);
          let selectedSkinToneObj = skinToneData.find(skintone => skintone.id === skinToneId);
          if(selectedSkinToneObj) setColor(selectedSkinToneObj.skinTone);
      }
    }, [skinToneData])

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };
    
    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const onColorChange = (val) => {
        setColor(val.hex);

        let selectedSkinToneObj = skinToneData.find(skintone => skintone.skinTone.toLowerCase() === val.hex.toLowerCase());
        if(selectedSkinToneObj.id == "none")
            setSelectedSkinToneId(0);  
        else 
            setSelectedSkinToneId(selectedSkinToneObj.id);
        
        if(onSkinToneChange) onSkinToneChange(selectedSkinToneObj.id);
        
        setDisplayColorPicker(false);
    } 

    return <div>
        <button onClick={ handleClick }>
            <div style={{
                overflow:'hidden',
                width:'100px'
            }}>
                <div style={{
                    borderRadius:"50%", 
                    backgroundColor: color !== "" ? color: 'transparent',
                    width:"20px", height: "20px", float:'left'
                }}></div>
                {"Skin tone"}
            </div>
            <ArrowDropDownIcon />
        </button>
        { displayColorPicker ? <div style={ popover }>
        <div style={ cover } onClick={ handleClose }/>
            <CirclePicker colors={colors} onChange={onColorChange}/>
        </div> : null }
    </div>
}

export default ImageLibrary;