import { request, success, failure } from ".";
import { ACTIVITIES_SELECT_IMAGE_ACTIONS, ADD_ACTIVITY_ACTIONS, SAVE_ACTIVITY_ACTIONS, GET_ACTIVITY_LIST_ACTIONS, GET_COLOR_LIST_ACTIONS, GET_ACTIVITY_ACTIONS, UPDATE_ACTIVITY_ACTIONS, TOOLS_SELECT_IMAGE_ACTIONS, ARCHIVE_ACTIVITY_ACTIONS } from "../../constants/ActionConstants";
import { IMAGE_TYPES, SCREEN_TYPE } from "../../constants/GenericConstants";
import ActivityService from "../../services/api/ActivityService";

export const selectImage = (image, imageType, screenType) => {
    return (dispatch) => {
        if (screenType === SCREEN_TYPE.ADD) {
            if (imageType === IMAGE_TYPES.ACTIVITY_IMAGE) {
                dispatch(request(ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_ACTIVITY_IMAGE, { image: image }));
            } else if (imageType === IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE) {
                dispatch(request(ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_SUBTASK_IMAGE, { image: image }));
            } else if (imageType === IMAGE_TYPES.TOOL_IMAGE) {
                dispatch(request(TOOLS_SELECT_IMAGE_ACTIONS.SELECT_TOOL_IMAGE, { image: image }));
            } else if (imageType === IMAGE_TYPES.TOOL_SUBTASK_IMAGE) {
                dispatch(request(TOOLS_SELECT_IMAGE_ACTIONS.SELECT_SUBTASK_IMAGE, { image: image }));
            }
        } else if (screenType === SCREEN_TYPE.EDIT) {
            if (imageType === IMAGE_TYPES.ACTIVITY_IMAGE) {
                dispatch(request(ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_EDIT_ACTIVITY_IMAGE, { image: image }));
            } else if (imageType === IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE) {
                dispatch(request(ACTIVITIES_SELECT_IMAGE_ACTIONS.SELECT_EDIT_SUBTASK_IMAGE, { image: image }));
            } else if (imageType === IMAGE_TYPES.TOOL_IMAGE) {
                dispatch(request(TOOLS_SELECT_IMAGE_ACTIONS.SELECT_EDIT_TOOL_IMAGE, { image: image }));
            } else if (imageType === IMAGE_TYPES.TOOL_SUBTASK_IMAGE) {
                dispatch(request(TOOLS_SELECT_IMAGE_ACTIONS.SELECT_EDIT_SUBTASK_IMAGE, { image: image }));
            }
        }

    };
}

export const clearImage = (imageType, screenType) => {
    return (dispatch) => {
        if (screenType === SCREEN_TYPE.ADD) {
            if (imageType === IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE) {
                dispatch(request(ACTIVITIES_SELECT_IMAGE_ACTIONS.CLEAR_SUBTASK_IMAGE, {}));
            }
        } else if (screenType === SCREEN_TYPE.EDIT) {
            if (imageType === IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE) {
                dispatch(request(ACTIVITIES_SELECT_IMAGE_ACTIONS.CLEAR_EDIT_SUBTASK_IMAGE, {}));
            }
        }
    };
}

export const addActivity = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ADD_ACTIVITY_ACTIONS.ADD_ACTIVITY, {}));
        try {
            const response = await ActivityService.addActivity(payload);
            dispatch(success(ADD_ACTIVITY_ACTIONS.ADD_ACTIVITY_SUCCESS, {}));
            onSuccess(payload);
        } catch (error) {
            dispatch(failure(ADD_ACTIVITY_ACTIONS.ADD_ACTIVITY_FAILURE, {}));
            onFailure();
        }
    };
}

export const saveData = (payload) => {
    return (dispatch) => {
        dispatch(request(SAVE_ACTIVITY_ACTIONS.SAVE_ACTIVITY, { data: payload }));
    };
}

export const clearActivityData = () => {
    return (dispatch) => {
        dispatch(request(SAVE_ACTIVITY_ACTIONS.CLEAR_ACTIVITY, {}));
    };
}

export const getAllActivities = (childId, page, isArchive, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_ACTIVITY_LIST_ACTIONS.GET_ACTIVITY_LIST, {}));
        try {
            const response = await ActivityService.getAllActivities(childId, page, isArchive);
            setTimeout(() => {
                dispatch(success(GET_ACTIVITY_LIST_ACTIONS.GET_ACTIVITY_LIST_SUCCESS, { data: response.data }));
                onSuccess(response.data.data);
            }, 1000);
        } catch (error) {
            dispatch(failure(GET_ACTIVITY_LIST_ACTIONS.GET_ACTIVITY_LIST_FAILURE, {}));
        }
    };
}

export const getActivity = (activityId, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_ACTIVITY_ACTIONS.GET_ACTIVITY, {}));
        try {
            const response = await ActivityService.getActivity(activityId);
            dispatch(success(GET_ACTIVITY_ACTIONS.GET_ACTIVITY_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_ACTIVITY_ACTIONS.GET_ACTIVITY_FAILURE, {}));
        }
    };
}

export const saveEditData = (payload) => {
    return (dispatch) => {
        dispatch(request(SAVE_ACTIVITY_ACTIONS.SAVE_EDIT_ACTIVITY, { data: payload }));
    };
}

export const updateActivity = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_ACTIVITY_ACTIONS.UPDATE_ACTIVITY, {}));
        try {
            await ActivityService.updateActivity(payload);
            dispatch(success(UPDATE_ACTIVITY_ACTIONS.UPDATE_ACTIVITY_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(UPDATE_ACTIVITY_ACTIONS.UPDATE_ACTIVITY_FAILURE, {}));
            onFailure();
        }
    };
}

export const clearEditActivityData = () => {
    return (dispatch) => {
        dispatch(request(SAVE_ACTIVITY_ACTIONS.CLEAR_EDIT_ACTIVITY, {}));
    };
}

export const archiveActivity = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ARCHIVE_ACTIVITY_ACTIONS.ARCHIVE_ACTIVITY, {}));
        try {
            await ActivityService.archiveActivity(payload);
            dispatch(success(ARCHIVE_ACTIVITY_ACTIONS.ARCHIVE_ACTIVITY_SUCCESS, {}));
            onSuccess();
        } catch (error) {
            dispatch(failure(ARCHIVE_ACTIVITY_ACTIONS.ARCHIVE_ACTIVITY_FAILURE, {}));
            onFailure(error);
        }
    };
}

export const saveCurrentPage = (data) => {
    return (dispatch) => {
        dispatch(request(GET_ACTIVITY_LIST_ACTIONS.SAVE_CURRENT_PAGE, { data }));
    };
}

export const clearCurrentPage = () => {
    return (dispatch) => {
        dispatch(request(GET_ACTIVITY_LIST_ACTIONS.CLEAR_CURRENT_PAGE, {}));
    };
}

export const getColorList = () => {
    return async (dispatch) => {
        dispatch(request(GET_COLOR_LIST_ACTIONS.GET_COLOR_LIST, {}));
        try {
          const colorList = await ActivityService.getColorList();
          dispatch(success(GET_COLOR_LIST_ACTIONS.GET_COLOR_LIST_SUCCESS, { data: colorList.data }));
        } catch (error) {
          dispatch(failure(GET_COLOR_LIST_ACTIONS.GET_COLOR_LIST_FAILURE, {}));
        }
      };
}