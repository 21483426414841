import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function MessageAttachmentPreview(props) {
  const [previewUrl, setPreviewUrl] = useState(null);

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
  };

  useEffect(() => {
    previewFile(props.file);
  }, []);

  const getPreview = () => {
    if (props.file.type.startsWith("video/")) {
      return (
        <video controls width={150} height={100}>
          <source src={previewUrl} type={props.file.type} />
          Your browser does not support the video.
        </video>
      );
    }

    if (props.file.type.startsWith("audio/")) {
      return (
        <audio controls width={150}>
          <source src={previewUrl} type={props.file.type} />
          Your browser does not support the audio.
        </audio>
      );
    }

    return (
      <iframe
        title={props.file.name}
        src={previewUrl}
        width="100px"
        height="100px"
      />
    );
  };

  return <>{previewUrl && getPreview()}</>;
}

export default MessageAttachmentPreview;

MessageAttachmentPreview.propTypes = {
  file: PropTypes.object.isRequired,
};
