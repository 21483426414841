import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";

import { MessageImageCanvas } from "./MessageImageCanvas";
import { removeAttachment } from "../../../../../store/actions/Chat/MessagePanel";

import styles from "./index.module.scss";
import MessageAttachmentPreview from "./MessageAttachmentPreview";

export const MessageAttachmentContainer = () => {
  const { attachments } = useSelector((state) => state.messagePanel);
  const dispatch = useDispatch();

  const onDeleteAttachment = (attachment) => {
    dispatch(removeAttachment(attachment));
  };

  return (
    <Box component="div" className={styles.messageAttachmentContainerStyle}>
      {attachments.map((attachment) => (
        <Box>
          <Box
            component={"div"}
            className={styles.messageAttachmentStyle}
            key={attachment.id}
          >
            {attachment.file.type.startsWith("image") ? (
              <MessageImageCanvas file={attachment.file} />
            ) : (
              <MessageAttachmentPreview file={attachment.file} />
            )}

            <ClearIcon
              className={styles.closeIcon}
              size={30}
              onClick={() => onDeleteAttachment(attachment)}
            />
          </Box>
          <p className={styles.attachmentFileName}>
            {attachment.file.name}
          </p>
        </Box>
      ))}
    </Box>
  );
};
