import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import { MessageItemAttachmentContainer } from "../attachments/MessageItemAttachmentContainer";

const useStyles = makeStyles(() => ({
  messageItemContent: {
    whiteSpace: "pre-wrap",
    alignItems: "flex-end",
    display: "flex",
    maxWidth: 600,
    minWidth: 100,
    borderRadius: "12px 0px 12px 12px",
    padding: "10px 16px",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
  },
  wrapper: {
    position: "relative",
    backgroundColor: "#EFF5FF",
    borderRadius: " 12px 0px 12px 12px",
    maxWidth: "60%",
    minWidth: 100,
    padding: "10px 16px",
    marginBottom: 10,
    color: "#333333",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
    fontSize: 14,
  },
  message: {
    // font-family: 'Open Sans';
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "22px",
  },

  authorName: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "22px",
  },
  right: {
    position: "absolute",
    top: 0,
    right: -11,
    borderLeft: "20px solid #EFF5FF",
    borderBottom: "20px solid transparent",
  },
}));

export const MessageRight = ({ message, isAttachmentType, padding }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapper}
      style={{
        // maxWidth: isAttachmentType ? "200px" : "60%",
      }}
    >
      <p className={classes.message}>
        <MessageItemAttachmentContainer message={message} />
        <span>{message.content || null}</span>
      </p>

      <span class={classes.right}></span>
    </Box>
  );

  //   return (
  //     <Box
  //       className={classes.messageItemContent}
  //       style={{
  //         backgroundColor: "#EFF5FF",
  //         color: "#333333",
  //         // marginRight: isAuthor ? 10 : 0,
  //       }}
  //     >
  //       <div>
  //         {message.attachments?.length === 0 ? (
  //           <span>{message.content || null}</span>
  //         ) : null}
  //         <MessageItemAttachmentContainer message={message} />
  //       </div>
  //     </Box>
  //   );
};
