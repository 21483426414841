import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputLabel, Input, FormHelperText, FormControlLabel, Checkbox, Button, Slider, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Cropper from 'react-easy-crop';
import { validateMandatoryValue, validateLength100C } from "../../helpers/Validation";
import { getCroppedImg } from '../../helpers/ImageCrop';
import { getPresignedURL, uploadImage, addImageInImageLibrary, showLoader } from "../../store/actions/ImageLibrary";

import imageCompression from 'browser-image-compression';
import Loader from "../Loader/Loader";


const CropperComponent = (props) => {

    const { t } = useTranslation();
    const { addedImage, closeDialog, onSave, compressing, setCompressing } = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    const [sliderValue, setSliderValue] = useState(1.5);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.5);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    let croppedImageFile;
    var compressedFileTemp = {};






    const handleSliderValueChange = (event, newValue) => {
        setSliderValue(newValue);
        setZoom(newValue);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }



    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const checkChanged = () => {
        let changed = false
        return true;
    }












    const onSubmit = () => {
        setCompressing(true)
        getCroppedImg(addedImage.image.src, croppedAreaPixels, addedImage.file.type, 0, afterCrop);
    }

    const afterCrop = (blob) => {
        croppedImageFile = new File([blob], addedImage.file.name, { type: blob.type });
        compressImage(croppedImageFile)
    }

    const compressImage = (croppedImageFile) => {
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 200,
            useWebWorker: true
        }
        if (croppedImageFile) {
            imageCompression(croppedImageFile, options)
                .then(function (compressedFile) {

                    setCompressing(false)
                    compressedFileTemp = compressedFile;
                    onSave(compressedFileTemp)


                })
                .catch(function (error) {

                });
        }
    }

    return (
        <div className="c-AddPopupBox">



            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("informationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={closeDialog} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>
                </DialogActions>
            </Dialog>



            <Grid container spacing={3} justify="center">

                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="imgCropContainer">
                        {addedImage && addedImage.image &&
                            <Cropper
                                image={addedImage.image.src}
                                crop={crop}
                                zoom={zoom}
                                showGrid={false}
                                onCropChange={(value) => {
                                    setCrop(value)
                                }}
                                onCropComplete={onCropComplete}
                                aspect={1}

                            />
                        }
                    </div>
                    <div className="slider">
                        <div className="c-slider">
                            <ImageOutlinedIcon />
                            <Slider value={sliderValue} onChange={handleSliderValueChange} min={1} max={3} step={0.1} />
                            <ImageOutlinedIcon />
                        </div>
                    </div>
                </Grid>



            </Grid>

            <footer className="AddPopup-Footer">
                <div className="buttons-footer">
                    <Button variant="contained" onClick={() => {
                        if (checkChanged()) {
                            setDialogOpen(true)
                        }
                        else {
                            closeDialog()
                        }

                    }}>{t("cancel")}</Button>
                    <Button variant="contained" onClick={onSubmit} color="primary">{t("save")}</Button>
                </div>
            </footer>

        </div>
    );
}

export default CropperComponent;