import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { APP_CONSTANTS } from "../../constants/GenericConstants";
import { handleResponse, handleError, handleS3ImageUploadResponse } from "./GenericResponseHandler";

class ImageLibraryService {

    getCategories() {
        let url = UrlConstants.GET_IMAGE_CATEGORIES;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSkintones() {
        let url = UrlConstants.GET_IMAGE_SKINTONES;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getImages(childId, page, searchTerm, category, skintoneId) {
        let url = UrlConstants.GET_IMAGE_LIBRARY.replace(/{childId}/, childId);
        url = url + '&page=' + page + '&take=' + APP_CONSTANTS.INITIAL_IMAGES;
        if (searchTerm.length > 0) {
            url = url + "&q=" + searchTerm;
        }
        
        if (category.length > 0) {
            if (category === APP_CONSTANTS.MY_UPLOADS_KEY) {
                url = url + '&isMyUpload=true';
            } else {
                url = url + '&category=' + category;
            }
        }
        if (skintoneId && skintoneId !== "" && skintoneId > 0 ) {
            url = url + '&skinToneId=' + skintoneId;
            // if (category === APP_CONSTANTS.MY_UPLOADS_KEY) {
            //     url = url + '&isMyUpload=true';
            // } else {
            //     url = url + '&category=' + category;
            // }
        }

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getPresignedURL(file) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=library&op=upload&file=" + file;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    uploadImagePut(imageData, urlAws) {
        let url = urlAws;

        var options = {
            headers: {
                'Content-Type': "image/*"
            }
        };

        return apiClient()
            .uploadImage(url, imageData, options)
            .then(handleS3ImageUploadResponse)
            .catch(handleError);
    }

    addImage(payload) {
        let url = UrlConstants.ADD_IMAGE_IN_IMAGE_LIBRARY;

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    updateImage(payload) {
        let url = UrlConstants.ADD_IMAGE_IN_IMAGE_LIBRARY;

        return apiClient()
            .put(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new ImageLibraryService();