import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Switch, HashRouter } from "react-router-dom";
import configureStore from "./store/configureStore";
import App from "./screens/App/App";
import RouteConstants from "./constants/RouteConstants";
import { PrivateRoute } from "./components/Routes/PrivateRoute";
import { PrivatePaymentValidRoute } from "./components/Routes/PrivatePaymentValidRoute";
import { PublicRoute } from "./components/Routes/PublicRoute";
import { EmailVerifyRoute } from "./components/Routes/EmailVerifyRoute";
import { PaymentRoute } from "./components/Routes/PaymentRoute";
import SetupChildProfileScreen from "./screens/SetupChildProfileScreen/SetupChildProfileScreen";
import ExternalUsersScreen from "./screens/ExternalUsersScreen/ExternalUsersScreen";
import AddExternalUserScreen from "./screens/ExternalUsersScreen/AddExternalUserScreen";
import ViewExternalUserScreen from "./screens/ExternalUsersScreen/ViewExternalUserScreen";
import EditChildScreen from "./screens/EditChildScreen/EditChildScreen";
import ExternalUserConfirmAccountScreen from "./screens/ExternalUserConfirmAccountScreen/ExternalUserConfirmAccountScreen";
import ImageLibraryScreen from "./screens/ImageLibraryScreen/ImageLibraryScreen";
import ChildActivitiesScreen from "./screens/ChildActivitiesScreen/ChildActivitiesScreen";
import AddActivityScreen from "./screens/AddActivityScreen/AddActivityScreen";
import EditActivityScreen from "./screens/EditActivityScreen/EditActivityScreen";
import ScheduleActivitiesScreen from "./screens/ScheduleActivitiesScreen/ScheduleActivitiesScreen";
import ResetPasswordScreen from "./screens/ResetPassword/ResetPasswordScreen";
import ForgotPasswordScreen from "./screens/ForgotPassword/ForgotPasswordScreen";
import ResetForgotPassword from "./screens/ForgotPassword/ResetForgotPassword";
import SelfRegulationScreen from "./screens/SelfRegulationScreen/SelfRegulationScreen";
import AddRegulationToolScreen from "./screens/AddRegulationToolScreen/AddRegulationToolScreen";
import EditRegulationToolScreen from "./screens/EditRegulationToolScreen/EditRegulationToolScreen";
import AccountScreen from "./screens/AccountScreens/AccountScreen";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import SignupScreen from "./screens/SignupScreen/SignupScreen";
import EmailVerificationScreen from "./screens/EmailVerificationScreen/EmailVerificationScreen";
import ConfirmEmailScreen from "./screens/ConfirmEmailScreen/ConfirmEmailScreen";
import SidebarScreen from "./screens/SidebarScreen/SidebarScreen";
import { AppEnvironment } from "./services/api/ApiClient";
import { registerDeviceForPushNotifications, unRegisterDeviceForPushNotifications } from "./store/actions/Authentication";
import ScrollToTop from "./helpers/ScrollToTop";
import NotificationHandler from "./components/Routes/NotificationHandler";
import PaymentSignupScreen from "./screens/PaymentSignupScreen/PaymentSignupScreen";
import PaymentFailureScreen from "./screens/PaymentSignupScreen/PaymentFailureScreen";
import PaymentSuccessScreen from "./screens/PaymentSignupScreen/PaymentSuccessScreen";
import InactivityLogout from "./components/Routes/InactivityLogout";
import SignupUserTypeScreen from "./screens/SignupScreen/SignupUserTypeScreen";
import MainChatScreen from "./screens/ChatScreen/MainChatScreen";

import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { ToastContainer } from "react-toastify";
import { SocketProvider } from "./context/SocketContext";
import SocketConnection  from "./helpers/SocketConnection";

import 'react-toastify/dist/ReactToastify.css';
import './lib/mixpanel';

const store = configureStore();
const history = createBrowserHistory();

const InitializeApp = () => {
  useEffect(() => {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;

    OneSignal.push(function () {
      OneSignal.init({
        appId: AppEnvironment.config().oneSignal.appId,
        safari_web_id: AppEnvironment.config().oneSignal.safariId,
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
        notificationClickHandlerMatch: "origin",
        notificationClickHandlerAction: "focus",
      });
    });
    if ("serviceWorker" in navigator && !navigator.serviceWorker.controller) {
      navigator.serviceWorker.register("/OneSignalSDKWorker.js");
    }

    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on("subscriptionChange", function (isSubscribed) {
        // console.log("The user's subscription state is:", isSubscribed);
        if (isSubscribed) {
          registerDeviceForPushNotifications();
        } else {
          unRegisterDeviceForPushNotifications()
        }
      });
    });

    // Cleanup function if needed
    return () => {
      OneSignal.off('subscriptionChange');
    };
  }, []);

  return (
    <FlagsmithProvider
      options={{
        environmentID: AppEnvironment.config().flagsmithEnvironmentId,
      }}
      flagsmith={flagsmith}
    >
    <SocketProvider>
      <SocketConnection />
      <Provider store={store}>
        <HashRouter history={history}>
          <ScrollToTop />
          <NotificationHandler />
          <InactivityLogout />
          <Switch>
            <PublicRoute path={RouteConstants.LOGIN} component={LoginScreen} />
            <PublicRoute
              path={RouteConstants.SIGN_UP_USER_TYPE}
              component={SignupUserTypeScreen}
            />
            <PublicRoute
              path={RouteConstants.SIGN_UP}
              component={SignupScreen}
            />
            <PublicRoute
              path={RouteConstants.CONFIRM_EMAIL}
              component={ConfirmEmailScreen}
            />
            <PublicRoute
              path={RouteConstants.CONFIRM_ACCOUNT}
              component={ExternalUserConfirmAccountScreen}
            />
            <PublicRoute
              path={RouteConstants.RESET_FORGOTTEN_PASSWORD}
              component={ResetForgotPassword}
            />
            <PublicRoute
              path={RouteConstants.FORFOT_PASSWORD}
              component={ForgotPasswordScreen}
            />
            <EmailVerifyRoute
              path={RouteConstants.VERIFY_EMAIL}
              component={EmailVerificationScreen}
            />
            <PaymentRoute
              path={RouteConstants.PAYMENT}
              component={PaymentSignupScreen}
            />
            <PaymentRoute
              path={RouteConstants.PAYMENT_SUCCESS}
              component={PaymentSuccessScreen}
            />
            <PaymentRoute
              path={RouteConstants.PAYMENT_FAILURE}
              component={PaymentFailureScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.SIDE_BAR}
              component={SidebarScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.CHAT}
              component={MainChatScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.GROUP}
              component={MainChatScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.SETUP_CHILD_PROFILE}
              component={SetupChildProfileScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.EXTERNAL_USERS}
              component={ExternalUsersScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.ADD_EXTERNAL_USERS}
              component={AddExternalUserScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.VIEW_EXTERNAL_USERS}
              component={ViewExternalUserScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.EDIT_CHILD_PROFILE}
              component={EditChildScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.IMAGE_LIBRARY}
              component={ImageLibraryScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.CHILD_ACTIVITIES}
              component={ChildActivitiesScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.ADD_ACTIVITY}
              component={AddActivityScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.EDIT_ACTIVITY}
              component={EditActivityScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.SCHEDULE_ACTIVITY}
              component={ScheduleActivitiesScreen}
            />
            <PrivateRoute
              path={RouteConstants.RESET_PASSWORD}
              component={ResetPasswordScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.SELF_REGULATION}
              component={SelfRegulationScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.ADD_SELF_REGULATION_TOOL}
              component={AddRegulationToolScreen}
            />
            <PrivatePaymentValidRoute
              path={RouteConstants.EDIT_SELF_REGULATION_TOOL}
              component={EditRegulationToolScreen}
            />
            <PrivateRoute
              path={RouteConstants.ACCOUNT_SETTINGS}
              component={AccountScreen}
            />
            <PrivateRoute path="/" component={App} />
          </Switch>
        </HashRouter>
      </Provider>
        <ToastContainer />
      </SocketProvider>
    </FlagsmithProvider>
  );
};

export default InitializeApp;
