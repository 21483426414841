import { request, success, failure } from ".";
import { SCHEDULE_GET_ACTIVITIES_ACTIONS, SCHEDULE_ACTIVITY_ACTIONS, UPDATE_SCHEDULE_ACTIVITY_ACTIONS, GET_SCHEDULE_LIST_ACTIONS, DELETE_SCHEDULE_ACTIVITY_ACTIONS, SEARCH_ACTIVITY_ACTIONS } from "../../constants/ActionConstants";
import ScheduleService from "../../services/api/ScheduleService";

export const getScheduleActivities = (childId, page, searchTerm) => {
    return async (dispatch) => {
        if (page === 1) {
            dispatch(request(SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES_CLEAR_OLD_DATA, {}));
        } else {
            dispatch(request(SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES, {}));
        }
        try {
            const response = await ScheduleService.getActivities(childId, page, searchTerm);
            dispatch(success(SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES_SUCCESS, { data: response.data }));
        } catch (error) {
            dispatch(failure(SCHEDULE_GET_ACTIVITIES_ACTIONS.SCHEDULE_GET_ACTIVITIES_FAILURE, {}));
        }
    };
}

export const scheduleActivity = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(SCHEDULE_ACTIVITY_ACTIONS.SCHEDULE_ACTIVITY, {}));
        try {
            const response = await ScheduleService.scheduleActivity(payload);
            dispatch(success(SCHEDULE_ACTIVITY_ACTIONS.SCHEDULE_ACTIVITY_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(SCHEDULE_ACTIVITY_ACTIONS.SCHEDULE_ACTIVITY_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
}

export const updateScheduleActivity = (payload, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_SCHEDULE_ACTIVITY_ACTIONS.UPDATE_SCHEDULE_ACTIVITY, {}));
        try {
            const response = await ScheduleService.updateScheduleActivity(payload);
            dispatch(success(UPDATE_SCHEDULE_ACTIVITY_ACTIONS.UPDATE_SCHEDULE_ACTIVITY_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(UPDATE_SCHEDULE_ACTIVITY_ACTIONS.UPDATE_SCHEDULE_ACTIVITY_FAILURE, {}));
            onFailure(error.message ?? (error.data && error.data.message? error.data.message: ""));
        }
    };
}

export const getScheduleList = (childId, startDate, type, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(GET_SCHEDULE_LIST_ACTIONS.GET_SCHEDULE_LIST, {}));
        try {
            const response = await ScheduleService.getScheduleList(childId, startDate, type);
            dispatch(success(GET_SCHEDULE_LIST_ACTIONS.GET_SCHEDULE_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_SCHEDULE_LIST_ACTIONS.GET_SCHEDULE_LIST_FAILURE, {}));
        }
    };
}

export const deleteScheduleActivity = (scheduleId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(DELETE_SCHEDULE_ACTIVITY_ACTIONS.DELETE_SCHEDULE_ACTIVITY, {}));
        try {
            const response = await ScheduleService.deleteScheduleActivity(scheduleId);
            dispatch(success(DELETE_SCHEDULE_ACTIVITY_ACTIONS.DELETE_SCHEDULE_ACTIVITY_SUCCESS, {}));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(DELETE_SCHEDULE_ACTIVITY_ACTIONS.DELETE_SCHEDULE_ACTIVITY_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
}

export const searchScheduledActivities = (childId, startDate, type, searchTerm, onSuccess) => {
    return async (dispatch) => {
        dispatch(request(SEARCH_ACTIVITY_ACTIONS.SEARCH_ACTIVITY_LIST, {}));
        try {
            const response = await ScheduleService.searchScheduledActivities(childId, startDate, type, searchTerm);
            dispatch(
                success(SEARCH_ACTIVITY_ACTIONS.SEARCH_ACTIVITY_LIST_SUCCESS, 
                    { data: response.data })
            );
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(SEARCH_ACTIVITY_ACTIONS.SEARCH_ACTIVITY_LIST_FAILURE, {}));
        }
    };
}