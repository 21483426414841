import React from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel } from "@material-ui/core";

const ChildStepper = (props) => {

    const { t } = useTranslation();
    const { activeStep, goToOtherStep } = props;

    const getSteps = () => {
        // THRIV-1603 changes
        // return [t("personalInformation"), t("contactAndAddress"), t("medicalCondition"), t("otherInformation")];
        return [t("personalInformation"), t("contactAndAddress")];
    }

    const CustomStepIcon = (props) => {
        const { active, completed, icon } = props;

        if (completed) {
            return <div className="step-completed" style={{ backgroundColor: '#02c8a7', borderColor: '#02c8a7', color: '#ffffff' }}>{icon}</div>;
        } else if (active) {
            return <div className="step-active" style={{ backgroundColor: '#103170', borderColor: '#103170', color: '#fff' }}>{icon}</div>;
        } else {
            return <div className="step-default" style={{ backgroundColor: '#fff', color: '#a4a4a4', borderColor: '#ddd' }}>{icon}</div>
        }
    }

    const moveToOtherStep = (index) => {
        if(index === activeStep) {
            return;
        }
        goToOtherStep(index);
    }

    return (

        <Stepper activeStep={activeStep - 1} alternativeLabel>
            {getSteps().map((label, index) => (
                <Step key={label} onClick={() => moveToOtherStep(index+1)}>
                    <StepLabel StepIconComponent={CustomStepIcon}>
                        {index < activeStep - 1 ? <div style={{ color: '#02c8a7' }}>{label}</div> :
                         index === activeStep - 1 ? <div style={{ color: '#103170' }}>{label}</div> :
                         <div style={{ color: '#a4a4a4' }}>{label}</div>
                        }
                    </StepLabel>
                </Step>
            ))}
        </Stepper>

    );
}

export default ChildStepper;