import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Container, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab";
import StepOne from "../../components/SetupChildProfile/StepOne/StepOne";
import StepTwo from "../../components/SetupChildProfile/StepTwo/StepTwo";
import StepThree from "../../components/SetupChildProfile/StepThree/StepThree";
import StepFour from "../../components/SetupChildProfile/StepFour/StepFour";
import { addChild, getPresignedURL, uploadImage } from "../../store/actions/Child";
import { ADD_CHILD_STATUS } from "../../constants/GenericConstants";
import RouteConstants from "../../constants/RouteConstants";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AssetConstants from "../../constants/AssetConstants";
import { getTimeInMS } from "../../helpers/GenericHelper";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";


const SetupChildProfileScreen = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const childState = useSelector((state) => state.child);

    const [step, setStep] = useState(1);
    const [addedChildId, setAddedChildId] = useState(0);

    const [stepOneData, setStepOneData] = useState(
        {
            firstName: "",
            lastName: "",
            month: "",
            date: "",
            year: "",
            age: "",
            email: "",
            gender: "",
            childImage: {},
        }
    );

    const [stepOneErrors, setStepOneErrors] = useState(
        {
            firstNameError: "",
            lastNameError: "",
            dateOfBirthError: "",
            ageError: "",
            emailError: "",
            genderError: "",
            childImageError: "",
        }
    );

    const [stepTwoData, setStepTwoData] = useState(
        {
            homeAddress: "",
            schoolName: "",
            schoolAddress: "",
            contactName1: "",
            contactPhone1: "",
            contactName2: "",
            contactPhone2: "",
            newsletterSubscribed: false
        }
    );
    const [stepTwoErrors, setStepTwoErrors] = useState(
        {
            schoolNameError: "",
            contactName1Error: "",
            contactPhone1Error: "",
            contactName2Error: "",
            contactPhone2Error: "",
        }
    );

    const [childAdded, setChildAdded] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [apiError, setApiError] = useState("");

    const onAddChildSuccess = (data) => {
        //mixpanel
        Mixpanel.track(MIX_PANEL_EVENTS.USER_PROFILE_CREATED, {
            profileId: data.id,
            newsletterSubscribed: data.newsletterSubscribed,
            firstName: data.profile.firstName,
            lastName: data.profile.lastName,
            emailAddress: data.profile.emailAddress,
            dateOfBirth: data.profile.dateOfBirth,
            status: data.status
        });

        setChildAdded(true);
        setAddedChildId(data ? data.id : -1)
    }

    const onAddChildFailure = (errorMessage) => {
        setApiError(errorMessage);
    }

    const onCancel = () => {
        const { firstName, lastName, month, date, year, age, email, gender } = stepOneData;
        if (firstName === "" && lastName === "" && month === "" && date === "" && year === "" && age === "" && email === "" && gender === "") {
            goToDashboard();
        } else {
            setDialogOpen(true);
        }
    }

    const onSubmit = () => {
        if (step === 2) {
            if (Object.keys(stepOneData.childImage).length !== 0) {
                stepOneData.childImage.file.name = getTimeInMS(stepOneData.childImage.file.name);
                dispatch(getPresignedURL(stepOneData.childImage.file.name, onGetUrl, onFailedUrl))
            }
            // else {
            // dispatch(addChild(ADD_CHILD_STATUS.ACTIVE, step, stepOneData, stepTwoData, stepThreeData, stepFourData, onAddChildSuccess, onAddChildFailure));
            // }
        } else {
            setStep(step + 1);
        }
    }


    const onGetUrl = (res) => {
        dispatch(uploadImage(stepOneData.childImage.file, res.data.url,
            (res) => {
                console.log("Success Called", res);
                dispatch(addChild(ADD_CHILD_STATUS.ACTIVE, step, stepOneData, stepTwoData, onAddChildSuccess, onAddChildFailure));
            }
            ,
            (err) => {
                console.log("Error Called", err);
            }
        ))
    }

    const onFailedUrl = (err) => {

    }

    const changeStep = (stepNumber) => {
        setStep(stepNumber);
    }

    const stepBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }


    const renderStepCard = () => {
        switch (step) {
            case 1:
                return <StepOne
                    step={step}
                    data={stepOneData}
                    setData={setStepOneData}
                    errors={stepOneErrors}
                    setErrors={setStepOneErrors}
                    changeStep={changeStep}
                    onCancel={onCancel}
                    onSubmit={onSubmit} />;
            case 2:
                return <StepTwo
                    step={step}
                    data={stepTwoData}
                    setData={setStepTwoData}
                    errors={stepTwoErrors}
                    setErrors={setStepTwoErrors}
                    changeStep={changeStep}
                    stepBack={stepBack}
                    onCancel={onCancel}
                    onSubmit={onSubmit} />;

            default: 
                return null
        }
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const goToDashboard = () => {
        history.replace({ pathname: RouteConstants.DASHBOARD });
    }

    const moveToChildProfile = () => {
        history.push({ pathname: RouteConstants.DASHBOARD });
    }

    const moveToEditChildScreen = () => {
        // alert(JSON.stringify(childState.addedChildData.profile))
        history.replace({ pathname: RouteConstants.EDIT_CHILD_PROFILE.replace(':childId', addedChildId) });
    }

    return (
        <div>
            {childState.loading && <Loader />}
            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("informationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={goToDashboard} color="primary">{t("leavePage")}</Button>
                </DialogActions>
            </Dialog>
            <Grid container className="app">
                <Grid xs={12} item className="app-header">
                    <Header />
                </Grid>
                <Grid item xs={12} className="grid-body">
                    <Container maxWidth="xl">
                        <div>
                            {
                                !childAdded ?
                                    <Container maxWidth="md">
                                        < h1 > {t("setupChildProfileHeading")}</h1 >
                                        {
                                            apiError && <Alert severity="error">{apiError}</Alert>
                                        }
                                        {renderStepCard()}
                                    </Container > :
                                    <Container maxWidth='sm'>
                                        <div className="create-child-profile-success">
                                            {/* <img alt="Congratulations image" /> */}
                                            <img src={AssetConstants.createprofilescucess} />
                                            <h1>{t("childCreatedHeading").replace(/{childName}/, childState.addedChildData.profile.firstName + " " + childState.addedChildData.profile.lastName)}</h1>
                                            <h4>{t("nextStepsLine")}</h4>
                                            <ol>
                                                <li>{t("ZeroStepChildCreated")}
                                                    <a href="https://apps.apple.com/us/app/thrive-thriving-on-spectrum/id1570418546" 
                                                        target="_blank" className="text-decoration-none">
                                                            "Thriving on the Spectrum"
                                                    </a>
                                                </li>
                                                <li>{t("firstStepChildCreated")}</li>
                                                <li>{t("secondStepChildCreated").replace(/{code}/, childState.addedChildData.childCode)}</li>
                                                <li>{t("thiredStepChildCreated")}</li>
                                            </ol>
                                            <Grid spacing={1} justify="center" container>
                                                <Button color="primary" variant="contained" disableElevation onClick={moveToEditChildScreen}>{t("viewFullProfile")}</Button>
                                                <Button color="primary" variant="contained" disableElevation onClick={goToDashboard}>{t("goToDashboard")}</Button>
                                            </Grid>

                                        </div>
                                    </Container>
                            }
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl">
                        <Grid container spacing={6} className="my-0">
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}

export default SetupChildProfileScreen;