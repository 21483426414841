import { Box, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SocketContext } from "../../../context/SocketContext";
import { updateType } from "../../../store/actions/Chat";
import { fetchGroupMessages } from "../../../store/actions/Chat/Group";
import { resetGroupMessages } from "../../../store/actions/Chat/GroupMessages";
import { MessagePanel } from "../components/messages/MessagePanel";

const useStyles = makeStyles(() => ({
  conversationChannelPageStyle: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#FFF",
  },
}));

const GroupChannelPage = () => {
  const classes = useStyles();

  const { id: groupId } = useParams();

  const { socket } = useContext(SocketContext);

  const dispatch = useDispatch();

  const { searchTerm } = useSelector((state) => state.groupMessages);

  useEffect(() => {
    dispatch(updateType("group"));
  }, [groupId, dispatch]);

  useEffect(() => {
    dispatch(fetchGroupMessages(groupId, 1, searchTerm));
  }, [groupId, dispatch, searchTerm]);

  useEffect(() => {
    console.log('socket GroupChannelPage =>', socket)
    if (socket) {
      socket.emit("onGroupJoin", { groupId });
    }

    return () => {
      if (socket) {
        socket.emit("onGroupLeave", { groupId });

        dispatch(resetGroupMessages());
      }
    };
  }, [socket, groupId, dispatch]);

  return (
    <Box className={classes.conversationChannelPageStyle}>
      <MessagePanel />
    </Box>
  );
};

export default GroupChannelPage;
