import { useContext, useEffect } from "react";
import io from "socket.io-client";

import { SocketContext } from "../context/SocketContext";
import { AppEnvironment } from "../services/api/ApiClient";

function SocketConnection() {
  const { socket, setSocket, token, setToken } = useContext(SocketContext);

  useEffect(() => {
    console.log("SocketConnection", {
      socket: socket?.connected,
      token,
    });

    if (!socket && token) {
      const newSocket = io(AppEnvironment.config().socketURL, {
        withCredentials: true,
        // reconnectionAttempts: 5,
        autoConnect: true,
        query: {
          authorization: token,
        },
      });

      setSocket(newSocket);
    }

    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
        setToken(null);
      }
    };
  }, [socket, setSocket, token, setToken]);

  // useEffect(() => {
  //   console.log('SocketConnection useEffect')
  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //       setSocket(null);
  //       setToken(null)
  //     }
  //   };
  // }, [socket]);

  return null;
}

export default SocketConnection;
