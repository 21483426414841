import authentication from "./Authentication";
import child from "./Child";
import externalUser from "./ExternalUser";
import imageLibrary from "./ImageLibrary";
import activities from "./Activities";
import schedule from "./Schedule";
import selfRegulation from "./SelfRegulation";
import notifications from "./Notifications";
import sideBar from "./SideBar";
import selectedType from "./Chat/ChatType";
import chat from "./Chat";
import conversations from "./Chat/Conversations";
import messages from "./Chat/Messages";
import messagePanel from "./Chat/MessagePanel";
import groups from "./Chat/Groups";
import groupMessages from "./Chat/GroupMessages";
import recipientReducer from "./Chat/Recipients";
import filtersReducer from "./Chat/Filters";

export default {
  authentication,
  child,
  externalUser,
  imageLibrary,
  activities,
  schedule,
  selfRegulation,
  notifications,
  sideBar,
  selectedConversationType: selectedType,
  chat,
  conversation: conversations,
  messages,
  messagePanel,
  groups,
  groupMessages,
  recipients: recipientReducer,
  filters: filtersReducer
};
