import {
  APP_CONSTANTS,
  ActivityColor,
  PAYMENT_PLAN,
  SUBSCRIPTION_STATUS,
  ROLE_TYPES,
} from "../constants/GenericConstants";
import queryString from "query-string";
import moment from "moment";

export function formatTimerNumber(num) {
  if (num < 10) {
    return "0" + num;
  }
  return "" + num;
}

export function getMonthsDays() {
  return {
    january: { order: 0, days: 31 },
    february: { order: 1, days: 29 },
    march: { order: 2, days: 31 },
    april: { order: 3, days: 30 },
    may: { order: 4, days: 31 },
    june: { order: 5, days: 30 },
    july: { order: 6, days: 31 },
    august: { order: 7, days: 31 },
    september: { order: 8, days: 30 },
    october: { order: 9, days: 31 },
    november: { order: 10, days: 30 },
    december: { order: 11, days: 31 },
  };
}

export function differenceYears(date1, date2) {
  let diff = Math.abs(date2 - date1) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(Math.floor(diff / 365.25)).toString();
}

export function equalArrays(array1, array2) {
  if (array1 == null || array2 == null) return false;
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
}

export function sameArray(arr1, arr2) {
  let sameValues = true;
  let arr1T = arr1.concat().sort();
  let arr2T = arr2.concat().sort();

  for (let i = 0; i < arr1T.length; i++) {
    if (arr1T[i] !== arr2T[i]) {
      sameValues = false;
    }
  }
  if (arr1.length !== arr2.length) {
    sameValues = false;
  }
  return sameValues;
}

export function equalMedicationArrays(array1, array2) {
  if (array1 == null || array2 == null) return false;
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if (
      array1[i].name !== array2[i].name ||
      array1[i].dosePerDay !== array2[i].dosePerDay ||
      array1[i].description !== array2[i].description
    ) {
      return false;
    }
  }
  return true;
}

export function getValidTags(array) {
  return array.filter((tagValue) => tagValue.length > 0);
}

export function convertStringIntoBoolean(str) {
  if (str === "true") return true;
  return false;
}

export function toTitleCase(str) {
  str = str.toLowerCase();
  let wordsArray = str.split(" ");
  for (let i = 0; i < wordsArray.length; i++) {
    wordsArray[i] = wordsArray[i][0].toUpperCase() + wordsArray[i].slice(1);
  }
  return wordsArray.join(" ");
}

export function getDropdownCategories(categories) {
  return [
    { value: "My uploads", name: APP_CONSTANTS.MY_UPLOADS_KEY },
    ...categories,
    { value: "All", name: "all" },
  ];
}

export function getDropdownSkintones(skintones) {
  return [
    { skinTone: "none", skinToneLabel: "None", id: "none" },
    ...skintones,
  ];
}

export function getColor(color) {
  switch (color) {
    case ActivityColor.YELLOW:
      return "#f9be02";

    case ActivityColor.RED:
      return "#f24c4e";

    case ActivityColor.GREEN_BLUE:
      return "#7cdbdf";

    case ActivityColor.SEA_GREEN:
      return "#02c8a7";

    case ActivityColor.DARK_BLUE:
      return "#0f3171";

    case ActivityColor.BLUE:
      return "";

    case ActivityColor.GREEN:
      return "";

    case ActivityColor.BLACK:
      return "";

    case ActivityColor.ORANGE:
      return "#ef661b";

    case ActivityColor.GREY:
      return "#6d7278";

    case ActivityColor.NAVY_BLUE:
      return "#103170";

    case ActivityColor.LIGHT_GREEN:
      return "#6dd401";

    case ActivityColor.SKY_BLUE:
      return "#32c5ff";
  }
}

export function getActivityBackgroundColor(color) {
  return color + "40";
}

export function getColorForSchedule(color) {
  return color.substring(1) + "h";
}

export function extractColorFromSchdule(color) {
  return "#" + color.substring(0, color.length - 1);
}

export function getTimeValueByLabel(timeValue) {
  const comp = timeValue.split(" ");
  const meridian = comp[1];
  if (meridian === "AM") {
    const timeComp = comp[0].split(":");
    const hour = timeComp[0];
    const minutes = timeComp[1];
    if (hour === "12") {
      return "00:" + minutes + ":00";
    } else {
      return comp[0] + ":00";
    }
  } else if (meridian === "PM") {
    const timeComp = comp[0].split(":");
    const hour = timeComp[0];
    const minutes = timeComp[1];
    if (hour === "12") {
      return hour + ":" + minutes + ":00";
    } else {
      const newHour = "" + (+hour + 12);
      return newHour + ":" + minutes + ":00";
    }
  } else {
    return "";
  }
}

export function getTimeLabelByValue(timeLabel) {
  const comp = timeLabel.split(":");
  let hour = comp[0];
  let hourNum = +comp[0];
  let minutes = comp[1];
  let meridian = "";
  if (hour === "00") {
    hour = "12";
    meridian = "AM";
  } else if (hourNum >= 1 && hourNum <= 11) {
    meridian = "AM";
  } else if (hourNum === 12) {
    meridian = "PM";
  } else {
    meridian = "PM";
    hourNum -= 12;
    hour = formatTimerNumber(hourNum);
  }
  return hour + ":" + minutes + " " + meridian;
}

export function getTimeDifferenceInMinutes(time1, time2) {
  const time1V = getTimeValueByLabel(time1);
  const time2V = getTimeValueByLabel(time2);
  let date1 = new Date();
  date1.setHours(time1V.substring(0, 2));
  date1.setMinutes(time1V.substring(3, 5));
  date1.setSeconds(time1V.substring(6));
  let date2 = new Date();
  date2.setHours(time2V.substring(0, 2));
  date2.setMinutes(time2V.substring(3, 5));
  date2.setSeconds(time2V.substring(6));

  if (time2V === "00:00:00") {
    date2.setDate(date2.getDate() + 1);
  }
  return Math.round((date2 - date1) / 1000 / 60);
}

export function timeDropDownSetting(startTime) {
  const timeList = [];

  // convert 12hr clock time into 24hr to avoid amPm handling
  startTime = getTimeValueByLabel(startTime);

  // set startTime parameter in the date object
  const date = moment(new Date());
  date.set("hours", startTime.substring(0, 2));
  date.set("minutes", startTime.substring(3, 5));

  // copy of date object for time difference
  const date1 = moment(new Date(date));

  for (let i = 0; i < 50; i++) {
    // time difference between previous and next dropdown option
    let duration = Math.round((date - date1) / 1000 / 60);
    let label = `(${duration} mins)`;

    if (i > 3) {
      const durationMin = duration / 60;
      label = `(${durationMin} ${durationMin > 1 ? "hrs" : "hr"})`;
    }

    timeList.push({
      label: `${moment(date.toISOString()).format("hh:mm A")} ${label}`,
      value: `${moment(date.toISOString()).format("hh:mm:ss")}`,
    });

    // first 5 options will have 15 mins difference afterwards 30 mins difference
    date.set("minute", date.get("minute") + (i < 4 ? 15 : 30));
  }

  return timeList;
}

export function getTimeDropdownOptions() {
  return [
    { label: "12:00 AM", value: "00:00:00" },
    { label: "12:15 AM", value: "00:15:00" },
    { label: "12:30 AM", value: "00:30:00" },
    { label: "12:45 AM", value: "00:45:00" },
    { label: "01:00 AM", value: "01:00:00" },
    { label: "01:15 AM", value: "01:15:00" },
    { label: "01:30 AM", value: "01:30:00" },
    { label: "01:45 AM", value: "01:45:00" },
    { label: "02:00 AM", value: "02:00:00" },
    { label: "02:15 AM", value: "02:15:00" },
    { label: "02:30 AM", value: "02:30:00" },
    { label: "02:45 AM", value: "02:45:00" },
    { label: "03:00 AM", value: "03:00:00" },
    { label: "03:15 AM", value: "03:15:00" },
    { label: "03:30 AM", value: "03:30:00" },
    { label: "03:45 AM", value: "03:45:00" },
    { label: "04:00 AM", value: "04:00:00" },
    { label: "04:15 AM", value: "04:15:00" },
    { label: "04:30 AM", value: "04:30:00" },
    { label: "04:45 AM", value: "04:45:00" },
    { label: "05:00 AM", value: "05:00:00" },
    { label: "05:15 AM", value: "05:15:00" },
    { label: "05:30 AM", value: "05:30:00" },
    { label: "05:45 AM", value: "05:45:00" },
    { label: "06:00 AM", value: "06:00:00" },
    { label: "06:15 AM", value: "06:15:00" },
    { label: "06:30 AM", value: "06:30:00" },
    { label: "06:45 AM", value: "06:45:00" },
    { label: "07:00 AM", value: "07:00:00" },
    { label: "07:15 AM", value: "07:15:00" },
    { label: "07:30 AM", value: "07:30:00" },
    { label: "07:45 AM", value: "07:45:00" },
    { label: "08:00 AM", value: "08:00:00" },
    { label: "08:15 AM", value: "08:15:00" },
    { label: "08:30 AM", value: "08:30:00" },
    { label: "08:45 AM", value: "08:45:00" },
    { label: "09:00 AM", value: "09:00:00" },
    { label: "09:15 AM", value: "09:15:00" },
    { label: "09:30 AM", value: "09:30:00" },
    { label: "09:45 AM", value: "09:45:00" },
    { label: "10:00 AM", value: "10:00:00" },
    { label: "10:15 AM", value: "10:15:00" },
    { label: "10:30 AM", value: "10:30:00" },
    { label: "10:45 AM", value: "10:45:00" },
    { label: "11:00 AM", value: "11:00:00" },
    { label: "11:15 AM", value: "11:15:00" },
    { label: "11:30 AM", value: "11:30:00" },
    { label: "11:45 AM", value: "11:45:00" },
    { label: "12:00 PM", value: "12:00:00" },
    { label: "12:15 PM", value: "12:15:00" },
    { label: "12:30 PM", value: "12:30:00" },
    { label: "12:45 PM", value: "12:45:00" },
    { label: "01:00 PM", value: "13:00:00" },
    { label: "01:15 PM", value: "13:15:00" },
    { label: "01:30 PM", value: "13:30:00" },
    { label: "01:45 PM", value: "13:45:00" },
    { label: "02:00 PM", value: "14:00:00" },
    { label: "02:15 PM", value: "14:15:00" },
    { label: "02:30 PM", value: "14:30:00" },
    { label: "02:45 PM", value: "14:45:00" },
    { label: "03:00 PM", value: "15:00:00" },
    { label: "03:15 PM", value: "15:15:00" },
    { label: "03:30 PM", value: "15:30:00" },
    { label: "03:45 PM", value: "15:45:00" },
    { label: "04:00 PM", value: "16:00:00" },
    { label: "04:15 PM", value: "16:15:00" },
    { label: "04:30 PM", value: "16:30:00" },
    { label: "04:45 PM", value: "16:45:00" },
    { label: "05:00 PM", value: "17:00:00" },
    { label: "05:15 PM", value: "17:15:00" },
    { label: "05:30 PM", value: "17:30:00" },
    { label: "05:45 PM", value: "17:45:00" },
    { label: "06:00 PM", value: "18:00:00" },
    { label: "06:15 PM", value: "18:15:00" },
    { label: "06:30 PM", value: "18:30:00" },
    { label: "06:45 PM", value: "18:45:00" },
    { label: "07:00 PM", value: "19:00:00" },
    { label: "07:15 PM", value: "19:15:00" },
    { label: "07:30 PM", value: "19:30:00" },
    { label: "07:45 PM", value: "19:45:00" },
    { label: "08:00 PM", value: "20:00:00" },
    { label: "08:15 PM", value: "20:15:00" },
    { label: "08:30 PM", value: "20:30:00" },
    { label: "08:45 PM", value: "20:45:00" },
    { label: "09:00 PM", value: "21:00:00" },
    { label: "09:15 PM", value: "21:15:00" },
    { label: "09:30 PM", value: "21:30:00" },
    { label: "09:45 PM", value: "21:45:00" },
    { label: "10:00 PM", value: "22:00:00" },
    { label: "10:15 PM", value: "22:15:00" },
    { label: "10:30 PM", value: "22:30:00" },
    { label: "10:45 PM", value: "22:45:00" },
    { label: "11:00 PM", value: "23:00:00" },
    { label: "11:15 PM", value: "23:15:00" },
    { label: "11:30 PM", value: "23:30:00" },
    { label: "11:45 PM", value: "23:45:00" },
    { label: "12:00 AM", value: "23:59:00" },
  ];
}

export function getUniqueTimeDropdownOptions() {
  const array = getTimeDropdownOptions();
  array.pop();
  return array;
}

export function compareTimes(time1, time2) {
  if (time1 === time2) return 0;

  let date1 = new Date();
  date1.setHours(time1.substring(0, 2));
  date1.setMinutes(time1.substring(3, 5));
  date1.setSeconds(time1.substring(6));
  let date2 = new Date();
  date2.setHours(time2.substring(0, 2));
  date2.setMinutes(time2.substring(3, 5));
  date2.setSeconds(time2.substring(6));

  if (date1 > date2) {
    if (time2 === "00:00:00") {
      return 1;
    }
    return -1;
  } else {
    return 1;
  }
}

export function arrayOfGivenElement(length, element) {
  let array = [];
  for (let i = 0; i < length; i++) {
    array.push(element);
  }
  return array;
}

export function allTrue(array) {
  for (let i = 0; i < array.length; i++) {
    if (!array[i]) return false;
  }
  return true;
}

export function atleastOneTrue(array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i]) return true;
  }
  return false;
}

export function formatDate(dateString) {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const date = dateString.substring(8, 10);
  return month + "/" + date + "/" + year;
}
export const getTimeAsDate = (hour, tzMoment) => {
  if (hour < 0 || hour > 23) {
    throw Error(
      `*${hour}* is an invalid 'hour' value, which must be between 0 and 23.`
    );
  }
  const m = tzMoment("1970-01-01");
  return new Date(m.hour(hour).minute(0).format());
};

export const convertDateTimeToDate = (datetime, tzMoment) => {
  return new Date(tzMoment(datetime).format()); // sets Date using ISO 8601 format
};

export const getNow = (now, tzMoment) => convertDateTimeToDate(now(), tzMoment);

export function parentAddedEntity(data) {
  return data.createdBy === "Parent";
}

export function isArchivedEntity(data) {
  return data.isArchive;
}

export function getTimeInMS(fileName) {
  let extIdx = fileName.indexOf(".");
  let ext = fileName.substring(extIdx);
  var d = new Date();
  var s = Date.UTC(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()
  );
  return fileName.substring(0, extIdx) + "_" + s.toString() + ext;
}

export function getPaymentAdverb(plan) {
  if (plan.indexOf("ly") === plan.length - 2) {
    return plan.toLowerCase();
  } else if (plan === "Free") {
    return "every 3-months";
  } else if (plan === "4-Day") {
    return plan.toLowerCase();
  } else {
    return plan.toLowerCase() + "ly";
  }
}

export function parseQueryParams(props) {
  return queryString.parse(props.location.search);
}

export function getSubscriptionPeriod(plan) {
  if (plan === PAYMENT_PLAN.MONTHLY) {
    return "month";
  } else if (plan === PAYMENT_PLAN.ANNUAL) {
    return "year";
  } else if (plan === PAYMENT_PLAN.DAILY) {
    return "day";
  } else {
    return "";
  }
}

export function hasValidPayment(userObj) {
  const accountsArray = userObj.accounts;
  const parentArray = accountsArray.filter(
    (account) => account.role === ROLE_TYPES.PARENT
  );
  const subscriptionStatus = parentArray[0].subscriptionStatus;
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED ||
    subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED
  ) {
    return false;
  }
  return true;
}

export function isValidTimeFormat(timeValue) {
  if (timeValue.length !== 8) return false;
  const comp = timeValue.split(" ");
  if (comp.length !== 2) return false;
  if (comp[1] !== "AM" && comp[1] !== "PM") return false;
  const timeComp = comp[0].split(":");
  if (timeComp.length !== 2) return false;
  if (timeComp[0].length !== 2 || timeComp[1].length !== 2) return false;
  if (isNaN(timeComp[0]) || isNaN(timeComp[1])) return false;
  return true;
}

export function isValidTime(timeValue) {
  const comp = timeValue.split(" ");
  const timeComp = comp[0].split(":");
  const hour = +timeComp[0];
  const minute = +timeComp[1];
  if (hour >= 1 && hour <= 12) {
    if (minute >= 0 && minute <= 59) {
      return true;
    }
  }
  return false;
}

export const parseValue = (value) => {
  try {
    const result = JSON.parse(value);
    return result;
  } catch (err) {
    // 👇️ This runs
    console.log("Error===: ", err.message);
  }
};

/** CHAT HELPERS */

export const getRecipientFromConversation = (conversation, user) => {
  return user?.id === conversation?.creator.id
    ? conversation?.recipient
    : conversation?.creator;
};

export const selectGroupById = (state, groupId) => {
  return state.groups.groups.find((group) => group.id === groupId);
};

export const isGroupOwner = (user, group) => user?.id === group?.owner.id;

export const getConversationUnread = (conversations = {}, userId) => {
  return conversations?.userConversations?.find(
    (conversation) => conversation?.user_id === userId
  );
};

export const getGroupUnread = (groups = {}, userId) => {
  return groups?.groupConversation?.find((group) => group?.user_id === userId);
};

export const getIdFromUrl = (history) => {
  return history.location.pathname.split("/").pop();
};

export function formatDateTime(date) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const diff = new Date().getTime() - date.getTime();
  const dayDiff = Math.floor(diff / (1000 * 3600 * 24));
  return dayDiff === 0
    ? "Today"
    : dayDiff === 1
    ? "Yesterday"
    : new Intl.DateTimeFormat("en-US", options).format(date);
}

export const setFormattedDate = (chatAt) => {
  const date = new Date(chatAt);
  const now = new Date();
  const isToday = now.toDateString() === date.toDateString();
  const isYesterday =
    now.toDateString() ===
    new Date(date.getTime() + 24 * 60 * 60 * 1000).toDateString();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const time = `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  return isToday
    ? `Today at ${time}`
    : isYesterday
    ? `Yesterday at ${time}`
    : `${date.toDateString()} at ${time}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

//mixpanel

const units = {
  s: {
    time: 10000,
  },
  m: {
    time: 1000 / 60,
  },
};

export const getTimeDifference = (startTime, endTime, unit = "s") => {
  let difference = endTime - startTime;
  const unitt = units[unit];

  console.log({ unitt });

  if (unit === "s") {
    difference = difference / 1000;
    return getRange(difference);
  } else if (unit === "m") {
    difference = difference / 1000 / 60;
    return getRange(difference, "m");
  }
};

const getRange = (time, unit = "s") => {
  if (time < 3) {
    return `less than 3${unit}`;
  } else if (time >= 3 && time <= 5) {
    return `between 3${unit} and 5${unit}`;
  } else {
    return `greater than 5${unit}`;
  }
};
