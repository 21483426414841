import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import NewGroupChat from "./NewGroupChat";
import NewChat from "./NewChat";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createConversation } from "../../store/actions/Chat/Conversations";
import { fetchRecipients } from "../../store/actions/Chat/Recipients";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "4rem 2rem",
    display: "flex",
    flexDirection: "column",
    outline: "none",
    maxWidth: "765px",
    width: "100%",
    borderRadius: "8px",
  },
  textArea: {
    display: "flex",
    flexDirection: "column",
    rowGap: "2rem",
  },
  createButton: {
    borderRadius: "20px",
    backgroundColor: "#032C61",
    color: "white",
    padding: "0.8rem 2.8rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  cancelButton: {
    borderRadius: "25.5px",
    backgroundColor: "white",
    border: "1px solid #979797",
    color: "#9E9E9E",
    padding: "0.8rem 2rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "18px",
  },
  buttonsDiv: {
    columnGap: "4px",
    flexDirection: "row-reverse",
    display: "flex",
    columnGap: "10px",
    marginTop: "auto",
  },
  spaceTitle: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "24px",
    color: "#003471",
  },
}));

export default function ChatModal({
  open,
  setOpen,
  modalType,
  conversationList,
}) {
  const classes = useStyles();
  const history = useHistory()

  const [selectedNewChatUser, setSelectedNewChatUser] = useState(null);
  const [selectedNewChatUserError, setSelectedNewChatUserError] =
    useState(null);
  const [newChatMessageError, setNewChatMessageError] = useState(null);
  const [userMessage, setUserMessage] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const childState = useSelector((state) => state.child);
  const externaluserState = useSelector((state) => state.externalUser);

  useEffect(() => {
    dispatch(fetchRecipients());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    let trimmedMessage = userMessage.trim();

    if (!selectedNewChatUser ) {
      if (!selectedNewChatUser) {
        setSelectedNewChatUserError(t("requiredField"));
      }
     
    } else {
      await dispatch(
        createConversation({
          userId: selectedNewChatUser?.user?.id,
          message: userMessage,
        }, history)
      );
      setOpen(false);
    }
  };

  const handleGroupSubmit = (e) => {
    // e.preventDefault();
    const trimmedMessage = userMessage.trim();

    // if (selectedRecipients.length === 0 || !trimmedMessage || !title) return;
    // const users = selectedRecipients.map((user) => user.username);
    // return dispatch(createGroupThunk({ title, users }))
    //   .unwrap()
    //   .then(({ data }) => {
    //     console.log(data);
    //     console.log("done");
    //     setShowModal(false);
    //     navigate(`/groups/${data.id}`);
    //   })
    //   .catch((err) => console.log(err));
  };

  const getChatUsersOptions = () => {
    const childOptions = childState.childrenList.map((user) => ({
      label: `${user.childFirstName} ${user.childLastName}`,
      user: user,
    }));

    const otherChildOptions =
      childState.otherChildrenList.map((otherChild) => {
        return otherChild.childProfiles.map((profile) => {
          return {
            label: `${profile.childFirstName} ${profile.childLastName}`,
            user: profile,
          };
        });
      })[0] || [];

    const externalUserOptions = externaluserState.externalUsersList.map(
      (user) => ({
        label: user.userAlias,
        user: user,
      })
    );

    let newChatUsers = [
      ...childOptions,
      ...otherChildOptions,
      ...externalUserOptions,
    ];

    const filteredNewChatUsers = newChatUsers.filter(
      (user) =>
        !conversationList.some(
          (conversation) => user.user.childId === conversation.recipient.id
        )
    );
    return filteredNewChatUsers;
  };
  const renderModalContents = () => {
    if (modalType === "group") {
      return (
        <>
          <h4 className={classes.spaceTitle}>Create a Group</h4>
          <NewGroupChat handleSubmit={handleGroupSubmit} setOpen={setOpen} />
        </>
      );
    } else if (modalType === "one-to-one") {
      return (
        <>
          <h4 className={classes.spaceTitle}>Create New Chat</h4>
          <NewChat
            getChatUsersOptions={getChatUsersOptions()}
            setSelectedNewChatUser={setSelectedNewChatUser}
            selectedNewChatUserError={selectedNewChatUserError}
            setSelectedNewChatUserError={setSelectedNewChatUserError}
            newChatMessageError={newChatMessageError}
            setNewChatMessageError={setNewChatMessageError}
            setUserMessage={setUserMessage}
            userMessage={userMessage}
          />
        </>
      );
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {modalType ? (
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.textArea}>{renderModalContents()}</div>

              {modalType === "one-to-one" && (
                <div className={classes.buttonsDiv}>
                  <Button
                    variant="contained"
                    className={classes.createButton}
                    onClick={
                      modalType === "one-to-one"
                        ? () => handleSubmit()
                        : () => {}
                    }
                  >
                    Create
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.cancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </Fade>
        ) : null}
      </Modal>
    </>
  );
}
