import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, InputLabel, Input, FormHelperText, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from "@material-ui/core";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AssetConstants from "../../constants/AssetConstants";
import RouteConstants from "../../constants/RouteConstants";
import { IMAGE_TYPES, ActivityColor, SCREEN_TYPE } from "../../constants/GenericConstants";
import { validateMandatoryValue, validateLength18C } from "../../helpers/Validation";
import { clearImage, saveData, addActivity, clearActivityData, getColorList } from "../../store/actions/Activities";
import Loader from '../../components/Loader/Loader';
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";
import { MIX_PANEL_EVENTS } from "../../constants/MixPanelConstants";
import { Mixpanel } from "../../lib/mixpanleEvents";
import { getTimeDifference } from "../../helpers/GenericHelper";
 
const AddActivity = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const activitiesState = useSelector((state) => state.activities);
    const childState = useSelector((state) => state.child);
    const { user } = useSelector((state) => state.authentication);

    const startTime = useRef(new Date().getTime());

    const [activityName, setActivityName] = useState("");
    const [activityNameError, setActivityNameError] = useState("");
    const [activityImageError, setActivityImageError] = useState("");
    const [indexGot, setIndexGot] = useState();


    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);


    const [activityColors, setActivityColors] = useState([]);

    const [activityColorError, setActivityColorError] = useState("");

    const [activityTaskName, setActivityTaskName] = useState("");
    const [activityTaskNameError, setActivityTaskNameError] = useState("");
    const [activityTaskImageError, setActivityTaskImageError] = useState("");
    const [subTaskAdded, setSubTaskAdded] = useState(false);

    const [subTasksList, setSubTasksList] = useState([]);

    const [addSuccess, setAddSuccess] = useState(false);
    const [addError, setAddError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const data = activitiesState.tempActivityData;
        dispatch(getColorList());
        if (Object.keys(data).length > 0) {
            setActivityName(data.activityName);
            setActivityNameError(data.activityNameError);
            setActivityColors(data.activityColors);
            setActivityColorError(data.activityColorError);
            setActivityTaskName(data.activityTaskName);
            setSubTasksList(data.subTasksList);
        }
    }, []); 

    useEffect(() => {
        setActivityColors(activitiesState.activityColorList);
    }, [activitiesState.activityColorList])

    const handleCloseDeleteDialog = (index) => {
        setDeleteDialogOpen(false);
    }

    const handleActivityNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setActivityName(input);
        validateActivityName(input);
    }

    const validateActivityName = (activityName) => {
        if (!validateMandatoryValue(activityName)) {
            setActivityNameError(t("activityNameRequiredError"));
            return false;
        } else if (!validateLength18C(activityName)) {
            setActivityNameError(t("activityNameLengthError"));
            return false;
        } else {
            setActivityNameError("");
            return true;
        }
    }

    const validateActivityImage = () => {
        if (!validateMandatoryValue(activitiesState.activityImage.imageURL)) {
            setActivityImageError(t("activityImageRequiredError"));
            return false;
        } else {
            setActivityImageError("");
            return true;
        }
    }

    const showInputError = (field) => {
        return field.length > 0;
    }

    const selectColor = (index) => {
        const colorsArray = activityColors.map((color, currentIndex) => {
            let selected = false;
            if (currentIndex === index) {
                selected = true;
            }
            return {
                ...color,
                selected: selected,
            };
        });
        setActivityColors(colorsArray);
        validateColor(colorsArray);
    }

    const validateColor = (colorsArray) => {
        const selectedColor = colorsArray.filter((color) => color.selected);
        if (selectedColor.length > 0) {
            setActivityColorError("");
            return true;
        } else {
            setActivityColorError(t("activityColorRequiredError"));
            return false;
        }
    }

    const handleActivityTaskNameChange = (event) => {
        const input = event.target && event.target.value ? event.target.value : "";
        setActivityTaskName(input);
        if (subTaskAdded) {
            validateActivityTaskName(input);
        }
    }

    const validateActivityTaskName = (activityTaskName) => {
        if (!validateMandatoryValue(activityTaskName)) {
            setActivityTaskNameError(t("activityTaskNameRequiredError"));
            return false;
        } else if (!validateLength18C(activityTaskName)) {
            setActivityTaskNameError(t("activityTaskNameLengthError"));
            return false;
        } else {
            setActivityTaskNameError("");
            return true;
        }
    }

    const validateSubTaskImage = () => {
        if (!validateMandatoryValue(activitiesState.subTaskImage.imageURL)) {
            setActivityTaskImageError(t("activityTaskImageRequiredError"));
            return false;
        } else {
            setActivityTaskImageError("");
            return true;
        }
    }

    const moveToImageLibrary = (imageType) => {
        const dataObject = {
            activityName: activityName,
            activityNameError: activityNameError,
            activityColors: activityColors,
            activityColorError: activityColorError,
            activityTaskName: activityTaskName,
            subTasksList: subTasksList,
        };
        dispatch(saveData(dataObject));
        history.push({ pathname: RouteConstants.IMAGE_LIBRARY, imageType: imageType, screenType: SCREEN_TYPE.ADD });
    }

    const clearSubTaskData = () => {
        setActivityTaskName("");
        setActivityTaskNameError("");
        setActivityTaskImageError("");
        dispatch(clearImage(IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE, SCREEN_TYPE.ADD));
    }

    const addSubTask = () => {
        setSubTaskAdded(true);
        if (validateActivityTaskName(activityTaskName) & validateSubTaskImage()) {
            const item = {
                name: activityTaskName,
                image: activitiesState.subTaskImage,
                order: subTasksList.length === 0 ? 1 : subTasksList[subTasksList.length - 1].order + 1,

            };
            setSubTasksList([...subTasksList, item]);
            clearSubTaskData();
            setSubTaskAdded(false);
        }
    }

    const deleteSubtask = (index) => {
        const subtasks = [...subTasksList];
        subtasks.splice(index, 1);
        setSubTasksList(subtasks);
    }

    const isFormValid = () => {
        return validateActivityName(activityName) & validateColor(activityColors) & validateActivityImage();
    }

    const onSuccess = (data) => {
        setAddSuccess(true);
    
        const endTime = new Date().getTime();
        const duration = getTimeDifference(startTime.current, endTime);

        Mixpanel.track(MIX_PANEL_EVENTS.ACTIVITY_CREATED, {
            [MIX_PANEL_EVENTS.ACTIVITY_CREATION_TIME_SPENT]: duration,
            [MIX_PANEL_EVENTS.SUBTASKS_COUNT]: data.subTasks.length,
            [MIX_PANEL_EVENTS.USER_TYPE]: user.userType,
          });
          Mixpanel.increment({
            [MIX_PANEL_EVENTS.ACTIVITY_COUNT]: 1,
            [MIX_PANEL_EVENTS.SUBTASKS_COUNT]: data.subTasks.length,
          });

        setTimeout(() => {
            history.goBack();
            dispatch(clearActivityData());
        }, 3000);
    }

    const onFailure = () => {
        setAddError(true);
    }

    const onCreatePress = () => {
        if (isFormValid()) {
            const payload = {
                name: activityName,
                imageId: activitiesState.activityImage.id,
                color: activityColors.filter((color) => color.selected)[0].value,
                accountId: childState.selectedChild.accountId,
                childId: childState.selectedChild.childId,
                subTasks: subTasksList.map((subtask, index) => { return { name: subtask.name, imageId: subtask.image.id, order: index + 1 }; }),
            };
            dispatch(addActivity(payload, onSuccess, onFailure));
        }
    }

    const handleAddSuccessClose = () => {
        setAddSuccess(false);
    }

    const handleAddErrorClose = () => {
        setAddError(false);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const goToActivitiesList = () => {
        history.goBack();
        dispatch(clearActivityData());
    }


    const checkChanged = () => {
        let changed = false;
        let colorChanged = false;
        activityColors.map(col => {
            if (col.selected === true) {
                colorChanged = true
            }
        })

        if (activityName !== "" || subTasksList.length !== 0 || colorChanged || activitiesState.activityImage.imageURL !== undefined || activitiesState.subTaskImage.imageURL !== undefined || activityTaskName !== "") {
            changed = true
        }
        return changed;
    }

    return (
        <div>
            {activitiesState.loading && <Loader />}
            <SnackbarAlert show={addSuccess} message={t("activityCreatedSuccessMessage")} duration={3000} onCloseAlert={handleAddSuccessClose} />
            <SnackbarAlert isError={true} show={addError} message={t("unknownError")} duration={5000} onCloseAlert={handleAddErrorClose} />


            <Dialog maxWidth="xs" open={deleteDialogOpen} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDeleteDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDeleteDialog} color="primary">{t("no")}</Button>
                    <Button variant="contained" onClick={() => { deleteSubtask(indexGot); handleCloseDeleteDialog(); }} color="primary">{t("yes")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="xs" open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <CloseIcon className="popup-close" onClick={handleCloseDialog} />
                <DialogTitle id="alert-dialog-title">{t("performActionConfirmHeading")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("informationLostMessage")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={goToActivitiesList} color="primary">{t("leavePage")}</Button>
                    <Button variant="contained" onClick={handleCloseDialog} color="primary">{t("cancel")}</Button>
                </DialogActions>
            </Dialog>
            <Card className="no-style activity-section">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="generic-card">
                            <div className="activity-listing-two-box-container">
                                <div className="col-left">
                                    <h3>{t("createNewActivity")}</h3>
                                    <div className="form-field">
                                        <InputLabel htmlFor="activityName" required>{t("activityName")}</InputLabel>
                                        <Input id="activityName" name="activityName" required type="text" value={activityName || ""} placeholder={t("activityNamePlaceholder")} onChange={handleActivityNameChange} error={showInputError(activityNameError)} />
                                        {
                                            showInputError(activityNameError) && <FormHelperText error={showInputError(activityNameError)}>{activityNameError}</FormHelperText>
                                        }
                                    </div>
                                    {
                                        activitiesState.activityImage.imageURL ?
                                            <div className="ImgBox">
                                                <span className="camera-icon"><CameraAltIcon onClick={() => moveToImageLibrary(IMAGE_TYPES.ACTIVITY_IMAGE)} /></span>
                                                <img src={AssetConstants.applewatch} alt="Apple Watch Image" />
                                                {
                                                    activitiesState.activityImage.imageURL &&
                                                    <img alt="Activity Image" src={activitiesState.activityImage.imageURL} className="m-icon" />
                                                }
                                            </div> :
                                            <div className="ImgBox">
                                                <img src={AssetConstants.applewatchgray} alt="Apple Watch Image" />
                                                <Button onClick={() => moveToImageLibrary(IMAGE_TYPES.ACTIVITY_IMAGE)} className="selectImageBtn">{t("selectImage")}</Button>
                                            </div>

                                    }
                                    {
                                        showInputError(activityImageError) && <FormHelperText error={showInputError(activityImageError)}>{activityImageError}</FormHelperText>
                                    }
                                    <div className="mt-15">
                                        <InputLabel required>{t("assignColorToActivity")}</InputLabel>
                                        <div className="colorChanger">
                                            {
                                                activityColors.map((color, index) => {
                                                    if (color.selected) {
                                                        return <div className="cc-oval-selected" style={{ backgroundColor: color.value }} onClick={() => selectColor(index)}>
                                                            <span></span>
                                                        </div>;
                                                    }
                                                    return <div className="cc-oval" style={{ backgroundColor: color.value }} onClick={() => selectColor(index)}></div>;
                                                })
                                            }
                                        </div>
                                        {
                                            showInputError(activityColorError) && <FormHelperText error={showInputError(activityColorError)}>{activityColorError}</FormHelperText>
                                        }
                                    </div>
                                </div>
                                <div className="col-right">
                                    {subTasksList.length < 4 && <div>
                                        <h3>{t("addSubTasksToActivityHeading")}<span> - {t("optional")}</span></h3>
                                        <div className="activityTask">
                                            {
                                                activitiesState.subTaskImage.imageURL ?
                                                    <img src={activitiesState.subTaskImage.imageURL} alt="Sub task image" onClick={() => moveToImageLibrary(IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE)} /> :
                                                    <img src={AssetConstants.oval} alt="Sub task image" onClick={() => moveToImageLibrary(IMAGE_TYPES.ACTIVITY_SUBTASK_IMAGE)} />
                                            }
                                            <div className="form-field">
                                                <InputLabel htmlFor="activityTaskName">{t("activityTaskName")}</InputLabel>
                                                <Input id="activityTaskName" name="activityTaskName" required type="text" value={activityTaskName || ""} placeholder={t("activityTaskNamePlaceholder")} onChange={handleActivityTaskNameChange} error={showInputError(activityTaskNameError)} />
                                                {
                                                    showInputError(activityTaskNameError) && <FormHelperText error={showInputError(activityTaskNameError)}>{activityTaskNameError}</FormHelperText>
                                                }
                                            </div>
                                        </div>
                                        {
                                            showInputError(activityTaskImageError) && <FormHelperText error={showInputError(activityTaskImageError)}>{activityTaskImageError}</FormHelperText>
                                        }
                                        <div className="addBtn">
                                            <Button onClick={addSubTask}>{t("add")}</Button>
                                        </div>
                                    </div>
                                    }
                                    <div className="sub-tasks-list-added">
                                        <h4>{t("addedSubTasksList")}</h4>
                                        {
                                            subTasksList.length > 0 ?
                                                <div className="scroll-div">
                                                    {
                                                        subTasksList.sort((a, b) => a.order - b.order).map((subTask, index) => {
                                                            return <div className="d-flex align-items-center mb-10">
                                                                <img src={subTask.image.imageURL} alt="Sub task image" />
                                                                <span>{subTask.name}</span>
                                                                <div className="edit-delete">
                                                                    <i className="ticon-trash-line" onClick={() => { setIndexGot(index); setDeleteDialogOpen(true) }}></i>
                                                                </div>

                                                                <Button className="arrow-btn"
                                                                    disabled={index === 0}
                                                                    onClick={() => {
                                                                        let tempSubtasks = subTasksList;
                                                                        let oldOrder = tempSubtasks[index].order
                                                                        tempSubtasks[index].order = tempSubtasks[index - 1].order
                                                                        tempSubtasks[index - 1].order = oldOrder
                                                                        // alert("Old " + tempSubtasks[index].order + "-- New" + tempSubtasks[index - 1].order)
                                                                        let onlyorders = subTasksList.map(s => { return { name: s.name, order: s.order } })
                                                                        // alert(JSON.stringify(onlyorders))
                                                                        setSubTasksList([...tempSubtasks])

                                                                    }}><KeyboardArrowUpIcon /></Button>
                                                                <Button className="arrow-btn"
                                                                    disabled={(subTasksList.length !== 0 && index === subTasksList.length - 1) || subTasksList.length === 0}
                                                                    onClick={() => {
                                                                        let tempSubtasks = subTasksList;
                                                                        let oldOrder = tempSubtasks[index].order
                                                                        tempSubtasks[index].order = tempSubtasks[index + 1].order
                                                                        tempSubtasks[index + 1].order = oldOrder
                                                                        // alert("Old " + tempSubtasks[index].order + "-- New" + tempSubtasks[index + 1].order)
                                                                        let onlyorders = subTasksList.map(s => { return { name: s.name, order: s.order } })
                                                                        // alert(JSON.stringify(onlyorders))
                                                                        setSubTasksList([...tempSubtasks])
                                                                    }}
                                                                ><KeyboardArrowDownIcon /></Button>
                                                            </div>;
                                                        })
                                                    }
                                                </div> :
                                                <div>
                                                    <span>{t("noSubtasksAdded")}</span>
                                                </div>
                                        }
                                    </div>
                                    <div className="activityFooterBtns">
                                        <div className="buttons-footer">
                                            <Button onClick={() => {
                                                if (checkChanged()) {
                                                    setDialogOpen(true)
                                                }
                                                else {
                                                    goToActivitiesList()
                                                }
                                            }}>{t("cancel")}</Button>
                                            <Button onClick={onCreatePress} color="primary" variant="contained">{t("create")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default AddActivity;