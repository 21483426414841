import { GET_IMAGE_CATEGORIES_ACTIONS, GET_IMAGE_SKINTONES_ACTIONS, GET_IMAGE_LIBRARY_ACTIONS, IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS, IMAGE_LIBRARY_UPLOAD_ACTIONS, IMAGE_LIBRARY_ADD_IMAGE_ACTIONS, IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS, IMAGE_LIBRARY_LOADER_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    secondLoading: false,
    imageCategories: [],
    imageSkintones: [],
    imagesList: [],
    metaImagesList: {},
    addedImage: {}
};

const imageLibrary = (state = initialState, action) => {
    switch (action.type) {
        case GET_IMAGE_CATEGORIES_ACTIONS.GET_IMAGE_CATEGORIES:
            return {
                ...state,
                loading: true,
            };

        case GET_IMAGE_CATEGORIES_ACTIONS.GET_IMAGE_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                imageCategories: action.payload.data,
            };

        case GET_IMAGE_CATEGORIES_ACTIONS.GET_IMAGE_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        //-----------------

        case GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES:
            return {
                ...state,
                loading: true,
            };

        case GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_SUCCESS:
            return {
                ...state,
                loading: false,
                imageSkintones: action.payload.data,
            };

        case GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        //-----------------

        case GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_CLEAR_DATA:
            return {
                ...state,
                imagesList: [],
            };

        case GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY:
            return {
                ...state,
                secondLoading: true,
            };

        case GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_FULL_LOAD:
            return {
                ...state,
                loading: true,
            };

        case GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_SUCCESS:
            return {
                ...state,
                loading: false,
                secondLoading: false,
                imagesList: action.payload.data.meta.page > 1 ? [...state.imagesList, ...action.payload.data.data] : action.payload.data.data,
                metaImagesList: action.payload.data.meta,
            };

        case GET_IMAGE_LIBRARY_ACTIONS.GET_IMAGE_LIBRARY_FAILURE:
            return {
                ...state,
                loading: false,
                secondLoading: false,
            };

        case IMAGE_LIBRARY_LOADER_ACTIONS.SHOW_LOADER:
            return {
                ...state,
                loading: true,
            };

        case IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS.GET_PRE_SIGNED_URL:
            return {
                ...state,
                loading: true,
            };

        case IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS.GET_PRE_SIGNED_URL_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case IMAGE_LIBRARY_PRE_SIGNED_URL_ACTIONS.GET_PRE_SIGNED_URL_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case IMAGE_LIBRARY_UPLOAD_ACTIONS.UPLOAD_IMAGE:
            return {
                ...state,
                loading: true,
            };

        case IMAGE_LIBRARY_UPLOAD_ACTIONS.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case IMAGE_LIBRARY_UPLOAD_ACTIONS.UPLOAD_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case IMAGE_LIBRARY_ADD_IMAGE_ACTIONS.ADD_IMAGE:
            return {
                ...state,
                loading: true,
            };

        case IMAGE_LIBRARY_ADD_IMAGE_ACTIONS.ADD_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                addedImage: action.payload.data,
                imagesList: [action.payload.data, ...state.imagesList],
            };

        case IMAGE_LIBRARY_ADD_IMAGE_ACTIONS.ADD_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS.UPDATE_IMAGE:
            return {
                ...state,
                loading: true,
            };

        case IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS.UPDATE_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case IMAGE_LIBRARY_UPDATE_IMAGE_ACTIONS.UPDATE_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
            };


        default:
            return state;
    }
};

export default imageLibrary;
