import React, { useEffect } from "react";
import "./App.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Dashboard from "../Dashboard/Dashboard";
import { Grid, Container } from "@material-ui/core";
// import ChatService from "../../services/api/ChatService";

const App = () => {
  document.title = "Thrive App - Dashboard";
  return (
    <Grid container className="app">
      <Grid xs={12} item className="app-header">
        <Header />
      </Grid>
      <Grid item xs={12} className="grid-body">
        <Container maxWidth="xl">
          {/* <Sidebar /> */}
          <Dashboard />
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="xl">
          <Grid container spacing={6} className="my-0">
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default App;
