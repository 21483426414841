import React from "react";
import { Route, Redirect } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

export const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LOGGED_IN) && localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) && localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ?
                    <Redirect to={{ pathname: RouteConstants.DASHBOARD, state: { from: props.location } }} /> :
                    <Component {...props} {...rest} />;
            }}
        />
    );
};