import React from "react";
import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import moment from "moment";

import { StyledBadge } from "./style";
import GroupAvatar from "./GroupAvatar";
import MessageUnreadIcon from "../conversation/MessageUnreadIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllGroups,
  groupMarkedAsRead,
} from "../../../../store/actions/Chat/Group";
import { getIdFromUrl } from "../../../../helpers/GenericHelper";

// import "./index.module.scss";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    color: "#333",
  },
  primaryText: {
    fontSize: "14px",
    fontWeight: 600,
  },
  secondaryText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  lastMessageTime: {
    fontSize: "10px",
  },
}));

export const GroupSidebarItem = ({ group, onContextMenu }) => {
  const { id: groupId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authentication.user);

  const MAX_TITLE_LENGTH = 20;
  const MAX_MESSAGE_LENGTH = 50;

  const getTransformedTitle = () => {
    //TODO: need users list if there is no title
    if (!group.title) {
      const usersToString = group?.users
        ?.map((user) => user.firstName)
        .join(", ");
      return usersToString?.length > MAX_TITLE_LENGTH
        ? usersToString.slice(0, MAX_TITLE_LENGTH).concat("...")
        : usersToString;
    }
    return group.title.length > MAX_TITLE_LENGTH
      ? group.title.slice(0, MAX_TITLE_LENGTH).concat("...")
      : group.title;
  };

  const handleClick = async () => {
    history.push({ pathname: `/group/${group.id}` });

    await dispatch(groupMarkedAsRead(group.id));
  };

  const unReadCount = group?.groupConversation?.find(
    (gr) => gr?.user_id === user?.id
  );

  const hasAnyUserProfilePic = (users = []) => {
    return users.every(
      (user) => user.photo !== 0 && user.photo.includes("http")
    );
  };

  return (
    <List component="div" disablePadding>
      <ListItem
        button
        // selected={groupId === group.id}
        selected={getIdFromUrl(history) === group.id}
        onClick={handleClick}
      >
        <GroupAvatar users={group?.users || []} />

        {/* <ListItemAvatar>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          >
            <Avatar alt={getTransformedTitle(group)} src={group.avatar || ""}>
              <GroupIcon />
            </Avatar>
          </StyledBadge>
        </ListItemAvatar> */}

        <ListItemText
          id={group.id}
          disableTypography={true}
          primary={
            <Typography className={`${classes.text} ${classes.primaryText}`}>
              {getTransformedTitle(group)}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle1"
              className={`${classes.text} ${classes.secondaryText}`}
            >
              {`Chatted ${moment(group?.lastMessageSentAt).fromNow()}`}
            </Typography>
          }
        />

        <ListItemSecondaryAction>
          <MessageUnreadIcon label={unReadCount?.unreadCount} />

          <Typography
            variant="h6"
            className={`${classes.text} ${classes.lastMessageTime}`}
          >
            {moment(group?.lastMessageSentAt).format("HH:mm")}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  // return (
  //   <ConversationSidebarItemStyle
  //     onClick={() => navigate(`/groups/${group.id}`)}
  //     onContextMenu={(e) => onContextMenu(e, group)}
  //     selected={parseInt(id!) === group.id}
  //   >
  //     {group.avatar ? (
  //       <img
  //         src={CDN_URL.BASE.concat(group.avatar)}
  //         alt="avatar"
  //         className={styles.groupAvatar}
  //       />
  //     ) : (
  //       <div className={styles.defaultGroupAvatar}>
  //         <PeopleGroup size={28} />
  //       </div>
  //     )}
  //     <div>
  //       <span className="title">{getTransformedTitle()}</span>
  //       <span className={styles.groupLastMessage}>
  //         {group.lastMessageSent?.content}
  //       </span>
  //     </div>
  //   </ConversationSidebarItemStyle>
  // );
};

GroupSidebarItem.propTypes = {};
