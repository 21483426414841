import { LOGIN_ACTIONS, SIGN_UP_ACTIONS, CONFIRM_EMAIL_ACTIONS, SIGN_OUT_ACTIONS, GET_EMAIL_FROM_INVITE_CODE_ACTIONS, RESEND_CONFIRMATION_EMAIL_ACTIONS, RESET_PASSWORD_ACTIONS, FORGOT_RESET_ACTIONS, VIEW_USER_ACTIONS, EDIT_USER_ACTIONS, GET_ALL_COUNTRIES_ACTIONS, GET_GENDER_LIST_ACTIONS, GET_COUNTRY_STATES_ACTIONS, GET_STATE_CITIES_ACTIONS, JUST_LOADING_ACTIONS, FIRST_LOGIN_ACTIONS, GET_SUBSCRIPTION_LIST_ACTIONS, CREATE_PAYMENT_SESSION_ACTIONS, CANCEL_SUBSCRIPTION_ACTIONS, UPDATE_PAYMENT_CARD_ACTIONS, RESUBSCRIBE_PAYMENT_ACTIONS, UPDATE_USER_ACTIONS, GET_HEAR_ABOUT_US_OPTIONS } from "../../constants/ActionConstants";
import { LOCAL_STORAGE_KEYS, ROLE_TYPES, USER_TYPE } from '../../constants/GenericConstants';
import { convertStringIntoBoolean } from "../../helpers/GenericHelper";

const userDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) : {};
const isExternalUserBoolean = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_EXTERNAL_USER) ? convertStringIntoBoolean(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_EXTERNAL_USER)) : false;
const isParentBoolean = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_PARENT) ? convertStringIntoBoolean(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_PARENT)) : false;
const isSingleUserBoolean = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_SINGLE_USER) ? convertStringIntoBoolean(localStorage.getItem(LOCAL_STORAGE_KEYS.IS_SINGLE_USER)) : false;

let initialState = {
  loading: false,
  isExternalUser: isExternalUserBoolean,
  isParent: isParentBoolean,
  user: userDetails,
  countriesLoading: false,
  countriesList: [],
  genderList: [],
  statesLoading: false,
  statesList: [],
  citiesLoading: false,
  citiesList: [],
  subscriptionList: [],
  hearAboutUsLoading: false,
  hearAboutUsList: [],
  isSingleUser: isSingleUserBoolean
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.LOGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        isExternalUser: action.payload.isExternalUser,
        isParent: action.payload.isParent,
        isSingleUser: action.payload.isSingleUser
      };

    case LOGIN_ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SIGN_UP_ACTIONS.SIGN_UP:
      return {
        ...state,
        loading: true,
      };

    case SIGN_UP_ACTIONS.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        isSingleUser: action.payload.isSingleUser
      };

    case SIGN_UP_ACTIONS.SIGN_UP_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case VIEW_USER_ACTIONS.VIEW_USER:
      return {
        ...state,
        loading: true,
      };
    case VIEW_USER_ACTIONS.VIEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VIEW_USER_ACTIONS.VIEW_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case EDIT_USER_ACTIONS.EDIT_USER:
      return {
        ...state,
        loading: true,
      };
    case EDIT_USER_ACTIONS.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,

      };
    case EDIT_USER_ACTIONS.EDIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CONFIRM_EMAIL_ACTIONS.CONFIRM_EMAIL:
      return {
        ...state,
        loading: true,
      };

    case CONFIRM_EMAIL_ACTIONS.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CONFIRM_EMAIL_ACTIONS.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case FORGOT_RESET_ACTIONS.FORGOT_RESET:
      return {
        ...state,
        loading: true,
      };

    case FORGOT_RESET_ACTIONS.FORGOT_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case FORGOT_RESET_ACTIONS.FORGOT_RESET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SIGN_OUT_ACTIONS.SIGN_OUT:
      return {
        ...state,
        loading: true,
      };

    case SIGN_OUT_ACTIONS.SIGN_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {},
        isExternalUser: false,
        isSingleUser: false
      };

    case SIGN_OUT_ACTIONS.SIGN_OUT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_EMAIL_FROM_INVITE_CODE_ACTIONS.GET_EMAIL_FROM_INVITE_CODE:
      return {
        ...state,
        loading: true,
      };

    case GET_EMAIL_FROM_INVITE_CODE_ACTIONS.GET_EMAIL_FROM_INVITE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GET_EMAIL_FROM_INVITE_CODE_ACTIONS.GET_EMAIL_FROM_INVITE_CODE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case RESEND_CONFIRMATION_EMAIL_ACTIONS.RESEND_CONFIRMATION_EMAIL:
      return {
        ...state,
        loading: true,
      };

    case RESEND_CONFIRMATION_EMAIL_ACTIONS.RESEND_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case RESEND_CONFIRMATION_EMAIL_ACTIONS.RESEND_CONFIRMATION_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case RESET_PASSWORD_ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        isExternalUser: action.payload.isExternalUser,
        isParent: action.payload.isParent,
        isSingleUser: action.payload.isSingleUser
      };

    case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES:
      return {
        ...state,
        countriesLoading: true,
      };

    case GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesLoading: false,
        countriesList: action.payload.data,
      };

    case GET_ALL_COUNTRIES_ACTIONS.GET_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        countriesLoading: false,
        countriesList: [],
      };

    case GET_GENDER_LIST_ACTIONS.GET_GENDER_LIST:
      return {
        ...state,
        genderListLoading: true,
      };

    case GET_GENDER_LIST_ACTIONS.GET_GENDER_LIST_SUCCESS:
      return {
        ...state,
        genderListLoading: false,
        genderList: action.payload.data,
      };

    case GET_GENDER_LIST_ACTIONS.GET_GENDER_LIST_FAILURE:
      return {
        ...state,
        genderListLoading: false,
        genderListList: [],
      };

    case GET_HEAR_ABOUT_US_OPTIONS.GET_HEAR_ABOUT_US_OPTIONS:
      return {
        ...state,
        hearAboutUsLoading: true,
      };

    case GET_HEAR_ABOUT_US_OPTIONS.GET_HEAR_ABOUT_US_OPTIONS_SUCCESS:
      return {
        ...state,
        hearAboutUsLoading: false,
        hearAboutUsList: action.payload.data,
      };

    case GET_HEAR_ABOUT_US_OPTIONS.GET_HEAR_ABOUT_US_OPTIONS_FAILURE:
      return {
        ...state,
        hearAboutUsLoading: false,
        hearAboutUsList: [],
      };

    case GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES:
      return {
        ...state,
        statesLoading: true,
        statesList: [],
        citiesList: [],
      };

    case GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES_SUCCESS:
      return {
        ...state,
        statesLoading: false,
        statesList: action.payload.data,
      };

    case GET_COUNTRY_STATES_ACTIONS.GET_COUNTRY_STATES_FAILURE:
      return {
        ...state,
        statesLoading: false,
        statesList: [],
      };

    case GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES:
      return {
        ...state,
        citiesLoading: true,
        citiesList: [],
      };

    case GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES_SUCCESS:
      return {
        ...state,
        citiesLoading: false,
        citiesList: action.payload.data,
      };

    case GET_STATE_CITIES_ACTIONS.GET_STATE_CITIES_FAILURE:
      return {
        ...state,
        citiesLoading: false,
        citiesList: [],
      };


    case JUST_LOADING_ACTIONS.JUST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case JUST_LOADING_ACTIONS.JUST_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case JUST_LOADING_ACTIONS.JUST_LOADING_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case FIRST_LOGIN_ACTIONS.FIRST_LOGIN:
      return {
        ...state,
        loading: true,
      };

    case FIRST_LOGIN_ACTIONS.FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        user: { ...state.user, loggedBefore: true },
        loading: false,
      };

    case FIRST_LOGIN_ACTIONS.FIRST_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBSCRIPTION_LIST_ACTIONS.GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBSCRIPTION_LIST_ACTIONS.GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionList: action.payload.data,
      };

    case GET_SUBSCRIPTION_LIST_ACTIONS.GET_SUBSCRIPTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CREATE_PAYMENT_SESSION_ACTIONS.CREATE_PAYMENT_SESSION:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PAYMENT_SESSION_ACTIONS.CREATE_PAYMENT_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CREATE_PAYMENT_SESSION_ACTIONS.CREATE_PAYMENT_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CANCEL_SUBSCRIPTION_ACTIONS.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };

    case CANCEL_SUBSCRIPTION_ACTIONS.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CANCEL_SUBSCRIPTION_ACTIONS.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_PAYMENT_CARD_ACTIONS.UPDATE_PAYMENT_CARD:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PAYMENT_CARD_ACTIONS.UPDATE_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_PAYMENT_CARD_ACTIONS.UPDATE_PAYMENT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case RESUBSCRIBE_PAYMENT_ACTIONS.RESUBSCRIBE_PAYMENT:
      return {
        ...state,
        loading: true,
      };

    case RESUBSCRIBE_PAYMENT_ACTIONS.RESUBSCRIBE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case RESUBSCRIBE_PAYMENT_ACTIONS.RESUBSCRIBE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_USER_ACTIONS.UPDATE_USER:
      return {
        ...state,
        user: action.payload.data,
      };

    case UPDATE_USER_ACTIONS.SAVE_EMAIL:
      return {
        ...state,
        user: { emailAddress: action.payload.data },
      };

    case UPDATE_USER_ACTIONS.UPDATE_STATUS:
      let details = state.user.accounts;
      details.map(d => {
        if (d.role === ROLE_TYPES.PARENT) {
          d.status = 3
        }
      })

      return {
        ...state,
        user: { ...state.user, accounts: details },
      };

    default:
      return state;
  }
};

export default authentication;
